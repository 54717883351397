import { DocumentDto, ModelSchemaNames } from "@masta/generated-model";
import { ref, Ref, watch } from "vue";
import { DocumentError, DocumentModel, DocumentSort, getDocumentDtoId } from "@/components/Documents/DocumentModel";
import { useLocalStorage } from "@vueuse/core";

export type ModelSchemaName = Extract<keyof typeof ModelSchemaNames, string> | string;

export interface IDocumentProvider {
  readonly profileName: ModelSchemaName;
  readonly loadingDocuments: Ref<boolean>;
  readonly dtos: Ref<DocumentDto[]>;
  readonly documents: Ref<DocumentModel[]>;
  readonly qrCodeOptions: Ref<IQrCodeOptions | null | undefined>;
  readonly sort: Ref<DocumentSort>;
  readonly searchText: Ref<string | null | undefined>;
}

export interface IQrCodeOptions {
  profileName: string;
  resourceId?: string;
  orderId?: string;
  costCatalogueItemId?: string;
}

export abstract class DocumentProvider implements IDocumentProvider {
  abstract profileName: ModelSchemaName;
  readonly loadingDocuments: Ref<boolean> = ref(false);
  readonly dtos: Ref<DocumentDto[]> = ref([]);
  readonly documents: Ref<DocumentModel[]> = ref([]);
  readonly qrCodeOptions: Ref<IQrCodeOptions | null | undefined> = ref(null);
  readonly sort: Ref<DocumentSort> = useLocalStorage("documentsBrowserSortMode", DocumentSort.FileNameAsc);
  readonly searchText: Ref<string | null | undefined> = ref("");
  private _allDocuments: DocumentModel[] = [];

  protected constructor() {
    watch(
      this.sort,
      () => {
        this.documents.value = [...this._allDocuments].sort(this.sortBy.bind(this));
      },
      { immediate: true }
    );
    watch(
      this.searchText,
      () => {
        this.documents.value = this._allDocuments.filter(this.filter.bind(this));
      },
      { immediate: true }
    );
  }

  async refresh(): Promise<void> {
    this.loadingDocuments.value = true;
    try {
      this.dtos.value = await this.getAll();
      const docs = this.dtos.value
        .map((doc) => {
          // find the document in the current list (pkey is businessId + revisionNumber)
          const document = this.documents.value.find((d) => d.id === getDocumentDtoId(doc));
          const isSame = document?.modifiedAt === doc.modifiedAt;
          if (isSame) {
            return document;
          } else {
            try {
              return new DocumentModel(doc);
            } catch (err: any) {
              if (err instanceof DocumentError) {
                console.warn(err.message);
              }
              return null;
            }
          }
        })
        .filter((doc) => doc !== null) as DocumentModel[];
      await Promise.all(docs.map((doc) => doc.synchronize()));
      this._allDocuments = docs;
      this.documents.value = [...this._allDocuments].sort(this.sortBy.bind(this));
    } finally {
      this.loadingDocuments.value = false;
    }
  }

  /*
   * Get all documents for the current context.
   */
  abstract getAll(): Promise<DocumentDto[]>;

  private sortBy(a: DocumentModel, b: DocumentModel): number {
    switch (this.sort.value) {
      case DocumentSort.FileNameAsc:
        return a.fileName.localeCompare(b.fileName);
      case DocumentSort.FileNameDesc:
        return b.fileName.localeCompare(a.fileName);
      case DocumentSort.CreatedAtAsc:
        return a.createdAt.localeCompare(b.createdAt);
      case DocumentSort.CreatedAtDesc:
        return b.createdAt.localeCompare(a.createdAt);
      case DocumentSort.ModifiedAtAsc:
        return a.modifiedAt.localeCompare(b.modifiedAt);
      case DocumentSort.ModifiedAtDesc:
        return b.modifiedAt.localeCompare(a.modifiedAt);
      case DocumentSort.DescriptionAsc:
        return a.description.localeCompare(b.description);
      case DocumentSort.DescriptionDesc:
        return b.description.localeCompare(a.description);
      case DocumentSort.TagsAsc:
        return a.tags.join().localeCompare(b.tags.join());
      case DocumentSort.TagsDesc:
        return b.tags.join().localeCompare(a.tags.join());
      default:
        return 0;
    }
  }

  private filter(doc: DocumentModel): boolean {
    if (this.searchText.value === null || this.searchText.value === undefined || this.searchText.value === "") {
      return true;
    }
    const search = this.searchText.value.toLowerCase().trim();
    return doc.fileName.toLowerCase().includes(search) || doc.description?.toLowerCase().includes(search) || doc.tags.join().toLowerCase().includes(search);
  }
}
