<script setup lang="ts">
import { ModelSchemaWithLabelDto } from "@/store/ModelInstancesStore";
import { UnwrapRef } from "vue";
import { $t } from "@/i18n";

const props = defineProps<{
  icon?: string;
}>();

const selectedSchema = defineModel<UnwrapRef<ModelSchemaWithLabelDto> | null | undefined>({
  required: true,
  default: null,
  local: true
});

const schemas = defineModel<ModelSchemaWithLabelDto[]>("schemas", {
  required: true,
  default: []
});

</script>

<template>
  <v-autocomplete
    v-model="selectedSchema"
    v-bind="$attrs"
    variant="outlined"
    density="compact"
    :items="schemas"
    item-title="label"
    clearable
    return-object
    hide-details
    :multiple="false"
    :label="$t('schemaSelector-component-selectedRepository-label', { $: 'Selected repository' })"
  >
    <template #item="{ props, item }">
      <v-list-item
        v-bind="props"
        :prepend-icon="icon"
        :title="item.raw.label"
      ></v-list-item>
    </template>
    <template #chip="{ props, item }">
      <v-chip v-bind="props" :title="item.raw.label" :subtitle="item?.raw?.name ?? ''" :prepend-icon="icon" class="w-100 resource-chip"></v-chip>
    </template>
  </v-autocomplete>
</template>

<style scoped lang="scss">

</style>
