import { inject, injectable } from "inversify";
import { GanttSettings, IContextMenuItemActionContext, IGanttAction, IocSymbols, Layer, Lifecycle } from "@masta/gantt2/core";
import { ResourceCapacityActivity } from "@/components/Gantt/Common/Model";
import { BehaviorSubject } from "rxjs";
import { CustomSettingKeys } from "@/components/Gantt/ResourcesGantt/CustomSettingKeys";
import { $t } from "@/i18n";
import { useProductionTasksStore } from "@/store/ProductionTasksStore";
import { useSnackbarsStore } from "@/store/SnackbarsStore";

@injectable()
export class RecalculateAction extends Lifecycle implements IGanttAction {
  constructor(@inject(IocSymbols.LayersSymbol) private _layers$$: BehaviorSubject<Layer[]>,
              @inject(GanttSettings) private _ganttSettings: GanttSettings) {
    super();
  }

  public async execute(context: IContextMenuItemActionContext) {
    const scenarioId = this._ganttSettings.getSetting<string>(CustomSettingKeys.SCENARIO_ID);

    if (!scenarioId) {
      console.warn("scenarioId is not set");
      return;
    }

    const request = {
      scenarioId: scenarioId!
    };

    const tasksStore = useProductionTasksStore();
    const snackbarsStore = useSnackbarsStore();

    try {
      await tasksStore.recalculateTasks(request);
      snackbarsStore.createSnackbar({
        message: $t("task-list-recalculationStarted-message", { $: "Recalculation started" }),
        closeable: true
      });
    } catch (e: any) {
      console.error(e);
      snackbarsStore.createSnackbar({
        message: e.message,
        type: "error",
        closeable: true
      });
    }
  }

}
