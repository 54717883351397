import { ServerSideDataSource } from "@/components/Grid/ServerSideDataSource";
import {
  AvailabilityRuleResourceDto,
  CreateResourceAvailabilityRuleCommand,
  GetAvailabilityRuleResourcesPaginatedQuery,
  IAgGridPaginationResponse,
  UpdateResourceAvailabilityRuleCommand
} from "@masta/generated-model";
import { Store } from "pinia";
import { State as ScenarioState, useScenariosStore } from "@/store/ScenariosStore";
import { IServerSideGetRowsParams } from "ag-grid-enterprise";
import ApiService from "@/services/api";
import { GUID_EMPTY } from "@/components/ValueCellEditor/CommonFormatters";

export class AvailabilityRuleResourcesServerSideDataSource extends ServerSideDataSource<
  AvailabilityRuleResourceDto,
  AvailabilityRuleResourceDto,
  CreateResourceAvailabilityRuleCommand,
  UpdateResourceAvailabilityRuleCommand,
  string
> {
  private _scenarioStore: Store<string, ScenarioState>;
  private _filterByAvailabilityRuleId: string | null | undefined;

  constructor(id: string) {
    super(id);
    this._scenarioStore = useScenariosStore();
  }

  public useFilteringByAvailabilityRuleId(resourceId: string | null | undefined) {
    this._filterByAvailabilityRuleId = resourceId;
  }

  async getAll(params: IServerSideGetRowsParams): Promise<IAgGridPaginationResponse<AvailabilityRuleResourceDto>> {
    const scenarioId = this._scenarioStore.selectedScenario?.id;
    if (!scenarioId) return { results: [], count: 0 } as IAgGridPaginationResponse<AvailabilityRuleResourceDto>;

    const request = {
      ...this.constructServerRequest(params),
      availabilityRuleId: this._filterByAvailabilityRuleId,
      scenarioId: scenarioId
    } as GetAvailabilityRuleResourcesPaginatedQuery;
    const response = await ApiService.resourceAvailabilityRules.getForAvailabilityRulePaginated(request);
    return response.data as IAgGridPaginationResponse<AvailabilityRuleResourceDto>;
  }

  async getSingle(id: string): Promise<AvailabilityRuleResourceDto | undefined> {
    const scenarioId = this._scenarioStore.selectedScenario?.id;
    if (!scenarioId || !this._filterByAvailabilityRuleId) return;
    const response = await ApiService.resourceAvailabilityRules.get(this._filterByAvailabilityRuleId, id, scenarioId);
    return response.data;
  }

  async create(entity: AvailabilityRuleResourceDto): Promise<string> {
    const request = this.transformForCreate(entity);
    const scenario = this._scenarioStore.selectedScenario;
    request.scenarioId = scenario?.id ?? "";
    const response = await ApiService.resourceAvailabilityRules.create(request);
    return response.data;
  }

  async update(entity: AvailabilityRuleResourceDto): Promise<string> {
    const request = this.transformForUpdate(entity);
    await ApiService.resourceAvailabilityRules.update(request);
    return entity.id;
  }

  async remove(entity: AvailabilityRuleResourceDto): Promise<void> {
    await ApiService.resourceAvailabilityRules.remove(entity.resourceId, entity.availabilityRuleId, entity.scenarioId);
    return;
  }

  protected transformForCreate(entity: AvailabilityRuleResourceDto): CreateResourceAvailabilityRuleCommand {
    return {
      scenarioId: this._scenarioStore.selectedScenario?.id ?? GUID_EMPTY,
      availabilityRuleId: this._filterByAvailabilityRuleId,
      resourceId: entity.resourceId,
      validFrom: entity.validFrom,
      validTo: entity.validTo
    } as CreateResourceAvailabilityRuleCommand;
  }

  protected transformForUpdate(entity: AvailabilityRuleResourceDto): UpdateResourceAvailabilityRuleCommand {
    return {
      scenarioId: this._scenarioStore.selectedScenario?.id ?? GUID_EMPTY,
      availabilityRuleId: this._filterByAvailabilityRuleId,
      resourceId: entity.resourceId,
      validFrom: entity.validFrom,
      validTo: entity.validTo
    } as UpdateResourceAvailabilityRuleCommand;
  }
}
