<script lang="ts" setup>
import { ref } from "vue";
import { useSnackbarsStore } from "@/store/SnackbarsStore";
import { CalculateKpiValuesCommand } from "@masta/generated-model";
import { useKeyPerformanceIndicatorsStore } from "@/store/KeyPerformanceIndicatorsStore";
import CalculateKpiDialog from "@/components/KpiResults/CalculateKpiDialog.vue";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import { $t } from "@/i18n";

interface Props {
  gridWrapper?: GridWrapperComponent;
}

const properties = defineProps<Props>();

const dialog = ref(false); // Reactive reference to manage dialog state

async function calculationParamsSet(settings: CalculateKpiValuesCommand) {
  await calculate(settings);
}

async function calculate(settings: CalculateKpiValuesCommand) {
  if (!settings) return;

  try {
    const keyPerformanceIndicatorsStore = useKeyPerformanceIndicatorsStore();
    await keyPerformanceIndicatorsStore.calculateKpiValues(settings);
    const snackbarsStore = useSnackbarsStore();
    await snackbarsStore.createSnackbar({
      message: "KPI Calculation launched",
      closeable: true
    });
    properties.gridWrapper?.crudActions?.onRefreshAction();
  } catch (e: any) {
    console.error(e);
    const snackbarsStore = useSnackbarsStore();
    await snackbarsStore.createSnackbar({
      message: e.message,
      type: "error",
      closeable: true
    });
  }
}

function triggerCalculateAction() {
  dialog.value = true; // Show the dialog
}
</script>

<template>
  <v-tooltip location="bottom" open-delay="300">
    <template #activator="{ props }">
      <div class="d-inline-flex pr-4">
        <v-btn size="small" variant="text" density="compact" v-bind="props" @click="triggerCalculateAction">
          <v-icon icon="mdi-calculator-variant-outline" class="pr-4" />
          {{ $t("calculateKpi-gridAction-calculate-label", { $: "Calculate" }) }}
        </v-btn>
      </div>
    </template>
    <span>{{ "Calculate" }}</span>
  </v-tooltip>
  <calculate-kpi-dialog v-model="dialog" @calculation-params-set="calculationParamsSet" />
</template>
