<script setup lang="ts">
import { $t } from "@/i18n";
import { CostCatalogueItemDto, ModelSchemaNames } from "@masta/generated-model";
import { ref } from "vue";
import CloseCardButton from "@/components/CloseCardButton.vue";
import Breadcrumbs from "@/components/Layout/Breadcrumbs.vue";
import CostCatalogueDocumentsBrowser from "@/components/Documents/CostCatalogueDocumentsBrowser.vue";

const props = defineProps<{
  item: CostCatalogueItemDto;
}>();

const emit = defineEmits<{
  (e: "updated", id: string): void;
  (e: "closed"): void;
}>();

const defaultTab = "gallery";
const currentTab = ref(defaultTab);

function closeDetails() {
  emit("closed");
}
</script>

<template>
  <v-card elevation="7" class="d-flex flex-column fill-height item-card">
    <close-card-button @click="closeDetails"></close-card-button>
    <v-card-title class="tabs">
      <breadcrumbs>{{ item?.name || item?.businessId }}</breadcrumbs>
      <v-tabs
        v-model="currentTab"
        class="task-details text-disabled"
        selected-class="v-slide-group-item--active text-primary bg-white"
        density="compact"
        color="primary"
        show-arrows
      >
        <v-tab value="gallery">
          <v-icon class="pr-2">mdi-folder-information</v-icon>
          {{ $t("costCatalogueItemDetails-view-gallery-label", { $: "Gallery" }) }}
        </v-tab>
      </v-tabs>
    </v-card-title>
    <v-card-text class="d-flex flex-column pa-0">
      <div class="flex-1-1 d-flex overflow-hidden">
        <v-card class="flex-1-1 d-flex flex-column rounded-0" elevation="6">
          <v-card-text class="flex-1-1 d-flex flex-column pa-0">
            <v-window v-model="currentTab" class="bg-white flex-fill d-block" style="position: absolute; width: 100%; height: 100%">
              <v-window-item class="fill-height pa-5 overflow-y-auto" value="gallery">
                <cost-catalogue-documents-browser v-if="item" :cost-catalogue-item="item" :context-name="ModelSchemaNames.documentCostCatalogueItemAttachment" />
              </v-window-item>
            </v-window>
          </v-card-text>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
</template>

<style lang="scss" scoped>
.item-card {
  border: 2px solid rgb(var(--v-theme-primary));
  border-radius: 0;

  .close-card-button {
    position: absolute;
    top: 0px;
    right: 10px;
  }
}
</style>
