<script lang="ts" setup>
import { OrganizationDto } from "@masta/generated-model";
import { computed, nextTick, ref, watch } from "vue";
import ApiService from "@/services/api";
import OrganizationGrid from "@/components/Organizations/OrganizationGrid.vue";
import CloseCardButton from "@/components/CloseCardButton.vue";
import { $t } from "@/i18n";
import { VAutocomplete } from "vuetify/lib/components/index.mjs";
import { useScenariosStore } from "@/store/ScenariosStore";

const modelValue = defineModel<OrganizationDto | null>({ required: true });
const props = defineProps<{
  label?: string;
  color?: string | undefined;
  placeholder?: string;
  readonly: boolean;
  rules?: any[] | undefined;
}>();
const scenarioStore = useScenariosStore();
const loading = ref(false);
const search = ref<string | undefined>();
const organizations = ref<OrganizationDto[]>([]);
const dialog = ref(false);
const selectedOrganization = ref<OrganizationDto>();

const autocompleteRef = ref<VAutocomplete>();

const noDataText = computed(() => {
  if (!search.value) {
    return $t("autocomplete-control-startTyping-label", { $: "Start typing ..." });
  }
  return $t("autocomplete-control-noData-label", { $: "No data" });
});

watch(search, (newValue) => {
  if (!newValue) {
    organizations.value = [];
    return;
  }
  querySelections(newValue);
});


async function querySelections(query: string) {
  if (!scenarioStore.selectedScenario) {
    return;
  }
  loading.value = true;
  const response = await ApiService.organizations.findOrganizations(query, scenarioStore.selectedScenario.id);
  organizations.value = response.data.organizations ?? [];
  loading.value = false;
}

function selectOrganization() {
  dialog.value = true;
}

function onBtnConfirm() {
  if (selectedOrganization.value) {
    modelValue.value = selectedOrganization.value;
  }
  dialog.value = false;
}

function onBtnCancel() {
  dialog.value = false;
}

function onRowSelected(row: OrganizationDto | null | undefined) {
  if (row) {
    selectedOrganization.value = row;
  } else {
    selectedOrganization.value = undefined;
  }
}

function onRowDoubleClicked(row: OrganizationDto) {
  if (row) {
    selectedOrganization.value = row;
  } else {
    selectedOrganization.value = undefined;
  }

  onBtnConfirm();
}

function focus() {
  nextTick(() => autocompleteRef.value?.focus());
}

function hasFocus(): boolean | undefined {
  return autocompleteRef.value?.focused;
}

function onSelectOrganization() {
  selectOrganization();
}

function setMenu(menu: boolean) {
  if (autocompleteRef.value) {
    autocompleteRef.value.menu = menu;
  }
}

function getMenu(): boolean | undefined {
  return autocompleteRef.value?.menu;
}

defineExpose({ focus, hasFocus, getMenu, setMenu, onSelectOrganization });
</script>

<template>
  <div class="d-flex align-center cost-cat-item-picker">
    <v-autocomplete
      ref="autocompleteRef"
      v-model="modelValue"
      v-model:search="search"
      class="flex-1-1-100"
      auto-select-first
      clearable
      :color="color"
      :label="label"
      :items="organizations"
      :loading="loading"
      :disabled="props.readonly"
      :rules="props.rules"
      no-filter
      item-title="businessId"
      variant="outlined"
      density="compact"
      hide-details
      return-object
      :placeholder="placeholder"
      hide-no-data
      :no-data-text="noDataText"
    >
      <template #item="{ props, item }">
        <v-list-item v-bind="props" :title="item?.raw?.businessId ?? ''" :subtitle="item?.raw?.name ?? ''"></v-list-item>
      </template>
      <template #chip="{ props, item }">
        <v-chip v-bind="props" :title="item?.raw?.businessId ?? ''" :subtitle="item?.raw?.name ?? ''" class="chip-select text-body-1"></v-chip>
      </template>
      <template #append-inner>
        <v-btn v-if="!props.readonly" variant="plain" icon="mdi-select-search" density="compact" :ripple="false" @click.stop="onSelectOrganization"></v-btn>
      </template>
    </v-autocomplete>
    <v-dialog v-model="dialog" width="100%" height="80vh" @click:outside="onBtnCancel">
      <v-card min-width="300px" height="100%" class="reference-picker-card">
        <close-card-button @click="onBtnCancel"></close-card-button>
        <v-card-title class="pa-0">
          <div class="text-h4 pb-5">{{ $t("organizationsPicker-list-chooseOrganization-label", { $: "Choose Organization" }) }}</div>
        </v-card-title>
        <v-card-text class="px-0">
          <organization-grid
            :reference-value="modelValue?.businessId"
            @row-selected="onRowSelected"
            @row-double-clicked="onRowDoubleClicked">
          </organization-grid>
        </v-card-text>
        <v-card-actions class="pa-0">
          <div class="left-col pl-0">
            <v-btn variant="elevated" color="secondary" class="w-100" @click="onBtnCancel">{{ $t("organizationPicker-list-cancel-label", { $: "Cancel" }) }}</v-btn>
          </div>
          <div class="middle-col"></div>
          <div class="right-col pr-0">
            <v-btn variant="elevated" color="primary" class="w-100" @click="onBtnConfirm">{{ $t("organizationPicker-list-confirm-label", { $: "Confirm" }) }}</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped lang="scss">
.cost-cat-item-picker {
  :deep(.v-autocomplete__menu-icon) {
    display: none;
  }
}
</style>
