<script lang="ts" setup>
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { inject, reactive, ref, watch } from "vue";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import { $dateTimeFormatterSymbol, DateFormatter } from "@masta/shared";
import {
  GridApi,
  GridOptions,
  ProcessCellForExportParams,
  RowSelectedEvent,
  StoreRefreshedEvent,
  ValueFormatterParams,
  ValueGetterParams,
  ValueSetterParams
} from "ag-grid-community";
import { getOrderLineStatusCellStyle } from "@/components/CustomerOrders/CustomerOrderUtils";
import { PurchaseOrderLinesServerSideDataSource } from "@/components/PurchaseOrders/PurchaseOrderLinesServerSideDataSource";
import {
  FinishPurchaseOrderCommand,
  MaterialDto,
  MaterialSubType,
  MeasurementUnit,
  OrderDto,
  OrderLineDto,
  OrderLineStatus,
  OrderStatusChangeNotificationEvent,
  OrderType,
  ResourceDto,
  ResourceInfoDto,
  ResourceType
} from "@masta/generated-model";
import { $t } from "@/i18n";
import { requiredRule } from "@/components/ValueCellEditor/CommonValidationRules";
import { translateMeasurementUnit, translateOrderLineStatus } from "@/composables/translateEnum";
import { OrderStatusChangedNotification, useNotification } from "@/notifications";
import { useDebounceFn } from "@vueuse/core/index";
import { enumToEditorEntries, translateEditorEntries } from "@/components/Grid/ColumnTypes";
import { useRouter } from "vue-router";
import { nameOrBusinessIdOrIdOrNull } from "../ValueCellEditor/CommonFormatters";
import { getNowInISOFormat } from "@/components/Datepicker/DatepickerUtil";
import { getSelectedRows } from "@/components/Grid/UseGridSelection";
import ApiService from "@/services/api";
import Api from "@/services/api";
import { useSnackbarsStore } from "@/store/SnackbarsStore";
import { useScenariosStore } from "@/store/ScenariosStore";
import type { IResourcePickerCellEditorParams } from "@/components/Grid/CellEditors/ResourcePickerCellEditor.vue";
import MaterialMovementDialog from "@/views/ProductOverview/MaterialMovementDialog.vue";

interface Props {
  filterByProductId?: string | null | undefined;
  readonly?: boolean;
  selectedOrder: OrderDto;
}

const router = useRouter();
const props = withDefaults(defineProps<Props>(), { filterByProductId: undefined });

const snackbarsStore = useSnackbarsStore();
const scenariosStore = useScenariosStore();

const serverSideDataSource = reactive(new PurchaseOrderLinesServerSideDataSource("purchase-order-lines"));
serverSideDataSource.useFilteringByProductId(props.filterByProductId);
serverSideDataSource.useFilteringByOrderId(props.selectedOrder.id);

const DEFAULT_CREATE_VALUE = {
  quantity: 1,
  deliveryDate: getNowInISOFormat(),
  position: () => resolveNextPosition(),
  status: OrderLineStatus.InPreparation
};

const isTransactionCreationDialogVisible = ref<boolean>(false);

const onTransactionCreateRequest = () => (isTransactionCreationDialogVisible.value = true);
const onTransactionCreated = async () => {
  await Api.purchaseOrders.finish({
    orderId: selectedOrderLine.value.orderId,
    orderLineIds: [selectedOrderLine.value.id],
    scenarioId: scenariosStore.selectedScenario.id
  } as FinishPurchaseOrderCommand);
  gridWrapperRef.value?.gridApi?.refreshServerSide();
  isTransactionCreationDialogVisible.value = false;
};
const onTransactionCreateCanceled = () => (isTransactionCreationDialogVisible.value = false);

useNotification(OrderStatusChangedNotification, async (e: OrderStatusChangeNotificationEvent) => {
  onOrderStatusChanged(e);
});

const onOrderStatusChanged: (e: OrderStatusChangeNotificationEvent) => void = useDebounceFn(async (e: OrderStatusChangeNotificationEvent) => {
  if (e.orderId === props.selectedOrder.id) {
    gridWrapperRef.value?.gridApi?.refreshServerSide();
  }
}, 800);

const isRowSelected = ref(false);
const isOnlyInPreparationSelected = ref(false);
const isEditBtnDisabled = ref(false);
const isDeleteBtnDisabled = ref(false);
const selectedOrderLine = ref<OrderLineDto>();

function onSelectionChanged({ api }: { api: GridApi }) {
  const selectedRows = getSelectedRows(api);
  isRowSelected.value = selectedRows.length > 0;
  isOnlyInPreparationSelected.value = selectedRows.every((row) => row.status === OrderLineStatus.InPreparation);
  selectedOrderLine.value = selectedRows.length === 1 ? selectedRows[0] : undefined;
}

const gridWrapperRef = ref<GridWrapperComponent>();
const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;

function resolveNextPosition(): number {
  const nextRowOrdinal = gridWrapperRef.value ? gridWrapperRef.value.gridApi.getDisplayedRowCount() + 1 : 1;
  return nextRowOrdinal * 10;
}

function mapToResourceInfoDto(itemBusinessId: string | undefined): ResourceInfoDto | null {
  if (itemBusinessId) {
    const resourceInfo: ResourceInfoDto = {
      id: "",
      scenarioId: "",
      name: itemBusinessId,
      businessId: itemBusinessId,
      type: ResourceType.Material
    };
    return resourceInfo;
  } else {
    return null;
  }
}

const gridOptions: GridOptions = {
  processCellForClipboard: (params: ProcessCellForExportParams) => {
    const { value, column } = params;

    if (column.getColDef().type === "resourcePickerTypeColumn") {
      return params.formatValue(value);
    }

    return value;
  }
};

function onPrepareColumns(columnDefs: any) {
  columnDefs.value = [
    {
      field: "itemName",
      editable: false,
      sortable: true,
      resizable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("purchaseOrderLine-list-itemName-label", { $: "Item Name" })
    },
    {
      field: "itemBusinessId",
      editable: true,
      sortable: true,
      filter: "agTextColumnFilter",
      resizable: true,
      type: "resourcePickerTypeColumn",
      headerValueGetter: (_: any) => $t("purchaseOrderLine-list-itemBusinessId-label", { $: "Item Business Id" }),
      cellEditorParams: {
        resourceTypes: [ResourceType.Material],
        resourceSubTypes: props.selectedOrder.orderType === OrderType.InboundDelivery ? [MaterialSubType.RawOrSubcomponent] : [MaterialSubType.SemiFinishedOrFinalProduct],
        rules: [requiredRule],
        placeholder: $t("purchaseOrderLine-list-itemBusinessId-label", { $: "Item Business Id" }),
        onValueChanged: (value: MaterialDto, params: IResourcePickerCellEditorParams) => {
          params.node.setDataValue("measurementUnit", value?.orderableUnit ?? value?.measurementUnit);
        }
      },
      valueFormatter: (params: ValueFormatterParams) => {
        const resourceInfo: ResourceDto = params.value;
        if (resourceInfo) {
          const result = nameOrBusinessIdOrIdOrNull(resourceInfo);
          return result ?? "";
        }
      },
      valueSetter: (params: ValueSetterParams) => {
        const resourceInfo: ResourceDto = params.newValue;
        params.data.itemBusinessId = resourceInfo?.businessId;
        params.data.measurementUnit = resourceInfo?.orderableUnit ?? resourceInfo?.measurementUnit;
        return true;
      },
      valueGetter: (params: ValueGetterParams) => {
        return mapToResourceInfoDto(params.data.itemBusinessId);
      }
    },
    {
      field: "description",
      editable: true,
      sortable: true,
      filter: "agTextColumnFilter",
      resizable: true,
      type: "textInputTypeColumn",
      cellEditorParams: {
        placeholder: $t("order-list-description-label", { $: "Description" })
      },
      headerValueGetter: (_: any) => $t("purchaseOrderLine-list-description-label", { $: "Description" })
    },
    {
      field: "quantity",
      sortable: true,
      editable: true,
      filter: "agNumberColumnFilter",
      resizable: true,
      type: "textInputTypeColumn",
      cellEditorParams: {
        rules: [requiredRule],
        placeholder: $t("purchaseOrderLine-list-quantity-label", { $: "Quantity" })
      },
      headerValueGetter: (_: any) => $t("purchaseOrderLine-list-quantity-label", { $: "Quantity" })
    },
    {
      field: "measurementUnit",
      editable: false,
      sortable: true,
      filter: "agNumberColumnFilter",
      resizable: true,
      type: "enumTypeColumn",
      cellEditorParams: {
        values: translateEditorEntries(enumToEditorEntries(MeasurementUnit), translateMeasurementUnit),
        placeholder: $t("purchaseOrderLine-list-measurementUnit-label", { $: "Measurement Unit" })
      },
      valueFormatter: (params: any) => (params.data ? translateMeasurementUnit(params.data.measurementUnit) : null),
      headerValueGetter: (_: any) => $t("purchaseOrderLine-list-measurementUnit-label", { $: "Measurement Unit" })
    },
    {
      field: "position",
      editable: true,
      sortable: true,
      filter: "agNumberColumnFilter",
      resizable: true,
      type: "textInputTypeColumn",
      cellEditorParams: {
        rules: [(v: any) => (v !== undefined && v !== null && v !== "") || "Required"],
        placeholder: $t("purchaseOrderLine-list-position-label", { $: "Position" })
      },
      headerValueGetter: (_: any) => $t("purchaseOrderLine-list-position-label", { $: "Position" })
    },
    {
      field: "deliveryDate",
      editable: true,
      sortable: true,
      filter: "agDateColumnFilter",
      resizable: true,
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.deliveryDate);
      },
      type: "datepickerTypeColumn",
      cellEditorParams: {
        rules: [requiredRule],
        placeholder: $t("purchaseOrderLine-list-deliveryDate-label", { $: "Delivery Date" })
      },
      headerValueGetter: (_: any) => $t("purchaseOrderLine-list-deliveryDate-label", { $: "Delivery Date" })
    },
    {
      field: "status",
      headerName: "Status",
      editable: false,
      sortable: true,
      filter: false,
      resizable: true,
      cellStyle: (params: any) => getOrderLineStatusCellStyle(params.data.status),
      valueGetter: (params: any) => translateOrderLineStatus(params.data.status),
      headerValueGetter: (_: any) => $t("purchaseOrderLine-list-status-label", { $: "Status" })
    }
  ];
}

function checkActionButtonsState() {
  const rows = getSelectedRows(gridWrapperRef.value?.gridApi);
  if (rows.length !== 1) {
    isEditBtnDisabled.value = true;
    return;
  } else {
    isEditBtnDisabled.value = rows[0].status !== OrderLineStatus.InPreparation && rows[0].status !== OrderLineStatus.Unknown;
    isDeleteBtnDisabled.value = rows[0].status !== OrderLineStatus.InPreparation && rows[0].status !== OrderLineStatus.Unknown;
  }
}

function onRowSelected(_: RowSelectedEvent<OrderLineDto>) {
  checkActionButtonsState();
}

function onStoreRefreshed(_: StoreRefreshedEvent<OrderLineDto>) {
  checkActionButtonsState();
}

function openProductCard() {
  const selectedRows = getSelectedRows(gridWrapperRef.value?.gridApi);
  if (selectedRows.length > 0) {
    const selectedRow = selectedRows[0];
    if (selectedRow.itemId) {
      router.push({ name: "Product Card", query: { product: selectedRow.itemId, selected: selectedRow.itemId }, params: { scenarioId: useScenariosStore().scenarioBusinessId } });
    }
  }
}

watch(props, (newProps) => {
  serverSideDataSource.useFilteringByProductId(newProps.filterByProductId);
  serverSideDataSource.useFilteringByOrderId(newProps.selectedOrder.id);
  gridWrapperRef.value?.gridApi.refreshServerSide();
});

async function confirm() {
  const selectedRows = getSelectedRows(gridWrapperRef.value?.gridApi);
  if (selectedRows.length > 0) {
    const orderLineIds = selectedRows.map(function(x) {
      return x.id;
    });
    if (!scenariosStore.selectedScenario) return;
    try {
      const request = {
        orderLineIds: orderLineIds,
        orderId: selectedRows[0].orderId,
        scenarioId: scenariosStore.selectedScenario.id
      };
      await ApiService.purchaseOrders.confirm(request);
      await snackbarsStore.createSnackbar({
        message: $t("customerOrderLine-list-onConfirmSuccess-message", { $: "Order lines have been confirmed" }),
        type: "success",
        closeable: true
      });
    } catch (e) {
      console.error(e);
      await snackbarsStore.createSnackbar({
        message: $t("customerOrderLine-list-onConfirmError-message", { $: "Could not confirm the order lines" }),
        type: "error",
        closeable: true
      });
    }
  }
}

async function approve() {
  const selectedRows = getSelectedRows(gridWrapperRef.value?.gridApi);
  if (selectedRows.length > 0) {
    const orderLineIds = selectedRows.map(function(x) {
      return x.id;
    });
    if (!scenariosStore.selectedScenario) return;
    try {
      const request = {
        orderLineIds: orderLineIds,
        orderId: selectedRows[0].orderId,
        scenarioId: scenariosStore.selectedScenario.id
      };
      await ApiService.purchaseOrders.approve(request);
      await snackbarsStore.createSnackbar({
        message: $t("customerOrderLine-list-onApproveSuccess-message", { $: "Order lines have been approved" }),
        type: "success",
        closeable: true
      });
    } catch (e) {
      console.error(e);
      await snackbarsStore.createSnackbar({
        message: $t("customerOrderLine-list-onApproveError-message", { $: "Could not approve the order lines" }),
        type: "error",
        closeable: true
      });
    }
  }
}

async function reject() {
  const selectedRows = getSelectedRows(gridWrapperRef.value?.gridApi);
  if (selectedRows.length > 0) {
    const orderLineIds = selectedRows.map(function(x) {
      return x.id;
    });
    if (!scenariosStore.selectedScenario) return;
    try {
      const request = {
        orderLineIds: orderLineIds,
        orderId: selectedRows[0].orderId,
        scenarioId: scenariosStore.selectedScenario.id
      };
      await ApiService.purchaseOrders.reject(request);
      await snackbarsStore.createSnackbar({
        message: $t("customerOrderLine-list-onRejectSuccess-message", { $: "Order lines have been rejected" }),
        type: "success",
        closeable: true
      });
    } catch (e) {
      console.error(e);
      await snackbarsStore.createSnackbar({
        message: $t("customerOrderLine-list-onRejectError-message", { $: "Could not reject the order lines" }),
        type: "error",
        closeable: true
      });
    }
  }
}

async function startDelivering() {
  const selectedRows = getSelectedRows(gridWrapperRef.value?.gridApi);
  if (selectedRows.length > 0) {
    const orderLineIds = selectedRows.map(function(x) {
      return x.id;
    });
    if (!scenariosStore.selectedScenario) return;
    try {
      const request = {
        orderLineIds: orderLineIds,
        orderId: selectedRows[0].orderId,
        scenarioId: scenariosStore.selectedScenario.id
      };
      await ApiService.purchaseOrders.startDelivering(request);
      await snackbarsStore.createSnackbar({
        message: $t("customerOrderLine-list-onStartDeliveringSuccess-message", { $: "Order lines have been send to delivery" }),
        type: "success",
        closeable: true
      });
    } catch (e) {
      console.error(e);
      await snackbarsStore.createSnackbar({
        message: $t("customerOrderLine-list-onStartDeliveringError-message", { $: "Could not send the order lines to delivery" }),
        type: "error",
        closeable: true
      });
    }
  }
}

async function finish() {
  const selectedRows = getSelectedRows(gridWrapperRef.value?.gridApi);
  if (selectedRows.length > 0) {
    const orderLineIds = selectedRows.map(function(x) {
      return x.id;
    });
    if (!scenariosStore.selectedScenario) return;
    try {
      const request = {
        orderLineIds: orderLineIds,
        orderId: selectedRows[0].orderId,
        scenarioId: scenariosStore.selectedScenario.id
      };
      await ApiService.purchaseOrders.finish(request);
      await snackbarsStore.createSnackbar({
        message: $t("customerOrderLine-list-onFinishSuccess-message", { $: "Order lines have been finished" }),
        type: "success",
        closeable: true
      });
    } catch (e) {
      console.error(e);
      await snackbarsStore.createSnackbar({
        message: $t("customerOrderLine-list-onApproveError-message", { $: "Could not finish the order lines" }),
        type: "error",
        closeable: true
      });
    }
  }
}

async function onDuplicate() {
  const selectedRows = getSelectedRows(gridWrapperRef.value?.gridApi);
  if (selectedRows.length > 0) {
    const selectedRow = selectedRows[0];
    const orderLine = { ...selectedRow, id: undefined, status: OrderLineStatus.InPreparation, position: resolveNextPosition() };
    await serverSideDataSource.create(orderLine);
    gridWrapperRef.value?.gridApi?.refreshServerSide();
  }
}
</script>

<template>
  <material-movement-dialog
    :visible="isTransactionCreationDialogVisible"
    :selected-material-id="filterByProductId"
    :selected-purchase-order-line-id="selectedOrderLine?.id"
    :selected-purchase-order-id="selectedOrder.id"
    :quantity="selectedOrderLine?.quantity"
    @cancel="onTransactionCreateCanceled"
    @create="onTransactionCreated"
  />
  <grid-wrapper
    ref="gridWrapperRef"
    identifier="order-lines"
    row-selection="multiple"
    :grid-options="gridOptions"
    :create-default-value="DEFAULT_CREATE_VALUE"
    :server-side="true"
    :server-side-datasource="serverSideDataSource"
    :server-side-infinite-scroll="false"
    :pagination="false"
    refresh-btn
    :edit-btn="!readonly"
    :edit-btn-disabled="isEditBtnDisabled"
    :create-btn="!readonly"
    :duplicate-btn="!readonly"
    custom-duplicate-action
    :delete-btn="!readonly"
    :delete-btn-disabled="isDeleteBtnDisabled"
    :hide-custom-actions-separator="readonly"
    @prepare-columns="onPrepareColumns"
    @selection-changed="onSelectionChanged"
    @row-selected="onRowSelected"
    @store-refreshed="onStoreRefreshed"
    @duplicate-action="onDuplicate"
  >
    <template #custom-buttons>
      <v-menu>
        <template #activator="{ props, isActive }">
          <v-btn :disabled="!isRowSelected" v-bind="props" size="small" variant="text" density="compact" class="mr-4">
            <span>{{ $t("purchaseOrderLine-list-actions-label", { $: "Actions" }) }}</span>
            <v-icon class="pl-4" :icon="isActive ? 'mdi-chevron-up' : 'mdi-chevron-down'" />
          </v-btn>
        </template>
        <v-list density="compact">
          <v-tooltip bottom open-delay="300">
            <template #activator="{ props }">
              <v-list-item :disabled="!isRowSelected" v-bind="props" @click="confirm">
                <template #prepend>
                  <v-icon icon="mdi-rocket-launch"></v-icon>
                </template>
                <v-list-item-title>{{ $t("purchaseOrderLine-list-confirm-action", { $: "1. Confirm Date" }) }}</v-list-item-title>
              </v-list-item>
            </template>
            <span>{{ $t("purchaseOrderLine-list-release-confirm-tooltip", { $: "Confirm" }) }}</span>
          </v-tooltip>
          <v-tooltip bottom open-delay="300">
            <template #activator="{ props }">
              <v-list-item :disabled="!isRowSelected" v-bind="props" @click="approve">
                <template #prepend>
                  <v-icon icon="mdi-rocket-launch"></v-icon>
                </template>
                <v-list-item-title>{{ $t("purchaseOrderLine-list-approve-action", { $: "2. Approve" }) }}</v-list-item-title>
              </v-list-item>
            </template>
            <span>{{ $t("purchaseOrderLine-list-release-approve-tooltip", { $: "Approve" }) }}</span>
          </v-tooltip>
          <v-tooltip bottom open-delay="300">
            <template #activator="{ props }">
              <v-list-item :disabled="!isRowSelected" v-bind="props" @click="reject">
                <template #prepend>
                  <v-icon icon="mdi-rocket-launch"></v-icon>
                </template>
                <v-list-item-title>{{ $t("purchaseOrderLine-list-reject-action", { $: "3. Reject / Cancel" }) }}</v-list-item-title>
              </v-list-item>
            </template>
            <span>{{ $t("purchaseOrderLine-list-release-reject-tooltip", { $: "Reject" }) }}</span>
          </v-tooltip>
          <v-tooltip bottom open-delay="300">
            <template #activator="{ props }">
              <v-list-item :disabled="!isRowSelected" v-bind="props" @click="startDelivering">
                <template #prepend>
                  <v-icon icon="mdi-rocket-launch"></v-icon>
                </template>
                <v-list-item-title>{{ $t("purchaseOrderLine-list-startDelivering-action", { $: "4. Create Inbound Delivery" }) }}</v-list-item-title>
              </v-list-item>
            </template>
            <span>{{ $t("purchaseOrderLine-list-release-startDelivering-tooltip", { $: "Create Inbound Delivery" }) }}</span>
          </v-tooltip>
          <v-divider horizontal />
          <v-tooltip bottom open-delay="300">
            <template #activator="{ props }">
              <v-list-item :disabled="!isRowSelected" v-bind="props" @click="onTransactionCreateRequest">
                <template #prepend>
                  <v-icon icon="mdi-warehouse"></v-icon>
                </template>
                <v-list-item-title>{{ $t("purchaseOrderLine-list-triggerMaterialMovement-action", { $: "5. Material movement" }) }}</v-list-item-title>
              </v-list-item>
            </template>
            <span>{{ $t("purchaseOrderLine-list-triggerMaterialMovement-tooltip", { $: "Directly create material movement" }) }}</span>
          </v-tooltip>
        </v-list>
      </v-menu>
      <v-tooltip bottom open-delay="300">
        <template #activator="{ props }">
          <v-btn size="small" v-bind="props" variant="text" density="compact" class="mr-4" @click="openProductCard">
            <v-icon class="pr-4" icon="mdi-account-hard-hat" />
            {{ $t("purchaseOrderLine-list-openProductCard-action", { $: "Product card" }) }}
          </v-btn>
        </template>
        <span>{{ $t("purchaseOrderLine-list-openProductCard-action-tooltip", { $: "Open product card" }) }}</span>
      </v-tooltip>
    </template>
  </grid-wrapper>
</template>

<style lang="scss" scoped></style>
