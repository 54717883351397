﻿<script setup lang="ts">
import { AvailabilityRuleDataDto, ResourceInfoDto, ResourceType } from "@masta/generated-model";
import { $t } from "@/i18n";
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { inject, reactive, ref, watch } from "vue";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import { CellClassParams, KeyCreatorParams, SelectionChangedEvent, ValueFormatterParams, ValueGetterParams, ValueSetterParams } from "ag-grid-community";
import { groupTypeCellStyle } from "@/components/Grid/CellRenderers/GroupTypeCellStyle";
import { enumToEditorEntriesOnlyIncluding, enumValueEntryWithLocaleComparator, translateEditorEntries } from "@/components/Grid/ColumnTypes";
import { translateResourceType } from "@/composables/translateEnum";
import { validDateRequiredRule } from "@/components/ValueCellEditor/CommonValidationRules";
import { $dateTimeFormatterSymbol, DateFormatter } from "@masta/shared";
import { AvailabilityRuleResourcesServerSideDataSource } from "@/components/AvailabilityRules/AvailabilityRuleResourcesServerSideDataSource";
import { nameOrBusinessIdOrIdOrNull, nonEmptyGuidOrNull } from "@/components/ValueCellEditor/CommonFormatters";

const props = defineProps<{
  rule: AvailabilityRuleDataDto;
}>();

const selectedRow = ref<ResourceInfoDto | null>(null);
const serverSideDataSource = reactive(new AvailabilityRuleResourcesServerSideDataSource("availability-rule-resources"));
serverSideDataSource.useFilteringByAvailabilityRuleId(props.rule.id);

const gridWrapperRef = ref<GridWrapperComponent>();
const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;

const defaultColumnDef = ref({
  floatingFilter: true,
  filterParams: {
    applyMiniFilterWhileTyping: true
  }
});

watch(
  () => props.rule,
  async (rule) => {
    if (rule) {
      serverSideDataSource.useFilteringByAvailabilityRuleId(rule.id);
      gridWrapperRef.value?.gridApi.refreshServerSide();
    }
  },
  { immediate: true }
);

function onSelectionChanged(e: SelectionChangedEvent) {
  if (e.source === "rowDataChanged") return;
  const selectedRows = e.api.getSelectedRows();
  selectedRow.value = selectedRows.length > 0 ? selectedRows[0] : null;
}

function isResourceGroup(resource: ResourceInfoDto) {
  return (
    resource.type === ResourceType.MaterialGroup ||
    resource.type === ResourceType.EquipmentGroup ||
    resource.type === ResourceType.PersonGroup ||
    resource.type === ResourceType.AgreementGroup
  );
}

function onPrepareColumns(columnDefs: any) {
  columnDefs.value = [
    {
      field: "resource", // important: this field is not a part of underlying DTO
      type: ["resourcePickerTypeColumn", "textFloatingFilterColumnType"],
      sortable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("availabilityRuleResources-list-resource-label", { $: "Resource" }),
      editable: true,
      floatingFilterComponentParams: {
        placeholder: $t("availabilityRuleResources-edit-resource-label", { $: "Resource" })
      },
      cellEditorParams: {
        resourceTypes: [ResourceType.Material, ResourceType.Service],
        placeholder: $t("availabilityRuleResources-edit-resource-label", { $: "Resource" })
        // isEditEnabled: () => isItemEditorEnabled,
        // onValueChange: onItemChange
      },
      valueFormatter: (params: ValueFormatterParams) => {
        const resourceInfo: ResourceInfoDto = params.value;
        if (resourceInfo) {
          const result = nameOrBusinessIdOrIdOrNull(resourceInfo);
          return result ?? "";
        }
        const data = params.data;
        return data?.itemName ?? data?.itemBusinessId ?? nonEmptyGuidOrNull(data?.resourceId) ?? null;
      },
      valueGetter: (params: ValueGetterParams) => {
        const { data } = params;
        const resourceInfo = mapToResourceInfoDto(data.resourceId, data.resourceBusinessId, data.resourceName, data.resourceType);
        return resourceInfo;
      },
      valueSetter: (params: ValueSetterParams) => {
        const resourceInfo: ResourceInfoDto = params.newValue;
        params.data.resourceId = resourceInfo?.id;
        params.data.itemBusinessId = resourceInfo?.businessId;
        params.data.itemName = resourceInfo?.name;
        return true;
      }
    },
    {
      field: "resourceName",
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("availabilityRuleResources-list-resourceName-label", { $: "Name" }),
      type: ["textFloatingFilterColumnType"],
      floatingFilterComponentParams: {
        placeholder: $t("availabilityRuleResources-list-resourceName-label", { $: "Name" })
      }
    },
    {
      field: "resourceBusinessId",
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("availabilityRuleResources-list-resourceBusinessId-label", { $: "Business ID" }),
      type: ["textFloatingFilterColumnType"],
      floatingFilterComponentParams: {
        placeholder: $t("availabilityRuleResources-list-resourceBusinessId-label", { $: "Business ID" })
      }
    },
    {
      field: "resourceType",
      type: ["enumTypeColumn", "setFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("availabilityRuleResources-list-resourceType-label", { $: "Type" }),
      cellStyle: (params: CellClassParams) => groupTypeCellStyle(params, isResourceGroup),
      editable: false,
      sortable: true,
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        keyCreator: (params: KeyCreatorParams) => params.value.value,
        values: translateEditorEntries(enumToEditorEntriesOnlyIncluding(ResourceType, [ResourceType.Material, ResourceType.MaterialGroup]), translateResourceType),
        comparator: enumValueEntryWithLocaleComparator
      },
      valueFormatter: (params: any) => translateResourceType(params.data.resourceType),
      floatingFilterComponentParams: {
        placeholder: $t("availabilityRuleResources-list-resourceType-label", { $: "Type" }),
        values: translateEditorEntries(enumToEditorEntriesOnlyIncluding(ResourceType, [ResourceType.Material, ResourceType.MaterialGroup]), translateResourceType)
      }
    },
    {
      field: "validFrom",
      editable: true,
      sortable: true,
      resizable: true,
      filter: "agDateColumnFilter",
      type: ["datepickerTypeColumn", "dateTimeTypeColumn"],
      valueFormatter: (params: ValueFormatterParams) => {
        return params.data && params.data.validFrom ? $dateTimeFormatter(params.data.validFrom) : "";
      },
      cellEditorParams: {
        rules: [validDateRequiredRule],
        placeholder: $t("availabilityRuleResources-list-validFrom-label", { $: "Valid From" })
      },
      headerValueGetter: (_: any) => $t("availabilityRuleResources-list-validFrom-label", { $: "Valid From" })
    },
    {
      field: "validTo",
      editable: true,
      sortable: true,
      resizable: true,
      filter: "agDateColumnFilter",
      type: ["datepickerTypeColumn", "dateTimeTypeColumn"],
      valueFormatter: (params: ValueFormatterParams) => {
        return params.data && params.data.validTo ? $dateTimeFormatter(params.data.validTo) : "";
      },
      cellEditorParams: {
        rules: [validDateRequiredRule],
        placeholder: $t("availabilityRuleResources-list-validTo-label", { $: "Valid To" })
      },
      headerValueGetter: (_: any) => $t("availabilityRuleResources-list-validTo-label", { $: "Valid To" })
    }
  ];
}

function mapToResourceInfoDto(resourceId: string | null, itemBusinessId: string | null, itemName: string | null, resourceType: ResourceType): ResourceInfoDto | null {
  if (resourceId || itemBusinessId || itemName) {
    const resourceInfo: ResourceInfoDto = {
      id: resourceId as string,
      scenarioId: "",
      name: itemName,
      businessId: itemBusinessId,
      type: resourceType
    };

    return resourceInfo;
  } else {
    return null;
  }
}
</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    identifier="availability-rule-resources"
    :default-col-def="defaultColumnDef"
    row-selection="single"
    :server-side="true"
    :server-side-datasource="serverSideDataSource"
    :pagination="false"
    refresh-btn
    create-btn
    delete-btn
    @selection-changed="onSelectionChanged"
    @prepare-columns="onPrepareColumns"
  >
  </grid-wrapper>
</template>
