<script setup lang="ts">
import { DocumentModel, DocumentType } from "@/components/Documents/DocumentModel";
import { $t } from "@/i18n";
import { computed, ref, UnwrapRef } from "vue";

const documents = defineModel<DocumentModel[]>({
  default: []
});

const selectedDocuments = defineModel<UnwrapRef<DocumentModel[]>>("selectedDocuments", {
  default: []
});

const assignedDocuments = defineModel<UnwrapRef<DocumentModel[]>>("assignedDocuments", {
  default: []
});

const props = defineProps<{
  multiple?: boolean;
  editable?: boolean;
  deletable?: boolean;
}>();

const $emit = defineEmits<{
  (event: "edit", value: DocumentModel): void;
  (event: "delete", value: DocumentModel): void;
  (event: "documentDblClicked", value: DocumentModel): void;
}>();

const canSelect = computed(() => props.multiple || selectedDocuments.value.length === 0);

function isSelected(document: DocumentModel) {
  return selectedDocuments.value.some((x) => x.id === document.id);
}

function isItemAssigned(document: DocumentModel) {
  return assignedDocuments.value.some((x) => x.id === document.id);
}

const clickTimeout = ref<any>(null);

function onItemClick(event: PointerEvent, document: DocumentModel) {
  if (clickTimeout.value) {
    clearTimeout(clickTimeout.value);
  }

  clickTimeout.value = setTimeout(() => {
    if (canSelect.value) {

      const selected = isSelected(document);
      const isCtrl = event.ctrlKey || event.metaKey;
      const isShift = event.shiftKey;

      if (isShift && !isCtrl) {
        if (selectedDocuments.value.length > 0) {
          const precedingSelectedIndex = documents.value.findIndex((x) => x.id === selectedDocuments.value[0].id);
          const followingSelectedIndex = documents.value.findIndex((x) => x.id === selectedDocuments.value[selectedDocuments.value.length - 1].id);
          const currentIndex = documents.value.findIndex((x) => x.id === document.id);

          // simulate behaviour like in system file explorer with shift
          if (precedingSelectedIndex < currentIndex) {
            selectedDocuments.value = documents.value.slice(precedingSelectedIndex, currentIndex + 1);
          } else {
            selectedDocuments.value = documents.value.slice(currentIndex, followingSelectedIndex + 1);
          }
        } else {
          selectedDocuments.value = [document];
        }
      }

      if (!isShift && isCtrl) {
        if (selected) {
          selectedDocuments.value = selectedDocuments.value.filter((x) => x.id !== document.id);
        } else {
          selectedDocuments.value = [...selectedDocuments.value, document];
        }
      }

      if (!isShift && !isCtrl) {
        selectedDocuments.value = [document];
      }
    }

    clickTimeout.value = null;
  }, 10);
}

function onItemDblClick(document: DocumentModel) {
  if (clickTimeout.value) {
    clearTimeout(clickTimeout.value);
    clickTimeout.value = null;
  }

  $emit("documentDblClicked", document);
}

async function openInNewTab(item: DocumentModel) {
  await item.openInNewTab();
}

function editItem(item: DocumentModel) {
  $emit("edit", item);
}

function deleteItem(item: DocumentModel) {
  $emit("delete", item);
}

</script>

<template>
  <v-fade-transition group tag="div" class="media-gallery" :class="{'no-items': documents.length === 0}">
    <template v-if="documents.length === 0">
      <div class="no-items-label">{{ $t("documents-noItems-label", { $: "No items found" }) }}</div>
    </template>
    <template v-for="item in documents" :key="item.id">
      <div class="media">
        <v-hover v-slot="{ isHovering, props }">
          <v-card
            v-if="item.documentType === DocumentType.ImageFile"
            class="d-flex cursor-pointer no-user-select"
            color=""
            :class="{ selected: isSelected(item), assigned: isItemAssigned(item) }"
            :elevation="isSelected(item) ? 6 : 2"
            height="100%"
            v-bind="props"
            :link="canSelect"
            :disabled="(!canSelect && !isSelected(item)) || isItemAssigned(item)"
            @click="(e: PointerEvent) => onItemClick(e, item)"
            @dblclick="onItemDblClick(item)"
          >
            <v-img :src="item.thumbnailUrl" :title="item.fileName">
              <div class="revision-number text-caption pt-1">
                <v-tooltip location="bottom" open-delay="300">
                  <template #activator="{ props }">
                    <v-chip v-bind="props" color="secondary" variant="elevated" density="compact">{{ item.revisionNumber }}</v-chip>
                  </template>
                  <span>{{ $t("documents-view-revisionNumber-tooltip", { $: "Revision number" }) }}</span>
                </v-tooltip>
              </div>
              <div v-if="isItemAssigned(item)" class="assigned-label text-caption pt-1">
                <v-chip color="primary" variant="elevated" density="compact">{{ $t("documents-view-assigned-label", { $: "Assigned" }) }}</v-chip>
              </div>
              <div v-if="isSelected(item)" class="assigned-label text-caption pt-1">
                <v-chip color="accent" variant="elevated" density="compact">{{ $t("documents-view-selected-label", { $: "Selected" }) }}</v-chip>
              </div>
              <div class="name-label text-caption">
                <v-card>{{ item.fileName }}</v-card>
              </div>
              <v-tooltip location="bottom" open-delay="300">
                <template #activator="{ props }">
                  <v-fade-transition v-if="editable">
                    <div v-if="isHovering" class="d-inline-flex">
                      <v-btn
                        v-bind="props"
                        class="float-right ma-5"
                        color="primary"
                        icon="mdi-information-outline"
                        density="compact"
                        position="absolute"
                        location="top left"
                        @click.stop="editItem(item)" />
                    </div>
                  </v-fade-transition>
                </template>
                <span>{{ $t("documents-view-infoItem-tooltip", { $: "Show detailed information" }) }}</span>
              </v-tooltip>
              <v-tooltip location="bottom" open-delay="300">
                <template #activator="{ props }">
                  <v-fade-transition v-if="deletable">
                    <div v-if="isHovering" class="d-inline-flex">
                      <v-btn
                        v-bind="props"
                        class="float-right ma-5"
                        color="error"
                        icon="mdi-trash-can"
                        density="compact"
                        position="absolute"
                        location="top right"
                        @click.stop="deleteItem(item)" />
                    </div>
                  </v-fade-transition>
                </template>
                <span>{{ $t("documents-view-deleteItem-tooltip", { $: "Delete" }) }}</span>
              </v-tooltip>
              <v-tooltip location="bottom" open-delay="300">
                <template #activator="{ props }">
                  <v-fade-transition>
                    <div v-if="isHovering" class="d-inline-flex">
                      <v-btn
                        v-bind="props"
                        class="float-right ma-5"
                        icon="mdi-open-in-new"
                        density="compact"
                        position="absolute"
                        location="bottom right"
                        @click.stop="openInNewTab(item)" />
                    </div>
                  </v-fade-transition>
                </template>
                <span>{{ $t("documents-view-openItemInNewTab-tooltip", { $: "Open in new tab" }) }}</span>
              </v-tooltip>
            </v-img>
          </v-card>
          <v-card
            v-else
            class="d-flex cursor-pointer no-user-select"
            color="grey-lighten-1"
            :class="{ selected: isSelected(item), assigned: isItemAssigned(item) }"
            height="100%"
            v-bind="props"
            :link="canSelect"
            :disabled="!canSelect && !isSelected(item)"
            @click="(e: PointerEvent) => onItemClick(e, item)"
            @dblclick="onItemDblClick(item)"
          >
            <div class="revision-number text-caption pt-1">
              <v-tooltip location="bottom" open-delay="300">
                <template #activator="{ props }">
                  <v-chip v-bind="props" color="secondary" variant="elevated" density="compact">{{ item.revisionNumber }}</v-chip>
                </template>
                <span>{{ $t("documents-view-revisionNumber-tooltip", { $: "Revision number" }) }}</span>
              </v-tooltip>
            </div>
            <div v-if="isItemAssigned(item)" class="assigned-label text-caption">
              <v-card>{{ $t("documents-view-assigned-label", { $: "Assigned" }) }}</v-card>
            </div>
            <div v-if="isSelected(item)" class="assigned-label text-caption pt-1">
              <v-chip color="accent" variant="elevated" density="compact">{{ $t("documents-view-selected-label", { $: "Selected" }) }}</v-chip>
            </div>
            <div class="name-label text-caption">
              <v-card>{{ item.fileName }}</v-card>
            </div>
            <v-card-text class="d-flex justify-center align-center">
              <v-icon size="50">mdi-file</v-icon>
            </v-card-text>
            <v-tooltip location="bottom" open-delay="300">
              <template #activator="{ props }">
                <v-fade-transition v-if="editable">
                  <div v-if="isHovering" class="d-inline-flex">
                    <v-btn
                      v-bind="props"
                      class="float-right ma-5"
                      color="primary"
                      icon="mdi-information-outline"
                      density="compact"
                      position="absolute"
                      location="top left"
                      @click.stop="editItem(item)" />
                  </div>
                </v-fade-transition>
              </template>
              <span>{{ $t("documents-view-itemInfo-tooltip", { $: "Document details" }) }}</span>
            </v-tooltip>
            <v-tooltip location="bottom" open-delay="300">
              <template #activator="{ props }">
                <v-fade-transition v-if="deletable">
                  <div v-if="isHovering" class="d-inline-flex">
                    <v-btn
                      v-bind="props"
                      class="float-right ma-5"
                      color="error"
                      icon="mdi-trash-can"
                      density="compact"
                      position="absolute"
                      location="top right"
                      @click.stop="deleteItem(item)" />
                  </div>
                </v-fade-transition>
              </template>
              <span>{{ $t("documents-view-deleteItem-tooltip", { $: "Delete" }) }}</span>
            </v-tooltip>
            <v-tooltip location="bottom" open-delay="300">
              <template #activator="{ props }">
                <v-fade-transition>
                  <div v-if="isHovering" class="d-inline-flex">
                    <v-btn
                      v-bind="props"
                      class="float-right ma-5"
                      icon="mdi-open-in-new"
                      density="compact"
                      position="absolute"
                      location="bottom right"
                      @click.stop="openInNewTab(item)" />
                  </div>
                </v-fade-transition>
              </template>
              <span>{{ $t("documents-view-openItemInNewTab-tooltip", { $: "Open in new tab" }) }}</span>
            </v-tooltip>
          </v-card>
        </v-hover>
      </div>
    </template>
  </v-fade-transition>
</template>

<style scoped lang="scss">
.media-gallery {
  flex: 1 0 auto;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 10px;
  padding: 10px;
  height: 100%;
  overflow-y: auto;

  &.no-items {
    display: flex;
    width: 100%;
    height: 100%;
    text-align: center;

    .no-items-label {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
    }
  }

  > .media {
    height: calc(100vh / 6);

    .v-card {
      border: 0.3em solid transparent;

      &.assigned {
        border: 0.3em solid rgb(var(--v-theme-secondary));
      }

      &.selected {
        border: 0.3em solid rgb(var(--v-theme-primary));
      }
    }
  }

  transition: border-color 0.2s ease-in-out;
}

.revision-number {
  position: absolute;
  top: 0;
  left: 0;
}


.assigned-label {
  position: absolute;
  top: 0;
  margin: 0 auto;
  text-align: center;
  width: 100%;
}

.name-label {
  position: absolute;
  bottom: 0;
  margin: 0 auto;
  text-align: center;
  width: 100%;
}
</style>
