import { ServerSideDataSource } from "@/components/Grid/ServerSideDataSource";
import ApiService from "@/services/api";
import { State as ScenarioState, useScenariosStore } from "@/store/ScenariosStore";
import {
  AgGridRequestBase,
  CreateMaterialCommand,
  CreateMaterialGroupCommand,
  IAgGridPaginationResponse,
  MaterialDto,
  ResourceType,
  UpdateMaterialCommand
} from "@masta/generated-model";
import { asServerSideDurationFormattedString } from "@masta/shared";
import { IServerSideGetRowsParams } from "ag-grid-enterprise";
import { Store } from "pinia";

type CreateMaterialCommandType = CreateMaterialCommand;
type UpdateMaterialCommandType = UpdateMaterialCommand;

export class MaterialServerSideDataSource extends ServerSideDataSource<MaterialDto, MaterialDto, CreateMaterialCommandType, UpdateMaterialCommandType, string> {
  private _scenarioStore: Store<string, ScenarioState>;

  constructor(id: string) {
    super(id);
    this._scenarioStore = useScenariosStore();
  }

  async create(entity: MaterialDto): Promise<string> {
    const request = this.transformForCreate(entity);
    const scenario = this._scenarioStore.selectedScenario;
    request.scenarioId = scenario?.id ?? "";
    const response = this.isGroup(entity)
      ? await ApiService.materials.createGroup(request as CreateMaterialGroupCommand)
      : await ApiService.materials.create(request as CreateMaterialCommand);
    return response.data.resourceId;
  }

  async getAll(params: IServerSideGetRowsParams): Promise<IAgGridPaginationResponse<MaterialDto>> {
    const groupKeys = params.request.groupKeys;
    const parentId = groupKeys[groupKeys.length - 1];

    const serverRequest = this.constructServerRequest(params);
    this.modifiedFilterModel(serverRequest);
    this.modifiedSortModel(serverRequest);

    const { data } = await ApiService.materials.getPaginated({
      ...serverRequest,
      scenarioId: this.currentScenarioId() ?? "",
      onlyReleasedModelInstances: false,
      groupId: parentId
    });
    return data;
  }

  async getSingle(id: string | undefined): Promise<MaterialDto | undefined> {
    const scenarioId = this.currentScenarioId();
    if (!scenarioId || !id) {
      return;
    }
    const response = await ApiService.materials.getSingle(id, scenarioId);
    return response.data;
  }

  async remove(entity: MaterialDto): Promise<void> {
    this.isGroup(entity) ? await ApiService.materials.removeGroup(entity.id, entity.scenarioId) : await ApiService.materials.remove(entity.id, entity.scenarioId);
  }

  async update(entity: MaterialDto): Promise<string> {
    this.isGroup(entity) ? await ApiService.materials.updateGroup(this.transformForUpdate(entity)) : await ApiService.materials.update(this.transformForUpdate(entity));

    return entity.id;
  }

  protected modifiedFilterModel(serverRequest: AgGridRequestBase) {
    const filterModel = serverRequest.filterModel;

    if (filterModel && "infiniteCapacityOffset" in filterModel) {
      if (filterModel.infiniteCapacityOffset) {
        // this is a purposed change of the filter type to `interval`. The handling of this type is on the server side
        filterModel.infiniteCapacityOffset.filterType = "interval";
      }
    }

    if (filterModel && "ag-Grid-AutoColumn" in filterModel) {
      // rewrite ag-Grid-AutoColumn to the businessId to the filterModel
      filterModel["businessId"] = filterModel["ag-Grid-AutoColumn"];
    }
  }

  protected modifiedSortModel(serverRequest: AgGridRequestBase) {
    const sortModel = serverRequest.sortModel;

    if (sortModel) {
      sortModel.forEach((sortModelItem) => {
        if (sortModelItem.colId === "ag-Grid-AutoColumn") {
          // rewrite ag-Grid-AutoColumn to the businessId
          sortModelItem.colId = "businessId";
        }
      });
    }
  }

  protected isGroup(listEntity: MaterialDto) {
    return listEntity.type === ResourceType.MaterialGroup;
  }

  protected transformForCreate(listEntity: MaterialDto): CreateMaterialCommandType {
    (listEntity as any).materialTypes = listEntity.subTypes;

    return {
      name: listEntity.name,
      businessId: listEntity.businessId,
      tags: listEntity.tags,
      color: listEntity.color,
      plannable: listEntity.plannable,
      infiniteCapacity: listEntity.infiniteCapacity,
      infiniteCapacityOffset: listEntity.infiniteCapacityOffset ? asServerSideDurationFormattedString(listEntity.infiniteCapacityOffset) : null,
      scenarioId: listEntity.scenarioId ?? this.currentScenarioId() ?? undefined,
      subTypes: Array.isArray(listEntity.subTypes) ? listEntity.subTypes : listEntity.subTypes ? [listEntity.subTypes] : [],
      measurementUnit: listEntity.measurementUnit,
      orderableUnit: listEntity.orderableUnit,
      purchaseConversionRatio: listEntity.purchaseConversionRatio,
      indivisibleQuantity: listEntity.indivisibleQuantity,
      itemName: listEntity.itemName,
      materialType: Array.isArray(listEntity.subTypes) ? (listEntity.subTypes[0] as any) : (listEntity.subTypes as any),
      trackingType: listEntity.trackingType,
      origin: listEntity.origin,
      algorithmicProperties: listEntity.algorithmicProperties,
      token: null,
      description: listEntity.description,
      parentId: listEntity.parentId
    } as CreateMaterialCommand;
  }

  protected transformForUpdate(listEntity: MaterialDto): UpdateMaterialCommandType {
    return {
      id: listEntity.id,
      ...this.transformForCreate(listEntity)
    } as UpdateMaterialCommand;
  }

  private currentScenarioId() {
    return this._scenarioStore.selectedScenario?.id;
  }
}
