<template>
  <split-layout ref="splitLayoutRef" identifier="tasks" :gutter="createSplitPanelGutterElement">
    <template #master>
      <v-card elevation="0" class="d-flex flex-column fill-height">
        <v-card-title v-if="!embedded">
          <breadcrumbs>{{ $t("task-view-tasks-title", { $: "Tasks" }) }}</breadcrumbs>
        </v-card-title>
        <v-card-text class="fill-height">
          <v-row class="fill-height" no-gutters>
            <v-col cols="12">
              <tasks-grid :filter-by-product-id="filterByProductId" :reference-value="$route.query.taskId?.toString()" @row-selected="rowSelected" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
    <template #detail>
      <v-card v-if="taskDetail" elevation="7" class="d-flex flex-column fill-height tasks-card">
        <close-card-button @click="closeDetails"></close-card-button>
        <v-card-title class="pt-2">
          <breadcrumbs>
            {{ taskDetail?.name }}
          </breadcrumbs>
        </v-card-title>
        <v-card-text class="fill-height">
          <v-row class="fill-height">
            <v-col cols="12">
              <task-details class="d-flex flex-column fill-height" :task="taskDetail" :schema-tabs="schemaTabs" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card v-if="!taskDetail" elevation="7" class="d-flex flex-column fill-height no-tasks-card">
        <close-card-button @click="closeDetails"></close-card-button>
        <v-card-text class="fill-height">
          <v-row class="fill-height">
            <v-col cols="12" class="d-flex align-center justify-center">
              <label class="text-secondary text-h6 font-weight-regular">
                {{ $t("task-view-noTaskSelected-label", { $: "No production task selected" }) }}
              </label>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </split-layout>
</template>

<script lang="ts" setup>
import Breadcrumbs from "@/components/Layout/Breadcrumbs.vue";
import SplitLayout from "@/components/Layout/SplitLayout.vue";
import TaskDetails from "@/components/Tasks/TaskDetails.vue";
import TasksGrid from "@/components/Tasks/TasksGrid.vue";
import { $t } from "@/i18n";
import { useModelInstancesStore } from "@/store/ModelInstancesStore";
import { useProductionTasksStore } from "@/store/ProductionTasksStore";
import { useScenariosStore } from "@/store/ScenariosStore";
import { ProductionTaskDetailsDto, ProductionTaskDto, StepDto } from "@masta/generated-model";
import { onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import CloseCardButton from "@/components/CloseCardButton.vue";
import { storeToRefs } from "pinia";
import { useSystemStore } from "@/store/SystemStore";
import { ModelInstanceTab } from "@/components/ModelInstances/model-instance-tab";

const props = defineProps<{
  componentRoutePath: string;
  embedded?: boolean;
  filterByProductId?: string;
}>();

const taskDetail = ref<ProductionTaskDetailsDto | null | undefined>(null);
const task = ref<ProductionTaskDto | null>(null);
const step = ref<StepDto | null>(null);
const refreshGridAction = ref<(() => void) | null>(null);
const splitLayoutRef = ref<typeof SplitLayout | null>(null);

const productionTasksStore = useProductionTasksStore();
const modelInstancesStore = useModelInstancesStore();
const scenariosStore = useScenariosStore();
const systemStore = useSystemStore();
const { schemas } = storeToRefs(modelInstancesStore);
const { modelInstanceTabs } = storeToRefs(systemStore);
const schemaTabs = ref<ModelInstanceTab[]>([]);

const router = useRouter();
const route = useRoute();

onMounted(async () => {
  await modelInstancesStore.fetchSchemas();
  for (const tab of modelInstanceTabs.value?.productionTasks ?? []) {
    const schema = schemas.value.find((x) => x.schemaName === tab.schemaName && x.schemaKey === tab.schemaKey && x.version === tab.schemaVersion);
    if (schema) {
      schemaTabs.value.push({ title: tab.title ?? "", schema, resourceSubTypes: tab.resourceSubTypes });
    }
  }

  if (taskDetail.value == null) {
    closeDetails();
  }
});

watch(
  () => route.query.taskId,
  async (taskId) => {
    if (taskId != null) {
      if (taskDetail.value != null && taskDetail.value.id === taskId) {
        return;
      } else {
        taskDetail.value = await productionTasksStore.fetchDetail(taskId as string);
        showDetails();
      }
    } else {
      taskDetail.value = null;
    }
  },
  { immediate: true }
);

const rowSelected = async (selectedTask: ProductionTaskDto) => {
  if (selectedTask !== null) {
    task.value = selectedTask;
    if (!props.embedded) {
      await router.push({ query: { taskId: selectedTask.id } });
    } else {
      if (taskDetail.value != null && taskDetail.value.id === selectedTask.id) {
        return;
      } else {
        taskDetail.value = await productionTasksStore.fetchDetail(selectedTask.id);
        showDetails();
      }
    }
  }
};

/**
 * Called to create gutter element for split panel.
 */
function createSplitPanelGutterElement(index: number, direction: "horizontal" | "vertical") {
  const gutter = document.createElement("div");
  gutter.classList.add("gutter-tasks");
  return gutter;
}

function showDetails() {
  splitLayoutRef.value?.showDetails();
}

function closeDetails() {
  splitLayoutRef.value?.closeDetails();
}
</script>

<style lang="scss">
.tasks-card {
  border: 2px solid rgb(var(--v-theme-primary));
  border-radius: 0;

  .close-card-button {
    position: absolute;
    top: 0px;
    right: 10px;
  }
}

.no-tasks-card {
  border: 2px solid rgb(var(--v-theme-primary), 0);
  border-radius: 0;

  .close-card-button {
    position: absolute;
    top: 0px;
    right: 10px;
  }
}

.gutter-tasks {
  cursor: col-resize;
}
</style>
