<script lang="ts" setup>
import { DocumentModel, DocumentType } from "@/components/Documents/DocumentModel";
import Online3dViewer from "@/views/ProductOverview/Online3dViewer.vue";
import { Ref, ref, UnwrapRef, watch } from "vue";

const documents: Ref<DocumentModel[]> = defineModel<DocumentModel[]>("documents", {
  default: [],
  required: true
});

const showGallery = defineModel<boolean>({
  default: false
});

const selectedDocument = defineModel<UnwrapRef<DocumentModel | null | undefined>>("selectedDocument", {
  default: null
});

const props = defineProps<{ showOnSelectionChange?: boolean }>();

const selectedItemIndex = ref<number>(0);

watch(selectedDocument, (newDoc) => {
  const newIdIndex = documents.value.findIndex((doc) => doc.id === newDoc?.id);
  if (newDoc?.id && newIdIndex !== -1 && newIdIndex !== selectedItemIndex.value) {
    selectedItemIndex.value = newIdIndex;
    if (props.showOnSelectionChange && showGallery.value === false) {
      showGallery.value = true;
    }
  }
}, {
  immediate: true
});

function left() {
  selectedItemIndex.value = (selectedItemIndex.value - 1 + documents.value.length) % documents.value.length;
  selectedDocument.value = documents.value[selectedItemIndex.value];
}

function right() {
  selectedItemIndex.value = (selectedItemIndex.value + 1) % documents.value.length;
  selectedDocument.value = documents.value[selectedItemIndex.value];
}
</script>

<template>
  <v-dialog v-model="showGallery" max-width="1500px" class="gallery-dialog" @keydown.left="left" @keydown.right="right">
    <div class="gallery">
      <v-carousel v-model="selectedItemIndex" hide-delimiters height="100%" class="text-center" show-arrows>
        <template #prev="{ props }">
          <v-btn fab color="primary" icon="mdi mdi-chevron-left" @click="props.onClick"></v-btn>
        </template>
        <template #next="{ props }">
          <v-btn fab color="primary" icon="mdi mdi-chevron-right" @click="props.onClick"></v-btn>
        </template>
        <v-carousel-item v-for="document in documents" :key="document.id">
          <div class="carousel-item-content">
            <div v-if="document.documentType === DocumentType.ModelFile && document.file" class="stp-model">
              <Online3dViewer :file="document.file" />
            </div>
            <v-img v-else-if="document.documentType === DocumentType.ImageFile" :src="document.downloadUrl" max-height="80vh" />
            <v-icon v-else size="80vh">mdi-file</v-icon>
            <div class="file-info py-8 text-white text-h4" :class="{'stp': document.documentType === DocumentType.ModelFile}">
              {{ document.fileName }}
            </div>
          </div>
        </v-carousel-item>
      </v-carousel>
      <v-btn color="primary" position="absolute" location="top right" class="mt-1 mr-1" icon="mdi mdi-close" @click="showGallery = false" />
    </div>
  </v-dialog>
</template>

<style scoped lang="scss">
.gallery-dialog {

  height: 100vh;

  :deep(.v-overlay__content) {
    height: calc(100vh - 48px);
  }

  .gallery {
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 1em;
    overflow: hidden;

    :deep(.v-window) {
      height: 100% !important;

      .v-window-item {
        height: 100% !important;
      }
    }

    .carousel-item-content {
      display: flex;
      width: 100%;
      height: calc(100vh - 48px);
      align-items: center;
      justify-content: center;

      .file-info {
        position: absolute;
        bottom: 0;
        width: 100%;
        text-align: center;

        &.stp {
          background-color: rgba(0, 0, 0, 0.8);
        }
      }

      .stp-model {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        canvas {
          min-height: 100%;
        }
      }
    }
  }
}
</style>
