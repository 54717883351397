<script lang="ts" setup>
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { inject, ref } from "vue";
import SystemEnumService from "@/services/system-enum.service";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import { useTheme } from "vuetify";
import { $dateTimeFormatterSymbol, DateFormatter } from "@masta/shared";
import { getResourceCapacitySourceCellStyle } from "@/components/Tasks/TaskUtils";
import { $t } from "@/i18n";
import { translateMeasurementUnit, translateResourceType } from "@/composables/translateEnum";

const theme = useTheme();

interface Props {
  step: any;
}

defineProps<Props>();
const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;
const gridWrapperRef = ref<GridWrapperComponent>();
const defaultColDef = ref({
  sortable: true,
  resizable: true,
  headerValueGetter: (_: any) => $t("task-resourceCapacityList-name-label", { $: "Name" })
});
const autoGroupColumnDef = ref({
  minWidth: 200
});

function onPrepareColumns(columnDefs: any) {
  columnDefs.value = [
    {
      field: "entrySource",
      headerValueGetter: (_: any) => $t("task-resourceCapacityList-source-label", { $: "Source" }),
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      enableRowGroup: true,
      cellStyle: (params: any) => getResourceCapacitySourceCellStyle(params.value),
      valueGetter: (params: any) => (params.data ? SystemEnumService.resourceCapacityEntrySourceSpecialMapping(params.data.entrySource) : null)
    },
    {
      field: "entrySourcePayloadType",
      headerValueGetter: (_: any) => $t("task-resourceCapacityList-entrySourcePayloadType-label", { $: "Type" }),
      editable: false,
      sortable: true,
      filter: true
    },
    {
      field: "businessId",
      headerValueGetter: (_: any) => $t("task-resourceCapacityList-businessId-label", { $: "Business ID" }),
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      enableRowGroup: true
    },
    {
      field: "name",
      headerValueGetter: (_: any) => $t("task-resourceCapacityList-resourceName-label", { $: "Resource name" }),
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      enableRowGroup: true
    },
    {
      field: "resourceType",
      headerValueGetter: (_: any) => $t("task-resourceCapacityList-resourceType-label", { $: "Resource Type" }),
      editable: false,
      sortable: true,
      filter: true,
      valueGetter: (params: any) => (params.data ? translateResourceType(params.data.resourceType) : null)
    },
    {
      field: "quantity",
      headerValueGetter: (_: any) => $t("task-resourceCapacityList-quantity-label", { $: "Quantity" }),
      editable: false,
      sortable: true,
      filter: true
    },
    {
      field: "quantityUnit",
      headerValueGetter: (_: any) => $t("task-resourceCapacityList-quantityUnit-label", { $: "Measurement Unit" }),
      editable: false,
      sortable: true,
      filter: true,
      valueGetter: (params: any) => (params.data ? translateMeasurementUnit(params.data.quantityUnit) : null)
    },
    {
      field: "periodStart",
      headerValueGetter: (_: any) => $t("task-resourceCapacityList-start-label", { $: "Start" }),
      editable: false,
      sortable: true,
      filter: "agDateColumnFilter",
      valueFormatter: (params: any) => {
        return params.data ? $dateTimeFormatter(params.data.periodStart) : null;
      }
    },
    {
      field: "periodEnd",
      headerValueGetter: (_: any) => $t("task-resourceCapacityList-end-label", { $: "End" }),
      editable: false,
      sortable: true,
      filter: "agDateColumnFilter",
      valueFormatter: (params: any) => {
        return params.data ? $dateTimeFormatter(params.data.periodEnd) : null;
      }
    },
    {
      field: "capacityGroup",
      headerValueGetter: (_: any) => $t("task-resourceCapacityList-capacityGroup-label", { $: "Group" }),
      editable: false,
      sortable: true,
      filter: true,
      valueGetter: (params: any) => (params.data ? SystemEnumService.resourceCapacityGroup(params.data.capacityGroup) : null)
    }
  ];
}
</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    identifier="task-resource-capacities"
    class="details"
    row-selection="single"
    :default-col-def="defaultColDef"
    :auto-group-column-def="autoGroupColumnDef"
    :grid-data="step.capacities"
    :animate-rows="true"
    @prepare-columns="onPrepareColumns"
  />
</template>

<style lang="scss" scoped>
.details {
  .ag-details-row {
    padding: 5px;
  }
}
</style>
