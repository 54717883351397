<script lang="ts" setup>
import Breadcrumbs from "@/components/Layout/Breadcrumbs.vue";
import { $t } from "@/i18n";
import { computed, onBeforeMount, ref, UnwrapRef, useSlots, watch } from "vue";
import { CreateDocumentForContextCommand, CreateNewDocumentRevisionWithNewAttachmentCommand, DocumentDto, ModelSchemaKeys, UpdateDocumentCommand } from "@masta/generated-model";
import { useDocumentsStore } from "@/store/DocumentsStore";
import { DocumentManager } from "@/components/Documents/DocumentManager";
import { DocumentError, DocumentModel } from "@/components/Documents/DocumentModel";
import ApiService from "@/services/api";
import { DocumentProvider } from "@/components/Documents/IDocumentProvider";
import DocumentsManager from "@/components/Documents/DocumentsManager.vue";
import { useRouteQuery } from "@vueuse/router";
import { v4 as uuidv4 } from "uuid";
import { useScenariosStore } from "@/store/ScenariosStore";
import ModelInstanceResourcesGrid from "@/components/ModelInstances/ModelInstanceResourcesGrid.vue";
import ModelInstanceCostCatalogueItemsGrid from "@/components/ModelInstances/ModelInstanceCostCatalogueItemsGrid.vue";
import ModelInstanceTasksGrid from "@/components/ModelInstances/ModelInstanceTasksGrid.vue";
import ModelInstanceOrdersGrid from "@/components/ModelInstances/ModelInstanceOrdersGrid.vue";
import DocumentDetails from "@/components/Documents/DocumentDetails.vue";
import CloseCardButton from "@/components/CloseCardButton.vue";
import SchemaSelector from "@/components/ModelInstances/SchemaSelector.vue";
import { storeToRefs } from "pinia";
import { ModelSchemaWithLabelDto, useModelInstancesStore } from "@/store/ModelInstancesStore";

const props = defineProps<{
  /**
   * If true, the component will use route queries to store the selected schema id and the current tab in query string.
   */
  routeQueries?: boolean;
}>();

const uploading = ref(false);
const uploadMessage = ref("");
const slots = useSlots();

const modelInstanceStore = useModelInstancesStore();
const documentStore = useDocumentsStore();
const scenariosStore = useScenariosStore();
const { schemas } = storeToRefs(modelInstanceStore);

type Tabs = "details" | "resources" | "tasks" | "orders" | "costCatalogueItems";

const schemaId = props.routeQueries ? useRouteQuery<string | null>("schemaId", null) : ref<string | null>(null);
const selectedSchema = ref<ModelSchemaWithLabelDto | null>(null);
const filteredSchemas = computed(() => {
  return schemas.value.filter((schema) => schema.schemaKey === ModelSchemaKeys.mODEL_SCHEMA_JSON_DOCUMENTDEFINITION);
});

const selectedDocuments = defineModel<UnwrapRef<DocumentModel[]>>("selectedDocuments", {
  local: true,
  required: false,
  default: []
});

onBeforeMount(async () => {
  await modelInstanceStore.fetchSchemas();

  watch(
    schemaId,
    async (id: string | null) => {
      if (id) {
        selectedSchema.value = filteredSchemas.value.find((schema) => schema.schemaId === id) ?? null;
      } else {
        selectedSchema.value = null;
      }
    },
    { immediate: true }
  );
});

function onSelectedSchemaChange(schema: UnwrapRef<ModelSchemaWithLabelDto> | null | undefined) {
  if (schema) {
    schemaId.value = schema.schemaId;
  } else {
    schemaId.value = null;
  }
}

const currentTab = props.routeQueries ? useRouteQuery<Tabs | null>("tab", null) : ref<Tabs | null>(null);

class AssignedDocumentProvider extends DocumentProvider {
  constructor() {
    super();
  }

  get profileName() {
    return selectedSchema.value?.schemaName ?? "";
  }

  async getAll(): Promise<DocumentDto[]> {
    const { data } = await ApiService.documents.getAllDocumentsForContext(this.profileName);
    return data.documentDtos;
  }
}

class RepositoryDocumentManager extends DocumentManager {
  constructor(public assignmentDocumentProvider: DocumentProvider) {
    super();
  }

  get profileName() {
    return selectedSchema.value?.schemaName ?? "";
  }

  async assignDocument(doc: DocumentModel): Promise<void> {
    throw new Error("Method intentionally not implemented.");
  }

  async unassignDocument(doc: DocumentModel): Promise<void> {
    throw new Error("Method intentionally not implemented.");
  }

  async uploadDocument(file: File, doAssignment: boolean): Promise<void> {
    const selectedScenario = scenariosStore.selectedScenario;

    if (!selectedScenario) throw new DocumentError("Scenario is not set");

    const modelInstanceId = await documentStore.createDocumentWithAttachment({
      scenarioId: selectedScenario.id,
      businessId: uuidv4(),
      profileName: this.assignmentDocumentProvider.profileName,
      tags: [],
      file: file
    } as Partial<CreateDocumentForContextCommand> as CreateDocumentForContextCommand);
  }

  async deleteDocument(doc: DocumentModel): Promise<void> {
    await documentStore.deleteDocumentWithAttachment(doc.dto);
  }

  async saveDocument(doc: DocumentModel): Promise<void> {
    await documentStore.updateDocumentWithAttachment({
      id: doc.dto.id,
      description: doc.description,
      revisionNumber: doc.dto.revisionNumber,
      tags: doc.tags,
      businessId: doc.businessId
    } as Partial<UpdateDocumentCommand> as UpdateDocumentCommand);

    await this.assignmentDocumentProvider.refresh();
  }

  async createNewRevision(file: File, doc: DocumentModel, doAssignment: boolean): Promise<void> {
    const selectedScenario = scenariosStore.selectedScenario;

    if (!selectedScenario) throw new DocumentError("Scenario is not set");

    await documentStore.createNewRevisionWithNewAttachment({ file: file, id: doc.dto.id } as CreateNewDocumentRevisionWithNewAttachmentCommand);
  }
}

const assignedDocumentProvider = new AssignedDocumentProvider();
const manager = new RepositoryDocumentManager(assignedDocumentProvider);

watch(
  selectedSchema,
  async (schema) => {
    if (schema) {
      await assignedDocumentProvider.refresh();
    }
  },
  { immediate: true }
);

function uploadStart() {
  uploading.value = true;
}

function uploadEnd() {
  uploading.value = false;
}

function handleUploadStage(stage: string) {
  uploadMessage.value = stage;
}
</script>

<template>
  <v-card elevation="0" class="document-repository-card d-flex flex-column fill-height">
    <v-overlay persistent :model-value="uploading" class="d-flex align-center justify-center">
      <v-progress-circular color="primary" indeterminate size="64"></v-progress-circular>
      <div class="mt-4">{{ uploadMessage }}</div>
    </v-overlay>
    <v-card-title>
      <breadcrumbs v-if="!slots.breadcrumbs">
        <div>{{ $t("documentRepository-view-title", { $: "Document repository" }) }}</div>
        <div class="d-flex pl-4 flex-grow-1" style="margin-top: -4px">
          <div class="d-inline-flex align-self-stretch text-secondary font-weight-bold px-4">
            <v-divider vertical />
          </div>
          <div class="d-flex" style="min-width: 20vw">
            <schema-selector :model-value="selectedSchema" :schemas="filteredSchemas" icon="mdi-folder-outline" @update:model-value="onSelectedSchemaChange" />
          </div>
        </div>
      </breadcrumbs>
      <div v-else class="d-flex align-center pt-4">
        <slot name="breadcrumbs" />
        <div class="d-flex pl-4 flex-grow-1" style="margin-top: -4px">
          <div class="d-inline-flex align-self-stretch text-secondary font-weight-bold px-4">
            <v-divider vertical />
          </div>
          <div class="d-flex" style="min-width: 20vw">
            <schema-selector :model-value="selectedSchema" :schemas="filteredSchemas" icon="mdi-folder-outline" @update:model-value="onSelectedSchemaChange" />
          </div>
        </div>
      </div>
      <div v-if="slots.breadcrumbs" class="py-4">
        <v-divider />
      </div>
    </v-card-title>
    <v-card-text v-if="selectedSchema" class="fill-height">
      <documents-manager
v-model:selected-documents="selectedDocuments" :manager="manager" hide-assignment-actions @upload-start="uploadStart" @upload-end="uploadEnd"
                         @upload-stage="handleUploadStage">
        <template v-if="slots.details" #details="scope">
          <slot name="details" v-bind="scope" />
        </template>
        <template v-else #details="scope">
          <div class="w-100 fill-height document-repository-details">
            <v-card-subtitle style="opacity: unset">
              <v-tabs
                v-model="currentTab"
                class="tab-details text-disabled bg-white"
                selected-class="v-slide-group-item--active text-primary bg-white"
                density="compact"
                color="primary"
                show-arrows
              >
                <v-tab value="details">
                  <v-icon class="pr-2">mdi-information-outline</v-icon>
                  {{ $t("documentRepository-view-details-tab-label", { $: "Details" }) }}
                </v-tab>
                <v-tab value="resources">
                  <v-icon class="pr-2">mdi-tag-multiple</v-icon>
                  {{ $t("modelInstanceAssignments-view-resources-tab-label", { $: "Resource Assign." }) }}
                </v-tab>
                <v-tab value="tasks">
                  <v-icon class="pr-2">mdi-list-box-outline</v-icon>
                  {{ $t("modelInstanceAssignments-view-tasks-tab-label", { $: "Tasks Assign." }) }}
                </v-tab>
                <v-tab value="orders">
                  <v-icon class="pr-2">mdi-cash-register</v-icon>
                  {{ $t("modelInstanceAssignments-view-orders-tab-label", { $: "Orders Assign." }) }}
                </v-tab>
                <v-tab value="costCatalogueItems">
                  <v-icon class="pr-2">mdi-currency-usd</v-icon>
                  {{ $t("modelInstanceAssignments-view-costCatalogueItems-tab-label", { $: "Cost Catalogue Items Assign." }) }}
                </v-tab>
              </v-tabs>
              <v-spacer />
              <close-card-button @click="scope.closeDetails"></close-card-button>
            </v-card-subtitle>
            <v-card-text class="fill-height">
              <v-window v-model="currentTab" class="bg-white flex-fill d-block" style="width: 100%; height: 100%">
                <v-window-item value="details" class="fill-height">
                  <document-details v-model="scope.selectedDocument" :editable="scope.editable" class="pa-0 pt-2" @save="scope.onSaveAction" />
                </v-window-item>
                <v-window-item value="resources" class="fill-height">
                  <model-instance-resources-grid v-if="scope.selectedDocument" :model-value="scope.selectedDocument.dto.id" />
                </v-window-item>
                <v-window-item value="tasks" class="fill-height">
                  <model-instance-tasks-grid v-if="scope.selectedDocument" :model-value="scope.selectedDocument.dto.id" />
                </v-window-item>
                <v-window-item value="orders" class="fill-height">
                  <model-instance-orders-grid v-if="scope.selectedDocument" :model-value="scope.selectedDocument.dto.id" />
                </v-window-item>
                <v-window-item value="costCatalogueItems" class="fill-height">
                  <model-instance-cost-catalogue-items-grid v-if="scope.selectedDocument" :model-value="scope.selectedDocument.dto.id" />
                </v-window-item>
              </v-window>
            </v-card-text>
          </div>
        </template>
      </documents-manager>
    </v-card-text>
    <v-card-text v-else class="fill-height">
      <v-row class="fill-height" no-gutters>
        <v-col cols="12" class="d-flex align-center justify-center">
          <label class="text-secondary text-h6 font-weight-regular">
            {{ $t("documentRepository-view-noDocumentContextSelected-label", { $: "No document context selected" }) }}
          </label>
        </v-col>
      </v-row>
    </v-card-text>
    <slot name="actions" />
  </v-card>
</template>

<style scoped lang="scss">
.document-repository-card {
  position: relative;
}

.tab-details {
  position: relative;

  > .v-tab {
    border-radius: 0.5rem 0.5rem 0 0;
  }
}

.document-repository-details {
  .v-card-subtitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 0;
  }

  .close-card-button {
    position: relative;
    top: unset;
    left: unset;
    right: unset;
  }
}
</style>
