<script lang="ts">
import { CustomEvents } from "@/components/Grid/Events/CustomEvents";
import { isDefined } from "@vueuse/core";
import { IRowNode, RowEditingStartedEvent, RowEditingStoppedEvent, RowGroupOpenedEvent } from "ag-grid-community";
import { onBeforeUnmount, ref, shallowRef } from "vue";
import { IActionsRowEvent } from "@/components/Grid/CellRenderers/IActionsRowEvent";
import { IActionsCellRendererParams } from "@/components/Grid/CellRenderers/IActionsCellRendererParams";

class ActionsRowEvent<TData = any> implements IActionsRowEvent<TData> {
  event: Event;
  data: TData | undefined;
  node: IRowNode<TData>;

  constructor(event: Event, data: TData | undefined, node: IRowNode<TData>) {
    this.event = event;
    this.data = data;
    this.node = node;
  }
}

export default {
  props: {
    params: {
      type: Object as () => IActionsCellRendererParams,
      required: true
    }
  },
  setup(props: { params: IActionsCellRendererParams }) {
    const { data, node, api } = props.params;
    const { onDetails, onEdit, onDelete, onDuplicate, onCreateChild, createChildDisabled } = props.params;

    const customActionComponent = shallowRef<any>(props.params.customActionComponent ?? undefined);
    const customActionComponentProps = ref<any>({ ...(props.params.customActionComponentProps ?? {}), gridApi: api, node });

    const crudActionsVisible = ref<boolean>(true);

    const onRowEditingStarted = function(event: RowEditingStartedEvent) {
      crudActionsVisible.value = false;
    };
    const onRowEditingStopped = function(event: RowEditingStoppedEvent) {
      crudActionsVisible.value = true;
    };
    api.addEventListener("rowEditingStarted", onRowEditingStarted);
    api.addEventListener("rowEditingStopped", onRowEditingStopped);
    onBeforeUnmount(() => {
      api.removeEventListener("rowEditingStarted", onRowEditingStarted);
      api.removeEventListener("rowEditingStopped", onRowEditingStopped);
    });

    const isCreateChildPossible = function() {
      if (isDefined(createChildDisabled)) {
        return !createChildDisabled(data);
      } else {
        const isServerSideGroup = api.getGridOption("isServerSideGroup");
        return isServerSideGroup ? isServerSideGroup(data) : true;
      }
    };

    const isDetailsDefined = ref<boolean>(isDefined(onDetails));
    const isEditDefined = ref<boolean>(isDefined(onEdit));
    const isDeleteDefined = ref<boolean>(isDefined(onDelete));
    const isDuplicateDefined = ref<boolean>(isDefined(onDuplicate));
    const isCreateChildDefined = ref<boolean>(isDefined(onCreateChild) && isCreateChildPossible());

    return {
      isDetailsDefined,
      isEditDefined,
      isDeleteDefined,
      isDuplicateDefined,
      isCreateChildDefined,
      crudActionsVisible,
      customActionComponent,
      customActionComponentProps
    };
  },
  methods: {
    onMouseEvent(event: MouseEvent, callback: (event: ActionsRowEvent) => void, selectNode: boolean = true) {
      const { data, node } = this.params;

      const doCallback = () => {
        setTimeout(() => {
          callback(new ActionsRowEvent(event, data, node));
        });
      };

      if (!selectNode) {
        doCallback();
        return;
      }

      if (selectNode && node.isRowPinned()) {
        doCallback();
        return;
      }

      if (node.isSelected()) {
        doCallback();
      } else {
        node.setSelected(true, true);
        doCallback();
      }
    },
    onDetailsClicked(event: MouseEvent) {
      this.onMouseEvent(event, this.params.onDetails);
    },
    onEditClicked(event: MouseEvent) {
      this.onMouseEvent(event, this.params.onEdit);
    },
    onDeleteClicked(event: MouseEvent) {
      this.onMouseEvent(event, this.params.onDelete);
    },
    onDuplicateClicked(event: MouseEvent) {
      this.onMouseEvent(event, this.params.onDuplicate);
    },
    onCreateChildClicked(event: MouseEvent) {
      event.preventDefault();
      event.stopPropagation();

      const { node, api: gridApi } = this.params;
      // the case where a node is a group and may have children but does not yet have as is not expandable
      if (!node.isExpandable()) {
        this.callOnCreateChild(event);

        return;
      }

      // the case where a node has children and can be expanded
      if (node.expanded) {
        this.callOnCreateChild(event);
        return;
      } else {
        // node was collapsed
        if (node.expanded === false) {

          const expandedChangedListener = (e: RowGroupOpenedEvent) => {
            if (e.node === node) {
              node.removeEventListener("expandedChanged", expandedChangedListener);
              this.callOnCreateChild(event);
            }
          };

          node.addEventListener("expandedChanged", expandedChangedListener);
          node.setExpanded(true);
          return;
        }

        // node was never expanded (need to load data from server first)
        if (node.expanded === undefined) {

          const loadedRowGroupDataListener = (e: { type: string, parentNode: IRowNode }) => {
            if (e.parentNode === node) {
              gridApi.removeEventListener(CustomEvents.GRID_ROWS_LOADED, loadedRowGroupDataListener);
              this.callOnCreateChild(event);
            }
          };

          gridApi.addEventListener(CustomEvents.GRID_ROWS_LOADED, loadedRowGroupDataListener);
          node.setExpanded(true);

          return;
        }
      }
    },
    callOnCreateChild(event: MouseEvent) {
      const { onCreateChild, data, node } = this.params;
      setTimeout(() => {
        onCreateChild(new ActionsRowEvent(event, data, node));
      });
    }
  }
};
</script>

<template>
  <div class="actions-cell w-100 h-100">
    <p>&nbsp;</p>

    <div class="actions-cell-content align-center pl-4">
      <v-tooltip v-if="isDetailsDefined" location="bottom" open-delay="300">
        <template #activator="{ props }">
          <v-btn
            v-show="crudActionsVisible"
            id="btn-details-row-action" size="large" density="compact" color="secondary"
            icon="mdi-text-box-search-outline" variant="plain" hide-details single-line v-bind="props"
            @click="onDetailsClicked">
          </v-btn>
        </template>
        <span>{{ $t("actions-column-details-action", { $: "Open details" }) }}</span>
      </v-tooltip>
      <v-tooltip v-if="isEditDefined" location="bottom" open-delay="300">
        <template #activator="{ props }">
          <v-btn
            v-show="crudActionsVisible"
            id="btn-edit-row-action" size="large" density="compact" color="primary"
            icon="mdi-pencil" variant="plain" hide-details single-line v-bind="props"
            @click="onEditClicked">
          </v-btn>
        </template>
        <span>{{ $t("list-menu-edit-tooltip", { $: "Edit" }) }}</span>
      </v-tooltip>
      <v-tooltip v-if="isDeleteDefined" location="bottom" open-delay="300">
        <template #activator="{ props }">
          <v-btn
            v-show="crudActionsVisible"
            id="btn-delete-row-action" size="large" density="compact" color="error"
            icon="mdi-delete" variant="plain" hide-details single-line v-bind="props"
            @click="onDeleteClicked">
          </v-btn>
        </template>
        <span>{{ $t("list-menu-delete-tooltip", { $: "Delete" }) }}</span>
      </v-tooltip>
      <v-tooltip v-if="isDuplicateDefined" location="bottom" open-delay="300">
        <template #activator="{ props }">
          <v-btn
            v-show="crudActionsVisible"
            id="btn-duplicate-row-action" size="large" density="compact" color="golden"
            icon="mdi-content-copy" variant="plain" hide-details single-line v-bind="props"
            @click="onDuplicateClicked">
          </v-btn>
        </template>
        <span>{{ $t("list-menu-duplicate-tooltip", { $: "Duplicate" }) }}</span>
      </v-tooltip>
      <v-tooltip v-if="isCreateChildDefined" location="bottom" open-delay="300">
        <template #activator="{ props }">
          <v-btn
            v-show="crudActionsVisible"
            id="btn-createChild-row-action" size="large" density="compact" color="info"
            icon="mdi-plus-circle" variant="plain" hide-details single-line v-bind="props"
            @click="onCreateChildClicked">
          </v-btn>
        </template>
        <span>{{ $t("list-menu-addChild-tooltip", { $: "Add child" }) }}</span>
      </v-tooltip>
      <component :is="customActionComponent" v-bind="customActionComponentProps" />
    </div>
  </div>
</template>

<style lang="scss">

$row-hover-background-color: rgb(239, 236, 255);
$row-selected-background-color: rgb(244, 242, 258);

.actions-cell {
  position: relative;
  overflow: hidden;
}

.ag-row-hover .actions-cell {
  overflow: visible;
}

.actions-cell-content {
  .v-btn {
    margin-left: -2px;
    margin-right: -2px;
  }

  display: none;
  position: absolute;
  top: 4px;
  left: 0;
  border-radius: 8px;
  z-index: 1000;
}

.ag-row-hover:not(.ag-row-selected) .actions-cell-content {
  display: flex;
  width: auto;
  overflow: visible;
  background-color: $row-hover-background-color;
}

.ag-row-hover.ag-row-selected .actions-cell-content {
  display: flex;
  width: auto;
  overflow: visible;
  background-color: $row-hover-background-color;
  background-image: linear-gradient(var(--ag-selected-row-background-color), var(--ag-selected-row-background-color))
}

.ag-row-hover.ag-row-pinned .actions-cell-content {
  left: 6px !important;
}

.ag-row-editing #btn-details-row-action,
.ag-row-editing #btn-edit-row-action,
.ag-row-editing #btn-delete-row-action,
.ag-row-editing #btn-duplicate-row-action,
.ag-row-editing #btn-createChild-row-action {
  display: none;
}
</style>
