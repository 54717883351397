import ApiService from "@/services/api";
import { defineStore } from "pinia";
import { AssignModelInstanceToResourceCommand, DeassignModelInstanceFromResourceCommand } from "@masta/generated-model";
import { useScenariosStore } from "@/store/ScenariosStore";
import { useErrorsStore } from "@/store/ErrorsStore";

interface State {}

export const useMaterialsStore = defineStore("materials", {
  state: (): State => ({}),
  actions: {
    async assignModelInstance(request: AssignModelInstanceToResourceCommand) {
      try {
        await ApiService.resources.assignModelInstance(request);
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async deassignModelInstance(request: DeassignModelInstanceFromResourceCommand) {
      try {
        await ApiService.resources.deassignModelInstance(request);
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async fetchMaterial(id: string) {
      try {
        const scenariosStore = useScenariosStore();
        if (!scenariosStore.selectedScenario) return;
        const response = await ApiService.materials.getSingle(id, scenariosStore.selectedScenario.id);
        return response.data;
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    }
  }
});
