import { MaterialDto } from "@masta/generated-model";
import { defineStore } from "pinia";
import { useMaterialsStore } from "@/store/MaterialsStore";

interface State {
  history: MaterialDto[];
  limit: number;
  compareByField: keyof MaterialDto;
}

function provideCompareByFn(material: MaterialDto, fieldName: keyof MaterialDto) {
  return (other: MaterialDto) => other[fieldName] === material[fieldName];
}

export const useRecentMaterialsStore = defineStore("recentMaterials", {
  state: (): State => ({
    history: [],
    limit: 5,
    compareByField: "id" as keyof MaterialDto
  }),
  actions: {
    useLimit(limit: number) {
      this.$state.limit = limit;
    },
    enforceLimit() {
      const history = this.$state.history;
      while (this.$state.limit !== -1 && history.length > this.$state.limit) {
        history.shift();
      }
    },
    append(material: MaterialDto) {
      if (!material) {
        return;
      }
      const history = this.$state.history;
      const index = history.findIndex(provideCompareByFn(material, this.$state.compareByField));
      if (index === -1) {
        history.push(material);
        this.enforceLimit();
      } else {
        history.splice(index, 1);
        history.push(material);
        this.enforceLimit();
      }
    },
    remove(material: MaterialDto): boolean {
      const history = this.$state.history;
      const index = history.findIndex(provideCompareByFn(material, this.$state.compareByField));
      if (index !== -1) {
        history.splice(index, 1);
        this.$state.history = history;
        return true;
      }
      return false;
    },
    contains(material: MaterialDto): boolean {
      const history = this.$state.history;
      const index = history.findIndex(provideCompareByFn(material, this.$state.compareByField));
      return index !== -1;
    },
    store() {
      const ids = this.$state.history.map((m) => m.id).join(";");
      localStorage.setItem("recentMaterials.ids", ids);
    },
    async restore() {
      const materialStore = useMaterialsStore();
      const materialIds = localStorage.getItem("recentMaterials.ids")?.split(";");
      if (!materialIds) {
        return;
      }
      const restored = [];
      for (const id of materialIds) {
        const material = await materialStore.fetchMaterial(id);
        if (!material) {
          continue;
        }
        restored.push(material);
      }
      this.$state.history = restored;
    }
  }
});
