<script lang="ts" setup>
// x
import { inject, nextTick, onBeforeUnmount, onMounted, ref, watch } from "vue";
import MegaMenuOverlayContent from "@/components/MegaMenu/MegaMenuOverlayContent.vue";
import { RouteLocation, useRoute } from "vue-router";
import { MenuItem, MenuMetaDescription } from "@/components/MegaMenu/MegaMenuModel";
import { storeToRefs } from "pinia";
import { useTranslationsStore } from "@/store/TranslationsStore";
import { $megaMenuSymbol, IMegaMenu } from "@/plugins/mega-menu.plugin";
import { useDebounceFn } from "@vueuse/core";
import { useI18n } from "vue-i18n";
import { over } from "lodash";

const $megaMenu = inject<IMegaMenu>($megaMenuSymbol);
if (!$megaMenu) {
  throw Error("Could not build Mega Menu. Mega Menu Plugin not initialized.");
}
$megaMenu.updateMenuItems();

const mainMenuItems = $megaMenu.getMainMenuItemsRef();

const overlay = ref(false);
const mainMenuRef = ref<HTMLDivElement>();
const currentRouteTab = ref<string | null>(null);
const currentTab = ref<string | null>(null);
const currentRoute = ref<RouteLocation>();
const overlayLeftMargin = ref<number>(215);
const tabRef = ref();
const mainMenuItemRefs = ref<HTMLDivElement[]>([]);

// initialize i18n
useI18n();

watch(
  useRoute(),
  (route) => {
    currentRoute.value = route;
    const menuMeta = route?.meta?.menu as MenuMetaDescription[] | undefined;

    if (menuMeta) {
      for (const menuMetaDescription of menuMeta) {
        if (menuMetaDescription.path.length > 0) {
          currentRouteTab.value = menuMetaDescription.path[0].toLowerCase();
          currentTab.value = currentRouteTab.value;
          return;
        }
      }
    } else if (route.query) {
      route.matched;
    }
  },
  { immediate: true }
);

watch(mainMenuItems, () => {
  checkTabberVisibility();
});

watch(currentRouteTab, () => {
  checkTabberVisibility();
});

const onLocaleChange = function() {
  nextTick(() => $megaMenu.translateMainMenu());
};

onMounted(() => {
  const { currentLocale } = storeToRefs(useTranslationsStore());
  watch(currentLocale, onLocaleChange);
  onLocaleChange();
});

function checkTabberVisibility() {
  if (mainMenuItemRefs.value && mainMenuItemRefs.value.length > 0 && tabRef.value) {
    tabRef.value.style.visibility = "visible";

    let menuItemRef = null;
    for (const valueElement of mainMenuItemRefs.value) {
      if (valueElement.getAttribute("data-id")?.toLowerCase() === currentTab.value?.toLowerCase()) {
        menuItemRef = valueElement;
      }
    }

    if (menuItemRef) {
      const menuItemRect = menuItemRef.getBoundingClientRect();

      tabRef.value.style.left = menuItemRect.width / 2 + menuItemRect.left - 20 + "px";
    }
  }
}

function toggleMenuTab(menuTab: string, event: MouseEvent | PointerEvent) {
  if (menuTab === currentTab.value && overlay.value) {
    overlay.value = false;
    currentTab.value = currentRouteTab.value;
    checkTabberVisibility();
    return;
  }
  overlay.value = true;
  currentTab.value = menuTab;
  if (event.target) {
    const { x } = (event.target as Element).getBoundingClientRect();
    overlayLeftMargin.value = x;

    nextTick(() => {
      const containers = document.getElementsByClassName("mega-menu-container");
      if (containers.length > 0) {
        const container = containers[0] as HTMLElement;
        const offset = overlayLeftMargin.value + container.clientWidth;
        if (offset > window.innerWidth) {
          overlayLeftMargin.value = Math.max(0, window.innerWidth - container.clientWidth - 10);
        }
      }
    });
  }
  checkTabberVisibility();
}

function close() {
  overlay.value = false;
  currentTab.value = currentRouteTab.value;
  checkTabberVisibility();
}

function clickedOutside($event: any) {
  const isMainMenuClick = mainMenuRef.value?.contains($event.target);
  if (!isMainMenuClick || mainMenuRef.value === $event.target) {
    close();
  }
}
</script>

<template>
  <div ref="mainMenuRef" class="text-body-1 font-weight-medium text-center d-flex pl-12 d-flex align-center main-menu-container" @keyup.esc="close">
    <div
      v-for="mainMenuItem in mainMenuItems"
      :key="mainMenuItem.id"
      ref="mainMenuItemRefs"
      :data-id="mainMenuItem.id"
      class="px-12 cursor-pointer no-user-select main-menu-item"
      :class="{
        active: currentRouteTab?.toLowerCase() === mainMenuItem.id?.toLowerCase(),
        'selected text-primary': currentRouteTab?.toLowerCase() === mainMenuItem.id?.toLowerCase()
      }"
      @click="toggleMenuTab(mainMenuItem.id, $event)"
    >
      {{ mainMenuItem.name }}
    </div>
    <span ref="tabRef" class="tab" />
  </div>
  <v-overlay
    v-model="overlay"
    persistent
    no-click-animation
    transition="fade"
    :scrim="false"
    class="mega-menu-overlay"
    content-class="mega-menu-container"
    :style="{ marginLeft: `${overlayLeftMargin}px` }"
    @click:outside="($event) => clickedOutside($event)"
  >
    <v-fade-transition>
      <div class="menu-content" :style="{ display: overlay ? 'flex' : 'none' }">
        <mega-menu-overlay-content :current-route="currentRoute" :current-tab="currentTab" :main-menu-items="mainMenuItems" @close="close" />
      </div>
    </v-fade-transition>
  </v-overlay>
</template>

<style lang="scss">
$app-bar-height: 81px;
.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.mega-menu-overlay {
  //justify-content: center;
  transition: margin-left 0.1s ease;
}

.main-menu-container {
  .main-menu-item {
    transition: color 0.2s ease;
    text-transform: uppercase;
  }

  .tab {
    position: absolute;
    width: 40px;
    height: 2px;
    background: rgb(var(--v-theme-primary), 1);
    display: inline-block;
    bottom: 30px;
    -webkit-transition: 0.3s left;
    transition: 0.3s left;
    visibility: hidden;
  }
}

.mega-menu-container {
  margin-top: $app-bar-height;
  max-width: 100%;

  .menu-content {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2);
    background: rgb(var(--v-theme-surface)) linear-gradient(rgba(0, 0, 0, 0.34) 0px, rgba(0, 0, 0, 0.1) 4px, rgba(0, 0, 0, 0) 10px);

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 30px 14px;
    gap: 24px;

    overflow-y: auto;
    max-height: calc(100vh - $app-bar-height);
  }
}
</style>
