<script lang="ts" setup>
import RescheduleRootTaskDialog from "@/components/Tasks/RescheduleRootTaskDialog.vue";
import { SelectRootTaskActivitiesAction } from "@/components/Gantt/ResourcesGantt/SelectRootTaskActivitiesAction";
import { useProductionTasksStore } from "@/store/ProductionTasksStore";
import { RegisterRootRescheduleCommand } from "@masta/generated-model";
import { useScenariosStore } from "@/store/ScenariosStore";
import { $t } from "@/i18n";
import { useSnackbarsStore } from "@/store/SnackbarsStore";
import { v4 as uuid } from "uuid";
import { EnhancedGanttResourceCapacityDto, ResourceCapacityActivity } from "@/components/Gantt/Common/Model";
import { UnwrapRef } from "vue";

const dialog = defineModel<boolean>("dialog", { required: true });

const date = defineModel<string | undefined>("date", { required: false });
const selectedActivities = defineModel<UnwrapRef<EnhancedGanttResourceCapacityDto[]>>("selectedActivities", {
  required: true,
  default: []
});

const tasksStore = useProductionTasksStore();
const scenariosStore = useScenariosStore();
const snackbarsStore = useSnackbarsStore();

function createRescheduleRootTaskRequests(pickedDate: string): RegisterRootRescheduleCommand[] {
  if (!selectedActivities.value || selectedActivities.value.length === 0) {
    throw new Error("No selected activties");
  }

  const ids = selectedActivities.value.map(x => x.task?.rootTaskId).filter(x => !!x);
  const uniqueIds = Array.from(new Set(ids));

  return uniqueIds.map(id => {
    return {
      rootTaskId: id,
      scenarioId: scenariosStore.selectedScenario?.id,
      start: pickedDate,
      businessKey: uuid()
    } as RegisterRootRescheduleCommand;
  });
}

async function onDateSelected(pickedDate: string) {
  try {
    const requests = createRescheduleRootTaskRequests(pickedDate);
    for (const request of requests) {
      await tasksStore.rescheduleRootTask(request);
    }
    await snackbarsStore.createSnackbar({
      message: $t("task-list-taskRescheduled-message", { $: "Tasks rescheduled" }),
      closeable: true
    });
  } catch (e: any) {
    console.error(e);
    await snackbarsStore.createSnackbar({
      message: e.message,
      type: "error",
      closeable: true
    });
  }
}
</script>

<template>
  <reschedule-root-task-dialog v-model="dialog" v-model:date="date" @date-selected="onDateSelected" />
</template>

<style scoped lang="scss">

</style>
