import ApiService from "@/services/api";
import { ServerSideDataSource } from "@/components/Grid/ServerSideDataSource";
import { CreateOrUpdateProductTemplateCommand, GetProductTemplatesPaginatedQuery, IAgGridPaginationResponse, ProductTemplateDto } from "@masta/generated-model";
import { IServerSideGetRowsParams } from "ag-grid-community";
import { Store } from "pinia";
import { State as ScenarioState, useScenariosStore } from "@/store/ScenariosStore";

type CreateProductTemplateCommandType = CreateOrUpdateProductTemplateCommand;
type UpdateProductTemplateCommandType = CreateOrUpdateProductTemplateCommand;

export class ProductTemplatesServerSideDataSource extends ServerSideDataSource<
  ProductTemplateDto,
  ProductTemplateDto,
  CreateProductTemplateCommandType,
  UpdateProductTemplateCommandType,
  string
> {
  private _scenarioStore: Store<string, ScenarioState>;
  private _filterByProductId?: string | null;
  private _filterByProcessDraftId: string | undefined;

  public constructor(id: string, itemId?: string | null, processDraftId?: string) {
    super(id);
    this._scenarioStore = useScenariosStore();
    this._filterByProductId = itemId;
    this._filterByProcessDraftId = processDraftId;
  }

  public useFilteringByProductId(itemId: string | null) {
    this._filterByProductId = itemId;
  }

  public useFilteringByProcessDraftId(processDraftId: string | undefined) {
    this._filterByProcessDraftId = processDraftId;
  }

  async create(entity: ProductTemplateDto): Promise<string> {
    const request = this.transformForCreate(entity);
    const scenario = this._scenarioStore.selectedScenario;
    request.scenarioId = scenario?.id ?? "";
    const response = await ApiService.productTemplates.create(request);
    return response.data;
  }

  async getAll(params: IServerSideGetRowsParams): Promise<IAgGridPaginationResponse<ProductTemplateDto>> {
    const scenarioId = this.selectedScenarioId();
    const groupId = this.asGroupId(params);
    const request = {
      ...this.constructServerRequest(params),
      scenarioId,
      groupId,
      resourceId: this._filterByProductId,
      processDraftId: this._filterByProcessDraftId
    } as GetProductTemplatesPaginatedQuery;
    const response = await ApiService.productTemplates.getPaginated(request);
    return response.data;
  }

  async getSingle(id: string): Promise<ProductTemplateDto | undefined> {
    const scenarioId = this.selectedScenarioId()!;

    const response = await ApiService.productTemplates.getById(id, scenarioId);
    return response.data;
  }

  async update(entity: ProductTemplateDto): Promise<string> {
    const request = this.transformForUpdate(entity);
    await ApiService.productTemplates.update(request);
    return entity.id;
  }

  async remove(entity: ProductTemplateDto): Promise<void> {
    await ApiService.productTemplates.remove(entity.id, this.selectedScenarioId()!);
  }

  protected transformForCreate(listEntity: ProductTemplateDto): CreateProductTemplateCommandType {
    return {
      id: null,
      businessId: listEntity.businessId,
      name: listEntity.name,
      taskSubType: listEntity.taskSubType,
      priority: listEntity.priority,
      parentProductTemplateTaskId: listEntity.parentTaskId ?? undefined,
      parentProductTemplateTaskBusinessId: null,
      steps: null, // no change
      wbs: listEntity.wbs,
      resourceId: listEntity.resourceId,
      resourceBusinessId: listEntity.itemBusinessId,
      revisionNumber: listEntity.revisionNumber,
      templateStatus: listEntity.templateStatus,
      templateValidFrom: listEntity.templateValidFrom,
      templateValidTo: listEntity.templateValidTo,
      tags: listEntity.tags,
      scenarioId: listEntity.scenarioId,
      useParentWbsAsPrefix: null
    } as CreateProductTemplateCommandType;
  }

  protected transformForUpdate(listEntity: ProductTemplateDto): UpdateProductTemplateCommandType {
    return {
      ...this.transformForCreate(listEntity),
      id: listEntity.id
    } as UpdateProductTemplateCommandType;
  }

  private asGroupId(params: IServerSideGetRowsParams) {
    const groupKeys = params.request.groupKeys;
    return groupKeys[groupKeys.length - 1];
  }

  private selectedScenarioId() {
    return this._scenarioStore.selectedScenario?.id;
  }
}
