<script setup lang="ts">
import { NormalToolbar } from "md-editor-v3";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { MD_IMAGE_URL_SEPARATOR } from "@/components/ModelInstances/Manual/useMarkdownImageLoader";
import { DocumentModel } from "@/components/Documents/DocumentModel";
import DocumentsPickerDialog from "@/components/Documents/DocumentsPickerDialog.vue";

const props = defineProps<{ editor: any; profileName: string }>();

const showDocumentAssignmentDialog = ref<boolean>(false);

const $t = useI18n().t;

const handler = (documentModel: DocumentModel | undefined) => {
  if (documentModel) {
    documentModel.getDocumentDownloadUrl().then((url) => {
      props.editor?.insert((selectedText: string) => {
        /**
         * @return targetValue    Content to be inserted
         * @return select         Automatically select content, default: true
         * @return deviationStart Start position of the selected content, default: 0
         * @return deviationEnd   End position of the selected content, default: 0
         */
        return {
          targetValue: documentModel !== undefined ? `![${documentModel.fileName}](${documentModel.dto?.id}${MD_IMAGE_URL_SEPARATOR}${documentModel.dto?.revisionNumber})` : "",
          select: true,
          deviationStart: 0,
          deviationEnd: 0
        };
      });
    });
  }
};

const selectedDocuments = ref<DocumentModel[]>([]);

async function onConfirm() {
  if (selectedDocuments.value.length === 1) {
    handler(selectedDocuments.value[0] as DocumentModel);
    selectedDocuments.value = [];
  }
  showDocumentAssignmentDialog.value = false;
}
</script>

<template>
  <NormalToolbar :title="$t('manualEditor-insertImageToolbar-label', { $: 'Insert image' }, 'Insert image')">
    <template #trigger>
      <v-icon @click="showDocumentAssignmentDialog = true">mdi-image</v-icon>
      <documents-picker-dialog v-model="showDocumentAssignmentDialog" v-model:selected-documents="selectedDocuments" @confirm="onConfirm" />
    </template>
  </NormalToolbar>
</template>

<style scoped lang="scss"></style>
