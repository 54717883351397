<script lang="ts" setup>
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { inject, ref } from "vue";
import { $t } from "@/i18n";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import { $dateTimeFormatterSymbol, DateFormatter } from "@masta/shared";
import { ValueFormatterParams } from "ag-grid-community";
import { joinArrayOfStrings } from "@/components/ValueCellEditor/CommonFormatters";

const gridWrapperRef = ref<GridWrapperComponent>();

defineExpose({
  gridWrapperRef: gridWrapperRef
});

const defaultColumnDef = ref({
  editable: false,
  sortable: false,
  resizable: true,
  floatingFilter: false,
  filterParams: {
    applyMiniFilterWhileTyping: false
  }
});
const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;

function onPrepareColumns(columnDefs: any) {
  columnDefs.value = [
    {
      type: ["documentModelTypeColumn"],
      headerValueGetter: () => $t("documents-thumbnail-label", { $: "Thumbnail" }),
      cellRendererParams: {}
    },
    {
      field: "fileName",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      filter: "agTextColumnFilter",
      headerValueGetter: () => $t("documents-fileName-label", { $: "File name" })
    },
    {
      field: "mimeType",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      filter: "agTextColumnFilter",
      headerValueGetter: () => $t("documents-mimeType-label", { $: "Mime Type" })
    },
    {
      field: "tags",
      headerValueGetter: (_: any) => $t("documents-tags-label", { $: "Tags" }),
      type: "tagsTypeColumn",
      resizable: true,
      valueFormatter: (params: ValueFormatterParams) => joinArrayOfStrings(params.data.tags),
      cellEditorParams: {
        placeholder: $t("documents-tags-label", { $: "Tags" })
      }
    },
    {
      field: "description",
      hide: true,
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      filter: "agTextColumnFilter",
      headerValueGetter: () => $t("documents-description-label", { $: "Description" })
    },
    {
      field: "businessId",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      filter: "agTextColumnFilter",
      headerValueGetter: () => $t("documents-businessId-label", { $: "Business ID" })
    },
    {
      field: "revisionNumber",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      filter: "agTextColumnFilter",
      headerValueGetter: () => $t("documents-revisionNumber-label", { $: "Revision" })
    },
    {
      field: "createdBy",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      filter: "agTextColumnFilter",
      editable: false,
      resizable: true,
      headerValueGetter: (_: any) => $t("documents-createdBy-label", { $: "Created By" }),
      floatingFilterComponentParams: {
        placeholder: $t("documents-createdBy-label", { $: "Created By" })
      }
    },
    {
      field: "createdAt",
      type: ["dateTimeTypeColumn"],
      filter: "agDateColumnFilter",
      editable: false,
      resizable: true,
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.createdAt);
      },
      headerValueGetter: (_: any) => $t("documents-createdAt-label", { $: "Created At" })
    },
    {
      field: "modifiedBy",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      filter: "agTextColumnFilter",
      editable: false,
      resizable: true,
      headerValueGetter: (_: any) => $t("documents-modifiedBy-label", { $: "Modified By" }),
      floatingFilterComponentParams: {
        placeholder: $t("documents-modifiedBy-label", { $: "Modified By" })
      }
    },
    {
      field: "modifiedAt",
      type: ["dateTimeTypeColumn"],
      filter: "agDateColumnFilter",
      editable: false,
      resizable: true,
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.modifiedAt);
      },
      headerValueGetter: (_: any) => $t("documents-modifiedAt-label", { $: "Modified At" })
    }
  ];
}
</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    v-bind="$attrs"
    identifier="documents"
    hide-custom-actions-separator
    :default-col-def="defaultColumnDef"
    :row-height="120"
    refresh-btn
    :overlay-no-rows-template="$t('documents-noItems-label', { $: 'No items found' })"
    @prepare-columns="onPrepareColumns"
  />
</template>

<style scoped lang="scss">

</style>
