<script lang="ts" setup>
import SplitLayout from "@/components/Layout/SplitLayout.vue";
import AvailabilityRulesGrid from "@/components/AvailabilityRules/AvailabilityRulesGrid.vue";
import Breadcrumbs from "@/components/Layout/Breadcrumbs.vue";
import { $t } from "@/i18n";
import { ref, watch } from "vue";
import { Availability, AvailabilityRuleDataDto } from "@masta/generated-model";
import { useRoute, useRouter } from "vue-router";
import CloseCardButton from "@/components/CloseCardButton.vue";
import { isDefined } from "@vueuse/core";
import { SelectionChangedEvent } from "ag-grid-community";
import { getSelectedRows } from "@/components/Grid/UseGridSelection";
import { useScenariosStore } from "@/store/ScenariosStore";
import ApiService from "@/services/api";
import { useErrorsStore } from "@/store/ErrorsStore";
import AvailabilityRuleDetails from "@/components/AvailabilityRules/AvailabilityRuleDetails.vue";

const rule = ref<AvailabilityRuleDataDto | null>(null);
const splitLayoutRef = ref<typeof SplitLayout | null>(null);
const availabilityRulesGridRef = ref<typeof AvailabilityRulesGrid | null>(null);
const router = useRouter();
const route = useRoute();
const scenarioStore = useScenariosStore();
const createMode = ref(false);

watch(
  () => rule.value,
  (newVal) => {
    if (newVal) {
      showDetails();
    } else {
      closeDetails();
    }
  }
);

watch(
  () => route.query.ruleId,
  async (ruleId, _) => {
    if (isDefined(ruleId)) {
      if (isDefined(rule.value) && rule.value.id === ruleId) {
        return;
      } else {
        rule.value = await fetchRule(ruleId as string);
      }
    } else {
      rule.value = null;
    }
  },
  { immediate: true }
);

function showDetails() {
  splitLayoutRef.value?.showDetails();
}

function closeDetails() {
  splitLayoutRef.value?.closeDetails();
}

function createSplitPanelGutterElement(index: number, direction: "horizontal" | "vertical") {
  const gutter = document.createElement("div");
  gutter.classList.add("gutter-orders");
  return gutter;
}

function onRuleSelected(event: SelectionChangedEvent) {
  const selectedRows = getSelectedRows(event.api);
  if (selectedRows.length === 0) {
    if (!createMode.value) {
      rule.value = null;
    }
    return;
  }
  createMode.value = false;

  const selectedRule = selectedRows[0];

  if (selectedRule) {
    rule.value = selectedRule;

    showDetails();

    const currentQuery = router.currentRoute.value.query;
    const newQuery = Object.assign({}, currentQuery, { ruleId: selectedRule.id });
    router.push({ query: newQuery });
  }
}

async function fetchRule(ruleId: string) {
  const scenariosStore = useScenariosStore();
  const selectedScenario = scenariosStore.selectedScenario;

  if (!selectedScenario) {
    return null;
  }
  try {
    const response = await ApiService.availabilityRules.get(ruleId, selectedScenario.id);
    return response.data;
  } catch (error) {
    const errorsStore = useErrorsStore();
    errorsStore.handleError(error);
  }
  return null;
}

async function ruleUpdated(id: string) {
  availabilityRulesGridRef.value?.refreshSingle(id);
}

async function onCreateNewRule() {
  createMode.value = true;
  availabilityRulesGridRef.value?.deselectAll();
  rule.value = createNewRule();
  console.log("create new rule", rule);
}

function createNewRule(): AvailabilityRuleDataDto {
  return {
    id: "",
    businessId: "",
    name: "",
    tenantId: "",
    scenarioId: scenarioStore.selectedScenario?.id ?? "",
    availability: Availability.Available,
    recurrence: "FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR",
    start: nowDateProducer(),
    end: nowPlusDateProducer(10),
    color: "#000000"
  };
}

function nowDateProducer() {
  const nowDate = new Date();
  nowDate.setMinutes(0);
  nowDate.setSeconds(0);
  nowDate.setMilliseconds(0);
  return nowDate.toISOString();
}

function nowPlusDateProducer(plusDays: number) {
  const nowDate = new Date();
  nowDate.setMinutes(0);
  nowDate.setSeconds(0);
  nowDate.setMilliseconds(0);

  nowDate.setDate(nowDate.getDate() + plusDays);
  return nowDate.toISOString();
}

function editCancelled() {
  console.log("edit canceled");
  if (createMode.value) {
    console.log("create mode off");
    rule.value = null;
    createMode.value = false;
  }
}
</script>
<template>
  <split-layout ref="splitLayoutRef" identifier="availability-rules" :gutter="createSplitPanelGutterElement">
    <template #master>
      <v-card elevation="0" class="flexcard fill-height">
        <v-card-title>
          <breadcrumbs>{{ $t("availabilityRule-view-calendarRules-title", { $: "Calendar Rules" }) }}</breadcrumbs>
        </v-card-title>
        <v-card-text class="fill-height">
          <v-row class="fill-height" no-gutters>
            <v-col cols="12">
              <availability-rules-grid ref="availabilityRulesGridRef" @selection-changed="onRuleSelected" @create="onCreateNewRule" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
    <template #detail>
      <availability-rule-details v-if="rule" :rule="rule" @updated="ruleUpdated" @closed="closeDetails" @cancelled="editCancelled" />
      <v-card v-if="!rule" elevation="7" class="d-flex flex-column fill-height no-services-card">
        <close-card-button @click="closeDetails"></close-card-button>
        <v-card-text class="fill-height">
          <v-row class="fill-height">
            <v-col cols="12" class="d-flex align-center justify-center">
              <label class="text-secondary text-h6 font-weight-regular">
                {{ $t("availabilityRule-view-noRuleSelected-title", { $: "No rule selected" }) }}
              </label>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </split-layout>
</template>

<style lang="scss" scoped></style>
