<script setup lang="ts">
import {
  AssignModelInstanceToOrderCommand,
  CreateDocumentForContextCommand,
  CreateNewDocumentRevisionWithNewAttachmentCommand,
  DocumentDto,
  OrderDto,
  UpdateDocumentCommand
} from "@masta/generated-model";
import { ref, toRef, watch } from "vue";
import { DocumentProvider, ModelSchemaName } from "@/components/Documents/IDocumentProvider";
import { useScenariosStore } from "@/store/ScenariosStore";
import ApiService from "@/services/api";
import { DocumentError, DocumentModel } from "@/components/Documents/DocumentModel";
import { useDocumentsStore } from "@/store/DocumentsStore";
import { v4 as uuidv4 } from "uuid";
import DocumentsManager from "@/components/Documents/DocumentsManager.vue";
import { DocumentManager } from "@/components/Documents/DocumentManager";

const props = defineProps<{ order: OrderDto | null | undefined; profileName: ModelSchemaName }>();
const order = toRef(props, "order");

const uploading = ref(false);
const uploadMessage = ref("");

const documentsStore = useDocumentsStore();
const scenariosStore = useScenariosStore();

class OrderAssignedDocumentProvider extends DocumentProvider {
  profileName = props.profileName;

  constructor() {
    super();

    watch(
      () => props.order,
      async () => {
        this.qrCodeOptions.value = {
          profileName: this.profileName,
          orderId: props.order?.id
        };
      },
      {
        immediate: true
      }
    );
  }

  async getAll(): Promise<DocumentDto[]> {
    if (!props.order?.id) return [];
    const scenarioStore = useScenariosStore();
    const { data } = await ApiService.documents.getDocumentsAssignedToOrderForContext(this.profileName, props.order.id, scenarioStore.selectedScenario?.id ?? "");
    return data.documentDtos;
  }
}

class OrderDocumentManager extends DocumentManager {
  profileName = props.profileName;

  constructor(public assignmentDocumentProvider: DocumentProvider) {
    super();
  }

  async assignDocument(doc: DocumentModel): Promise<void> {
    if (!order.value) throw new DocumentError("Order is not set");

    await ApiService.orders.assignModelInstance({
      orderId: order.value.id,
      orderBusinessId: order.value.businessId,
      scenarioId: order.value.scenarioId,
      modelInstanceId: doc.dto.id,
      modelInstanceBusinessId: doc.dto.businessId
    } as Partial<AssignModelInstanceToOrderCommand> as AssignModelInstanceToOrderCommand);
  }

  async unassignDocument(doc: DocumentModel): Promise<void> {
    if (!order.value) throw new DocumentError("Order is not set");

    await documentsStore.deassignOrderDocument(doc.dto.id, order.value.id);
  }

  async uploadDocument(file: File, doAssignment: boolean): Promise<void> {
    const selectedScenario = scenariosStore.selectedScenario;

    if (!selectedScenario) throw new DocumentError("Scenario is not set");

    const modelInstanceId = await documentsStore.createDocumentWithAttachment({
      scenarioId: selectedScenario.id,
      businessId: uuidv4(),
      profileName: this.assignmentDocumentProvider.profileName,
      tags: [],
      file: file,
      orderId: doAssignment ? order.value?.id : null
    } as Partial<CreateDocumentForContextCommand> as CreateDocumentForContextCommand);
  }

  async deleteDocument(doc: DocumentModel): Promise<void> {
    if (!order.value) throw new DocumentError("Order is not set");

    await documentsStore.deleteOrderDocument(doc.dto.id, doc.dto.revisionNumber, order.value.id);
  }

  async saveDocument(doc: DocumentModel): Promise<void> {
    if (!order.value) throw new DocumentError("Order is not set");

    await documentsStore.updateDocumentWithAttachment({
      id: doc.dto.id,
      description: doc.description,
      revisionNumber: doc.dto.revisionNumber,
      tags: doc.tags,
      businessId: doc.businessId
    } as Partial<UpdateDocumentCommand> as UpdateDocumentCommand);

    await this.assignmentDocumentProvider.refresh();
  }

  async createNewRevision(file: File, doc: DocumentModel, doAssignment: boolean): Promise<void> {
    const selectedScenario = scenariosStore.selectedScenario;

    if (!selectedScenario) throw new DocumentError("Scenario is not set");

    await documentsStore.createNewRevisionWithNewAttachment({ file: file, id: doc.dto.id } as CreateNewDocumentRevisionWithNewAttachmentCommand);
    if (doAssignment) {
      if (!order.value) throw new DocumentError("Order is not set");

      await ApiService.orders.assignModelInstance({
        scenarioId: selectedScenario.id,
        orderId: order.value.id,
        modelInstanceId: doc.dto.id
      } as AssignModelInstanceToOrderCommand);
    }
  }
}

const assignedDocumentsProvider = new OrderAssignedDocumentProvider();
const manager = new OrderDocumentManager(assignedDocumentsProvider);

watch(
  order,
  async () => {
    await assignedDocumentsProvider.refresh();
  },
  { immediate: true }
);

function uploadStart() {
  uploading.value = true;
}

function uploadEnd() {
  uploading.value = false;
}

function handleUploadStage(stage: string) {
  uploadMessage.value = stage;
}
</script>

<template>
  <v-overlay persistent :model-value="uploading" class="d-flex align-center justify-center">
    <v-progress-circular color="primary" indeterminate size="64"></v-progress-circular>
    <div class="mt-4">{{ uploadMessage }}</div>
  </v-overlay>
  <documents-manager :manager="manager" @upload-start="uploadStart" @upload-end="uploadEnd" @upload-stage="handleUploadStage" />
</template>

<style scoped lang="scss"></style>
