<script lang="ts" setup>
import { $t } from "@/i18n";
import { inject, ref, watch } from "vue";
import {
  MeasurementUnit,
  PublishExecutionEventCommand,
  ResourceInfoDto,
  ResourceType,
  WorkJournalRecordDto,
  WorkJournalRecordEventAction,
  WorkJournalRecordEventType
} from "@masta/generated-model";
import { getEnumTitleValuePairs } from "@/composables/enumHelpers";
import { translateWorkJournalRecordEventAction, translateWorkJournalRecordEventType } from "@/composables/translateEnum";
import CodeEditor from "@/components/CodeEditor.vue";
import TagsPicker from "@/components/Tags/TagsPicker.vue";
import ResourcePicker from "@/components/Resources/ResourcePicker.vue";
import DatepickerField from "@/components/Datepicker/DatepickerField.vue";
import { useI18n } from "vue-i18n";
import { $dateTimeFormatterSymbol, DateFormatter } from "@masta/shared";
import ResourcesPicker from "@/components/Resources/ResourcesPicker.vue";
import { useAuthStore } from "@/store/AuthStore";
import { v4 as uuidv4 } from "uuid";

const authStore = useAuthStore();

const Guid = {
  Empty: "00000000-0000-0000-0000-000000000000"
};

interface EditableWorkJournalRecordDto {
  id: string;
  affectedWorkJournalRecordId: string;
  resource: ResourceInfoDto | undefined;
  taskId: string;
  taskBusinessId: string;
  taskName: string;
  stepId: string;
  stepPosition: number;
  stepName: string;
  person: ResourceInfoDto | undefined;
  agreement: ResourceInfoDto | undefined;
  equipment: ResourceInfoDto | undefined;
  assets: ResourceInfoDto[];
  eventType: WorkJournalRecordEventType;
  eventAction: WorkJournalRecordEventAction;
  eventStart: string;
  timestamp: string;
  eventPayload: any;
  cancelled: boolean;
  isAutomation: boolean;
  businessId: string;
  tags: string[];
}

function createEmptyEditableWorkJournalRecordDto(): EditableWorkJournalRecordDto {
  return {
    id: uuidv4(),
    affectedWorkJournalRecordId: Guid.Empty,
    resourceId: Guid.Empty,
    taskId: "",
    taskBusinessId: "",
    taskName: "",
    stepId: "",
    stepPosition: 0,
    stepName: "",
    person: undefined,
    agreement: undefined,
    equipment: undefined,
    assets: [],
    eventType: WorkJournalRecordEventType.Quantity,
    eventAction: WorkJournalRecordEventAction.Regular,
    eventStart: "",
    timestamp: "",
    eventPayload: {},
    cancelled: false,
    isAutomation: false,
    businessId: "",
    tags: []
  };
}

function convertToEditable(dto: WorkJournalRecordDto): EditableWorkJournalRecordDto {
  return {
    id: dto.id,
    affectedWorkJournalRecordId: dto.affectedWorkJournalRecordId,
    resource: dto.resourceId ? getResourceInfo(dto.resourceId, "", "") : undefined,
    taskId: dto.taskId,
    taskBusinessId: dto.taskBusinessId,
    taskName: dto.taskName,
    stepId: dto.stepId,
    stepPosition: dto.stepPosition,
    stepName: dto.stepName,
    person: dto.personId ? getResourceInfo(dto.personId, dto.personBusinessId, dto.personName) : undefined,
    agreement: dto.agreementId ? getResourceInfo(dto.agreementId, dto.agreementBusinessId, dto.agreementName) : undefined,
    equipment: dto.equipmentId ? getResourceInfo(dto.equipmentId, dto.equipmentBusinessId, dto.equipmentName) : undefined,
    assets: dto.assetIds.map(id => getResourceInfo(id, "", "")), // Assuming only IDs for assets are provided
    eventType: dto.eventType,
    eventAction: dto.eventAction,
    eventStart: dto.eventStart,
    timestamp: dto.timestamp,
    eventPayload: dto.eventPayload,
    cancelled: dto.cancelled,
    isAutomation: dto.isAutomation,
    businessId: dto.businessId,
    tags: dto.tags
  };
}

function convertToWorkJournalRecord(editableDto: EditableWorkJournalRecordDto): WorkJournalRecordDto {
  return {
    id: editableDto.id ?? Guid.Empty,
    affectedWorkJournalRecordId: editableDto.affectedWorkJournalRecordId ?? Guid.Empty,
    resourceId: editableDto.resource?.id ?? Guid.Empty,
    taskId: editableDto.taskId ?? Guid.Empty,
    taskBusinessId: editableDto.taskBusinessId,
    taskName: editableDto.taskName,
    stepId: editableDto.stepId ?? Guid.Empty,
    stepPosition: editableDto.stepPosition,
    stepName: editableDto.stepName,
    // stepType: editableDto.stepType as StepType,
    personId: editableDto.person?.id ?? Guid.Empty,
    personBusinessId: editableDto.person?.businessId ?? null,
    personName: editableDto.person?.name ?? null,
    agreementId: editableDto.agreement?.id ?? Guid.Empty,
    agreementBusinessId: editableDto.agreement?.businessId ?? null,
    agreementName: editableDto.agreement?.name ?? null,
    equipmentId: editableDto.equipment?.id ?? Guid.Empty,
    equipmentBusinessId: editableDto.equipment?.businessId ?? null,
    equipmentName: editableDto.equipment?.name ?? null,
    // serviceId: Guid.Empty, // Uncomment and set if needed
    // serviceBusinessId: null, // Uncomment if needed
    // serviceName: null, // Uncomment if needed
    assetIds: editableDto.assets.map(asset => asset.id ?? Guid.Empty),
    eventType: editableDto.eventType,
    eventAction: editableDto.eventAction,
    eventStart: editableDto.eventStart,
    timestamp: editableDto.timestamp,
    eventPayload: editableDto.eventPayload,
    cancelled: editableDto.cancelled,
    isAutomation: editableDto.isAutomation,
    businessId: editableDto.businessId,
    tags: editableDto.tags,
    tenantId: authStore.currentTenantId
  };
}


function getResourceInfo(
  id: string,
  businessId: string = "",
  name: string = "",
  type: ResourceType = undefined,
  plannable: boolean = false,
  measurementUnit: MeasurementUnit = undefined,
  orderableUnit: MeasurementUnit = undefined
): ResourceInfoDto {
  return {
    id,
    businessId,
    name,
    scenarioId: "",
    type,
    plannable,
    measurementUnit,
    orderableUnit
  };
}

const props = defineProps<{
  modelValue: boolean;
  template?: WorkJournalRecordDto; // Make template prop optional
}>();
const emit = defineEmits<{
  (e: "update:modelValue", value: boolean): void;
  (e: "dataFilled", workJournal: PublishExecutionEventCommand): void;
}>();


const { locale } = useI18n();
const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;

function formatDate(date: Date) {
  return $dateTimeFormatter(date);
}

const editedWorkJournal = ref<EditableWorkJournalRecordDto>(createEmptyEditableWorkJournalRecordDto());
const payloadString = ref<string>("");
const workJournal = ref<PublishExecutionEventCommand>();

const eventTypes = getEnumTitleValuePairs(WorkJournalRecordEventType, translateWorkJournalRecordEventType, ...[WorkJournalRecordEventType.Unknown, WorkJournalRecordEventType.ProductionCommand, WorkJournalRecordEventType.FinishCommand, WorkJournalRecordEventType.InterruptCommand]).sort((a, b) => a.title.localeCompare(b.title));
const eventActions = getEnumTitleValuePairs(WorkJournalRecordEventAction, translateWorkJournalRecordEventAction, ...[WorkJournalRecordEventAction.Unknown, WorkJournalRecordEventAction.Subsequent]).sort((a, b) => a.title.localeCompare(b.title));

watch(
  () => props.template,
  (newVal: WorkJournalRecordDto) => {
    editedWorkJournal.value = convertToEditable(newVal);
    editedWorkJournal.value.affectedWorkJournalRecordId = newVal.id;
    editedWorkJournal.value.eventAction = newVal.affectedWorkJournalRecordId ? WorkJournalRecordEventAction.Correction : WorkJournalRecordEventAction.Regular;
  }
);

function cancel() {
  setModal(false);
}

function sendWorkJournal() {
  workJournal.value = convertToWorkJournalRecord(editedWorkJournal.value);
  setModal(false);
  emit("dataFilled", workJournal.value);
  workJournal.value = undefined;
}

function setModal(visible: boolean) {
  emit("update:modelValue", visible);
}
</script>

<template>
  <v-dialog :model-value="modelValue" width="80%" @update:model-value="setModal($event)">
    <v-card class="pa-8">
      <v-card-title class="d-flex align-center justify-space-between">
        <div class="text-h4">{{ $t("workJournalRecord-createDialog-title-label", { $: "Enter data to manually create a Work Journal Record" }) }}</div>
        <v-icon @click="cancel">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="editedWorkJournal.businessId"
              variant="outlined"
              density="compact"
              :label="$t('workJournalRecord-createDialog-businessId-label', { $: 'Business Id' })"
            />
          </v-col>
          <v-col cols="6">
            <!--              <tags-picker v-model="editedWorkJournal.tags" :readonly="disabled" :label="$t('modelInstance-edit-tags-label', { $: 'Tags' })" color="primary" />-->
            <tags-picker v-model="editedWorkJournal.tags" :single="false" :label="$t('workJournalRecord-createDialog-tags-label', { $: 'Tags' })" color="primary" />

          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-select
              v-model="editedWorkJournal.eventType"
              variant="outlined"
              density="compact"
              :label="$t('workJournalRecord-createDialog-eventType-label', { $: 'Event type:' })"
              :items="eventTypes"
            />
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="editedWorkJournal.eventAction"
              variant="outlined"
              density="compact"
              :label="$t('workJournalRecord-createDialog-eventAction-label', { $: 'Event action:' })"
              :items="eventActions"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="editedWorkJournal.affectedWorkJournalRecordId"
              variant="outlined"
              density="compact"
              :label="$t('workJournalRecord-createDialog-affectedWorkJournalRecordId-label', { $: 'Affected Work Journal Record Id' })"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="editedWorkJournal.taskId"
              variant="outlined"
              density="compact"
              :label="$t('workJournalRecord-createDialog-taskId-label', { $: 'Task Id' })"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="editedWorkJournal.stepId"
              variant="outlined"
              density="compact"
              :label="$t('workJournalRecord-createDialog-stepId-label', { $: 'Step Id' })"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <ResourcePicker
              v-model="editedWorkJournal.person"
              class="flex-1-1-100"
              :readonly="false"
              :hide-details="'auto'"
              :label="$t('workJournalRecord-createDialog-personId-label', { $: 'Person Id' })"
              :placeholder="$t('workJournalRecord-createDialog-personId-label', { $: 'Person Id' })"
              :resource-types="[ResourceType.Person]"
            />
          </v-col>
          <v-col cols="6">
            <ResourcePicker
              v-model="editedWorkJournal.equipment"
              class="flex-1-1-100"
              :readonly="false"
              :hide-details="'auto'"
              :label="$t('workJournalRecord-createDialog-equipmentId-label', { $: 'Equipment Id' })"
              :placeholder="$t('workJournalRecord-createDialog-equipmentId-label', { $: 'Equipment Id' })"
              :resource-types="[ResourceType.Equipment]"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <ResourcePicker
              v-model="editedWorkJournal.agreement"
              class="flex-1-1-100"
              :readonly="false"
              :hide-details="'auto'"
              :label="$t('workJournalRecord-createDialog-agreementId-label', { $: 'Agreement Id' })"
              :placeholder="$t('workJournalRecord-createDialog-agreementId-label', { $: 'Agreement Id' })"
              :resource-types="[ResourceType.Agreement]"
            />
          </v-col>
          <v-col cols="6">
            <ResourcesPicker
              v-model="editedWorkJournal.assets" class="flex-1-1-100" :hide-details="'auto'"
              :label="$t('workJournalRecord-createDialog-assetIds-label', { $: 'Asset Ids' })"
              :placeholder="$t('workJournalRecord-createDialog-assetIds-label', { $: 'Asset Ids' })" :readonly="false" :resource-type="[ResourceType.Asset]" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <ResourcePicker
              v-model="editedWorkJournal.resource"
              class="flex-1-1-100"
              :readonly="false"
              :hide-details="'auto'"
              :label="$t('workJournalRecord-createDialog-resourceId-label', { $: 'Resource Id' })"
              :placeholder="$t('workJournalRecord-createDialog-resourceId-label', { $: 'Resource Id' })"
              :resource-types="[ResourceType.Asset, ResourceType.Material, ResourceType.Person, ResourceType.Equipment, ResourceType.Agreement, ResourceType.Location]"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <datepicker-field
              v-model="editedWorkJournal.eventStart"
              :clearable="false"
              :format="formatDate"
              :locale="locale"
              teleport="body"
              :enable-time-picker="true"
              :label="$t('workJournalRecord-createDialog-eventStart-label', { $: 'Event Start' })"
              :placeholder="$t('workJournalRecord-createDialog-eventStart-label', { $: 'Event Start' })"
            >
            </datepicker-field>
          </v-col>
          <v-col cols="6">
            <datepicker-field
              v-model="editedWorkJournal.timestamp"
              :clearable="false"
              :format="formatDate"
              :locale="locale"
              teleport="body"
              :enable-time-picker="true"
              :label="$t('workJournalRecord-createDialog-timestamp-label', { $: 'Created At' })"
              :placeholder="$t('workJournalRecord-createDialog-timestamp-label', { $: 'Created At' })"
            >
            </datepicker-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <CodeEditor
              v-model="editedWorkJournal.eventPayload"
              :language="'json'"
              :theme="'vs-dark'"
              :min-height="'300px'"
              :readonly="false"
            />
          </v-col>
        </v-row>

        <!--        <v-row>-->
        <!--          <v-col cols="6">-->
        <!--            <v-row>-->
        <!--              <v-col cols="4">-->
        <!--                <v-checkbox-->
        <!--                  v-model="editedWorkJournal.cancelled"-->
        <!--                  variant="outlined"-->
        <!--                  density="compact"-->
        <!--                  :label="$t('workJournalRecord-createDialog-cancelled-label', { $: 'Is Cancelled?' })"-->
        <!--                />-->
        <!--              </v-col>-->
        <!--              <v-col cols="4">-->
        <!--                <v-checkbox-->
        <!--                  v-model="editedWorkJournal.isAutomation"-->
        <!--                  variant="outlined"-->
        <!--                  density="compact"-->
        <!--                  :label="$t('workJournalRecord-createDialog-isAutomation-label', { $: 'Is Created Automatically?' })"-->
        <!--                />-->
        <!--              </v-col>-->
        <!--            </v-row>-->
        <!--          </v-col>-->
        <!--        </v-row>-->
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col cols="6">
            <v-btn class="ma-4" variant="elevated" color="secondary" block @click="cancel">
              {{ $t("workJournalRecord-createDialog-cancel-action", { $: "Cancel" }) }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn class="ma-4" variant="elevated" color="primary" block @click="sendWorkJournal">
              {{ $t("workJournalRecord-createDialog-send-action", { $: "Create" }) }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
