import { DocumentProvider, ModelSchemaName } from "@/components/Documents/IDocumentProvider";
import { DocumentDto } from "@masta/generated-model";
import ApiService from "@/services/api";
import { DocumentModel, getDocumentDtoId } from "@/components/Documents/DocumentModel";
import { Ref, UnwrapRef } from "vue";

export class ContextDocumentProvider extends DocumentProvider {
  constructor(public profileName: ModelSchemaName, public assignedDocuments?: Ref<DocumentModel[] | UnwrapRef<DocumentModel[]>>) {
    super();
  }

  async getAll(): Promise<DocumentDto[]> {
    const { data } = await ApiService.documents.getAllDocumentsForContext(this.profileName);
    if (this.assignedDocuments) {
      return data.documentDtos.filter((doc) => this.assignedDocuments?.value.find((d) => d.id === getDocumentDtoId(doc)) === undefined);
    } else {
      return data.documentDtos;
    }
  }
}
