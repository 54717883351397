<script lang="ts" setup>

import { computed } from "vue";
import { useTagsStore } from "@/store/TagsStore";
import { TagDto } from "@masta/generated-model";

const tagsModel = defineModel<string | string[] | null | undefined>({
  default: []
});

const value = computed(() => {
  const _tags = tagsModel.value;
  if (_tags === undefined || _tags === null || _tags === "") {
    return {
      value: ""
    };
  }
  if (Array.isArray(_tags)) {
    const tagsStore = useTagsStore();
    const tags = tagsStore.tags;
    const tagNames = _tags.map((tagName: string) => {
      const tag = tags.find((t: TagDto) => t.name === tagName);
      return tag ? `${tag.emoji ? `${tag.emoji} ` : ""}${tag.name}` : "";
    });
    return tagNames.join(", ");
  }
});
</script>

<template>
  {{ value }}
</template>
