import ApiService from "@/services/api";
import { ServerSideDataSource } from "@/components/Grid/ServerSideDataSource";
import { IAgGridPaginationResponse, ResourceAssignmentType, ResourceInfoDto, ResourceType } from "@masta/generated-model";
import { IServerSideGetRowsParams } from "ag-grid-enterprise";
import { Store } from "pinia";
import { State as ScenarioState, useScenariosStore } from "@/store/ScenariosStore";

export interface ResourceAssignmentServerSideDataSourceOptions {
  assignmentType: ResourceAssignmentType;
  resourceType: ResourceType;
  owningResourceId: string;
  reverse: boolean;
}

export class ResourceAssignmentServerSideDataSource extends ServerSideDataSource<ResourceInfoDto> {
  private _options: ResourceAssignmentServerSideDataSourceOptions;
  private _scenarioStore: Store<string, ScenarioState>;

  constructor(id: string, options: ResourceAssignmentServerSideDataSourceOptions) {
    super(id);
    this._options = options;
    this._scenarioStore = useScenariosStore();
  }

  async create(entity: ResourceInfoDto): Promise<string | undefined> {
    throw new Error("not supported");
  }

  async getAll(params: IServerSideGetRowsParams): Promise<IAgGridPaginationResponse<ResourceInfoDto>> {
    const { data } = await ApiService.resourceAssignment.getPaginated({
      ...this.constructServerRequest(params),
      assignmentType: this._options.assignmentType,
      owningResourceId: this._options.owningResourceId,
      reverse: this._options.reverse,
      scenarioId: this._scenarioStore.selectedScenario?.id ?? ""
    });
    return data;
  }

  async getSingle(id: string | undefined): Promise<ResourceInfoDto | undefined> {
    throw new Error("not supported");
  }

  async remove(entity: ResourceInfoDto): Promise<void> {
    throw new Error("not supported");
  }

  async update(entity: ResourceInfoDto): Promise<string | undefined> {
    throw new Error("not supported");
  }

  protected transformForCreate(listEntity: ResourceInfoDto): ResourceInfoDto {
    return listEntity;
  }

  protected transformForUpdate(listEntity: ResourceInfoDto): ResourceInfoDto {
    return listEntity;
  }

  useFilteringByOwningResourceId(id: string) {
    this._options.owningResourceId = id;
  }
}
