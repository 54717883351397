import { DocumentProvider, ModelSchemaName } from "@/components/Documents/IDocumentProvider";
import { DocumentModel } from "@/components/Documents/DocumentModel";

export abstract class DocumentManager {
  abstract profileName: ModelSchemaName;
  abstract assignmentDocumentProvider: DocumentProvider;

  abstract assignDocument(doc: DocumentModel): Promise<void>;

  abstract unassignDocument(doc: DocumentModel): Promise<void>;

  abstract uploadDocument(file: File, doAssignment: boolean): Promise<void>;

  abstract deleteDocument(doc: DocumentModel): Promise<void>;

  abstract saveDocument(doc: DocumentModel): Promise<void>;

  abstract createNewRevision(file: File, doc: DocumentModel, doAssignment: boolean): Promise<void>;
}
