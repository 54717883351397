<script setup lang="ts">
import DocumentsManagerActions from "@/components/Documents/DocumentsManagerActions.vue";
import ContextDocumentsBrowserDialog from "@/components/Documents/ContextDocumentsBrowserDialog.vue";
import DocumentsDeleteConfirmationModalDialog from "@/components/Documents/DocumentsDeleteConfirmationModalDialog.vue";
import DocumentsBrowser from "@/components/Documents/DocumentsBrowser.vue";
import { DocumentManager } from "@/components/Documents/DocumentManager";
import { ref, toRef, UnwrapRef } from "vue";
import { DocumentsBrowserViewMode } from "@/components/Documents/DocumentsBrowserViewMode";
import { ContextDocumentProvider } from "@/components/Documents/ContextDocumentProvider";
import { DocumentModel } from "@/components/Documents/DocumentModel";
import { useSnackbarsStore } from "@/store/SnackbarsStore";
import { $t } from "@/i18n";
import { compressImageFile, isFileValidImageType } from "@/composables/ImageFileCompression";
import { useErrorsStore } from "@/store/ErrorsStore";
import { useMultipleDragAndDropArea } from "@/components/Documents/useDragAndDropArea";
import { useLocalStorage } from "@vueuse/core";

const emit = defineEmits(["upload-start", "upload-end", "upload-stage"]);

const props = defineProps<{
  manager: DocumentManager;
  hideAssignmentActions?: boolean;
}>();

const selectedDocumentsModel = defineModel<UnwrapRef<DocumentModel[]>>("selectedDocuments", {
  local: true,
  required: false,
  default: []
});

const manager = props.manager;

const snackbarStore = useSnackbarsStore();

const browserMode = useLocalStorage("documentsBrowserViewMode", DocumentsBrowserViewMode.Grid);

const showDocumentAssignmentDialog = ref(false);

const assignedDocumentsProvider = manager.assignmentDocumentProvider;
const contextDocumentsProvider = new ContextDocumentProvider(assignedDocumentsProvider.profileName, assignedDocumentsProvider.documents);

const qrCodeOptions = toRef(assignedDocumentsProvider, "qrCodeOptions");
const documentsForModification = ref<DocumentModel[]>([]);
const fileInputRef = ref<HTMLInputElement>();
const newRevisionFileInputRef = ref<HTMLInputElement>();
const isUploading = ref(false);

const showDeleteDialog = ref<boolean>(false);
const documentForDeletion = ref<DocumentModel | null | undefined>(null);

const assignedDocumentsBrowserRef = ref<HTMLElement | undefined | null>();
const contextDocumentsBrowserRef = ref<HTMLElement | undefined | null>();

const documentForNewRevision = ref<DocumentModel | null | undefined>(null);

const { isDraggingOver } = useMultipleDragAndDropArea([assignedDocumentsBrowserRef, contextDocumentsBrowserRef], {
  multiple: true,
  onUpload: uploadMultipleFiles
});

// Functions
async function onAssignDocument() {
  await contextDocumentsProvider.refresh();
  showDocumentAssignmentDialog.value = true;
}

async function onUnassignDocument() {
  for (const doc of selectedDocumentsModel.value) {
    await unassignDocument(doc as DocumentModel);
  }
  await assignedDocumentsProvider.refresh();
}

async function onDownloadDocument() {
  for (const doc of selectedDocumentsModel.value) {
    await doc.download();
  }
}

async function onNewRevision() {
  documentForNewRevision.value = null;
  if (showDocumentAssignmentDialog.value && documentsForModification.value.length > 0) {
    documentForNewRevision.value = documentsForModification.value[0];
  } else if (!showDocumentAssignmentDialog.value && selectedDocumentsModel.value.length === 1) {
    documentForNewRevision.value = selectedDocumentsModel.value[0];
  }
  if (documentForNewRevision.value && newRevisionFileInputRef.value) {
    newRevisionFileInputRef.value.click();
  }
}

async function onAssignDocumentConfirmed() {
  const documentsForAssignment = documentsForModification.value;

  for (const doc of documentsForAssignment) {
    if (assignedDocumentsProvider.documents.value.map((x) => x.id).includes(doc.id)) continue;
    await assignDocument(doc as DocumentModel);
  }
  await contextDocumentsProvider.refresh();
  await assignedDocumentsProvider.refresh();
  showDocumentAssignmentDialog.value = false;
  documentsForModification.value = [];
}

async function assignDocument(doc: DocumentModel) {
  try {
    await manager.assignDocument(doc);
  } catch (e: any) {
    snackbarStore.createSnackbar({
      message: e && e.detail ? e.detail : $t("documentManager-assignError-message", { $: "Could not assign" }),
      type: "error",
      closeable: true
    });
  }
}

async function unassignDocument(doc: DocumentModel) {
  try {
    await manager.unassignDocument(doc);
    selectedDocumentsModel.value = selectedDocumentsModel.value.filter((x) => x.id !== doc.id);
  } catch (e: any) {
    snackbarStore.createSnackbar({
      message: e && e.detail ? e.detail : $t("documentManager-deleteError-message", { $: "Could not delete" }),
      type: "error",
      closeable: true
    });
  }
}

async function uploadItem(file: File): Promise<void> {
  try {
    await manager.uploadDocument(file, !showDocumentAssignmentDialog.value);

    snackbarStore.createSnackbar({
      message: $t("documentManager-itemUploaded-message", { $: "Uploaded" }),
      closeable: true
    });
  } catch (e: any) {
    const errorsStore = useErrorsStore();
    errorsStore.handleError(e);
  }
}

async function onDeleteDocumentConfirm() {
  if (!documentForDeletion.value) return;

  try {
    await manager.deleteDocument(documentForDeletion.value as DocumentModel);

    snackbarStore.createSnackbar({
      message: $t("resourceDocumentBrowser-itemDeleted-message", { $: "Deleted" }),
      closeable: true
    });
  } catch (e: any) {
    const errorsStore = useErrorsStore();
    errorsStore.handleError(e);
  }

  await assignedDocumentsProvider.refresh();
  await contextDocumentsProvider.refresh();
}

async function onDesktopUpload() {
  fileInputRef.value?.click();
}

async function handleFileSelection(event: Event) {
  const input = event.target as HTMLInputElement;
  if (input.files) {
    await uploadMultipleFiles(input.files);
  }
}

async function handleNewRevisionFileSelection(event: Event) {
  try {
    isUploading.value = true;
    emit("upload-start");
    emit("upload-stage", $t("documentManager-uploadStage-Initiating-message", { $: "Initiating" }));
    if (!documentForNewRevision.value) return;

    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length === 1) {
      let file = input.files.item(0);
      if (!file) {
        console.error("New revision file not selected", input.files);
        snackbarStore.createSnackbar({
          message: $t("documentManager-newRevisionError-message", { $: "Could not upload new revision" }),
          type: "error",
          closeable: true
        });
        return;
      }

      if (file.name !== documentForNewRevision.value.fileName) {
        console.error("New revision file name does not match the selected document", file.name, "<>", documentForNewRevision.value.fileName);
        snackbarStore.createSnackbar({
          message: $t("documentManager-revisionFileNameMismatch-message", { $: "Selected file name does not match the document" }),
          type: "warning",
          closeable: true
        });
        return;
      }

      try {

        if (isFileValidImageType(file)) {
          emit("upload-stage", $t("documentManager-uploadStage-compressing-message", { $: "Compressing" }));
          file = await compressImageFile(file);
        }
        emit("upload-stage", $t("documentManager-uploadStage-uploading-message", { $: "Uploading" }));
        await manager.createNewRevision(file, documentForNewRevision.value as DocumentModel, !showDocumentAssignmentDialog.value);

        snackbarStore.createSnackbar({
          message: $t("documentManager-newRevisionCreated-message", { $: "New revision created" }),
          closeable: true
        });

        if (newRevisionFileInputRef.value) {
          newRevisionFileInputRef.value.value = ""; // reset
        }
        await assignedDocumentsProvider.refresh();
        await contextDocumentsProvider.refresh();
      } catch (e: any) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
      }
    }
  } finally {
    documentForNewRevision.value = null;
    isUploading.value = false;
    emit("upload-end");
  }
}

async function uploadMultipleFiles(files: FileList) {
  try {
    isUploading.value = true;
    emit("upload-start");
    emit("upload-stage", $t("documentManager-uploadStage-initiating-message", { $: "Initiating" }));
    for (let i = 0; i < files.length; i++) {
      let file = files.item(i);

      if (isFileValidImageType(file)) {
        emit("upload-stage", $t("documentManager-uploadStage-compressing-message", { $: "Compressing" }));
        file = await compressImageFile(file);
      }
      emit("upload-stage", $t("documentManager-uploadStage-uploading-message", { $: "Uploading" }));

      if (!file) continue;

      await uploadItem(file);
    }
    if (fileInputRef.value) {
      fileInputRef.value.value = ""; // reset
    }
    await assignedDocumentsProvider.refresh();
    await contextDocumentsProvider.refresh();
  } finally {
    isUploading.value = false;
    emit("upload-end");
  }
}

async function onDeleteDocument(doc: DocumentModel) {
  documentForDeletion.value = doc;
  showDeleteDialog.value = true;
}

async function onSaveDocument(doc: DocumentModel) {
  try {
    await manager.saveDocument(doc);
    snackbarStore.createSnackbar({
      message: $t("documentManager-itemSaved-message", { $: "Saved" }),
      closeable: true
    });
  } catch (e: any) {
    const errorsStore = useErrorsStore();
    errorsStore.handleError(e);
  }
}
</script>

<template>
  <documents-browser
    ref="assignedDocumentsBrowserRef"
    v-model:selected-documents="selectedDocumentsModel"
    v-model:mode="browserMode"
    :documents-provider="assignedDocumentsProvider"
    editable
    deletable
    @save="onSaveDocument"
    @delete="onDeleteDocument"
  >
    <documents-manager-actions
      v-model="selectedDocumentsModel"
      :hide-assignment-actions="hideAssignmentActions"
      :uploading="isUploading"
      :qr-code-options="qrCodeOptions"
      @assign="onAssignDocument"
      @unassign="onUnassignDocument"
      @download="onDownloadDocument"
      @new-revision="onNewRevision"
      @desktop-upload="onDesktopUpload"
    />
    <input ref="fileInputRef" type="file" class="d-none" :multiple="true" @change="handleFileSelection" />
    <input ref="newRevisionFileInputRef" type="file" class="d-none" @change="handleNewRevisionFileSelection" />
    <template #details="scope">
      <slot name="details" v-bind="scope" />
    </template>
  </documents-browser>
  <context-documents-browser-dialog v-model="showDocumentAssignmentDialog" :confirm-action="onAssignDocumentConfirmed" :profile-name="manager.profileName">
    <documents-browser
      ref="contextDocumentsBrowserRef"
      v-model:selected-documents="documentsForModification"
      v-model:mode="browserMode"
      :documents-provider="contextDocumentsProvider"
      deletable
      @delete="onDeleteDocument"
    >
      <documents-manager-actions
        v-model="documentsForModification"
        hide-assignment-actions
        :uploading="isUploading"
        :qr-code-options="qrCodeOptions"
        @assign="onAssignDocument"
        @unassign="onUnassignDocument"
        @download="onDownloadDocument"
        @new-revision="onNewRevision"
        @desktop-upload="onDesktopUpload"
      />
    </documents-browser>
  </context-documents-browser-dialog>
  <v-fade-transition>
    <div v-if="isDraggingOver" class="drag-drop-area">
      <div class="drag-message">
        {{ $t("mediaPickerDialog-drop-label", { $: "Drop files to upload" }) }}
      </div>
    </div>
  </v-fade-transition>
  <documents-delete-confirmation-modal-dialog v-model="showDeleteDialog" @confirm="onDeleteDocumentConfirm"></documents-delete-confirmation-modal-dialog>
</template>

<style scoped lang="scss">
.drag-drop-area {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.drag-message {
  text-align: center;
  opacity: 1;
  pointer-events: none;
}
</style>
