export const GUID_EMPTY = "00000000-0000-0000-0000-000000000000";

export function nonEmptyGuidOrNull(guid: string): string | null {
  return guid !== GUID_EMPTY ? guid : "";
}

export function nameOrBusinessIdOrIdOrNull(data: any): string | null {
  return data?.name || data?.businessId || nonEmptyGuidOrNull(data?.id) || null;
}

export function joinArrayOfStrings(data: string[]): string {
  return (data ?? []).join(", ");
}

export function durationOrEmpty(
  duration: string | null | undefined,
  formatter: (duration: string) => string
): string {
  if (!duration || duration === "P0D") {
    return "";
  }
  return formatter(duration);
}