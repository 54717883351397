<template>
  <split-layout ref="splitLayoutRef" identifier="cost-catalogue" :gutter="createSplitPanelGutterElement">
    <template #master>
      <v-card elevation="0" class="flexcard fill-height">
        <v-card-title>
          <breadcrumbs>{{ $t("costCatalogue-view-costCatalogueItems-title", { $: "Cost catalogue" }) }}</breadcrumbs>
        </v-card-title>
        <v-card-text class="fill-height">
          <v-row class="fill-height" no-gutters>
            <v-col cols="12">
              <cost-catalogue-grid ref="costCatalogueGridRef" @selection-changed="onItemSelected" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
    <template #detail>
      <cost-catalogue-item-details v-if="item" :item="item" @updated="itemUpdated" @closed="closeDetails" />
      <v-card v-if="!item" elevation="7" class="d-flex flex-column fill-height no-services-card">
        <close-card-button @click="closeDetails"></close-card-button>
        <v-card-text class="fill-height">
          <v-row class="fill-height">
            <v-col cols="12" class="d-flex align-center justify-center">
              <label class="text-secondary text-h6 font-weight-regular">
                {{ $t("costCatalogue-view-noItemSelected-title", { $: "No item selected" }) }}
              </label>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </split-layout>
</template>

<script setup lang="ts">
import Breadcrumbs from "@/components/Layout/Breadcrumbs.vue";
import CostCatalogueGrid from "@/components/CostCatalogue/CostCatalogueGrid.vue";
import { $t } from "@/i18n";
import { ref, watch } from "vue";
import SplitLayout from "@/components/Layout/SplitLayout.vue";
import CloseCardButton from "@/components/CloseCardButton.vue";
import CostCatalogueItemDetails from "@/components/CostCatalogue/CostCatalogueItemDetails.vue";
import { CostCatalogueItemDto } from "@masta/generated-model";
import { useRoute, useRouter } from "vue-router";
import { SelectionChangedEvent } from "ag-grid-community";
import { getSelectedRows } from "@/components/Grid/UseGridSelection";
import { isDefined } from "@vueuse/core";
import { useScenariosStore } from "@/store/ScenariosStore";
import ApiService from "@/services/api";
import { useErrorsStore } from "@/store/ErrorsStore";

const item = ref<CostCatalogueItemDto | null>(null);
const splitLayoutRef = ref<typeof SplitLayout | null>(null);
const costCatalogueGridRef = ref<typeof CostCatalogueGrid | null>(null);
const router = useRouter();
const route = useRoute();

interface CostCatalogueItemsProps {
  embedded?: boolean;
}

defineProps<CostCatalogueItemsProps>();

watch(
  () => item.value,
  (newVal) => {
    if (newVal) {
      showDetails();
    } else {
      closeDetails();
    }
  }
);

watch(
  () => route.query.itemId,
  async (itemId, _) => {
    if (isDefined(itemId)) {
      if (isDefined(item.value) && item.value.id === itemId) {
        return;
      } else {
        item.value = await fetchItem(itemId as string);
      }
    } else {
      item.value = null;
    }
  },
  { immediate: true }
);

function showDetails() {
  splitLayoutRef.value?.showDetails();
}

function closeDetails() {
  splitLayoutRef.value?.closeDetails();
}

function createSplitPanelGutterElement(index: number, direction: "horizontal" | "vertical") {
  const gutter = document.createElement("div");
  gutter.classList.add("gutter-orders");
  return gutter;
}

function onItemSelected(event: SelectionChangedEvent) {
  const selectedRows = getSelectedRows(event.api);
  if (selectedRows.length === 0) {
    item.value = null;
    return;
  }

  const selectedItem = selectedRows[0];

  if (selectedItem) {
    item.value = selectedItem;

    showDetails();

    const currentQuery = router.currentRoute.value.query;
    const newQuery = Object.assign({}, currentQuery, { itemId: selectedItem.id });
    router.push({ query: newQuery });
  }
}

async function fetchItem(itemId: string) {
  const scenariosStore = useScenariosStore();
  const selectedScenario = scenariosStore.selectedScenario;

  if (!selectedScenario) {
    return null;
  }
  try {
    const response = await ApiService.costCatalogue.getSingle(itemId, selectedScenario.id);
    return response.data;
  } catch (error) {
    const errorsStore = useErrorsStore();
    errorsStore.handleError(error);
  }
  return null;
}

async function itemUpdated(id: string) {
  costCatalogueGridRef.value?.refreshSingle(id);
}
</script>

<style lang="scss" scoped></style>
