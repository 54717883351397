<script lang="ts" setup>
import { columnTypes } from "@/components/Grid/ColumnTypes";
import DeleteConfirmationModalDialog from "@/components/Grid/DeleteConfirmationModalDialog.vue";
import { onAutoGroupColumnCellClicked, onGroupOpenedExpandModeListener } from "@/components/Grid/GridExpandMode";
import { useGridStoreBinding } from "@/components/Grid/GridStoreBinding";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import { SettingsColDef } from "@/components/Grid/Profile/SettingsColDef";
import { useGridProfile } from "@/components/Grid/Profile/UseGridProfile";
import { useContextMenu } from "@/components/Grid/UseContextMenu";
import { useCrudActions } from "@/components/Grid/UseCrudActions";
import { useGridContext } from "@/components/Grid/UseGridContext";
import { useGridI18n } from "@/components/Grid/UseGridI18n";
import { useGridKeyboardShortcuts } from "@/components/Grid/UseGridKeyboardShortcuts";
import { useGridRowDoubleClicked } from "@/components/Grid/UseGridRowDoubleClicked";
import { getSelectedRows } from "@/components/Grid/UseGridSelection";
import { useInvalidColumnScroll } from "@/components/Grid/UseInvalidColumnScroll";
import { useServerSideGrid } from "@/components/Grid/UseServerSideGrid";
import { useSideBar } from "@/components/Grid/UseSideBar";
import { $t } from "@/i18n";
import { useGridStore } from "@/store/GridRegistryState";
import { UserInterfaceProfileDto } from "@masta/generated-model";
import { GridApi, GridReadyEvent, IsServerSideGroupOpenByDefaultParams, PostProcessPopupParams, RowClassParams, SuppressKeyboardEventParams } from "ag-grid-community";
import { AgGridVue } from "ag-grid-vue3";
import * as changeCase from "change-case";
import { computed, onBeforeMount, onBeforeUnmount, reactive, Ref, ref, toRef, UnwrapRef, useAttrs, useSlots } from "vue";
import { useTheme } from "vuetify";
import { getOS, OperatingSystem } from "@masta/gantt2/core";
import { useActionsColumn } from "@/components/Grid/UseActionsColumn";
import { IGridWrapperProps } from "@/components/Grid/GridWrapperProps";
import { IGridWrapperEmits } from "@/components/Grid/GridWrapperEmits";

interface Attributes {
  onPrepareColumns: (columnDefs: Ref<UnwrapRef<SettingsColDef[]>>) => void;
  onFetchData?: () => Promise<any>;
  onResolveRowClass?: (params: RowClassParams) => string[];
}

const $properties = withDefaults(defineProps<IGridWrapperProps>(), {
  actions: () => [],
  agGridStyle: () => ({ width: "100%", height: "100%" }),
  gridData: () => [],
  getParentIdPropertyName: () => "parentId",
  createChildBtnDisabled: undefined,
  setParentId: undefined,
  identifier: undefined,
  serverSideDatasource: undefined,
  contextMenuItems: undefined,
  expandOnGroupClick: true,
  checkboxSelection: true,
  noActionsRow: false,
  disableActionsColumn: false,
  enableColumnsToolPanel: false,
  enableFiltersToolPanel: false,
  enableChildrenSelectionOnly: false,
  disableRootPopupParent: false
});

const $emits = defineEmits<IGridWrapperEmits>();

const data = toRef($properties, "gridData");
const theme = useTheme();
const $attributes = useAttrs();
const dynamicAttrs = reactive({
  enableRangeSelection: false,
  suppressMultiRangeSelection: true,
  rowSelection: "multiple",
  isServerSideGroupOpenByDefault,
  defaultColDef: {
    suppressKeyboardEvent: (params: any) => {
      return params.event.key === "Delete";
    },
    wrapHeaderText: true,
    autoHeaderHeight: true
  },
  ...($properties.serverSide
    ? {
      serverSideDatasource: $properties.serverSideDatasource
    }
    : {
      rowData: data
    })
});
const $updatedAttrs = computed(() => {
  const returnObj: any = {
    ...dynamicAttrs
  };
  // use const below
  for (const attr in $attributes) {
    const key = attr.includes(":") ? attr : changeCase.camelCase(attr);
    if (returnObj[key] && typeof returnObj[key] === "object") {
      returnObj[key] = {
        ...returnObj[key],
        ...($attributes[attr] as any)
      };
    } else {
      returnObj[key] = $attributes[attr];
    }
  }
  return returnObj;
});

const $profile = ref<UserInterfaceProfileDto | undefined>(undefined);
const { onPrepareColumns, onFetchData, onResolveRowClass }: Attributes = $attributes as unknown as Attributes;

const rowModelType = $properties.serverSide ? "serverSide" : "clientSide";
const agGridRef = ref<typeof AgGridVue>(null!);
const rootElement = ref<any>(undefined!);
const gridApi = ref<GridApi>(null!);

const gridStore = useGridStore($properties.identifier);
const gridStoreBinding = useGridStoreBinding(gridStore);
const columnDefs = ref<SettingsColDef[]>([]);
const $gridProfile = useGridProfile({
  columnDefs: columnDefs as Ref<SettingsColDef[]>,
  identifier: toRef($properties, "identifier")
});
const crudActions: ReturnType<typeof useCrudActions> = useCrudActions($properties, $emits, gridStore);
const actionsControl = crudActions.actionsControl;
const deleteDialog = crudActions.deleteDialog;
const slots = useSlots();
const gridLocale = useGridI18n();
const gridKeyboardShortcuts = useGridKeyboardShortcuts();
const gridRowDoubleClicked = useGridRowDoubleClicked($properties, crudActions as any, gridStore);
const invalidColumnScroll = useInvalidColumnScroll();
const showCrudActionsSeparator = computed(() => !$properties.hideCustomActionsSeparator && !!slots["custom-buttons"]);
const isCypress = computed(() => !!window.Cypress);

defineExpose<GridWrapperComponent>({
  gridApi: gridApi as unknown as GridApi,
  isEditing: () => gridStore.isEditing,
  isUpdating: () => gridStore.isUpdating,
  contextRowNode: crudActions.contextRowNode as any,
  crudActions: crudActions as any,
  refreshSingleRow: crudActions.refreshSingleRow,
  toggleSidebar
});

const sideBar = useSideBar($properties.identifier, $properties.enableColumnsToolPanel, $properties.enableFiltersToolPanel, $gridProfile);

useServerSideGrid($properties.serverSide, dynamicAttrs);
useContextMenu($properties, dynamicAttrs, crudActions);
useGridContext(dynamicAttrs);

onBeforeMount(async () => {
  if (onPrepareColumns) {
    onPrepareColumns(columnDefs);
    addActionsColumn(columnDefs);
    modifyKeyboardNavigation(columnDefs);
  }
  if (onFetchData && !$properties.serverSide) {
    await onFetchData();
  }
});

const { addActionsColumn } = useActionsColumn(gridApi as Ref<GridApi>, $updatedAttrs, $gridProfile, crudActions, $properties, $emits);


function modifyKeyboardNavigation(columnDefs: Ref<UnwrapRef<SettingsColDef[]>>) {
  columnDefs.value.forEach((col) => {
    if (!col.suppressKeyboardEvent) {
      col.suppressKeyboardEvent = (params: SuppressKeyboardEventParams) => {
        const isEditing = params.api.getEditingCells().length > 0 || params.editing;
        if (params.event.key === "Enter" && isEditing) {
          params.event.preventDefault();
          params.api.tabToNextCell();
          return true;
        }
        if (params.event.key === "s" && isEditing) {
          const isModifier = getOS() === OperatingSystem.Mac ? params.event.metaKey : params.event.ctrlKey;
          if (isModifier) {
            params.event.preventDefault();
            params.api.stopEditing();
            return true;
          }
        }
        return false;
      };
    }
  });
}

async function onGridReady(params: GridReadyEvent) {
  crudActions.onGridReady(params);
  gridStoreBinding.onGridReady(params);
  invalidColumnScroll.onGridReady(params);
  gridKeyboardShortcuts.onGridReady(params);
  gridRowDoubleClicked.onGridReady(params);
  await $gridProfile.onGridReady(params);
  gridApi.value = params.api;

  if ($properties.serverSideDatasource) {
    gridApi.value.setGridOption("serverSideDatasource", $properties.serverSideDatasource);
  }

  if (!$properties.disableRootPopupParent && rootElement.value && rootElement.value.$el) {
    gridApi.value.setGridOption("popupParent", rootElement.value.$el);
    gridApi.value.setGridOption("postProcessPopup", (params: PostProcessPopupParams) => {
      // following code should be applied to the pagination ag-list popup only
      const isPaginationDropdownSource = params.eventSource?.closest(".ag-paging-page-size")?.contains(params.eventSource);
      if (params.type === "ag-list" && isPaginationDropdownSource) {
        const sourceBox = params.eventSource?.getBoundingClientRect();
        const popupBox = params.ePopup.getBoundingClientRect();
        if (sourceBox && popupBox) {
          // reposition top to stick to the bottom of the source element
          params.ePopup.style.top = `${params.ePopup.offsetTop + (sourceBox.top - popupBox.bottom - 2)}px`;
        }
      }
    });
  }

  if ($properties.expandOnlyOneGroupRow) {
    gridApi.value.addEventListener("rowGroupOpened", onGroupOpenedExpandModeListener);
  }

  if ($properties.expandOnGroupClick) {
    gridApi.value.addEventListener("cellClicked", onAutoGroupColumnCellClicked);
  }

  $emits("ready", params);
}

onBeforeUnmount(() => {
  if ($properties.expandOnlyOneGroupRow) {
    gridApi.value?.removeEventListener("rowGroupOpened", onGroupOpenedExpandModeListener);
  }

  if ($properties.expandOnGroupClick) {
    gridApi.value?.removeEventListener("cellClicked", onAutoGroupColumnCellClicked);
  }
});

function isServerSideGroupOpenByDefault(params: IsServerSideGroupOpenByDefaultParams) {
  return gridStoreBinding.isServerSideGroupOpenByDefault(params);
}

function getRowClass(params: RowClassParams) {
  const r = getSelectedRows(gridApi.value)[0];
  const result: string[] = [];
  if (params.node.id === r?.id) {
    result.push("data-edit-shadow");
  }
  if (onResolveRowClass) {
    result.push(...onResolveRowClass(params));
  }
  return result;
}

function toggleSidebar() {
  gridApi.value.setSideBarVisible(!gridApi.value.isSideBarVisible());
}
</script>
<script lang="ts">
// declare additional options
export default {
  inheritAttrs: false
};
</script>

<template>
  <v-row ref="rootElement" no-gutters class="grid-wrapper fill-height" :data-cy="identifier + '-grid'">
    <v-col cols="12" class="d-flex flex-column">
      <delete-confirmation-modal-dialog v-model="deleteDialog" @confirm="crudActions.onDeleteConfirm" @cancel="crudActions.onDeleteCancel" />
      <div v-if="!$properties.noActionsRow" class="position-relative pb-4">
        <slot
          name="custom-action-row"
          :crud-actions="crudActions"
          :grid-api="gridApi as GridApi"
          :toggle-sidebar="toggleSidebar"
        >
          <v-row class="actions-row h-100 align-center">
            <v-tooltip v-if="actionsControl.cancelActionEnabled" location="bottom" open-delay="300">
              <template #activator="{ props }">
                <div class="d-inline-flex pr-4">
                  <v-btn
                    id="btn-cancel-action"
                    density="compact"
                    variant="text"
                    icon="mdi-cancel"
                    v-bind="props"
                    @click="crudActions.onCancelAction"
                  ></v-btn>
                </div>
              </template>
              <span>{{ $t("list-menu-cancel-action", { $: "Cancel" }) }}</span>
            </v-tooltip>
            <v-tooltip v-if="actionsControl.saveActionEnabled" location="bottom" open-delay="300">
              <template #activator="{ props }">
                <div class="d-inline-flex pr-4">
                  <v-btn
                    id="btn-save-action"
                    density="compact"
                    variant="text"
                    icon="mdi-content-save"
                    color="primary"
                    v-bind="props"
                    @click="crudActions.onSaveAction"
                  ></v-btn>
                </div>
              </template>
              <span>{{ $t("list-menu-save-action", { $: "Save" }) }}</span>
            </v-tooltip>
            <v-tooltip v-if="createBtn && !actionsControl.saveActionEnabled" location="bottom" open-delay="300">
              <template #activator="{ props }">
                <div class="d-inline-flex">
                  <v-btn
                    id="btn-create-action"
                    density="compact"
                    variant="text"
                    icon="mdi-plus"
                    v-bind="props"
                    :disabled="actionsControl.createDisabled || createBtnDisabled"
                    @click="crudActions.onCreateAction"
                  ></v-btn>
                </div>
              </template>
              <span>{{ $t("list-menu-add-action", { $: "Add new" }) }}</span>
            </v-tooltip>
            <v-tooltip v-if="duplicateBtn && !actionsControl.saveActionEnabled" location="bottom" open-delay="300">
              <template #activator="{ props }">
                <div class="d-inline-flex">
                  <v-btn
                    id="btn-duplicate-action"
                    density="compact"
                    variant="text"
                    icon="mdi-content-copy"
                    v-bind="props"
                    :disabled="actionsControl.duplicateDisabled || duplicateBtnDisabled"
                    @click="crudActions.onDuplicateAction"
                  ></v-btn>
                </div>
              </template>
              <span>{{ $t("list-menu-duplicate-tooltip", { $: "Duplicate" }) }}</span>
            </v-tooltip>
            <v-tooltip v-if="editBtn && !actionsControl.saveActionEnabled" location="bottom" open-delay="300">
              <template #activator="{ props }">
                <div class="d-inline-flex">
                  <v-btn
                    id="btn-edit-action"
                    density="compact"
                    variant="text"
                    icon="mdi-pencil"
                    v-bind="props"
                    :disabled="actionsControl.editDisabled || editBtnDisabled"
                    @click="crudActions.onEditAction"
                  ></v-btn>
                </div>
              </template>
              <span>{{ $t("list-menu-edit-action", { $: "Edit" }) }}</span>
            </v-tooltip>
            <v-tooltip v-if="deleteBtn && !actionsControl.cancelActionEnabled" location="bottom" open-delay="300">
              <template #activator="{ props }">
                <div class="d-inline-flex">
                  <v-btn
                    id="btn-delete-action"
                    density="compact"
                    variant="text"
                    icon="mdi-delete"
                    v-bind="props"
                    :disabled="actionsControl.deleteDisabled || deleteBtnDisabled"
                    @click="crudActions.onDeleteAction"
                  ></v-btn>
                </div>
              </template>
              <span>{{ $t("list-menu-delete-action", { $: "Delete" }) }}</span>
            </v-tooltip>
            <div v-if="showCrudActionsSeparator" class="d-inline-flex text-secondary font-weight-bold pl-4 pr-6 h-separator">
              <v-divider vertical />
            </div>
            <slot name="custom-buttons" :grid-api="gridApi" />
            <slot name="filter" />
            <v-spacer></v-spacer>
            <div class="d-inline-flex">
              <v-tooltip location="bottom" open-delay="300">
                <template #activator="{ props }">
                  <div class="d-inline-flex">
                    <v-btn
                      id="btn-sidebar-action"
                      density="compact"
                      variant="text"
                      icon="mdi-cog"
                      v-bind="props"
                      @click="toggleSidebar"
                    ></v-btn>
                  </div>
                </template>
                <span>{{ $t("list-menu-toggleSidebar-action", { $: "Show/hide sidebar" }) }}</span>
              </v-tooltip>
            </div>
          </v-row>
        </slot>
      </div>
      <div
        class="flex-grow-1 ag-theme-custom"
        @keydown="gridKeyboardShortcuts.handleKeyDown">
        <ag-grid-vue
          ref="agGridRef"
          v-bind="$updatedAttrs"
          :style="agGridStyle"
          animate-rows
          :column-defs="columnDefs"
          :side-bar="sideBar"
          :row-model-type="rowModelType"
          :column-types="columnTypes"
          :suppress-click-edit="true"
          :suppress-column-virtualisation="true"
          :suppress-row-deselection="true"
          :suppress-group-rows-sticky="true"
          :get-row-class="getRowClass"
          :locale-text="gridLocale"
          :suppress-row-virtualisation="isCypress"
          :maintain-column-order="true"
          edit-type="fullRow"
          style="position: relative"
          @grid-ready="onGridReady"
        />
        <slot />
      </div>
    </v-col>
  </v-row>
</template>

<style lang="scss" scoped>
.h-separator {
  height: 26px;
}

.actions-row {
  display: flex;
  flex-wrap: wrap;
  padding: 6px;
  row-gap: 10px;
}

.spam {
  background-color: red !important;
}
</style>
