<script setup lang="ts">

import { DocumentModel } from "@/components/Documents/DocumentModel";
import { inject, ref, UnwrapRef, watch } from "vue";
import { $t } from "@/i18n";
import { $dateTimeFormatterSymbol, DateFormatter } from "@masta/shared";
import { requiredRule } from "@/components/ValueCellEditor/CommonValidationRules";
import TagsPicker from "@/components/Tags/TagsPicker.vue";
import { VForm } from "vuetify/components";
import _ from "lodash";

const props = defineProps<{
  editable?: boolean;
}>();

const model = defineModel<UnwrapRef<DocumentModel>>({
  required: true
});

const $emit = defineEmits<{
  (e: "save", document: DocumentModel): void;
  (e: "cancel"): void;
}>();

const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;

const formRef = ref<VForm | null>(null);
const formValid = ref(false);

const saving = ref(false);
const disabled = ref(true);

const document = ref<DocumentModel>(model.value as DocumentModel);

watch(model, (newVal) => {
  document.value = _.cloneDeep(newVal);
});

async function onSaveAction() {
  saving.value = true;
  try {
    const validationResult = await formRef.value?.validate();
    if (validationResult && validationResult.valid) {
      $emit("save", document.value as DocumentModel);
      disabled.value = true;
    }
  } finally {
    saving.value = false;
  }
}

function onCancelAction() {
  $emit("cancel");
  disabled.value = true;
}

function onEditAction() {
  disabled.value = false;
}
</script>

<template>
  <v-card-text class="fill-height">
    <v-row v-if="editable" class="actions-row mb-4">
      <v-tooltip v-if="disabled" location="bottom" open-delay="300">
        <template #activator="{ props }">
          <div class="d-inline-flex pr-4">
            <v-btn size="small" v-bind="props" variant="tonal" density="compact" @click="onEditAction">
              <v-icon icon="mdi-pencil" class="pr-4" />
              {{ $t("modelInstance-edit-edit-action", { $: "Edit" }) }}
            </v-btn>
          </div>
        </template>
        <span>{{ $t("modelInstance-edit-edit-action", { $: "Edit" }) }}</span>
      </v-tooltip>
      <v-tooltip v-if="!disabled" location="bottom" open-delay="300">
        <template #activator="{ props }">
          <div class="d-inline-flex pr-4">
            <v-btn size="small" v-bind="props" variant="tonal" density="compact" @click="onCancelAction">
              <v-icon icon="mdi-cancel" class="pr-4" />
              {{ $t("modelInstance-edit-cancel-action", { $: "Cancel" }) }}
            </v-btn>
          </div>
        </template>
        <span>{{ $t("modelInstance-edit-cancel-action", { $: "Cancel" }) }}</span>
      </v-tooltip>
      <v-tooltip v-if="!disabled" location="bottom" open-delay="300">
        <template #activator="{ props }">
          <div class="d-inline-flex pr-4">
            <v-btn size="small" v-bind="props" color="primary" density="compact" @click="onSaveAction">
              <v-icon class="pr-4" icon="mdi-content-save" />
              {{ $t("modelInstance-edit-save-action", { $: "Save" }) }}
            </v-btn>
          </div>
        </template>
        <span>{{ $t("modelInstance-edit-save-action", { $: "Save" }) }}</span>
      </v-tooltip>
    </v-row>
    <v-form ref="formRef" v-model="formValid">
      <v-row>
        <v-col cols="10">
          <v-text-field
            v-model="document.description"
            variant="outlined"
            density="compact"
            hide-details
            :disabled="disabled"
            :label="$t('documents-details-description-label', { $: 'Description' })"
          />
        </v-col>
        <v-col cols="10">
          <tags-picker v-model="document.tags" :disabled="disabled" :label="$t('documents-details-tags-label', { $: 'Tags' })" color="primary" />
        </v-col>
        <v-col cols="10">
          <v-divider />
        </v-col>
        <v-col cols="10">
          <v-text-field
            v-model="document.businessId"
            variant="outlined"
            density="compact"
            hide-details
            disabled
            :label="$t('documents-details-businessId-label', { $: 'Business ID' })"
          />
        </v-col>
        <v-col cols="10">
          <v-text-field
            v-model="document.revisionNumber"
            variant="outlined"
            density="compact"
            hide-details
            disabled
            :label="$t('documents-details-revisionNumber-label', { $: 'Revision number' })"
          />
        </v-col>
        <v-col cols="10">
          <v-text-field
            v-model="document.fileName"
            variant="outlined"
            density="compact"
            hide-details
            disabled
            :label="$t('documents-details-fileName-label', { $: 'File name' })"
          />
        </v-col>
        <v-col cols="10">
          <v-text-field
            v-model="document.mimeType"
            variant="outlined"
            density="compact"
            hide-details
            disabled
            :label="$t('documents-details-mimeType-label', { $: 'Mime type' })"
          />
        </v-col>
        <v-col cols="10">
          <v-text-field
            v-model="document.createdBy"
            variant="outlined"
            density="compact"
            hide-details
            disabled
            :label="$t('documents-details-createdBy-label', { $: 'Created by' })"
          />
        </v-col>
        <v-col cols="10">
          <v-text-field
            :model-value="$dateTimeFormatter(document.createdAt)"
            variant="outlined"
            density="compact"
            hide-details
            disabled
            :label="$t('documents-details-createdAt-label', { $: 'Created at' })"
          />
        </v-col>
        <v-col cols="10">
          <v-text-field
            v-model="document.modifiedBy"
            variant="outlined"
            density="compact"
            hide-details
            disabled
            :label="$t('documents-details-modifiedBy-label', { $: 'Modified by' })"
          />
        </v-col>
        <v-col cols="10">
          <v-text-field
            :model-value="$dateTimeFormatter(document.modifiedAt)"
            variant="outlined"
            density="compact"
            hide-details
            disabled
            :label="$t('documents-details-modifiedAt-label', { $: 'Modified at' })"
          />
        </v-col>
      </v-row>
    </v-form>
  </v-card-text>
</template>

<style scoped lang="scss">
.actions-row {
  display: flex;
  flex-wrap: wrap;
  padding: 6px;
  row-gap: 10px;
}
</style>
