<script lang="ts" setup>
import { GridApi, SelectionChangedEvent } from "ag-grid-community";
import { onBeforeUnmount, ref, toRef, watch } from "vue";
import { $t } from "@/i18n";
import { useSnackbarsStore } from "@/store/SnackbarsStore";
import CreateWorkJournalRecordDialog from "@/components/WorkJournalRecords/CreateWorkJournalRecordDialog.vue";
import { PublishExecutionEventCommand, WorkJournalRecordDto, WorkJournalRecordEventType } from "@masta/generated-model";
import Api from "@/services/api";
import { v4 as uuidv4 } from "uuid";
import { getSelectedRows } from "@/components/Grid/UseGridSelection";

interface Props {
  gridApi: GridApi | null;
}

const $props = defineProps<Props>();
const snackbarsStore = useSnackbarsStore();
const dialog = ref(false);

const selectedWorkJournal = ref<WorkJournalRecordDto>(createEmptyWorkJournalRecordDto());

const watchHandler = watch(toRef($props, "gridApi"), (value) => {
  if (value) {
    $props.gridApi?.addEventListener("selectionChanged", onSelectionChanged);
    watchHandler();
  }
});

onBeforeUnmount(() => {
  $props.gridApi?.removeEventListener("selectionChanged", onSelectionChanged);
});

const isAnyRowSelected = ref(false);

function onSelectionChanged(e: SelectionChangedEvent) {
  const selectedRows = getSelectedRows(e.api);
  isAnyRowSelected.value = selectedRows.length > 0;
  if (isAnyRowSelected.value) {
    selectedWorkJournal.value = selectedRows[0] as WorkJournalRecordDto;
  } else {
    selectedWorkJournal.value = createEmptyWorkJournalRecordDto();
  }
}


function createEmptyWorkJournalRecordDto(): WorkJournalRecordDto {
  return {
    id: "00000000-0000-0000-0000-000000000000",
    affectedWorkJournalRecordId: "00000000-0000-0000-0000-000000000000",
    resourceId: "00000000-0000-0000-0000-000000000000",
    taskId: "",
    taskBusinessId: "",
    taskName: "",
    stepId: "",
    stepPosition: 0,
    stepName: "",
    //stepType: StepType.Undefined,
    personId: "00000000-0000-0000-0000-000000000000",
    personBusinessId: "",
    personName: "",
    agreementId: "00000000-0000-0000-0000-000000000000",
    agreementBusinessId: "",
    agreementName: "",
    equipmentId: "00000000-0000-0000-0000-000000000000",
    equipmentBusinessId: "",
    equipmentName: "",
    serviceId: "00000000-0000-0000-0000-000000000000",
    serviceBusinessId: "",
    serviceName: "",
    assetIds: [],
    //eventType: WorkJournalRecordEventType.Quantity,
    //eventAction: WorkJournalRecordEventAction.Regular,
    eventStart: "",
    timestamp: "",
    eventPayload: {},
    cancelled: false,
    isAutomation: false,
    businessId: "",
    tags: [],
    tenantId: ""
  };
}

async function dataFilled(workJournal: PublishExecutionEventCommand) {
  workJournal.id = uuidv4();
  await sendWorkJournal(workJournal);
}

async function sendWorkJournal(workJournal: PublishExecutionEventCommand) {
  try {
    switch (workJournal.eventType) {
      case WorkJournalRecordEventType.ClockIn:
      case WorkJournalRecordEventType.ClockOut:
      case WorkJournalRecordEventType.MachineInterruptStart:
      case WorkJournalRecordEventType.ResourceResourceAssignment:
        await Api.executionEvents.sendResourceExecutionEvent(workJournal);
        break;
      case WorkJournalRecordEventType.Production:
      case WorkJournalRecordEventType.Finish:
      case WorkJournalRecordEventType.Quantity:
        await Api.executionEvents.sendTaskExecutionEvent(workJournal);
        break;
      case WorkJournalRecordEventType.QualityCheck:
        await Api.executionEvents.sendQualityCheckExecutionEvent(workJournal);
        break;
      default:
        throw new Error("Unsupported work journal event type");
        break;

        await snackbarsStore.createSnackbar({
          message: $t("workJournalRecords-list-sent-message", { $: "Work Journal Record sent" }),
          closeable: true
        });
    }
  } catch (e: any) {
    console.error(e);
    await snackbarsStore.createSnackbar({
      message: e.message,
      type: "error",
      closeable: true
    });
  }
}
</script>

<template>
  <v-tooltip location="bottom" open-delay="300">
    <template #activator="{ props }">
      <div class="d-inline-flex pr-4">
        <!--        <v-btn size="small" variant="text" density="compact" v-bind="props" :disabled="!isAnyRowSelected" @click="dialog = true">-->
        <v-btn size="small" variant="text" density="compact" v-bind="props" @click="dialog = true">
          <v-icon icon="mdi-pencil-outline" class="pr-4" />
          {{ $t("workJournalRecords-list-create-action", { $: "1. Create Work Journal entry" }) }}
        </v-btn>
      </div>
    </template>
    <span>{{ $t("workJournalRecords-list-create-tooltip", { $: " Create Work Journal using parameters of selected row" }) }}</span>
  </v-tooltip>
  <create-work-journal-record-dialog v-model="dialog" persistent no-click-animation :template="selectedWorkJournal" @data-filled="dataFilled" />
</template>
