import ApiService from "@/services/api";
import { CreateModelInstanceCommand, ModelInstanceAssignmentsDto, ModelInstanceDto, ModelInstanceIdDto, ModelSchemaDto, UpdateModelInstanceCommand } from "@masta/generated-model";
import { defineStore } from "pinia";
import { useErrorsStore } from "@/store/ErrorsStore";
import { useScenariosStore } from "@/store/ScenariosStore";
import { $t, $te } from "@/i18n";
import { camelCase, kebabCase } from "change-case";

interface State {
  schemas: ModelSchemaWithLabelDto[];
}

export type ModelSchemaWithLabelDto = ModelSchemaDto & { schemaId: string; label: string };

function getSchemaLabel(schema: ModelSchemaDto) {
  const id = kebabCase(`${schema.schemaKey}.${camelCase(schema.schemaName)}`, { locale: false });
  const exists = $te(id);
  if (!exists) console.warn(`Missing translation for schema: ${getSchemaId(schema)}. Translation key: ${id}`);
  return exists ? $t(id) : schema.schemaName;
}

function getSchemaId(schema: ModelSchemaDto) {
  return `${schema.schemaKey}.${schema.schemaName}`;
}

export function getPartsFromSchemaId(schemaId: string) {
  const index = schemaId.lastIndexOf(".");
  return {
    key: schemaId.slice(0, index),
    name: schemaId.slice(index + 1)
  };
}

export const useModelInstancesStore = defineStore("model-instances", {
  state: (): State => ({
    schemas: []
  }),
  actions: {
    async fetchSchemas() {
      try {
        const response = await ApiService.modelInstances.getSchemas();
        this.schemas = response.data.schemas.map((x) => {
          return {
            ...x,
            schemaId: getSchemaId(x),
            label: getSchemaLabel(x)
          };
        });
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async fetchSchemaInstanceIds(schema: ModelSchemaDto): Promise<ModelInstanceIdDto[]> {
      try {
        const { data } = await ApiService.modelInstances.getModelInstanceIds(getSchemaId(schema), schema.version);
        return data.modelInstanceIds;
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async fetchModelInstanceAssignments(modelInstanceId: string): Promise<ModelInstanceAssignmentsDto> {
      try {
        const scenarioStore = useScenariosStore();
        const { data } = await ApiService.modelInstances.getAssignments(modelInstanceId, scenarioStore.selectedScenario?.id ?? "");
        return data;
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async createInstance(instance: CreateModelInstanceCommand) {
      try {
        const { data } = await ApiService.modelInstances.createInstance(instance);
        return data;
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        return null;
      }
    },
    async updateInstance(instance: ModelInstanceDto) {
      try {
        await ApiService.modelInstances.updateInstance({
          id: instance.id,
          value: instance.value,
          tags: instance.tags,
          revisionNumber: instance.revisionNumber,
          businessId: instance.businessId,
          schemaTypes: instance.schemaTypes
        });
        return true;
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        return false;
      }
    },
    async updateModelInstance(request: UpdateModelInstanceCommand) {
      try {
        await ApiService.modelInstances.updateInstance(request);
        return true;
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        return false;
      }
    },
    async deleteInstance(instance: ModelInstanceDto) {
      try {
        await ApiService.modelInstances.deleteInstance(instance.id, instance.revisionNumber);
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async makeCopy(instance: ModelInstanceDto) {
      try {
        const { data } = await ApiService.modelInstances.makeCopy({ id: instance.id, revisionNumber: instance.revisionNumber, businessId: null! });
        return data;
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async release(instance: ModelInstanceDto) {
      try {
        await ApiService.modelInstances.release({ id: instance.id, revisionNumber: instance.revisionNumber, businessId: null! });
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async archive(instance: ModelInstanceDto) {
      try {
        await ApiService.modelInstances.archive({ id: instance.id, revisionNumber: instance.revisionNumber, businessId: null! });
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async createNewRevision(instance: ModelInstanceDto) {
      try {
        await ApiService.modelInstances.createNewRevision({ id: instance.id });
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    }
  }
});
