<script lang="ts" setup>
import CloseCardButton from "@/components/CloseCardButton.vue";
import ModelInstanceIdGrid from "@/components/ModelInstances/ModelInstanceAssignment/ModelInstanceIdGrid.vue";
import { ModelSchemaWithLabelDto, useModelInstancesStore } from "@/store/ModelInstancesStore";
import { ModelInstanceDto, ModelInstanceIdDto, ModelSchemaDto } from "@masta/generated-model";
import { storeToRefs } from "pinia";
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import { $t } from "@/i18n";

const props = defineProps<{
  schema?: ModelSchemaWithLabelDto;
  modelInstances: ModelInstanceDto[];
  opened: boolean;
}>();

const emit = defineEmits<{
  (e: "confirm", ids: ModelInstanceIdDto[]): void;
  (e: "cancel"): void;
}>();

onMounted(() => {
  document.addEventListener("keydown", escapeHandler);
});

onUnmounted(() => {
  document.removeEventListener("keydown", escapeHandler);
});

const miStore = useModelInstancesStore();
const { schemas } = storeToRefs(miStore);

const assignedInstances = ref<ModelInstanceIdDto[]>([]);
const unassignedInstances = ref<ModelInstanceIdDto[]>([]);
const allInstances = ref<ModelInstanceIdDto[]>([]);
const selectedUnassignedInstances = ref<ModelInstanceIdDto[]>([]);
const selectedSchema = ref<ModelSchemaWithLabelDto>();

watch(
  () => props.opened,
  async (newValue) => {
    if (newValue) {
      await loadData();
    }
  },
  {
    immediate: true
  }
);

async function loadData() {
  if (!schemas.value || schemas.value.length <= 0) {
    return;
  }
  if (props.schema) {
    selectedSchema.value = props.schema;
  } else if (!selectedSchema.value) {
    selectedSchema.value = schemas.value[0];
  }
  allInstances.value = await miStore.fetchSchemaInstanceIds(selectedSchema.value);
  assignedInstances.value = allInstances.value.filter((x) => props.modelInstances.findIndex((y) => y.id === x.id) >= 0);
  unassignedInstances.value = allInstances.value.filter((x) => assignedInstances.value.findIndex((y) => y.id === x.id) === -1);
}

const schemaSelectionReadonly = computed(() => !!props.schema);

function confirm() {
  emit("confirm", selectedUnassignedInstances.value);
}

function cancel() {
  emit("cancel");
}

function escapeHandler(e: KeyboardEvent) {
  if (e.key === "Escape") {
    emit("cancel");
  }
}

function onUnassignedSelectionChanged(selection: ModelInstanceIdDto[]) {
  selectedUnassignedInstances.value = selection;
}

function onUnassignedDoubleCliked(selection: ModelInstanceIdDto) {
  emit("confirm", [selection]);
}

async function selectSchema(newSchema: ModelSchemaDto) {
  selectedSchema.value = newSchema;
  await loadData();
}
</script>

<template>
  <v-dialog :model-value="opened" style="z-index: 2000" :persistent="true" class="assignment-editor-dialog" @click:outside="cancel">
    <v-card width="100%" height="90vh" class="assignment-editor-card">
      <close-card-button @click="cancel"></close-card-button>
      <v-card-title class="pa-0">
        <div class="text-h4 pb-5">
          {{ $t("modelInstance-assignmentEditor-modelInstanceAssignment-title", { $: "Model Instance Assignment" }) }}
        </div>
      </v-card-title>
      <v-card-text class="d-flex flex-column flex-grow-1 px-0">
        <v-row class="flex-nowrap flex-grow-0">
          <div class="left-col d-flex flex-row flex-grow-1 flex-shrink-1">
            <v-autocomplete
              :model-value="selectedSchema"
              :items="schemas"
              :item-title="(v) => `${v.label} (v. ${v.version})`"
              :item-value="(v) => v"
              :label="$t('modelInstance-assignmentEditor-selectSchema-label', { $: 'Select Schema' })"
              :persistent-hint="selectedSchema === null"
              :hint="selectedSchema === null ? $t('modelInstance-assignmentEditor-selectSchema-label', { $: 'Select schema' }) : null"
              :disabled="schemaSelectionReadonly"
              variant="outlined"
              density="compact"
              color="primary"
              class="align-self-start"
              @update:model-value="selectSchema"
            />
          </div>
          <div class="middle-col d-flex flex-column justify-center flex-shrink-1"></div>
          <div class="right-col d-flex flex-column flex-grow-1 flex-shrink-1"></div>
        </v-row>
        <v-row class="flex-nowrap flex-grow-1">
          <div class="left-col d-flex flex-column flex-grow-1 flex-shrink-1">
            <model-instance-id-grid :model-instance-ids="unassignedInstances" @selection-changed="onUnassignedSelectionChanged" @double-clicked="onUnassignedDoubleCliked" />
          </div>
        </v-row>
      </v-card-text>
      <v-card-actions class="pa-0">
        <div class="left-col pl-0">
          <v-btn variant="elevated" color="secondary" class="w-100" @click="cancel">
            {{ $t("modelInstance-assignmentEditor-cancel-action", { $: "Cancel" }) }}
          </v-btn>
        </div>
        <div class="middle-col"></div>
        <div class="right-col pr-0">
          <v-btn variant="elevated" color="primary" class="w-100" @click="confirm">
            {{ $t("modelInstance-assignmentEditor-confirm-action", { $: "Confirm" }) }}
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
