<script lang="ts" setup>
import { IDocumentProvider } from "@/components/Documents/IDocumentProvider";
import DocumentsListView from "@/components/Documents/DocumentsListView.vue";
import { CellDoubleClickedEvent, GridApi, GridReadyEvent, IRowNode, SelectionChangedEvent } from "ag-grid-community";
import { DocumentModel } from "@/components/Documents/DocumentModel";
import { ref, UnwrapRef, watch } from "vue";
import DocumentsGalleryDialog from "@/components/Documents/DocumentsGalleryDialog.vue";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import DocumentsBrowserActionsRow from "@/components/Documents/DocumentsBrowserActionsRow.vue";
import { DocumentsBrowserViewMode } from "@/components/Documents/DocumentsBrowserViewMode";
import DocumentsGridView from "@/components/Documents/DocumentsGridView.vue";
import DocumentsGalleryView from "@/components/Documents/DocumentsGalleryView.vue";
import SplitLayout from "@/components/Layout/SplitLayout.vue";
import CloseCardButton from "@/components/CloseCardButton.vue";
import DocumentDetails from "@/components/Documents/DocumentDetails.vue";
import { $t } from "@/i18n";
import DocumentsListViewInfoActionButton from "@/components/Documents/DocumentsListViewInfoActionButton.vue";

export interface DocumentBrowserProps {
  documentsProvider: IDocumentProvider;
  editable?: boolean;
  deletable?: boolean;
}

const props = defineProps<DocumentBrowserProps>();
const $emit = defineEmits<{
  (event: "edit", value: DocumentModel): void;
  (event: "delete", value: DocumentModel): void;
  (event: "save", value: DocumentModel): void;
}>();

const { documents, sort, searchText } = props.documentsProvider;

const browserMode = defineModel<DocumentsBrowserViewMode>("mode", {
  required: false,
  default: DocumentsBrowserViewMode.Grid,
  local: true
});

const detailsVisible = ref<boolean>(false);
const showGallery = ref<boolean>(false);
const documentForGalleryPreview = ref<DocumentModel | null | undefined>(null);
const selectedDocuments = defineModel<UnwrapRef<DocumentModel[]>>("selectedDocuments", {
  required: false,
  default: [],
  local: true
});

const splitLayoutRef = ref<typeof SplitLayout | null>(null);

const documentsGridRef = ref<{
  gridWrapperRef: GridWrapperComponent
}>();

const selectedDocumentForDetails = ref<DocumentModel | null | undefined>(null);

const documentsListViewInfoActionButtonProps = ref({
  onInfo(params: { node: IRowNode<any>; api: GridApi<any>; }) {
    onEditDocumentsFromList();
  }
});

watch(documents, (newVal) => {
  // detect if in selectedDocuments are some documents that are not in the new list
  const newSelectedDocuments = selectedDocuments.value.filter(x => newVal.map(y => y.id).includes(x.id));
  selectedDocuments.value = newSelectedDocuments;
});

watch(selectedDocuments, (newVal) => {
  if (newVal.length == 1) {
    selectedDocumentForDetails.value = newVal[0];
  } else {
    selectedDocumentForDetails.value = null;
  }
});


function onDocumentsListViewReady(event: GridReadyEvent<DocumentModel>) {
  event.api.forEachNode((n) => {
    if (n.data?.dto.id && selectedDocuments.value.map(x => x.id).includes(n.data?.id)) {
      n.setSelected(true);
    }
  });
}

function onCellDoubleClicked(params: CellDoubleClickedEvent<DocumentModel>) {
  if (params.data) {
    documentForGalleryPreview.value = params.data;
    showGallery.value = true;
  }
}

function onSelectionChanged(e: SelectionChangedEvent<DocumentModel>) {
  if (e.source === "rowDataChanged") return;
  selectedDocuments.value = e.api.getSelectedRows();
}

function onDocumentDblClicked(document: DocumentModel) {
  documentForGalleryPreview.value = document;
  showGallery.value = true;
}

function onEditDocumentsFromList() {
  const documentsForEdition = documentsGridRef.value?.gridWrapperRef.gridApi.getSelectedRows();
  if (documentsForEdition && documentsForEdition.length > 0) {
    $emit("edit", documentsForEdition[0]);
    splitLayoutRef.value?.showDetails();
  }
}

function onDeleteDocumentsFromList() {
  const documentsForDeletion = documentsGridRef.value?.gridWrapperRef.gridApi.getSelectedRows();
  if (documentsForDeletion && documentsForDeletion.length > 0) {
    $emit("delete", documentsForDeletion[0]);
  }
}

function oEditDocumentsFromGrid(document: DocumentModel) {
  if (document) {
    selectedDocuments.value = [document];
    $emit("edit", document);
    splitLayoutRef.value?.showDetails();
  }
}

function onDeleteDocumentsFromGrid(document: DocumentModel) {
  if (document) {
    $emit("delete", document);
  }
}

function onEditDocumentsFromGallery(document: DocumentModel) {
  if (document) {
    selectedDocuments.value = [document];
    $emit("edit", document);
    splitLayoutRef.value?.showDetails();
  }
}

function onDeleteDocumentsFromGallery(document: DocumentModel) {
  if (document) {
    $emit("delete", document);
  }
}

function onSaveAction(document: DocumentModel) {
  if (document) {
    $emit("save", document);
  }
}

/**
 * Called to create gutter element for split panel.
 */
function createSplitPanelGutterElement(index: number, direction: "horizontal" | "vertical") {
  const gutter = document.createElement("div");
  gutter.classList.add("gutter-documents");
  return gutter;
}

function closeDetails() {
  splitLayoutRef.value?.closeDetails();
}
</script>

<template>
  <div class="fill-height d-flex flex-column">
    <documents-browser-actions-row
      v-model:mode="browserMode"
      v-model:document-sort="sort"
      v-model:search-text="searchText"
      :toggle-sidebar="documentsGridRef?.gridWrapperRef.toggleSidebar"
    >
      <slot :browser-mode="browserMode" />
    </documents-browser-actions-row>
    <split-layout
      ref="splitLayoutRef"
      v-model="detailsVisible"
      identifier="documents"
      :gutter="createSplitPanelGutterElement"
      class="documents-spit-view">
      <template #master>
        <!--    <v-slide-x-transition mode="out-in">-->
        <div v-if="browserMode === DocumentsBrowserViewMode.Grid" :key="DocumentsBrowserViewMode.Grid" class="browser-view">
          <documents-grid-view
            v-model="documents"
            v-model:selected-documents="selectedDocuments"
            multiple
            :editable="editable"
            :deletable="deletable"
            @document-dbl-clicked="onDocumentDblClicked"
            @edit="oEditDocumentsFromGrid"
            @delete="onDeleteDocumentsFromGrid"
          />
        </div>

        <div v-if="browserMode === DocumentsBrowserViewMode.List" class="browser-view">
          <documents-list-view
            :key="DocumentsBrowserViewMode.List"
            ref="documentsGridRef"
            v-model="documents"
            delete-multiple
            no-actions-row
            :delete-btn="deletable"
            :custom-delete-action="deletable"
            :delete-btn-disabled="!deletable"
            :custom-actions-column-component="DocumentsListViewInfoActionButton"
            :custom-actions-column-component-props="documentsListViewInfoActionButtonProps"
            @ready="onDocumentsListViewReady"
            @selection-changed="onSelectionChanged"
            @cell-double-clicked="onCellDoubleClicked"
            @delete-action="onDeleteDocumentsFromList"
            @edit-action="onEditDocumentsFromList"
          />
        </div>

        <div v-if="browserMode === DocumentsBrowserViewMode.Gallery" :key="DocumentsBrowserViewMode.Gallery" class="browser-view">
          <documents-gallery-view
            v-model="documents"
            v-model:selected-documents="selectedDocuments"
            multiple
            :editable="editable"
            :deletable="deletable"
            @document-dbl-clicked="onDocumentDblClicked"
            @delete="onDeleteDocumentsFromGallery"
            @edit="onEditDocumentsFromGallery"
          />
        </div>
        <!--    </v-slide-x-transition>-->
      </template>
      <template #detail>
        <v-card elevation="7" class="d-flex flex-column fill-height document-details">
          <close-card-button v-if="!selectedDocumentForDetails" style="right: 0" @click="closeDetails"></close-card-button>
          <v-fade-transition mode="out-in">
            <slot
              v-if="selectedDocumentForDetails"
              name="details"
              :selected-document="selectedDocumentForDetails"
              :editable="editable"
              :on-save-action="onSaveAction"
              :close-details="closeDetails"
            >
              <div class="w-100 fill-height">
                <close-card-button @click="closeDetails"></close-card-button>
                <document-details
                  v-model="selectedDocumentForDetails"
                  :editable="editable"
                  @save="onSaveAction"
                />
              </div>
            </slot>
            <v-card-text v-else-if="selectedDocuments.length > 1" class="fill-height">
              <v-row class="fill-height">
                <v-col cols="12" class="d-flex align-center justify-center">
                  <label class="text-secondary font-weight-regular">
                    {{ $t("documents-toManyDocumentsSelected-title", { $: "Select single documents to see details" }) }}
                  </label>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text v-else class="fill-height">
              <v-row class="fill-height">
                <v-col cols="12" class="d-flex align-center justify-center">
                  <label class="text-secondary font-weight-regular">
                    {{ $t("documents-noDocumentSelected-title", { $: "No document selected" }) }}
                  </label>
                </v-col>
              </v-row>
            </v-card-text>
          </v-fade-transition>
        </v-card>
      </template>
    </split-layout>

    <documents-gallery-dialog
      v-model="showGallery"
      v-model:selected-document="documentForGalleryPreview"
      :documents="documents"
    ></documents-gallery-dialog>
  </div>
</template>

<style scoped lang="scss">
.documents-spit-view {
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;

  :deep(.split-layout-panel) {
    position: relative;
  }

  :deep(.gutter-documents) {
    z-index: 3;
  }

  :deep(.panel-2) {
    z-index: 2;
  }
}

.browser-view {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
}

.document-details {
  border-radius: 0px;

  .close-card-button {
    position: absolute;
    top: 0px;
    right: 10px;
  }
}
</style>
