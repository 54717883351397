﻿<template>
  <div v-if="step" class="fill-height d-flex flex-column task-steps-details">
    <div class="tabs">
      <v-tabs
        v-model="tab"
        class="tab-details text-disabled bg-white"
        selected-class="v-slide-group-item--active text-primary bg-white"
        density="compact"
        color="primary"
        show-arrows
      >
        <v-tab :theme="theme.name" value="specifications">
          {{ $t("task-taskDetails-specification-label", { $: "Specification" }) }}
        </v-tab>
        <v-tab :theme="theme.name" value="capacities">
          {{ $t("task-taskDetails-capacities-label", { $: "Capacities" }) }}
        </v-tab>
      </v-tabs>
    </div>
    <v-sheet class="flex-fill" rounded>
      <v-window v-model="tab" :theme="theme.name" class="fill-height">
        <v-window-item value="specifications" class="fill-height">
          <step-resource-spec-grid :step="step" />
        </v-window-item>
        <v-window-item value="capacities" class="fill-height">
          <task-resource-capacities-grid :step="step" />
        </v-window-item>
      </v-window>
    </v-sheet>
  </div>
</template>

<script>
import StepResourceSpecGrid from "@/components/Tasks/StepResourceSpecGrid.vue";
import TaskResourceCapacitiesGrid from "@/components/Tasks/TaskResourceCapacitiesGrid.vue";
import SystemEnumService from "@/services/system-enum.service";
import { $t } from "@/i18n";

export default {
  name: "TaskStepDetails",
  components: {
    TaskResourceCapacitiesGrid,
    StepResourceSpecGrid
  },
  props: {
    theme: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      step: null,
      task: null,
      spec: null,
      tab: null,
      refreshGridAction: null
    };
  },
  methods: {
    $t,
    getResourceType() {
      return SystemEnumService.resourceType(this.spec.resourceType);
    }
    ,
    async loadStepDetails(taskStep, taskDetail) {
      if (taskStep) {
        this.step = taskStep;
        if (taskDetail) {
          this.task = taskDetail;
        } else {
          this.task = await this.fetchDetail(taskStep.taskId);
        }
      } else {
        this.step = undefined;
        this.task = undefined;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "vuetify/lib/styles/settings/colors";
//
.task-steps-details {
  display: flex;
  flex-direction: row;

  .tabs {
    .v-tab {
      color: rgba(var(--v-theme-on-background), var(--v-disabled-opacity));
    }

    .v-tab:not(.v-tab--selected) {
      border-bottom: 1px solid #e0e0e0;
    }
  }
}
</style>
