<script setup lang="ts">
import { $t } from "@/i18n";
import { DocumentModel } from "@/components/Documents/DocumentModel";
import { ref, UnwrapRef } from "vue";
import DocumentsUnassignConfirmationModalDialog from "@/components/Documents/DocumentsUnassignConfirmationModalDialog.vue";
import { onKeyStroke } from "@vueuse/core";
import { IQrCodeOptions } from "@/components/Documents/IDocumentProvider";
import QrCodeGetter from "@/components/ModelInstances/QrCodeGetter/QrCodeGetter.vue";
import ActionsButton from "@/components/Layout/ActionsButton.vue";

const documents = defineModel<UnwrapRef<DocumentModel[]>>({
  default: []
});

const props = defineProps<{
  qrCodeOptions?: IQrCodeOptions | null;
  uploading?: boolean;
  disabled?: boolean;
  hideAssignmentActions?: boolean;
}>();

const emit = defineEmits<{
  (e: "assign"): void;
  (e: "unassign"): void;
  (e: "desktopUpload"): void;
  (e: "download"): void;
  (e: "newRevision"): void;
}>();

const showUnassignDialog = ref<boolean>(false);
const qrCodeGetterRef = ref<typeof QrCodeGetter | null>(null);

onKeyStroke(["Delete", "Backspace"], (e) => {
  const target = e.target as (HTMLElement | undefined);
  const isInput = target?.tagName.toLowerCase() === "input" ||
    target?.tagName.toLowerCase() === "textarea" ||
    target?.isContentEditable;
  if (documents.value.length > 0 && !isInput) {
    showUnassignDialog.value = true;
  }
}, { dedupe: true });

function onAssignDocument() {
  emit("assign");
}

function onUnassignConfirm() {
  emit("unassign");
}

function onDesktopUpload() {
  emit("desktopUpload");
}

function onDownload() {
  emit("download");
}

const actions = ref([
...(props.hideAssignmentActions ? [] : [
    {
      title: $t("documents-assign-action", { $: "1. Assign" }),
      tooltip: $t("documents-assign-tooltip", { $: "Assign" }),
      action: onAssignDocument,
      icon: "mdi-playlist-plus",
      order: 1
    },
    {
      title: $t("documents-unassign-action", { $: "2. Unassign" }),
      tooltip: $t("documents-unassign-tooltip", { $: "Unassign" }),
      action: () => {
        showUnassignDialog.value = true;
      },
      disabled: () => documents.value.length === 0 || props.disabled,
      icon: "mdi-playlist-minus",
      order: 2
    }
  ]),
  {
    separator: true,
    order: 3
  },
  {
    title: $t("documents-upload-action", { $: "3. Upload" }),
    tooltip: $t("documents-upload-tooltip", { $: "Upload" }),
    action: onDesktopUpload,
    disabled: () => props.disabled || props.uploading,
    icon: "mdi-upload",
    order: 4
  },
  ...(!props.qrCodeOptions ? [] : [
    {
      title: $t("documents-qrCodeUpload-action", { $: "4. Upload from Mobile" }),
      tooltip: $t("documents-qrCodeUpload-tooltip", { $: "Upload from Mobile" }),
      action: () => {
        qrCodeGetterRef.value?.getDocumentQrCode();
      },
      icon: "mdi-qrcode",
      order: 5
    }
  ]),
  {
    title: $t("documents-newRevision-action", { $: "5. Upload New Revision" }),
    tooltip: $t("documents-newRevision-tooltip", { $: "Upload New Revision" }),
    action: () => {
      emit("newRevision");
    },
    disabled: () => documents.value.length !== 1 || props.disabled,
    icon: "mdi-upload-circle",
    order: 6
  },
  {
    title: $t("documents-download-action", { $: "6. Download" }),
    tooltip: $t("documents-download-tooltip", { $: "Download" }),
    action: onDownload,
    disabled: () => documents.value.length === 0 || props.disabled || props.uploading,
    icon: "mdi-download-circle",
    order: 7
  }
]);
</script>

<template>
  <div class="d-inline-flex">
    <actions-button :model-value="actions"></actions-button>
  </div>
  <qr-code-getter v-if="qrCodeOptions" v-bind="qrCodeOptions" ref="qrCodeGetterRef" is-new hide-default-slot />
  <documents-unassign-confirmation-modal-dialog v-model="showUnassignDialog" @confirm="onUnassignConfirm"></documents-unassign-confirmation-modal-dialog>
</template>

<style scoped lang="scss">

</style>
