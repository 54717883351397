<script lang="ts">
import { ICellRendererParams } from "ag-grid-community";
import { DocumentModel, DocumentType } from "@/components/Documents/DocumentModel";

export default {
  setup(props: { params: ICellRendererParams<DocumentModel> }) {
    const data = props.params.data as DocumentModel;
    const rowHeight = props.params.api.getGridOption("rowHeight") ?? 50;
    return {
      data: data!,
      isImage: data?.documentType === DocumentType.ImageFile,
      rowHeight
    };
  }
};
</script>

<template>
  <div class="image-cell-renderer">
    <v-img v-if="isImage" :src="data.thumbnailUrl" :title="data.fileName" :height="rowHeight" />
    <v-icon v-else :size="rowHeight">mdi-file</v-icon>
  </div>
</template>

<style lang="scss">
.image-cell-renderer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
