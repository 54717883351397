type TranslateEditorEntryFn = (value: any) => string;

export function getEnumTitleValuePairs<T extends Record<string, any>>(
  enumType: T,
  translateFn: (key: any) => string,
  ...excludeValues: T[keyof T][]
): { title: string; value: any }[] {
  const filteredValues = getEnumValuesExceptProvided(enumType, ...excludeValues);

  return filteredValues.map((x) => ({
    title: translateFn(x),
    value: x
  }));
}


export function getEnumValuesExceptProvided<T extends Record<string, any>>(enumType: T, ...values: T[keyof T][]): T[keyof T][] {
  return Object.keys(enumType)
    .map((x) => enumType[x])
    .filter((x) => typeof x === "number")
    .filter((x) => !values.includes(x));
}
