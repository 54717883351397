import { useEventListener } from "@vueuse/core";
import { computed, Ref, ref } from "vue";

export interface DragAndDropAreaOptions {
  multiple?: boolean;
  onUpload: (files: FileList) => Promise<void>;
}

export function useDragAndDropArea(elementRef: Ref<HTMLElement | null | undefined>, options: DragAndDropAreaOptions) {
  const isDraggingOver = ref(false);

  function setDragDropEffect(event: DragEvent) {
    // we can filter on the type of file here
    if (event.dataTransfer) event.dataTransfer.dropEffect = "copy";
  }

  async function handleDroppedFiles(files: FileList | undefined) {
    if (!options.onUpload) return;

    // If multiple files are not allowed, take only the first file
    if (!options.multiple && files && files.length > 0) {
      const singleFileList = new DataTransfer();
      singleFileList.items.add(files[0]);
      await options.onUpload(singleFileList.files);
    } else if (files) {
      // If multiple files are allowed, emit all files
      await options.onUpload(files);
    }
  }

  useEventListener(elementRef, "dragover", (event) => {
    event.preventDefault();
    isDraggingOver.value = true;
    setDragDropEffect(event);
  });
  useEventListener(elementRef, "dragenter", (event) => {
    event.preventDefault();
  });
  useEventListener(elementRef, "dragleave", (event) => {
    event.preventDefault();
    isDraggingOver.value = false;
  });
  useEventListener(elementRef, "drop", async (event) => {
    event.preventDefault();

    isDraggingOver.value = false;
    // Handle dropped files
    const files = event.dataTransfer?.files;
    await handleDroppedFiles(files);
  });

  return { isDraggingOver };
}

export function useMultipleDragAndDropArea(elementRefs: Ref<HTMLElement | null | undefined>[], options: DragAndDropAreaOptions) {
  const draggingOvers: Ref<boolean>[] = [];
  for (const elementRef of elementRefs) {
    const { isDraggingOver } = useDragAndDropArea(elementRef, options);
    draggingOvers.push(isDraggingOver);
  }

  const isDraggingOver = computed(() => draggingOvers.some((draggingOver) => draggingOver.value));

  return { isDraggingOver };
}
