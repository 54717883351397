<script setup lang="ts">
import { $t } from "@/i18n";
import { BomItemDto, MaterialDto, ResourceAssignmentType, ResourceSubType, ResourceType } from "@masta/generated-model";
import Tasks from "@/views/Tasks.vue";
import ResourceModelInstances from "@/components/ModelInstances/ResourceModelInstances.vue";
import { ModelInstanceTab } from "@/components/ModelInstances/model-instance-tab";
import OrdersView from "@/views/OrdersView.vue";
import EcoEfficiencyKpi from "@/views/ProductOverview/EcoEfficiencyKpi.vue";
import StorageOverview from "@/views/ProductOverview/StorageOverview.vue";
import ProductTemplates from "@/views/ProductTemplates.vue";
import BomGrid from "@/components/Bom/BomGrid.vue";
import CostCatalogueGrid from "@/components/CostCatalogue/CostCatalogueGrid.vue";
import { resolveComponentRoutePath } from "@/components/Layout/UseMasterDetailLayoutRoute";
import { computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import ProcessDrafts from "@/views/ProcessDrafts.vue";
import CloseCardButton from "@/components/CloseCardButton.vue";
import Breadcrumbs from "@/components/Layout/Breadcrumbs.vue";
import ResourceAssignmentsDetails from "@/components/Resources/ResourceAssignment/ResourceAssignmentsDetails.vue";

const productTemplateRoutePath = resolveComponentRoutePath("ProductTemplates");
const tasksRoutePath = resolveComponentRoutePath("Tasks");

const material = defineModel<MaterialDto>();

const props = defineProps<{
  schemaTabs: ModelInstanceTab[];
  productCard?: boolean;
}>();

const emit = defineEmits<{
  (e: "updated", id: string): void;
  (e: "closed"): void;
  (e: "templatesUpdated"): void;
  (e: "bomDetail", item: BomItemDto): void;
}>();

const tab = ref("overview");

const route = useRoute();
const router = useRouter();
const currentTab = computed({
  get: () => {
    let t = props.productCard ? (route.query.tab as string) ?? "bom" : tab.value;
    if (t === "productTemplates" && !displayTemplates.value) {
      t = "bom";
    }
    return t;
  },
  set: (value: string | undefined) => {
    if (props.productCard) {
      router.replace({ query: { ...route.query, tab: value } });
    } else {
      tab.value = value ?? "bom";
    }
  }
});
const visibleTabs = computed(() => props.schemaTabs.filter(showTab));
const displayTemplates = computed(() => !material.value?.subTypes?.includes(ResourceSubType.MaterialRawOrSubcomponent) ?? true);
const isScrap = computed(() => material.value?.subTypes?.includes(ResourceSubType.MaterialScrap) ?? false);
const isScrappable = computed(
  () =>
    (material.value?.subTypes?.includes(ResourceSubType.MaterialSemiFinishedOrFinalProduct) || material.value?.subTypes?.includes(ResourceSubType.MaterialRawOrSubcomponent)) ??
    false
);

function showTab(t: ModelInstanceTab) {
  if (!t.resourceSubTypes) return true;
  if (!material.value?.subTypes) return false;

  return t.resourceSubTypes.some((x) => material.value?.subTypes.includes(x));
}

async function modelInstanceUpdated(resourceId: string) {
  emit("updated", resourceId);
}

function onTemplateAction() {
  emit("templatesUpdated");
}

function onBomDetail(item: BomItemDto) {
  emit("bomDetail", item);
}

function closeDetails() {
  emit("closed");
}
</script>

<template>
  <v-card v-if="material" elevation="7" class="d-flex flex-fill flex-column fill-height materials2-card" :class="{ 'materials-card': !productCard }">
    <close-card-button v-if="!productCard" @click="closeDetails"></close-card-button>
    <v-card-title class="tabs">
      <breadcrumbs>{{ material?.name || material?.businessId }}</breadcrumbs>
      <v-tabs
        v-model="currentTab"
        class="task-details text-disabled"
        selected-class="v-slide-group-item--active text-primary bg-white"
        density="compact"
        color="primary"
        show-arrows
      >
        <v-tab v-for="tab in visibleTabs" :key="tab.schema.schemaId" :value="tab.schema.schemaId">
          <v-icon class="pr-2">mdi-tag-multiple</v-icon>
          {{ tab.schema.label }}
        </v-tab>
        <v-tab v-if="displayTemplates" value="productTemplates">
          <v-icon class="pr-2">mdi-account-hard-hat</v-icon>
          {{ $t("materialDetails-view-productTemplates-label", { $: "Technologies" }) }}
        </v-tab>
        <v-tab v-if="displayTemplates" value="processDrafts">
          <v-icon class="pr-2">mdi-account-hard-hat</v-icon>
          {{ $t("materialDetails-view-processDrafts-label", { $: "Technology Builder" }) }}
        </v-tab>
        <v-tab value="bom">
          <v-icon class="pr-2">mdi-cash-register</v-icon>
          {{ $t("materialDetails-view-bom-label", { $: "BOM" }) }}
        </v-tab>
        <v-tab value="costCatalogue">
          <v-icon class="pr-2">mdi-currency-usd</v-icon>
          {{ $t("materialDetails-view-costCatalogue-label", { $: "Cost Catalogue" }) }}
        </v-tab>
        <v-tab value="orders">
          <v-icon class="pr-2">mdi-cash-register</v-icon>
          {{ $t("materialDetails-view-orders-label", { $: "Quotations & Orders" }) }}
        </v-tab>
        <!--        <v-tab value="productionTasks">-->
        <!--          <v-icon class="pr-2">mdi-list-box-outline</v-icon>-->
        <!--          {{ $t("materialDetails-view-implementations-label", { $: "Implementations" }) }}-->
        <!--        </v-tab>-->
        <v-tab value="storage">
          <v-icon class="pr-2">mdi-treasure-chest</v-icon>
          {{ $t("materialDetails-view-storage-label", { $: "Storage" }) }}
        </v-tab>
        <v-tab value="circularityKpis">
          <v-icon class="pr-2">mdi-leaf</v-icon>
          {{ $t("materialDetails-view-circularityKpis-label", { $: "Circularity KPI’s" }) }}
        </v-tab>
        <v-tab v-if="isScrappable" value="material-scrap">
          <v-icon class="pr-2">mdi-trash-can</v-icon>
          {{ $t("materialDetails-view-materialScrap-label", { $: "Material -> Scrap" }) }}
        </v-tab>
        <v-tab v-if="isScrap" value="scrap-material">
          <v-icon class="pr-2">mdi-recycle</v-icon>
          {{ $t("materialDetails-view-scrapMaterial-label", { $: "Scrap <- Material" }) }}
        </v-tab>
      </v-tabs>
    </v-card-title>
    <v-card-text class="d-flex flex-column pa-0">
      <div class="flex-1-1 d-flex overflow-hidden">
        <v-card class="flex-1-1 d-flex flex-column rounded-0" elevation="6">
          <v-card-text class="flex-1-1 d-flex flex-column pa-0">
            <v-window v-model="currentTab" class="bg-white flex-fill d-block" style="position: absolute; width: 100%; height: 100%">
              <v-window-item v-for="tab in visibleTabs" :key="tab.schema.schemaId" :value="tab.schema.schemaId" class="fill-height pt-5 overflow-y-auto">
                <ResourceModelInstances :resource="material" :schema="tab.schema" @updated="modelInstanceUpdated" />
              </v-window-item>
              <v-window-item v-if="displayTemplates" class="fill-height pa-2 overflow-y-auto" value="productTemplates">
                <product-templates
                  :filter-by-product-id="material?.id ?? undefined"
                  embedded
                  :component-route-path="productTemplateRoutePath"
                  @archive="onTemplateAction"
                  @create="onTemplateAction"
                />
              </v-window-item>
              <v-window-item v-if="displayTemplates" class="fill-height pt-5 overflow-y-auto" value="processDrafts">
                <process-drafts embedded :filter-by-product-id="material?.id ?? undefined" />
              </v-window-item>
              <v-window-item class="fill-height pa-2 overflow-y-auto" value="bom">
                <bom-grid :resource="material" @detail="onBomDetail" />
              </v-window-item>
              <v-window-item class="fill-height pa-2 overflow-y-auto" value="costCatalogue">
                <cost-catalogue-grid :resource="material" />
              </v-window-item>
              <v-window-item class="fill-height pt-5 overflow-y-auto" value="orders">
                <orders-view :filter-by-product-id="material?.id ?? null" embedded readonly />
              </v-window-item>
              <!--              <v-window-item class="fill-height pa-2 overflow-y-auto" value="productionTasks">-->
              <!--                <tasks :filter-by-product-id="material?.id" embedded :component-route-path="tasksRoutePath" />-->
              <!--              </v-window-item>-->
              <v-window-item class="fill-height pa-2 overflow-y-auto" value="storage">
                <storage-overview :filter-by-product-id="material?.id" />
              </v-window-item>
              <v-window-item class="fill-height pa-2 overflow-y-auto" value="circularityKpis">
                <eco-efficiency-kpi :product="material" />
              </v-window-item>
              <v-window-item v-if="isScrappable" class="fill-height pa-2 overflow-y-auto" value="material-scrap">
                <resource-assignments-details
                  :assignment-type="ResourceAssignmentType.MaterialToScrap"
                  :resource-type="ResourceType.Material"
                  :resource="material"
                  :reverse="false"
                />
              </v-window-item>
              <v-window-item v-if="isScrap" class="fill-height pa-2 overflow-y-auto" value="scrap-material">
                <resource-assignments-details
                  :assignment-type="ResourceAssignmentType.MaterialToScrap"
                  :resource-type="ResourceType.Material"
                  :resource="material"
                  :reverse="true"
                />
              </v-window-item>
            </v-window>
          </v-card-text>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
</template>

<style lang="scss">
.overview-container {
  .tabs {
    .v-tab:not(.v-tab--selected) {
      border-bottom: 1px solid #e0e0e0;
    }
  }
}

.materials-card {
  border: 2px solid rgb(var(--v-theme-primary));
  border-radius: 0;

  .close-card-button {
    position: absolute;
    top: 0px;
    right: 10px;
  }
}
</style>
