import { inject, injectable } from "inversify";
import { GanttSettings, IContextMenuItemActionContext, IGanttAction, IocSymbols, Layer, Lifecycle } from "@masta/gantt2/core";
import { ResourceCapacityActivity } from "@/components/Gantt/Common/Model";
import { BehaviorSubject } from "rxjs";
import { CustomSettingKeys } from "@/components/Gantt/ResourcesGantt/CustomSettingKeys";
import { $t } from "@/i18n";
import { useProductionTasksStore } from "@/store/ProductionTasksStore";
import { useSnackbarsStore } from "@/store/SnackbarsStore";

@injectable()
export class DescheduleTaskAction extends Lifecycle implements IGanttAction {
  constructor(@inject(IocSymbols.LayersSymbol) private _layers$$: BehaviorSubject<Layer[]>,
              @inject(GanttSettings) private _ganttSettings: GanttSettings) {
    super();
  }

  public getTaskId(context: IContextMenuItemActionContext) {
    if (context.activityBounds.length < 1) {
      console.warn("invalid activity bounds count , should be 1 or more but was ", context.activityBounds.length);
      return;
    }
    const layers = this._layers$$;
    const bound = context.activityBounds[0];
    const activity: ResourceCapacityActivity | undefined = context.row.repository.getActivity(layers.value.find(l => l.id === bound.layerId)!, bound.activityId);
    if (!activity) {
      console.warn("no activity found");
      return;
    }
    return activity.userObject;
  }

  public async execute(context: IContextMenuItemActionContext) {
    const activity = this.getTaskId(context);

    const scenarioId = this._ganttSettings.getSetting<string>(CustomSettingKeys.SCENARIO_ID);

    if (!activity || !activity.task || !scenarioId) {
      console.warn("no activity found or activity.task or scenarioId is not set");
      return;
    }

    const request = {
      rootTaskId: activity.task.rootTaskId!,
      scenarioId: scenarioId!,
      taskIds: [activity.task.id]
    };

    const tasksStore = useProductionTasksStore();
    const snackbarsStore = useSnackbarsStore();

    try {
      await tasksStore.descheduleTasks(request);
      snackbarsStore.createSnackbar({
        message: $t("task-list-taskDescheduled-message", { $: "Tasks queued for descheduling" }),
        closeable: true
      });
    } catch (e: any) {
      console.error(e);
      snackbarsStore.createSnackbar({
        message: e.message,
        type: "error",
        closeable: true
      });
    }
  }

}
