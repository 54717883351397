﻿<script lang="ts" setup>
import { ResourceInfoDto, ResourceSubType, ResourceType } from "@masta/generated-model";
import { computed, nextTick, ref, watch } from "vue";
import ApiService from "@/services/api";
import { VAutocomplete, VBtn } from "vuetify/lib/components/index.mjs";
import { useDebounceFn } from "@vueuse/core";
import { useScenariosStore } from "@/store/ScenariosStore";
import ResourcePickerDialog from "@/components/Resources/ResourcePickerDialog.vue";
import { $t } from "@/i18n";

const modelValue = defineModel<ResourceInfoDto | null | undefined>({ required: true });
const props = defineProps<{
  label?: string;
  color?: string | undefined;
  placeholder?: string;
  resourceTypes?: ResourceType[];
  resourceSubTypes?: ResourceSubType[];
  fullTextSearch?: boolean;
  readonly: boolean;
  rules?: any[] | undefined;
  hideDetails?: boolean | "auto" | undefined;
}>();

const loading = ref(false);
const search = ref<string | undefined>();
const resourceInfos = ref<ResourceInfoDto[]>([]);
const dialog = ref(false);
const scenarioStore = useScenariosStore();
const fts = computed(() => props.fullTextSearch ?? false);

const autocompleteRef = ref<VAutocomplete>();

const noDataText = computed(() => {
  if (!search.value) {
    return $t("autocomplete-control-startTyping-label", { $: "Start typing ..." });
  }
  return $t("autocomplete-control-noData-label", { $: "No data" });
});

watch(search, (newValue) => {
  if (!newValue) {
    resourceInfos.value = [];
    return;
  }
  querySelections(newValue);
});

const querySelections = useDebounceFn(_querySelections, 500);

async function _querySelections(query: string) {
  loading.value = true;
  const response = await ApiService.resources.findResources(query, scenarioStore.selectedScenario?.id ?? "", props.resourceTypes, props.resourceSubTypes, fts.value);
  resourceInfos.value = response.data.resources ?? [];
  loading.value = false;
}

function focus() {
  nextTick(() => autocompleteRef.value?.focus());
}

function hasFocus(): boolean | undefined {
  return autocompleteRef.value?.focused;
}

function onSelectResource() {
  dialog.value = true;
}

function setMenu(menu: boolean) {
  if (autocompleteRef.value) {
    autocompleteRef.value.menu = menu;
  }
}

function getMenu(): boolean | undefined {
  return autocompleteRef.value?.menu;
}

defineExpose({ focus, hasFocus, getMenu, setMenu, onSelectResource });
</script>

<template>
  <div class="resource-picker align-center">
    <v-autocomplete
      ref="autocompleteRef"
      v-model="modelValue"
      v-model:search="search"
      class="flex-1-1-100"
      auto-select-first
      clearable
      :color="color"
      :label="label"
      :items="resourceInfos"
      :loading="loading"
      :disabled="props.readonly"
      :rules="props.rules"
      no-filter
      item-title="businessId"
      variant="outlined"
      density="compact"
      :hide-details="props.hideDetails ?? true"
      return-object
      :placeholder="placeholder"
      hide-no-data
      :no-data-text="noDataText"
    >
      <template #item="{ props, item }">
        <v-list-item v-bind="props" :title="item?.raw?.businessId ?? ''" :subtitle="item?.raw?.name ?? ''"></v-list-item>
      </template>
      <template #chip="{ props, item }">
        <v-chip v-bind="props" :title="item?.raw?.businessId ?? ''" :subtitle="item?.raw?.name ?? ''" class="chip-select text-body-1"></v-chip>
      </template>
      <template #append-inner>
        <v-btn v-if="!props.readonly" variant="plain" icon="mdi-select-search" density="compact" :ripple="false" @click.stop="onSelectResource"></v-btn>
      </template>
    </v-autocomplete>
    <resource-picker-dialog v-model:resource="modelValue" v-model:dialog="dialog" :resource-types="resourceTypes" :resource-sub-types="resourceSubTypes" />
  </div>
</template>

<style scoped lang="scss">
.resource-picker {
  :deep(.v-autocomplete__menu-icon) {
    display: none;
  }
}

.chip-select {
  color: #000000de;

  :deep(.v-chip__underlay) {
    background-color: #593cff;
  }
}
</style>
