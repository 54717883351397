<script lang="ts" setup>
import { DocumentData } from "@masta/generated-model";
import { onMounted, ref, watch } from "vue";
import DocumentAttachmentPreview from "@/components/ModelInstances/DocumentAttachmentDefinition/DocumentAttachmentPreview.vue";
import QrCodeHyperlinkDialog from "@/components/ModelInstances/QrCodeHyperlinkDialog/QrCodeHyperlinkDialog.vue";
import { useI18n } from "vue-i18n";
import { $t } from "@/i18n";
import { useSettingsStore } from "@/store/SettingsStore";
import { compressImageFile, isFileValidImageType } from "@/composables/ImageFileCompression";

const props = defineProps<{
  modelValue: DocumentData;
  isNew: boolean;
  disabled: boolean;
  modelInstanceId: string | undefined;
  revisionNumber: number | undefined;
  showQrCode?: boolean;
  profileName: string;
}>();

const i18n = useI18n();
const settingsStore = useSettingsStore();

const storageDefinitions = ref<StorageDefinition[]>([]);

const dialog = ref(false);
const link = ref("NO LINK SPECIFIED");

const emit = defineEmits(["update:modelValue", "requestForQrCode"]);

const files = ref<File[]>([]);
const fileLoading = ref(false);

const rules = [
  (v: string) =>
    !!v ||
    $t("documentAttachmentDefinition-textEditor-onFieldRequired-message", {
      $: "Field is required"
    })
];

watch(files, async (_files) => {
  fileLoading.value = true;
  if (files.value.length === 1) {
    let file = files.value[0];

    if (isFileValidImageType(file)) {
      const compressedFile = await compressImageFile(file);
      if (compressedFile != null) {
        file = compressedFile;
      } else {
        console.error("Failed to compress image file");
      }
    }

    const newAttachment = {
      ...props.modelValue.attachment,
      file: file,
      fileName: file.name,
      mimeType: file.type,
      attachmentContentStorageConfigurationId: resolveStorageConfigurationId(file, props.modelValue.attachment?.attachmentContentStorageConfigurationId)
    };
    update("attachment", newAttachment);
  } else {
    const newAttachment = {
      ...props.modelValue.attachment,
      file: null,
      fileName: null,
      mimeType: null,
      attachmentContentStorageConfigurationId: null
    };
    update("attachment", newAttachment);
  }

  fileLoading.value = false;
});

function isBlobFile(file: any) {
  const fileName = file.name;
  return fileName.endsWith(".obj") || fileName.endsWith(".stp");
}

function resolveStorageConfigurationId(file: any, defaultValue: string | null = null): string | null {
  const definitions = storageDefinitions.value;
  if (definitions.length === 0) {
    return defaultValue;
  }

  if (isBlobFile(file)) {
    return definitions.find((d) => d.isDefault === false)?.attachmentContentStorageConfigurationId ?? defaultValue;
  } else {
    return definitions.find((d) => d.isDefault === true)?.attachmentContentStorageConfigurationId ?? defaultValue;
  }
}

function update(propertyName: string, value: any) {
  const newRequirement = { ...props.modelValue, [propertyName]: value };
  emit("update:modelValue", newRequirement);
}

function updateAttachment(propertyName: string, value: any) {
  const newAttachment = { ...props.modelValue.attachment, [propertyName]: value };
  update("attachment", newAttachment);
}

interface StorageDefinition {
  attachmentContentStorageConfigurationId: string;
  isDefault: boolean;
  attachmentSourceType: number;
}

function prepareAvailableStorageConfigurations() {
  settingsStore.settings
    .filter((setting) => setting.key === "app.data.attachmentContentStorage")
    .flatMap((setting) => (setting.value as any)?.attachmentContentStorageDefinitions ?? [])
    .forEach((definition: any) => {
      storageDefinitions.value.push(definition as StorageDefinition);
    });
}

// Ensure modelValue.attachment is initialized
if (!props.modelValue.attachment) {
  props.modelValue.attachment = {};
}

onMounted(() => {
  settingsStore.fetch().then(() => prepareAvailableStorageConfigurations());
});
</script>

<template>
  <v-row>
    <v-col cols="12">
      <v-text-field
        :label="$t('documentAttachmentDefinition-name-textField-label', { $: 'Name' })"
        :model-value="modelValue.name"
        density="compact"
        hide-details
        variant="outlined"
        :disabled="disabled"
        @update:model-value="update('name', $event)"
      />
    </v-col>
    <v-col cols="12">
      <v-divider />
    </v-col>
    <v-col cols="6">
      <v-text-field
        :label="$t('documentAttachmentDefinition-fileName-textField-label', { $: 'File name' })"
        :model-value="modelValue.attachment?.fileName"
        disabled
        density="compact"
        hide-details
        variant="outlined"
      />
    </v-col>
    <v-col cols="6">
      <v-text-field
        :label="$t('documentAttachmentDefinition-mimeType-textField-label', { $: 'Mime type' })"
        :model-value="modelValue.attachment?.mimeType"
        disabled
        density="compact"
        hide-details
        variant="outlined"
      />
    </v-col>
    <v-col cols="12">
      <v-divider />
    </v-col>
    <v-col cols="12">
      <document-attachment-preview
        :document-id="modelInstanceId"
        :revision-number="revisionNumber"
        :file-name="modelValue.attachment?.fileName"
        :uri="modelValue.attachment?.uri"
        :attachment-source-type="modelValue.attachment?.sourceType"
        :attachment-content-storage-configuration-id="modelValue.attachment?.attachmentContentStorageConfigurationId"
        :disabled="disabled"
      >
        <v-file-input
          v-model="files"
          color="accent"
          :loading="fileLoading"
          :label="$t('documentAttachmentDefinition-fileInput-label', { $: 'File' })"
          :placeholder="$t('documentAttachmentDefinition-fileInput-placeholder', { $: 'Select file for upload' })"
          show-size
          density="compact"
          hide-details
          variant="outlined"
          :disabled="disabled"
        ></v-file-input>
      </document-attachment-preview>
    </v-col>
  </v-row>
  <qr-code-hyperlink-dialog v-model="dialog" :link="link" />
</template>

<style lang="scss" scoped></style>
