<script lang="ts" setup>
import { GridApi, IRowNode, SelectionChangedEvent } from "ag-grid-community";
import { nextTick, onBeforeUnmount, onMounted, reactive, ref, toRef, watch } from "vue";
import { AvailabilityRulesController } from "@/services/api-service";
import { useTheme } from "vuetify";
import ScheduleTasksDialog from "@/components/Tasks/ScheduleTasksDialog.vue";
import { useProductionTasksStore } from "@/store/ProductionTasksStore";
import { $t } from "@/i18n";
import { useSnackbarsStore } from "@/store/SnackbarsStore";
import { useScenariosStore } from "@/store/ScenariosStore";
import { RegisterScheduleTasksCommand } from "@masta/generated-model";
import { getSelectedNodes } from "@/components/Grid/UseGridSelection";

interface Props {
  gridApi: GridApi | null;
}

const $props = defineProps<Props>();
const snackbarsStore = useSnackbarsStore();
const scenariosStore = useScenariosStore();
const tasksStore = useProductionTasksStore();
const theme = reactive(useTheme());
const dialog = ref(false);


onMounted(() => {
  const watchHandler = watch(toRef($props, "gridApi"), (value) => {
    if (value) {
      $props.gridApi?.addEventListener("selectionChanged", onSelectionChanged);
      new AvailabilityRulesController().getAll(scenariosStore.selectedScenario?.id);
      onSelectionChanged({ api: value } as SelectionChangedEvent);
      nextTick(() => {
        watchHandler();
      });
    }
  }, { immediate: true });
});

onBeforeUnmount(() => {
  $props.gridApi?.removeEventListener("selectionChanged", onSelectionChanged);
});

const isAnyRowSelected = ref(false);

function onSelectionChanged(e: SelectionChangedEvent) {
  isAnyRowSelected.value = getSelectedNodes(e.api).length > 0;
}

async function settingSelected(setting: string) {
  await schedule(setting);
}

async function schedule(setting: string) {
  const request = createSchedulingRequest(setting);
  if (!request) return;

  try {
    await tasksStore.complexScheduleTasks(request);
    await snackbarsStore.createSnackbar({
      message: $t("task-list-taskScheduled-message", { $: "Tasks scheduled" }),
      closeable: true
    });
  } catch (e: any) {
    console.error(e);
    await snackbarsStore.createSnackbar({
      message: e.message,
      type: "error",
      closeable: true
    });
  }
}

function createSchedulingRequest(setting: string): RegisterScheduleTasksCommand | null {
  const selectedNodes = getSelectedNodes($props.gridApi);
  const selectedTasks = selectedNodes ?? [];

  if (!selectedTasks || !scenariosStore.selectedScenario) return null;

  const rootIds = [...new Set(selectedTasks.map((x) => x.data.rootTaskId))];
  const rootId = rootIds.length === 1 ? rootIds[0] : null;

  return {
    rootTaskId: rootId,
    scenarioId: scenariosStore.selectedScenario.id,
    taskIds: selectedTasks.map((x) => x.id),
    settingName: setting,
    start: null,
    businessKey: null,
    canReschedule: false,
    shouldRelease: false,
    resourceId: undefined
  } as RegisterScheduleTasksCommand;
}

/**
 * Expose triggerScheduledAction function to parent component.
 * Thanks of that we can trigger scheduled action from code.
 */
function triggerScheduledAction() {
  dialog.value = true;
}

defineExpose({
  triggerScheduledAction
});
</script>

<template>
  <v-tooltip location="bottom" open-delay="300">
    <template #activator="{ props }">
      <v-list-item
        style="order: 6"
        v-bind="props" dense class="pt-0 pb-0 action-btn" :title="$t('task-list-scheduleTask-action', { $: '4. Schedule' })" prepend-icon="mdi-calendar" :disabled="!isAnyRowSelected"
        @click="dialog = true" />
    </template>
    <span>{{ $t("task-list-scheduleTask-action", { $: "4. Schedule" }) }}</span>
  </v-tooltip>
  <schedule-tasks-dialog v-model="dialog" @setting-selected="settingSelected" />
</template>
