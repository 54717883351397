<script lang="ts" setup>
import { $t } from "@/i18n";

const model = defineModel<boolean>({
  default: false
});

const $emits = defineEmits(["confirm", "cancel"]);

function onBtnConfirm() {
  model.value = false;
  $emits("confirm");
}

function onBtnCancel() {
  model.value = false;
  $emits("cancel");
}
</script>

<template>
  <v-dialog v-model="model" max-width="60vw">
    <v-card min-width="30vw">
      <v-card-title class="text-h4">
        <v-icon class="pr-2">mdi-delete</v-icon>
        {{ $t("DocumentsDeleteConfirmationModalDialog-question-label", { $: "Do you want to delete selected document(s)?" }) }}
      </v-card-title>
      <v-card-actions>
        <v-row>
          <v-col cols="6">
            <v-btn color="secondary" variant="elevated" block @click="onBtnCancel">{{ $t("DocumentsDeleteConfirmationModalDialog-cancel-label", { $: "Cancel" }) }}</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn color="primary" variant="elevated" block @click="onBtnConfirm">{{ $t("DocumentsDeleteConfirmationModalDialog-confirm-label", { $: "Confirm" }) }}</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped></style>
