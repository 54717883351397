<script lang="ts" setup>
import SplitPanel from "@/components/Layout/SplitPanel.vue";
import EditModelInstance from "@/components/ModelInstances/EditModelInstance.vue";
import ModelInstanceAssignmentDialog from "@/components/ModelInstances/ModelInstanceAssignment/ModelInstanceAssignmentDialog.vue";
import { ModelInstanceChange, ModelInstanceSaveAction } from "@/components/ModelInstances/ModelInstanceChange";
import ResourceModelInstancesGrid from "@/components/ModelInstances/ResourceModelInstancesGrid.vue";
import { $t } from "@/i18n";
import ApiService from "@/services/api";
import { useDocumentsStore } from "@/store/DocumentsStore";
import { useErrorsStore } from "@/store/ErrorsStore";
import { useModelInstancesStore } from "@/store/ModelInstancesStore";
import {
  CreateDocumentForContextCommand,
  IResourceDto,
  ModelInstanceIdDto,
  ModelInstanceStatus,
  ModelSchemaDto,
  ModelSchemaKeys,
  ResourceModelInstanceDto,
  UpdateDocumentCommand
} from "@masta/generated-model";
import { GridApi } from "ag-grid-community";
import { computed, ref, watch } from "vue";
import ResourceDocumentsBrowser from "@/components/Documents/ResourceDocumentsBrowser.vue";
import ActionsButton from "@/components/Layout/ActionsButton.vue";

const props = defineProps<{
  resource: IResourceDto;
  schema: ModelSchemaDto;
}>();

const emit = defineEmits<{
  (e: "updated", id: string): void;
}>();

const miStore = useModelInstancesStore();
const documentStore = useDocumentsStore();

const selectedModelInstance = ref<ResourceModelInstanceDto | null>(null);
const createdModelInstanceId = ref<string | null>(null);
const createMode = ref(false);
const assignModelInstanceAssignmentDialog = ref(false);

const resourceModelInstancesGridRef = ref<typeof ResourceModelInstancesGrid | null>(null);

watch(
  () => props.resource,
  (newResource) => {
    if (!selectedModelInstance.value || !newResource || !newResource.modelInstances) {
      selectedModelInstance.value = null;
      return;
    }

    const modelInstance = newResource.modelInstances.find((x) => x.id === selectedModelInstance.value?.id && x.revisionNumber === selectedModelInstance.value?.revisionNumber);
    selectedModelInstance.value = modelInstance || null;
  }
);

const modelInstances = computed<ResourceModelInstanceDto[]>(() => {
  if (!props.resource || !props.resource.modelInstances || !props.schema) return [];
  return props.resource.modelInstances.filter((x) => x.schemaId === `${props.schema.schemaKey}.${props.schema.schemaName}` && x.schemaVersion === props.schema.version);
});

const showEditView = computed(() => selectedModelInstance.value || createMode.value);

function selectionChanged(modelInstance: ResourceModelInstanceDto | null) {
  selectedModelInstance.value = modelInstance;
  createMode.value = false;
}

function modelInstancesGridDataUpdated(gridApi: GridApi) {
  if (createdModelInstanceId.value) {
    gridApi.forEachNode((node) => {
      if (node.data.id === createdModelInstanceId.value) {
        createdModelInstanceId.value = null;
        node.setSelected(true);
      }
    });
  }
}

async function saveAction(action: ModelInstanceSaveAction) {
  await action.save(async (change: ModelInstanceChange) => {
    let success = false;
    if (action.type === "document") {
      try {
        if (selectedModelInstance.value) {
          await documentStore.updateDocumentWithAttachment({
            businessId: selectedModelInstance.value.businessId,
            revisionNumber: selectedModelInstance.value.revisionNumber,
            id: selectedModelInstance.value.id,
            ...change.value.attachment
          } as UpdateDocumentCommand);
        } else {
          createdModelInstanceId.value = await documentStore.createDocumentWithAttachment({
            profileName: props.schema.schemaName,
            businessId: change.businessId,
            tags: change.tags,
            ...change.value.attachment
          } as CreateDocumentForContextCommand);
          await ApiService.resources.assignModelInstance({
            resourceId: props.resource.id,
            resourceBusinessId: null!,
            scenarioId: props.resource.scenarioId,
            modelInstanceId: createdModelInstanceId.value!,
            modelInstanceBusinessId: null!
          });

          success = true;
        }
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        success = false;
      }
    } else if (action.type === "instance") {
      try {
        if (selectedModelInstance.value) {
          success = await miStore.updateModelInstance({
            id: selectedModelInstance.value.id,
            revisionNumber: selectedModelInstance.value.revisionNumber,
            ...(change as any)
          });
        } else {
          createdModelInstanceId.value = await miStore.createInstance({
            ...(change as any),
            schemaName: props.schema.schemaName,
            schemaKey: props.schema.schemaKey,
            schemaVersion: props.schema.version,
            release: false
          });
          await ApiService.resources.assignModelInstance({
            resourceId: props.resource.id,
            resourceBusinessId: null!,
            scenarioId: props.resource.scenarioId,
            modelInstanceId: createdModelInstanceId.value!,
            modelInstanceBusinessId: null!
          });
          success = true;
        }
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        success = false;
      }
    }
    if (success) {
      notifyUpdated();
      createMode.value = false;
    }
    return success;
  });
}

async function saveModelInstance(change: ModelInstanceChange, callback: (ok: boolean) => void) {
  let success = false;
  try {
    if (selectedModelInstance.value) {
      success = await miStore.updateModelInstance({
        id: selectedModelInstance.value.id,
        revisionNumber: selectedModelInstance.value.revisionNumber,
        ...change
      });
    } else {
      const id = await miStore.createInstance({
        ...change,
        schemaName: props.schema.schemaName,
        schemaKey: props.schema.schemaKey,
        schemaVersion: props.schema.version,
        release: false
      });

      createdModelInstanceId.value = id;
      success = id;

      if (success) {
        try {
          await ApiService.resources.assignModelInstance({
            resourceId: props.resource.id,
            resourceBusinessId: null,
            scenarioId: props.resource.scenarioId,
            modelInstanceId: id,
            modelInstanceBusinessId: null
          });
          success = true;
        } catch (e) {
          const errorsStore = useErrorsStore();
          errorsStore.handleError(e);
          success = false;
        }
      }
    }
    if (success) {
      notifyUpdated();
      createMode.value = false;
    }
  } catch (_) {
    success = false;
  } finally {
    callback(success);
  }
}

async function saveDocument(change: ModelInstanceChange, callback: (ok: boolean) => void) {
  let success = false;
  try {
    if (selectedModelInstance.value) {
      await documentStore.updateDocumentWithAttachment({
        businessId: selectedModelInstance.value.businessId,
        revisionNumber: selectedModelInstance.value.revisionNumber,
        id: selectedModelInstance.value.id,
        ...change.value.attachment
      } as UpdateDocumentCommand);
      success = true;
    } else {
      const id = await documentStore.createDocumentWithAttachment({
        profileName: props.schema.schemaName,
        businessId: change.businessId,
        tags: change.tags,
        ...change.value.attachment
      } as CreateDocumentForContextCommand);
      await ApiService.resources.assignModelInstance({
        resourceId: props.resource.id,
        resourceBusinessId: null,
        scenarioId: props.resource.scenarioId,
        modelInstanceId: id,
        modelInstanceBusinessId: null
      });
      success = true;
    }
    if (success) {
      notifyUpdated();
      createMode.value = false;
    }
  } catch (_) {
    success = false;
  } finally {
    callback(success);
  }
}

async function editCancelled() {
  notifyUpdated();
  createMode.value = false;
}

async function releaseModelInstance() {
  if (selectedModelInstance.value) {
    await miStore.release(selectedModelInstance.value);
    notifyUpdated();
  }
}

async function archiveModelInstance() {
  if (selectedModelInstance.value && selectedModelInstance.value.status !== ModelInstanceStatus.Archived) {
    await miStore.archive(selectedModelInstance.value);
    notifyUpdated();
  }
}

async function createNewRevisionOfModelInstance() {
  if (selectedModelInstance.value) {
    await miStore.createNewRevision(selectedModelInstance.value);
    notifyUpdated();
  }
}

async function makeCopy() {
  if (selectedModelInstance.value) {
    const id = await miStore.makeCopy(selectedModelInstance.value);
    await ApiService.resources.assignModelInstance({
      resourceId: props.resource.id,
      resourceBusinessId: null,
      scenarioId: props.resource.scenarioId,
      modelInstanceId: id,
      modelInstanceBusinessId: null
    });
    notifyUpdated();
  }
}

async function createNewModelInstance() {
  selectedModelInstance.value = null;
  createMode.value = true;
}

async function deassignModelInstances() {
  if (selectedModelInstance.value) {
    await ApiService.resources.deassignModelInstance({
      resourceId: props.resource.id,
      resourceBusinessId: null,
      scenarioId: props.resource.scenarioId,
      modelInstanceId: selectedModelInstance.value.id,
      modelInstanceBusinessId: null
    });
    notifyUpdated();
  }
}

function assignModelInstance() {
  assignModelInstanceAssignmentDialog.value = true;
}

function assignmentCancelled() {
  assignModelInstanceAssignmentDialog.value = false;
}

async function assignementConfirmed(ids: ModelInstanceIdDto[]) {
  assignModelInstanceAssignmentDialog.value = false;

  try {
    for (const id of ids) {
      await ApiService.resources.assignModelInstance({
        resourceId: props.resource.id,
        resourceBusinessId: null,
        scenarioId: props.resource.scenarioId,
        modelInstanceId: id.id,
        modelInstanceBusinessId: null
      });
    }

    notifyUpdated();
  } catch (e) {
    const errorsStore = useErrorsStore();
    errorsStore.handleError(e);
  }
}

async function unassignModelInstance() {
  try {
    if (resourceModelInstancesGridRef.value === null) return;

    const resourceModelInstaceGridApi = resourceModelInstancesGridRef.value?.gridWrapperRef.gridApi;
    if (resourceModelInstaceGridApi == null) return;

    const selectedRows = resourceModelInstaceGridApi.getSelectedRows();
    if (selectedRows.length === 0) return;

    for (const row of selectedRows) {
      const selectedResourceModelInstance = row as ResourceModelInstanceDto;

      await ApiService.resources.deassignModelInstance({
        resourceId: props.resource.id,
        resourceBusinessId: null,
        scenarioId: props.resource.scenarioId,
        modelInstanceId: selectedResourceModelInstance.id,
        modelInstanceBusinessId: null
      });
    }

    notifyUpdated();
  } catch (e) {
    const errorsStore = useErrorsStore();
    errorsStore.handleError(e);
  }
}

function createSplitPanelGutterElement(index: number, direction: "horizontal" | "vertical") {
  const gutter = document.createElement("div");
  gutter.classList.add("gutter-resource-model-instances");
  return gutter;
}

function notifyUpdated() {
  emit("updated", props.resource.id);
}

const actions = ref([

  {
    title: $t("modelInstance-list-assign-action", { $: "1. Assign" }),
    tooltip: $t("modelInstance-list-assign-tooltip", { $: "Assign" }),
    action: assignModelInstance,
    icon: "mdi-playlist-plus",
    order: 1
  },
  {
    title: $t("modelInstance-list-unassign-action", { $: "2. Unassign" }),
    tooltip: $t("modelInstance-list-unassign-tooltip", { $: "Unassign" }),
    action: unassignModelInstance,
    disabled: () => !selectedModelInstance.value,
    icon: "mdi-playlist-minus",
    order: 2
  },
  {
    separator: true,
    order: 3
  },
  {
    title: $t("modelInstance-list-create-action", { $: "3. Add New" }),
    tooltip: $t("modelInstance-list-create-tooltip", { $: "Add New" }),
    action: createNewModelInstance,
    icon: "mdi-plus",
    order: 4
  },
  {
    title: $t("resource-modelInstance-list-createNewVersion-action", { $: "4. Create New Version" }),
    tooltip: $t("resource-modelInstance-list-createNewVersion-action-tooltip", { $: "Create new version/revision of existing" }),
    action: createNewRevisionOfModelInstance,
    disabled: () => !selectedModelInstance.value,
    icon: "mdi-ab-testing",
    order: 5
  },
  {
    title: $t("resource-modelInstance-list-release-action", { $: "5. Release" }),
    tooltip: $t("resource-modelInstance-list-release-action-tooltip", { $: "Release" }),
    action: releaseModelInstance,
    disabled: () => !selectedModelInstance.value,
    icon: "mdi-rocket-launch",
    order: 6
  },
  {
    title: $t("modelInstance-list-archive-action", { $: "6. Archive" }),
    tooltip: $t("resource-modelInstance-list-archive-tooltip", { $: "Archive" }),
    action: archiveModelInstance,
    disabled: () => !selectedModelInstance.value || selectedModelInstance.value.status === ModelInstanceStatus.Archived,
    icon: "mdi-archive-arrow-down-outline",
    order: 7
  }
]);
</script>

<template>
  <resource-documents-browser
    v-if="schema?.schemaKey === ModelSchemaKeys.mODEL_SCHEMA_JSON_DOCUMENTDEFINITION && resource"
    :resource="resource"
    :profile-name="schema.schemaName"
  />
  <div v-else class="flex-1-1 d-flex flex-column fill-height">
    <v-row class="fill-height" no-gutters>
      <split-panel
        :identifier="`split-panel-${schema.schemaName}`"
        :sizes="[30, 70]"
        :min-size="[50, 50]"
        direction="horizontal"
        class2="overflow-x-hidden"
        :gutter="createSplitPanelGutterElement"
      >
        <template #panel-1>
          <v-card elevation="0" class="d-flex flex-column fill-height">
            <v-card-text class="fill-height pa-0">
              <v-row class="fill-height" no-gutters>
                <v-col cols="12">
                  <ResourceModelInstancesGrid
                    ref="resourceModelInstancesGridRef"
                    :model-instances="modelInstances"
                    hide-custom-actions-separator
                    @selection-changed="selectionChanged"
                    @data-updated="modelInstancesGridDataUpdated"
                  >
                    <template #custom-buttons>
                      <actions-button :model-value="actions"></actions-button>
                    </template>
                  </ResourceModelInstancesGrid>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
        <template #panel-2>
          <v-card v-if="showEditView" elevation="7" class="d-flex flex-column fill-height edit-model-instance-card">
            <v-card-text class="fill-height">
              <edit-model-instance v-if="showEditView" title="test" :schema="schema" :model-instance="selectedModelInstance" @save-action="saveAction" @cancel="editCancelled" />
            </v-card-text>
          </v-card>
          <v-card v-if="!showEditView" elevation="7" class="d-flex flex-column fill-height no-edit-model-instance-card">
            <v-card-text class="fill-height">
              <v-row class="fill-height" no-gutters>
                <v-col cols="12" class="d-flex align-center justify-center">
                  <label class="text-secondary text-h6 font-weight-regular">
                    {{ $t("modelInstance-noModelInstanceSelected-label", { $: "No model instance selected" }) }}
                  </label>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
      </split-panel>
    </v-row>

    <model-instance-assignment-dialog
      :schema="schema"
      :model-instances="resource.modelInstances"
      :opened="assignModelInstanceAssignmentDialog"
      @cancel="assignmentCancelled"
      @confirm="assignementConfirmed"
    />
  </div>
</template>

<style lang="scss">
.edit-model-instance-card {
  border-left: 2px solid rgb(var(--v-theme-primary));
  border-top: 2px solid rgb(var(--v-theme-primary));
  border-radius: 0;
  overflow-y: scroll;
}

.no-edit-model-instance-card {
  border-radius: 0;
}

.gutter-resource-model-instances {
  cursor: col-resize;
}
</style>
