﻿<script lang="ts" setup>
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { inject, ref } from "vue";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import { useTheme } from "vuetify";
import { $durationFormatterSymbol, DateFormatter } from "@masta/shared";
import { ModelInstanceDto } from "@masta/generated-model";
import { $t } from "@/i18n";
import { translateAssignmentType, translateInventoryChangeType, translateMeasurementUnit, translateResourceType, translateUsageType } from "@/composables/translateEnum";
import { ValueFormatterParams, ValueGetterParams } from "ag-grid-community";
import { asResourcesRepresentation } from "@/components/Tasks/TaskUtils";

const theme = useTheme();

interface Props {
  step: any;
}

defineProps<Props>();

const gridWrapperRef = ref<GridWrapperComponent>();
const defaultColDef = ref({
  sortable: true,
  resizable: true,
  headerValueGetter: (_: any) => $t("task-list-name-label", { $: "Name" })
});

const goupRowRendererParams = {
  suppressCount: true
};

const $durationFormatter = inject<DateFormatter>($durationFormatterSymbol)!;

function displayModelInstances(modelInstances: ModelInstanceDto[]) {
  if (!modelInstances) return "";

  return modelInstances.map((x) => x.businessId).join(",");
}

function onPrepareColumns(columnDefs: any) {
  columnDefs.value = [
    {
      field: "resourceType",
      sortable: true,
      valueGetter: (params: ValueGetterParams) => translateResourceType(params.data?.resourceType),
      headerValueGetter: (_: any) => $t("task-list-resourceType-label", { $: "Resource Type" })
    },
    {
      field: "assignmentType",
      sortable: true,
      valueGetter: (params: ValueGetterParams) => translateAssignmentType(params.data?.assignmentType),
      headerValueGetter: (_: any) => $t("task-list-assignmentType-label", { $: "Assignment Type" })
    },
    {
      field: "usageType",
      sortable: true,
      valueGetter: (params: ValueGetterParams) => translateUsageType(params.data?.usageType),
      headerValueGetter: (_: any) => $t("task-list-usageType-label", { $: "Usage Type" })
    },
    {
      field: "inventoryChangeType",
      sortable: true,
      valueGetter: (params: ValueGetterParams) => translateInventoryChangeType(params.data?.inventoryChangeType),
      headerValueGetter: (_: any) => $t("task-list-inventoryChangeType-label", { $: "Inventory Change Type" })
    },
    {
      field: "inventoryChangeThresholdQuantity",
      headerValueGetter: (_: any) => $t("task-list-inventoryChangeThresholdQuantity-label", { $: "Inventory Change Threshold" }),
      sortable: true
    },
    {
      field: "alternativesCategory",
      sortable: true,
      headerValueGetter: (_: any) => $t("task-list-alternativesCategory-label", { $: "Alternatives Category" })
    },
    // {
    //   field: "resource.businessId",
    //   sortable: true,
    //   valueGetter: (params: any) => (params.data.resource ? params.data.resource.businessId : ""),
    //   headerValueGetter: (_: any) => $t("task-list-businessId-label", { $: "Business ID" })
    // },
    // {
    //   field: "resource.name",
    //   sortable: true,
    //   valueGetter: (params: any) => (params.data.resource ? params.data.resource.name : ""),
    //   headerValueGetter: (_: any) => $t("task-list-name-label", { $: "Name" })
    // },
    {
      field: "resources",
      sortable: true,
      valueFormatter: (params: ValueFormatterParams) => asResourcesRepresentation(params.value),
      headerValueGetter: (_: any) => $t("task-list-resources-label", { $: "Resources" })
    },
    {
      field: "quantity",
      headerName: "Quantity",
      sortable: true,
      headerValueGetter: (_: any) => $t("task-list-quantity-label", { $: "Quantity" })
    },
    {
      field: "quantityUnit",
      headerName: "Measurement Unit",
      sortable: true,
      valueGetter: (params: ValueGetterParams) => translateMeasurementUnit(params.data?.quantityUnit),
      headerValueGetter: (_: any) => $t("task-list-quantityUnit-label", { $: "Measurement Unit" })
    },
    {
      field: "processingTime",
      editable: false,
      sortable: true,
      valueFormatter: (params: any) => {
        return $durationFormatter(params.data.processingTime);
      },
      headerValueGetter: (_: any) => $t("task-list-processingTime-label", { $: "Processing Time" })
    },
    {
      field: "quantityPerTime",
      editable: false,
      sortable: true,
      headerValueGetter: (_: any) => $t("task-list-quantityPerTime-label", { $: "Quantity Per Time" })
    },
    {
      field: "modelInstances",
      sortable: true,
      valueFormatter: (params: any) => displayModelInstances(params.data.modelInstances),
      headerValueGetter: (_: any) => $t("task-list-requirements-label", { $: "Requirements" })
    }
  ];
}
</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    identifier="step-resource-specs"
    row-selection="multiple"
    :default-col-def="defaultColDef"
    :grid-data="step.resources"
    group-display-type="groupRows"
    group-default-expanded="-1"
    :group-row-renderer-params="goupRowRendererParams"
    :suppress-group-rows-sticky="true"
    :animate-rows="true"
    @prepare-columns="onPrepareColumns"
  />
</template>

<style lang="scss" scoped></style>
