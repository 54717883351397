import { v4 as uuid } from "uuid";
import { defineStore } from "pinia";
import { useSnackbarsStore } from "@/store/SnackbarsStore";
import { AppError } from "@/services/api/errors";
import { $t } from "@/i18n";

interface State {
  errors: any[];
}

// $t("tracking-generateAndCreateSerialNumbers-exception-materialNotTracked", { $: "Material doesn't exist or its serial numbers are not tracked" })
// $t("tracking-generateAndCreateSerialNumbers-exception-quantityMustBeInteger", { $: "In order to track serial number order quantity must be integer" })
// $t("database-duplicateKey-exception-label", { $: "Duplicate key (probably business ID)" })
// $t("resource-duplicateBusinessId-exception-label", { $: "Duplicate Business ID: it has to be unique among all resource types" })
// $t("database-resourceIsUsedInSpecsAndCannotBeDeleted-exception-label", { $: "Resource is used in product technology or production tasks and cannot be deleted" })
// $t("database-onlyOneBaseCalendar-exception-label", { $: "There can be only one base calendar" })
// $t("database-noBaseCalendarErrorCode-exception-label", { $: "There is no base calendar" })
// $t("resource-cannotChangeParentBecauseOfPlannableErrorCode-exception-label", { $: "Resource parent cannot be be changed: child resource (or one of its successors) and requested parent (or one of its predecessors) are both planable" })
// $t("resource-cannotChangePlannableBecauseOfParentErrorCode-exception-label", { $: "Resource cannot be planable: it has at least one planable parent/predecessor" })
// $t("resource-cannotChangePlannableBecauseOfChildErrorCode-exception-label", { $: "Resource cannot be planable: it has at least one planable child/successor" })
// $t("planning-bomGraph-resourceNotFoundError-message", { $: "Resource not found" })
// $t("planning-bomGraph-templateNotFoundError-message", { $: "Template not found" })
// $t("planning-bomGraph-wrongTemplateTypeError-message", { $: "Wrong template type" })
// $t("orders-customerOrders-orderLinesMustHaveUniquePositionsError-message", { $: "Order lines must have unique positions" })
// $t("orders-inboundDeliveries-orderLinesMustHaveUniquePositionsError-message", { $: "Order lines must have unique positions" })
// $t("orders-purchaseOrders-orderLinesMustHaveUniquePositionsError-message", { $: "Order lines must have unique positions" })
// $t("modelInstances-cannotDeleteAssignedModelInstanceErrorCode-exception-label", { $: "Cannot delete assigned model instance" })
// $t("productTemplate-unrelease-cannotUnreleaseTemplateWithProductionTasks-label", { $: "Cannot unrelease template with production tasks" })

export const useErrorsStore = defineStore("errors", {
  state: (): State => ({
    errors: []
  }),
  actions: {
    handleError(error: any) {
      this.errors = [
        ...this.errors,
        {
          id: uuid(),
          ...error
        }
      ];
      let message = "";
      if (error instanceof AppError) {
        message = `${error.status}: ${error.title ?? ""} `;
        if (error.detail) {
          if (error.args) {
            message = $t(error.detail, error.args) ?? "";
          } else {
            message = $t(error.detail, { $: error.detail }) ?? "";
          }
        } else if (error.message) {
          message += error.message;
        }

        if (error.errors) {
          for (const [_, reason] of Object.entries(error.errors)) {
            message = `${message}<br>&rarr; ${reason}`;
          }
        }

        const snackbarsStore = useSnackbarsStore();
        snackbarsStore.createSnackbar({
          message,
          error,
          type: error.errors ? "warning" : "error"
        });
      } else {
        console.error("Unknown error", error);
        message = error.message;
        if (error && error.response) {
          const { status, data } = error.response;
          if (data && (data.title || data.detail)) {
            const { title, detail } = data;
            message = `${status}: ${title ?? ""} ${$t(error.detail, { $: error.detail }) ?? ""}`;
          }
        }
        const snackbarsStore = useSnackbarsStore();
        snackbarsStore.createSnackbar({
          message,
          type: "error"
        });
      }
    }
  }
});
