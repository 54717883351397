import { defineStore } from "pinia";

export const useMaterialsDemandStore = defineStore("materialsDemand", {
  state: () => ({
    taskIds: [] as string[]
  }),
  actions: {
    setTaskIds(taskIds: string[]) {
      this.taskIds = taskIds;
    },
    clear() {
      this.taskIds = [];
    }
  }
});
