﻿<script lang="ts" setup>
import TasksGrid from "@/components/Tasks/TasksGrid.vue";
import { onBeforeUnmount, ref } from "vue";
import { useScenariosStore } from "@/store/ScenariosStore";
import { storeToRefs } from "pinia";
import { $t } from "@/i18n";
import EditGanttNoteActionModal from "@/components/Gantt/EditGanttNoteActionModal.vue";
import {
  GanttChartResourceTypeVisibilitySetting,
  GanttNoteDto,
  GanttResourceDto,
  ProductionTaskDto,
  ResourceCapacityEntrySource,
  ResourcesCapacitiesChangeNotificationEvent,
  ResourceType,
  SchedulingFinishedNotificationEvent
} from "@masta/generated-model";
import { NoteClickEvent, NoteHandler } from "@/components/Gantt/ResourcesGantt/NoteHandler";
import { EnhancedGanttResourceCapacityDto, ExtendedGanttResourceDto, ResourceCapacityActivity, ResourceRow, ResourcesGanttLoader } from "@/components/Gantt/ResourcesGantt";
import { useSubscription } from "@vueuse/rxjs";
import _ from "lodash";
import { tryOnMounted } from "@vueuse/core";
import { ResourceCapacityChangedNotification, SchedulingFinishedNotification, useNotification } from "@/notifications";
import SplitPanel from "@/components/Layout/SplitPanel.vue";
import { useI18n } from "vue-i18n";
import { GanttActionManager, GanttEvents, GanttSettings, IContextMenuItem, IContextMenuItemActionContext, IocSymbols, Layer, SettingKey } from "@masta/gantt2/core";
import ResourceCapacityCreator from "@/components/Resources/ResourceCapacityCreator.vue";
import { BehaviorSubject } from "rxjs";
import { ViewportRowsContainer } from "@masta/gantt2/gantt";
import { useRouter } from "vue-router";
import { taskGroupValueCellStyle } from "@/components/Grid/CellRenderers/GroupValueCellStyle";
import { CustomSettingKeys } from "@/components/Gantt/ResourcesGantt/CustomSettingKeys";
import { translateResourceType } from "@/composables/translateEnum";
import { SelectRootTaskActivitiesAction } from "@/components/Gantt/ResourcesGantt/SelectRootTaskActivitiesAction";
import RescheduleRootTaskGanttAction from "@/components/Gantt/RescheduleRootTaskGanttAction.vue";
import { OpenRelatedTaskAction } from "@/components/Gantt/ResourcesGantt/OpenRelatedTaskAction";
import { OpenProductCardAction } from "@/components/Gantt/ResourcesGantt/OpenProductCardAction";
import { RecalculateAction } from "@/components/Gantt/ResourcesGantt/RecalculateAction";
import { DescheduleTaskAction } from "@/components/Gantt/ResourcesGantt/DescheduleTaskAction";
import RescheduleSelectedTasksGanttAction from "@/components/Gantt/RescheduleSelectedTasksGanttAction.vue";

interface GanttProps {
  hideTasks?: boolean | null | undefined;
  filterExpressionProvider?: () => string[] | string | null | undefined;
  height?: string | null | undefined;
}

const props = defineProps<GanttProps>();

const emits = defineEmits<{
  (e: "reload"): void;
}>();

const router = useRouter();
const store = useScenariosStore();
const i18n = useI18n();
const { selectedScenario } = storeToRefs(store);

const showNoteActionModal = ref(false);
const showResourceCapacityCreator = ref(false);
const showRescheduleRootTaskDialog = ref(false);
const showRescheduleSelectedTasksDialog = ref(false);
const rescheduleRootTaskGanttAction = ref<SelectRootTaskActivitiesAction | null | undefined>();
const selectedNote = ref({} as GanttNoteDto);
const lastNoteClickEvent = ref<NoteClickEvent>();
const selectedResource = ref<GanttResourceDto>();
const selectedDate = ref<string>();
const selectedActivities = ref<EnhancedGanttResourceCapacityDto[]>([]);
const container = ref<HTMLDivElement>();
let loader: ResourcesGanttLoader;
let noteHandler: NoteHandler;

const tasksTab = ref<"tasks" | "workOrders">("tasks");

const autoGroupColumnDef = ref({
  field: "businessId",
  headerValueGetter: (__: any) => $t("gantt-resource-businessId-label", { $: "Business ID" }),
  cellStyle: taskGroupValueCellStyle,
  minWidth: 330,
  cellRendererParams: {
    suppressCount: true
  },
  floatingFilterComponentParams: {
    placeholder: $t("gantt-resource-businessId-label", { $: "Business ID" })
  }
});
tryOnMounted(async () => {
  container.value?.addEventListener("rowNameClick", onRowNameClicked as any);
  loader = new ResourcesGanttLoader(container, selectedScenario, i18n, props.filterExpressionProvider);
  onBeforeUnmount(async () => {
    await loader.destroy();
    container.value?.removeEventListener("rowNameClick", onRowNameClicked as any);
  });
  await loader.initialize();

  noteHandler = await loader.getNoteHandler();
  useSubscription(
    noteHandler.noteClickEvent$.subscribe((e) => {
      showNoteActionModal.value = true;
      selectedNote.value = _.cloneDeep(e.note!);
      lastNoteClickEvent.value = e;
    })
  );
  const contextMenuProvider = await loader.getContextMenuProvider();
  contextMenuProvider.setProvider(async (menuProviderContext: IContextMenuItemActionContext) => {
    const rowData = menuProviderContext.row.userObject as ExtendedGanttResourceDto;
    const ganttEvents = await menuProviderContext.iocContainer.getAsync<GanttEvents>(GanttEvents);
    const ganttSettings = await menuProviderContext.iocContainer.getAsync<GanttSettings>(GanttSettings);
    const layers = await menuProviderContext.iocContainer.getAsync<BehaviorSubject<Layer[]>>(IocSymbols.LayersSymbol);
    const viewportRowsContainer = await menuProviderContext.iocContainer.getAsync<ViewportRowsContainer>(ViewportRowsContainer);
    const layersToToggle = layers.value.filter(
      (l) =>
        l.id.endsWith(`${rowData.type}-${ResourceCapacityEntrySource.Calendar.valueOf()}`) ||
        l.id.endsWith(`${rowData.type}-${ResourceCapacityEntrySource.AvailabilityRule.valueOf()}`) ||
        l.id.endsWith(`${rowData.type}-${ResourceCapacityEntrySource.Execution.valueOf()}`) ||
        l.id.endsWith(`${rowData.type}-${ResourceCapacityEntrySource.Scheduling.valueOf()}`)
    );

    const showChartSetting = ganttSettings.getSetting<boolean>(SettingKey.CHART_SHOW) ?? false;
    const ganttChartResourceTypeVisibilitySettings =
      ganttSettings.getSetting<GanttChartResourceTypeVisibilitySetting[]>(CustomSettingKeys.CHART_RESOURCE_TYPE_VISIBILITY_SETTINGS) ?? [];
    const chartVisibilitySetting = ganttChartResourceTypeVisibilitySettings.find((s) => s.type === rowData.type) ?? { type: rowData.type, visible: showChartSetting };

    const layerGroups: LayerGroup[] = [];

    class LayerGroup {
      public readonly layers: Layer[] = [];

      constructor(public readonly id: string, private _visibleLabel: string, private _hiddenLabel: string, private _visibleIcon: string, private _hiddenIcon: string) {
      }

      get label() {
        const isAnyNotVisible = this.layers.some((l) => !l.visible);
        return isAnyNotVisible ? this._hiddenLabel : this._visibleLabel;
      }

      get icon() {
        const isAnyNotVisible = this.layers.some((l) => !l.visible);
        return isAnyNotVisible ? this._hiddenIcon : this._visibleIcon;
      }
    }

    for (const layer of layersToToggle) {
      if (layer.id.endsWith(`-${ResourceCapacityEntrySource.Calendar.valueOf()}`) || layer.id.endsWith(`-${ResourceCapacityEntrySource.AvailabilityRule.valueOf()}`)) {
        let lg = layerGroups.find((l) => l.id === "calendar-availability");
        if (!lg) {
          lg = new LayerGroup(
            "calendar-availability",
            $t("gantt-resource-context-menu-hide-Calendar-AvailabilityRule-layer-label", { type: translateResourceType(rowData.type), $: "{type} - Hide Calendar" }),
            $t("gantt-resource-context-menu-show-Calendar-AvailabilityRule-layer-label", { type: translateResourceType(rowData.type), $: "{type} - Show Calendar" }),
            "<span class=\"mdi mdi-layers-remove\"></span><span class=\"mdi mdi-eye-outline\"></span>",
            "<span class=\"mdi mdi-layers-plus\"></span><span class=\"mdi mdi-eye-off-outline\"></span>"
          );
          lg.layers.push(layer);
          layerGroups.push(lg);
        } else {
          lg.layers.push(layer);
        }
      }
      if (layer.id.endsWith(`-${ResourceCapacityEntrySource.Execution.valueOf()}`) || layer.id.endsWith(`-${ResourceCapacityEntrySource.Scheduling.valueOf()}`)) {
        let lg = layerGroups.find((l) => l.id === "execution-scheduling");
        if (!lg) {
          lg = new LayerGroup(
            "execution-scheduling",
            $t("gantt-resource-context-menu-hide-Execution-Scheduling-layer-label", { type: translateResourceType(rowData.type), $: "{type} - Hide Tasks" }),
            $t("gantt-resource-context-menu-show-Execution-Scheduling-layer-label", { type: translateResourceType(rowData.type), $: "{type} - Show Tasks" }),
            "<span class=\"mdi mdi-layers-remove\"></span><span class=\"mdi mdi-eye-outline\"></span>",
            "<span class=\"mdi mdi-layers-plus\"></span><span class=\"mdi mdi-eye-off-outline\"></span>"
          );
          lg.layers.push(layer);
          layerGroups.push(lg);
        } else {
          lg.layers.push(layer);
        }
      }
    }

    const layerToggleActions: IContextMenuItem[] = layerGroups.map((lg) => {
      return {
        label: lg.label,
        icon: lg.icon,
        action: async (_context: IContextMenuItemActionContext) => {
          lg.layers.forEach((l) => {
            l.visible = !l.visible;
          });
          if (lg.id === "execution-scheduling") {
            const isAnyNotVisible = lg.layers.some((l) => !l.visible);
            ganttSettings.setSetting({ key: SettingKey.TOOLTIP_DISABLED, value: isAnyNotVisible });
          }
          viewportRowsContainer.batchDraw(true);
        }
      };
    });

    const chartLayerActions: IContextMenuItem[] = [];

    chartLayerActions.push({
      label: chartVisibilitySetting.visible
        ? $t("gantt-resource-context-menu-hide-resource-type-chart-layer-label", { type: translateResourceType(rowData.type), $: "{type} - Hide Capacity Curve" })
        : $t("gantt-resource-context-menu-show-resource-type-chart-layer-label", { type: translateResourceType(rowData.type), $: "{type} - Show Capacity Curve" }),
      icon: chartVisibilitySetting.visible
        ? "<span class=\"mdi mdi-layers-remove\"></span><span class=\"mdi mdi-eye-outline\"></span>"
        : "<span class=\"mdi mdi-layers-plus\"></span><span class=\"mdi mdi-eye-off-outline\"></span>",
      action: async (_context: IContextMenuItemActionContext) => {
        const dto = _context.row.userObject as unknown as GanttResourceDto;

        const s = ganttChartResourceTypeVisibilitySettings.find((x) => x.type === dto.type);

        if (s) {
          s.visible = !s.visible;
        } else {
          ganttChartResourceTypeVisibilitySettings.push({ type: dto.type, visible: !chartVisibilitySetting.visible });
        }
        ganttSettings.setSetting({ key: CustomSettingKeys.CHART_RESOURCE_TYPE_VISIBILITY_SETTINGS, value: [...ganttChartResourceTypeVisibilitySettings] });
      },
      disabled: async (_context: IContextMenuItemActionContext) => !showChartSetting
    });

    // chartLayerActions.push({
    //   label: showChartSetting
    //     ? $t("gantt-resource-context-menu-hide-chart-layer-label", { $: "Disable Capacity Curve" })
    //     : $t("gantt-resource-context-menu-show-chart-layer-label", { $: "Enable Capacity Curve" }),
    //   icon: showChartSetting
    //     ? "<span class=\"mdi mdi-layers-remove\"></span><span class=\"mdi mdi-eye-outline\"></span>"
    //     : "<span class=\"mdi mdi-layers-plus\"></span><span class=\"mdi mdi-eye-off-outline\"></span>",
    //   action: async (_context: IContextMenuItemActionContext) => {
    //     ganttSettings.setSetting({ key: SettingKey.CHART_SHOW, value: !showChartSetting });
    //   }
    // });

    const hasActivityBounds = menuProviderContext.activityBounds.length > 0;
    const hasSelectedActivities = ganttEvents.selectedActivities$$.value.length > 0;

    const recalculateAction = await menuProviderContext.iocContainer.getAsync<RecalculateAction>(RecalculateAction);
    const recalculateMenuAction = recalculateAction ? {
      label: $t("gantt-resource-context-menu-recalculate-label", { $: "Recalculate" }),
      icon: "<span class=\"mdi mdi-calendar-refresh\"></span>",
      action: async (context: IContextMenuItemActionContext) => {
        await recalculateAction.execute(context);
      }
    } : undefined;

    const descheduleAction = await menuProviderContext.iocContainer.getAsync<DescheduleTaskAction>(DescheduleTaskAction);
    const descheduleMenuAction = descheduleAction ? {
      label: $t("gantt-resource-context-menu-descheduleTask-label", { $: "Deschedule" }),
      icon: "<span class=\"mdi mdi-calendar-remove\"></span>",
      action: async (context: IContextMenuItemActionContext) => {
        await descheduleAction.execute(context);
      }
    } : undefined;

    const manager = await menuProviderContext.iocContainer.getAsync<GanttActionManager>(IocSymbols.GanttActionManager);

    rescheduleRootTaskGanttAction.value = manager.runningActions.find(ra => ra instanceof SelectRootTaskActivitiesAction) as SelectRootTaskActivitiesAction;

    const rescheduleSelectedTasksContextMenuAction = hasSelectedActivities ? {
      label: $t("gantt-resource-context-menu-rescheduleSelectedTasks-label", { $: "Reschedule marked tasks to this time point" }),
      icon: "<span class=\"mdi mdi-source-branch-sync\"></span>",
      action: async (context: IContextMenuItemActionContext) => {
        selectedDate.value = context.timePoint.toJSON();
        selectedActivities.value = ganttEvents.selectedActivities$$.value.map(x => x.activity.userObject);
        showRescheduleSelectedTasksDialog.value = true;
      }
    } : undefined;

    const rescheduleRootTaskContextMenuAction = rescheduleRootTaskGanttAction.value ? {
      label: $t("gantt-resource-context-menu-rescheduleRootTask-label", { $: "Reschedule root task to this time point" }),
      icon: "<span class=\"mdi mdi-source-branch-sync\"></span>",
      action: async (context: IContextMenuItemActionContext) => {
        selectedDate.value = context.timePoint.toJSON();
        showRescheduleRootTaskDialog.value = true;
      }
    } : undefined;

    const cancelRescheduleRootTaskContextMenuAction = rescheduleRootTaskGanttAction.value ? {
      label: $t("gantt-resource-context-menu-deselectRootTask-label", { $: "Unmark all related tasks" }),
      icon: "<span class=\"mdi mdi-source-branch-remove\"></span>",
      action: async () => {
        rescheduleRootTaskGanttAction.value?.cancel();
      }
    } : undefined;

    if (hasActivityBounds) {
      const openRelatedTaskGanttAction = await menuProviderContext.iocContainer.getAsync<OpenRelatedTaskAction>(OpenRelatedTaskAction);
      const openRelatedTaskContextMenuAction = openRelatedTaskGanttAction.getTaskId(menuProviderContext) ? {
        label: $t("gantt-resource-context-menu-openTaskDetailsView-label", { $: "Open task details" }),
        icon: "<span class=\"mdi mdi-chart-timeline\"></span>",
        action: async (context: IContextMenuItemActionContext) => {
          const taskId = await manager.execute(context, openRelatedTaskGanttAction);
          if (taskId) {
            await router.push({ name: "Tasks", query: { taskId } });
          }
        }
      } : undefined;
      const openProductCardAction = await menuProviderContext.iocContainer.getAsync<OpenProductCardAction>(OpenProductCardAction);
      const openProductCardContextMenuAction = openProductCardAction.getResourceId(menuProviderContext) ? {
        label: $t("gantt-resource-context-menu-openProductCardView-label", { $: "Open product card" }),
        icon: "<span class=\"mdi mdi-account-hard-hat\"></span>",
        action: async (context: IContextMenuItemActionContext) => {
          const product = await manager.execute(context, openProductCardAction);
          if (product) {
            await router.push({ name: "Product Card", query: { product } });
          }
        }
      } : undefined;

      return [
        openRelatedTaskContextMenuAction,
        openProductCardContextMenuAction,
        rescheduleRootTaskContextMenuAction,
        rescheduleRootTaskGanttAction.value ? cancelRescheduleRootTaskContextMenuAction : {
          label: $t("gantt-resource-context-menu-selectRootTaskActivities-label", { $: "Mark all related tasks" }),
          icon: "<span class=\"mdi mdi-source-branch\"></span>",
          action: async (context: IContextMenuItemActionContext) => {
            const action = await menuProviderContext.iocContainer.getAsync<SelectRootTaskActivitiesAction>(SelectRootTaskActivitiesAction);
            manager.execute(context, action);
          }
        },
        rescheduleRootTaskGanttAction.value ? undefined : rescheduleSelectedTasksContextMenuAction,
        descheduleMenuAction,
        "separator",
        recalculateMenuAction,
        "separator",
        ...layerToggleActions,
        ...chartLayerActions
      ];
    }
    return [
      {
        label: $t("gantt-resource-context-menu-addNewResourceCapacity-label", { $: "Add Resource Capacity" }),
        icon: "<span class=\"mdi mdi-vector-point-plus\"></span>",
        action: async (context: IContextMenuItemActionContext) => {
          selectedResource.value = context.row.userObject as unknown as GanttResourceDto;
          selectedDate.value = context.timePoint.toJSON();
          showResourceCapacityCreator.value = true;
        }
      },
      "separator",
      rescheduleRootTaskContextMenuAction,
      rescheduleRootTaskGanttAction.value ? cancelRescheduleRootTaskContextMenuAction : undefined,
      rescheduleRootTaskGanttAction.value ? undefined : rescheduleSelectedTasksContextMenuAction,
      "separator",
      recalculateMenuAction,
      "separator",
      ...layerToggleActions,
      ...chartLayerActions
    ];
  });
});

useNotification(ResourceCapacityChangedNotification, onResourceCapacityChanged);
useNotification(SchedulingFinishedNotification, onSchedulingFinished);

async function onResourceCapacityChanged(event: ResourcesCapacitiesChangeNotificationEvent) {
  await loader.resourcesCapacitiesChangeNotificationEventHandler(event);
}

async function onSchedulingFinished(event: SchedulingFinishedNotificationEvent) {
  await loader.schedulingFinishedNotificationEventHandler(event);
  rescheduleRootTaskGanttAction.value?.onSchedulingFinished(event);
}

function onSaveNoteAction(note: GanttNoteDto) {
  noteHandler.addNote(note);
}

function onDeleteNoteAction(note: GanttNoteDto) {
  noteHandler.deleteNote(note);
}

async function onRowDragEnter(dragId: string, productionTaskDto: ProductionTaskDto, event: MouseEvent) {
  await loader.onTaskRowDragEnter(dragId, productionTaskDto, event);
}

async function onRowDragMove(dragId: string, event: MouseEvent) {
  await loader.onTaskRowDragMove(dragId, event);
}

async function onRowDragLeave(dragId: string, event: MouseEvent) {
  await loader.onTaskRowDragLeave(dragId, event);
}

async function onRowDragEnd(dragId: string, event: MouseEvent) {
  await loader.onTaskRowDragEnd(dragId, event);
}

function onRowNameClicked(event: CustomEvent<{ row: ResourceRow }>) {
  const row: GanttResourceDto = event.detail.row.userObject as any;
  switch (row.type) {
    case ResourceType.EquipmentGroup:
    case ResourceType.Equipment:
      router.push({ name: "Equipment", query: { resourceId: row.id } });
      break;
    case ResourceType.Material:
    case ResourceType.MaterialGroup:
      router.push({ name: "Material", query: { resourceId: row.id } });
      break;
    case ResourceType.PersonGroup:
    case ResourceType.Person:
      router.push({ name: "Personnel", query: { resourceId: row.id } });
      break;
    case ResourceType.Asset:
      router.push({ name: "Assets", query: { resourceId: row.id } });
      break;
    case ResourceType.Location:
      router.push({ name: "Location", query: { resourceId: row.id } });
      break;
    case ResourceType.Service:
      router.push({ name: "Services", query: { resourceId: row.id } });
      break;
    case ResourceType.Agreement:
      router.push({ name: "Agreements", query: { resourceId: row.id } });
      break;
  }
}

const postProcessPopup = ref(() => {
  // must empty to fix error with horizontal split panel and ag-grid context menu position
});
</script>

<template>
  <!-- when hideTasks=true display ganta in full height -->
  <div v-if="hideTasks" class="gantt-container">
    <div class="outer">
      <div class="inner">
        <div ref="container" />
      </div>
    </div>
  </div>

  <!-- when hideTasks=false display ganta and tasks in splited view -->
  <split-panel v-else identifier="split-panel-gantt" :sizes="[70, 30]" :min-size="[300, 0]">
    <template #panel-1>
      <div class="outer">
        <div class="inner">
          <div id="resources-gantt" ref="container" />
        </div>
      </div>
    </template>
    <template #panel-2>
      <div ref="splitPanel2Ref" class="d-flex flex-row fill-height tasks">
        <div class="pb-5">
          <v-tabs v-model="tasksTab" class="text-disabled" direction="vertical" selected-class="v-slide-group-item--active text-primary bg-white" density="compact" color="primary">
            <v-tab value="tasks">
              <v-tooltip location="bottom" open-delay="300">
                <template #activator="{ props }">
                  <v-icon size="large" v-bind="props" class="pr-2">mdi-format-list-checks</v-icon>
                </template>
                <span>{{ $t("gantt-resource-tabs-tasks-label", { $: "Tasks" }) }}</span>
              </v-tooltip>
            </v-tab>
            <v-tab value="workOrders">
              <v-tooltip location="bottom" open-delay="300">
                <template #activator="{ props }">
                  <v-icon size="large" v-bind="props" class="pr-2">mdi-text-box-multiple-outline</v-icon>
                </template>
                <span>{{ $t("gantt-resource-tabs-workOrders-label", { $: "Work Orders" }) }}</span>
              </v-tooltip>
            </v-tab>
          </v-tabs>
        </div>
        <tasks-grid
          v-if="tasksTab === 'tasks'"
          drop-zone-id="resources-gantt"
          no-auto-height
          flat-hierarchy
          :ag-grid-style="{ width: '100%', height: '100%' }"
          unscheduled-production-tasks-data
          droppable
          no-actions-row
          disable-root-popup-parent
          :post-process-popup="postProcessPopup"
          @row-drag-enter="onRowDragEnter"
          @row-drag-move="onRowDragMove"
          @row-drag-leave="onRowDragLeave"
          @row-drag-end="onRowDragEnd"
        />
        <tasks-grid
          v-if="tasksTab === 'workOrders'"
          drop-zone-id="resources-gantt"
          no-auto-height
          :auto-group-column-def="autoGroupColumnDef"
          :ag-grid-style="{ width: '100%', height: '100%' }"
          unscheduled-work-orders-data
          droppable
          no-actions-row
          disable-root-popup-parent
          :post-process-popup="postProcessPopup"
          @row-drag-enter="onRowDragEnter"
          @row-drag-move="onRowDragMove"
          @row-drag-leave="onRowDragLeave"
          @row-drag-end="onRowDragEnd"
        />
      </div>
    </template>
  </split-panel>
  <edit-gantt-note-action-modal v-model="showNoteActionModal" :note="selectedNote" @save="onSaveNoteAction" @delete="onDeleteNoteAction" />
  <resource-capacity-creator v-model="showResourceCapacityCreator" :resource="selectedResource" :date="selectedDate!" />
  <reschedule-root-task-gantt-action v-model:dialog="showRescheduleRootTaskDialog" v-model:action="rescheduleRootTaskGanttAction" v-model:date="selectedDate" />
  <reschedule-selected-tasks-gantt-action v-model:dialog="showRescheduleSelectedTasksDialog" v-model:date="selectedDate" v-model:selected-activities="selectedActivities" />
</template>

<style lang="scss" scoped>
.gantt-container {
  width: 100%;
  height: v-bind(height);
}

.outer {
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
  flex-direction: row;
  width: 100%;
  height: 100%;

  .bar {
    width: 100%;
  }

  .inner {
    width: 100%;
    height: 100%;
    position: relative;
  }
}

.tasks {
  .v-tab {
    min-width: unset
  }
}

.charts {
  display: flex;
  flex: 1 1 auto;
  padding: 2px;
  overflow: hidden;
  flex-direction: row;
  width: 100%;
  height: 30%;
}
</style>
