﻿import {
  ActivityRendererRegistry,
  GanttSettings,
  IContextMenuProvider,
  IGanttSettingsLoader,
  ILinesManager,
  IocContainer,
  IocSymbols,
  IRowChartScaleProvider,
  IRowInfoColumnCellRegistry,
  onActivationLifecycle,
  onDeactivationLifecycle,
  SettingKey, type TimeLineStyleSetting
} from "@masta/gantt2/core";
import { ResourceGanttActivityFactory } from "@/components/Gantt/ResourcesGantt/ResourceGanttActivityFactory";
import { AppContextDto, GanttSettings as GanttSettingsDto, GanttTimeSectionAggregation, MeasurementUnit } from "@masta/generated-model";
import Api from "@/services/api";
import { CustomSettingKeys } from "@/components/Gantt/ResourcesGantt/CustomSettingKeys";
import { ChronoUnit, Instant } from "@js-joda/core";
import { IResourcesGanttOptions } from "@/components/Gantt/ResourcesGantt/ResourcesGanttLoader";
import { AppIocSymbols } from "@/components/Gantt/ResourcesGantt/AppIocSymbols";
import { RouteLocationNormalizedLoaded } from "vue-router";
import { ResourceFixedRowChartScaleProvider } from "@/components/Gantt/ResourcesGantt/ResourceFixedRowChartScaleProvider";
import {
  AvailabilityRuleResourceCapacityActivity,
  CalendarResourceCapacityActivity,
  DemandExecutionResourceCapacityActivity,
  DemandSchedulingResourceCapacityActivity,
  InterruptionExecutionResourceCapacityActivity,
  NoteActivity,
  ResourceCapacityActivity,
  WaitingSchedulingResourceCapacityActivity
} from "src/components/Gantt/Common/Model";
import {
  AvailabilityRuleActivityRenderer,
  CalendarResourceActivityRenderer,
  ExecutionActivityRenderer,
  InterruptionActivityRenderer,
  ResourceNoteActivityRenderer,
  SchedulingActivityRenderer,
  WaitingActivityRenderer
} from "@/components/Gantt/ResourcesGantt/Renderers";
import { ActivityTooltipRendererRegistry, ChartLayer, LayerManager, RowScaleRowInfoTemplateCellWrapper } from "@masta/gantt2/gantt";
import { CustomActivityTooltipRenderer } from "@/components/Gantt/ResourcesGantt/Renderers/CustomActivityTooltipRenderer";
import { NoteActivityTooltipRenderer } from "@/components/Gantt/ResourcesGantt/Renderers/NoteActivityTooltipRenderer";
import type { interfaces } from "inversify";
import { NoteHandler } from "@/components/Gantt/ResourcesGantt/NoteHandler";
import { ResourceGanttRowInfoColumnProvider } from "@/components/Gantt/ResourcesGantt/RowInfo/ResourceGanttRowInfoColumnProvider";
import { DemandSupplyScaleRowInfoTemplateCellWrapper } from "@/components/Gantt/ResourcesGantt/RowInfo/DemandSupplyScaleRowInfoTemplateCellWrapper";
import { DemandSupplyScaleRowInfoTemplateCellCanvas } from "@/components/Gantt/ResourcesGantt/RowInfo/DemandSupplyScaleRowInfoTemplateCellCanvas";
import { ResourceCapacityChangeNotificationHandler } from "@/components/Gantt/ResourcesGantt/ResourceCapacityChangeNotificationHandler";
import { getChronoUnitFromTimelineUnit } from "@/components/Gantt/Common/CommonFrontModule";
import { translateMeasurementUnit } from "@/composables/translateEnum";
import { ContextMenuProvider } from "@/components/Gantt/ResourcesGantt/ContextMenuProvider";
import { EnhancedAutoLineManger } from "@/components/Gantt/ResourcesGantt/EnhancedAutoLineManger";
import { ResourceRowNameRowInfoTemplateCell } from "@/components/Gantt/ResourcesGantt/RowInfo/ResourceRowNameRowInfoTemplateCell";
import { ResourceLineManger } from "@/components/Gantt/ResourcesGantt/ResourceLineManger";
import { ResourceRowFactory } from "@/components/Gantt/ResourcesGantt/ResourceRowFactory";
import { OverlappingActivityLayer } from "@/components/Gantt/ResourcesGantt/Layers/OverlappingActivityLayer";
import { SupplyDemandChartLayer } from "@/components/Gantt/ResourcesGantt/Layers/SupplyDemandChartLayer";
import { DemandChartLayerRenderer } from "@/components/Gantt/ResourcesGantt/Layers/Renderers/DemandChartLayerRenderer";
import { SupplyChartLayerRenderer } from "@/components/Gantt/ResourcesGantt/Layers/Renderers/SupplyChartLayerRenderer";
import { useTagsStore } from "@/store/TagsStore";
import { SelectRootTaskActivitiesAction } from "@/components/Gantt/ResourcesGantt/SelectRootTaskActivitiesAction";
import { ActivityConnectorsLayer } from "@/components/Gantt/ResourcesGantt/Layers/ActivityConnectorsLayer";
import { OpenRelatedTaskAction } from "@/components/Gantt/ResourcesGantt/OpenRelatedTaskAction";
import { OpenProductCardAction } from "@/components/Gantt/ResourcesGantt/OpenProductCardAction";
import { DescheduleTaskAction } from "@/components/Gantt/ResourcesGantt/DescheduleTaskAction";
import { RecalculateAction } from "@/components/Gantt/ResourcesGantt/RecalculateAction";
// import { ResourceGanttDragAndDropManager } from "@/components/Gantt/ResourcesGantt/DragAndDrop/ResourceGanttDragAndDropManager";
// import { ResourceGanttDragLayer } from "@/components/Gantt/ResourcesGantt/DragAndDrop/ResourceGanttDragLayer";
// import { SchedulingOperationService } from "@/components/Gantt/ResourcesGantt/DragAndDrop/SchedulingOperationService";
// import { SchedulingGhostActivityRenderer } from "@/components/Gantt/ResourcesGantt/DragAndDrop/Renderers/SchedulingGhostActivityRenderer";
// import { SchedulingGhostActivityFactory } from "@/components/Gantt/ResourcesGantt/DragAndDrop/SchedulingGhostActivityFactory";

export const name = "ResourcesFrontModule";

function provideMeasurementUnitTranslations() {
  const measurementUnitKeys = Object.keys(MeasurementUnit) as Array<keyof typeof MeasurementUnit>;
  const measurementUnitTranslations: { [key: string]: string } = {};
  // Iterate over enum values
  measurementUnitKeys.forEach(key => {
    const value = MeasurementUnit[key];
    measurementUnitTranslations[value] = translateMeasurementUnit(value);
  });
  return measurementUnitTranslations;
}

function parseQueryFilter(ganttSettings: GanttSettings, route: RouteLocationNormalizedLoaded, filterExpressionProvider?: () => (string[] | string | null | undefined)) {
  if (filterExpressionProvider) {
    const filterExpression = filterExpressionProvider();
    if (filterExpression) {
      if (Array.isArray(filterExpression)) {
        ganttSettings.setSetting({ key: CustomSettingKeys.RESOURCE_ID_FILTER, value: filterExpression });
        ganttSettings.setSetting({ key: CustomSettingKeys.QUERY_FILTER, value: "" });
      } else if (typeof filterExpression === "string") {
        ganttSettings.setSetting({ key: CustomSettingKeys.QUERY_FILTER, value: filterExpression });
      }
    }
  }
  const query: any = route.query;
  if (query && query.types && !filterExpressionProvider) {
    let types: any[];
    if (Array.isArray(query.types)) {
      types = [...query.types];
    } else {
      types = query.types.split(",");
    }
    ganttSettings.setSetting({ key: CustomSettingKeys.QUERY_FILTER, value: types.map((x) => `Type = ${x}`).join(" OR ") });
  }
  if (query && query.resources && !filterExpressionProvider) {
    const resources = query.resources.split(",");
    if (resources.length > 0) {
      ganttSettings.setSetting({ key: CustomSettingKeys.RESOURCE_ID_FILTER, value: resources });
    }
  }
  if (query && query.timeSectionAggregation) {
    const timeSectionAggregation = query.timeSectionAggregation;
    if (!isNaN(+timeSectionAggregation) && +timeSectionAggregation >= 0) {
      ganttSettings.setSetting({ key: CustomSettingKeys.TIME_SECTION_AGGREGATION, value: +timeSectionAggregation });
    }
  } else {
    ganttSettings.setSetting({ key: CustomSettingKeys.TIME_SECTION_AGGREGATION, value: GanttTimeSectionAggregation.None });
  }
}

export const settingsLoader = {
  async loadSettings(ganttSettings: GanttSettings, iocContainer: IocContainer) {
    parseQueryFilter(ganttSettings, iocContainer.get<RouteLocationNormalizedLoaded>(AppIocSymbols.$route), iocContainer.get<() => (string[] | string | null | undefined)>(AppIocSymbols.filterExpressionProvider));

    const { data }: { data: GanttSettingsDto } = await Api.gantt.getGanttSettings({ profile: "resources" });

    const chronoUnit = getChronoUnitFromTimelineUnit(data.timelineUnit);

    const layerSettings = data.layers ?? [];
    const isChartVisible = (data.chart!.show ?? true) && (layerSettings.find(x => x.id === SupplyDemandChartLayer.name)?.visible ?? true);

    ganttSettings.setSettings([
      {
        key: SettingKey.START_TIME,
        value: Instant.now()
          .minusSeconds(ChronoUnit[chronoUnit as unknown as keyof typeof ChronoUnit].duration().seconds())
          .toEpochMilli()
      },
      { key: SettingKey.TIMELINE_UNIT, value: chronoUnit },
      { key: SettingKey.TIMELINE_UNIT_WIDTH, value: data.timelineUnitWidth ?? 400 },
      { key: SettingKey.TIMELINE_ZOOM_FACTOR, value: data.mouseSensitivity ?? 0.05 },
      {
        key: SettingKey.TIMELINE_STYLE, value: data.timeLineStyle ?? {
          top: {
            width: 1.5,
            color: "rgba(0, 0, 0, 0.38)"
          },
          bottom: {
            day: {
              width: 1.5,
              color: "rgba(0, 0, 0, 0.38)"
            },
            other: {
              width: 0.5,
              color: "rgba(187,187,187,0.75)"
            }
          }
        } as TimeLineStyleSetting
      },
      { key: SettingKey.ROW_FIXED_SCALE_ACTIVITY_DISPLAY_RANGE, value: { from: 0, to: 1 } },
      { key: SettingKey.ROW_FIXED_SCALE, value: { min: data.chart!.fixedScaleFromValue ?? 0, max: data.chart!.fixedScaleToValue ?? 3 } },
      { key: SettingKey.CHART_NEGATIVE_BOUNDARY_VALUE, value: data.chart!.negativeBoundaryValue ?? 0 },
      // { key: SettingKey.LINE_MANAGER, value: "EnhancedAutoLineManger" },
      { key: SettingKey.LINE_MANAGER, value: "ResourceLineManger" },
      { key: SettingKey.ROW_CHART_SCALE_PROVIDER, value: "ResourceFixedRowChartScaleProvider" },
      { key: SettingKey.ROW_EXPAND_BY_DEFAULT, value: true },
      { key: SettingKey.ROW_DEFAULT_HEIGHT, value: data.row.defaultHeight },
      { key: SettingKey.CHART_LINE_WIDTH, value: 3 },
      { key: SettingKey.CHART_SHOW, value: isChartVisible },
      { key: SettingKey.CHART_HEADER_WIDTH, value: data.chart!.headerWidth ?? 100 },
      { key: SettingKey.CHART_POSITIVE_COLOR, value: data.chart!.linePositiveColor ?? "rgb(4,211,0)" },
      { key: SettingKey.CHART_NEGATIVE_COLOR, value: data.chart!.lineNegativeColor ?? "rgba(255,0,0,0.49)" },
      { key: CustomSettingKeys.CHART_SUPPLY_DEMAND_POSITIVE_COLOR, value: data.chart!.positiveColor ?? "rgba(4,211,0,0.49)" },
      { key: CustomSettingKeys.CHART_SUPPLY_DEMAND_NEGATIVE_COLOR, value: data.chart!.negativeColor ?? "rgba(255,0,0,0.49)" },
      { key: SettingKey.CHART_LINE_WIDTH, value: data.chart!.lineWidth ?? 3 },

      { key: CustomSettingKeys.SCENARIO_ID, value: iocContainer.get<string>(AppIocSymbols.$selectedScenario) },
      { key: CustomSettingKeys.LOCAL_STORAGE_PREFIX, value: "gantt#resources#" },
      { key: CustomSettingKeys.FILTER, value: data.filter ?? null },
      { key: CustomSettingKeys.CHART_RESOURCE_TYPE_SCALE_SETTINGS, value: data.chart!.resourceTypeScaleSettings },
      { key: CustomSettingKeys.CHART_RESOURCE_SCALE_SETTINGS, value: data.chart!.resourceScaleSettings },
      { key: CustomSettingKeys.CHART_INCLUDE_RESOURCE_GROUPS, value: data.chart!.includeResourceGroups ?? false },
      { key: CustomSettingKeys.CHART_RESOURCE_TYPE_VISIBILITY_SETTINGS, value: data.chart!.resourceTypeVisibilitySettings ?? false },
      { key: CustomSettingKeys.ACTIVITY_FILL_COLOR_SCHEDULING, value: data.activity!.schedulingActivityFillColor },
      { key: CustomSettingKeys.ACTIVITY_FILL_COLOR_EXECUTION, value: data.activity!.executionActivityFillColor },
      { key: CustomSettingKeys.ACTIVITY_FILL_COLOR_INTERRUPTED, value: data.activity!.interruptedActivityFillColor },
      { key: CustomSettingKeys.ACTIVITY_FILL_COLOR_CALENDAR, value: data.activity!.calendarActivityFillColor },
      { key: CustomSettingKeys.ACTIVITY_FILL_COLOR_CALENDAR_RESOURCE, value: data.activity!.calendarResourceActivityFillColor },
      { key: CustomSettingKeys.ACTIVITY_FILL_COLOR_AVAILABILITY_RULE, value: data.activity!.availabilityRuleActivityFillColor },
      { key: CustomSettingKeys.ACTIVITY_TEXT_COLOR_SCHEDULING, value: data.activity!.schedulingActivityTextColor },
      { key: CustomSettingKeys.ACTIVITY_TEXT_COLOR_EXECUTION, value: data.activity!.executionActivityTextColor },
      { key: CustomSettingKeys.ACTIVITY_TEXT_COLOR_INTERRUPTED, value: data.activity!.interruptedActivityTextColor },
      { key: CustomSettingKeys.ACTIVITY_TEXT_COLOR_CALENDAR, value: data.activity!.calendarActivityTextColor },
      { key: CustomSettingKeys.ACTIVITY_TEXT_COLOR_CALENDAR_RESOURCE, value: data.activity!.calendarResourceActivityTextColor },
      { key: CustomSettingKeys.ACTIVITY_TEXT_COLOR_AVAILABILITY_RULE, value: data.activity!.availabilityRuleActivityTextColor },
      { key: CustomSettingKeys.ACTIVITY_TOOLTIP_DISPLAYED_PROPERTIES, value: data.activity!.activityTooltipDisplayProperties },
      { key: CustomSettingKeys.ACTIVITY_DISPLAYED_PROPERTIES, value: data.activity!.schedulingActivityDisplayProperties },
      { key: CustomSettingKeys.ACTIVITY_CALENDAR_HEIGHT, value: data.activity!.calendarActivityHeight ?? 4 },
      { key: CustomSettingKeys.ROW_EXCLUDE_RESOURCE_GROUPS, value: data.row.excludeResourceGroups ?? false },
      { key: CustomSettingKeys.ACTIVITY_STATUS_SETTINGS, value: iocContainer.get<AppContextDto>(AppIocSymbols.$appContext).statusSettings ?? null },
      { key: CustomSettingKeys.STEP_STATUS_COLOR_SETTINGS, value: iocContainer.get<AppContextDto>(AppIocSymbols.$appContext).stepStatusSettings ?? null },
      { key: CustomSettingKeys.LAYERS, value: layerSettings },
      { key: CustomSettingKeys.MEASUREMENT_UNIT_TRANSLATIONS, value: provideMeasurementUnitTranslations() },
      { key: CustomSettingKeys.TAGS, value: useTagsStore().tags },
      { key: CustomSettingKeys.DND_FILTER_ROWS, value: data.dnD.filterRows ?? true }
    ]);

    const timeSectionAggregation = ganttSettings.getSetting<GanttTimeSectionAggregation>(CustomSettingKeys.TIME_SECTION_AGGREGATION);
    if (!!timeSectionAggregation && +timeSectionAggregation > 0) {
      ganttSettings.setSetting({ key: SettingKey.ROW_CHART_SCALE_PROVIDER, value: "CalculatedRowChartScaleProvider" });
    }

    return;
  }
} as IGanttSettingsLoader;

export function rowFactoryIocExtension(container: interfaces.Container) {
  container.bind<DemandSupplyScaleRowInfoTemplateCellCanvas<any>>(DemandSupplyScaleRowInfoTemplateCellCanvas<any>).to(DemandSupplyScaleRowInfoTemplateCellCanvas).inSingletonScope();

  const layerManager = container.get<LayerManager>(IocSymbols.RowLayerManager);

  layerManager.removeRowForegroundSystemLayers(ChartLayer);
  layerManager.addRowForegroundSystemLayers(OverlappingActivityLayer);
  layerManager.addRowForegroundSystemLayers(SupplyDemandChartLayer);

  container.bind<DemandChartLayerRenderer>(DemandChartLayerRenderer.Identifier).to(DemandChartLayerRenderer).inSingletonScope();
  container.onActivation<DemandChartLayerRenderer>(DemandChartLayerRenderer.Identifier, onActivationLifecycle);
  container.onDeactivation<DemandChartLayerRenderer>(DemandChartLayerRenderer.Identifier, onDeactivationLifecycle);

  container.bind<SupplyChartLayerRenderer>(SupplyChartLayerRenderer.Identifier).to(SupplyChartLayerRenderer).inSingletonScope();
  container.onActivation<SupplyChartLayerRenderer>(SupplyChartLayerRenderer.Identifier, onActivationLifecycle);
  container.onDeactivation<SupplyChartLayerRenderer>(SupplyChartLayerRenderer.Identifier, onDeactivationLifecycle);
}

export async function load(container: IocContainer, options: IResourcesGanttOptions) {
  container.bind<ResourceRowFactory>(IocSymbols.RowFactorySymbol).to(ResourceRowFactory).inSingletonScope().onActivation(onActivationLifecycle);

  container.bind<ResourceGanttActivityFactory>(IocSymbols.ActivityFactory).to(ResourceGanttActivityFactory).inSingletonScope().onActivation(onActivationLifecycle);
  container.bind(IocSymbols.GanttSettingsLoader).toConstantValue(settingsLoader);
  container
    .bind<ResourceCapacityChangeNotificationHandler>(ResourceCapacityChangeNotificationHandler.name)
    .to(ResourceCapacityChangeNotificationHandler)
    .inSingletonScope()
    .onActivation(onActivationLifecycle);

  container
    .bind<IRowChartScaleProvider>(IocSymbols.RowChartScaleProviderClass)
    .toConstructor(ResourceFixedRowChartScaleProvider)
    .whenTargetNamed("ResourceFixedRowChartScaleProvider");

  container.bind<NoteHandler>(NoteHandler).to(NoteHandler).inSingletonScope().onActivation(onActivationLifecycle);

  const activityRendererRegistry = await container.getAsync<ActivityRendererRegistry>(ActivityRendererRegistry);
  activityRendererRegistry.registerActivityRenderer(DemandSchedulingResourceCapacityActivity, SchedulingActivityRenderer);
  activityRendererRegistry.registerActivityRenderer(WaitingSchedulingResourceCapacityActivity, WaitingActivityRenderer);
  activityRendererRegistry.registerActivityRenderer(DemandExecutionResourceCapacityActivity, ExecutionActivityRenderer);
  activityRendererRegistry.registerActivityRenderer(InterruptionExecutionResourceCapacityActivity, InterruptionActivityRenderer);
  activityRendererRegistry.registerActivityRenderer(CalendarResourceCapacityActivity, CalendarResourceActivityRenderer);
  activityRendererRegistry.registerActivityRenderer(AvailabilityRuleResourceCapacityActivity, AvailabilityRuleActivityRenderer);
  activityRendererRegistry.registerActivityRenderer(NoteActivity, ResourceNoteActivityRenderer);

  const activityTooltipRendererRegistry = await container.getAsync<ActivityTooltipRendererRegistry>(ActivityTooltipRendererRegistry);
  activityTooltipRendererRegistry.registerActivityTooltipRenderer(ResourceCapacityActivity, CustomActivityTooltipRenderer);
  activityTooltipRendererRegistry.registerActivityTooltipRenderer(DemandSchedulingResourceCapacityActivity, CustomActivityTooltipRenderer);
  activityTooltipRendererRegistry.registerActivityTooltipRenderer(WaitingSchedulingResourceCapacityActivity, CustomActivityTooltipRenderer);
  activityTooltipRendererRegistry.registerActivityTooltipRenderer(DemandExecutionResourceCapacityActivity, CustomActivityTooltipRenderer);
  activityTooltipRendererRegistry.registerActivityTooltipRenderer(InterruptionExecutionResourceCapacityActivity, CustomActivityTooltipRenderer);
  // activityTooltipRendererRegistry.registerActivityTooltipRenderer(CalendarResourceCapacityActivity, CustomActivityTooltipRenderer);
  // activityTooltipRendererRegistry.registerActivityTooltipRenderer(AvailabilityRuleResourceCapacityActivity, CustomActivityTooltipRenderer);
  activityTooltipRendererRegistry.registerActivityTooltipRenderer(NoteActivity, NoteActivityTooltipRenderer);
  //activityTooltipRendererRegistry.registerDefaultActivityTooltipRenderer();

  container.bind<ILinesManager<any>>(IocSymbols.LinesManagerClass).toConstructor(EnhancedAutoLineManger).whenTargetNamed("EnhancedAutoLineManger");
  container.bind<ILinesManager<any>>(IocSymbols.LinesManagerClass).toConstructor(ResourceLineManger).whenTargetNamed("ResourceLineManger");

  container.unbind(IocSymbols.RowInfoColumnProvider);
  container.bind<ResourceGanttRowInfoColumnProvider>(IocSymbols.RowInfoColumnProvider).to(ResourceGanttRowInfoColumnProvider).inSingletonScope().onActivation(onActivationLifecycle);

  const infoColumnCellRegistry = await container.getAsync<IRowInfoColumnCellRegistry>(IocSymbols.RowInfoColumnCellRegistry);

  infoColumnCellRegistry.register("text", ResourceRowNameRowInfoTemplateCell);
  infoColumnCellRegistry.register("demand-supply-info", DemandSupplyScaleRowInfoTemplateCellWrapper);
  infoColumnCellRegistry.register("scale", RowScaleRowInfoTemplateCellWrapper);

  container.bind<IContextMenuProvider>(IocSymbols.ContextMenuProvider).to(ContextMenuProvider).inSingletonScope().onActivation(onActivationLifecycle);

  container.bind<SelectRootTaskActivitiesAction>(SelectRootTaskActivitiesAction).to(SelectRootTaskActivitiesAction).inTransientScope().onActivation(onActivationLifecycle);
  container.bind<OpenRelatedTaskAction>(OpenRelatedTaskAction).to(OpenRelatedTaskAction).inTransientScope().onActivation(onActivationLifecycle);
  container.bind<OpenProductCardAction>(OpenProductCardAction).to(OpenProductCardAction).inTransientScope().onActivation(onActivationLifecycle);
  container.bind<DescheduleTaskAction>(DescheduleTaskAction).to(DescheduleTaskAction).inTransientScope().onActivation(onActivationLifecycle);
  container.bind<RecalculateAction>(RecalculateAction).to(RecalculateAction).inTransientScope().onActivation(onActivationLifecycle);

  const layerManager = container.get<LayerManager>(IocSymbols.LayerManager);
  layerManager.addAboveRowContentSystemLayers(ActivityConnectorsLayer);
}
