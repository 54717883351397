<script lang="ts" setup>
import MaterialMovement from "@/views/MaterialMovement.vue";

interface MaterialMovementDialogProps {
  visible: boolean;
  selectedMaterialId?: string | undefined | null;
  selectedPurchaseOrderLineId?: string | undefined | null;
  selectedPurchaseOrderId?: string | undefined | null;
  quantity?: number | undefined | null;
}

const props = defineProps<MaterialMovementDialogProps>();

const emit = defineEmits(["create", "cancel", "update:modelValue"]);

function onCreated() {
  setVisible(false);
  emit("create");
}

function onCancel() {
  setVisible(false);
  emit("cancel");
}

function setVisible(visible: boolean) {
  emit("update:modelValue", visible);
}
</script>

<template>
  <v-dialog :model-value="visible" content-class="material-movement-dialog " @update:model-value="setVisible($event)">
    <v-card class="align-center bg-transparent" elevation="0">
      <material-movement
        :embedded="true" :selected-material-id="selectedMaterialId" :selected-purchase-order-line-id="selectedPurchaseOrderLineId"
        :selected-purchase-order-id="selectedPurchaseOrderId" class="align-center"
        :quantity="quantity"
        @cancel="onCancel" @created="onCreated" />
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
.material-movement-dialog {
  width: 60vw !important;
}

@media screen and (max-width: 1900px) {
  .material-movement-dialog {
    width: 100vw !important;
  }
}

@media screen and (max-width: 1450px) {
  .material-movement-dialog {
    width: 100vw !important;
  }
}
</style>
