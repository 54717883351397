import { v4 as uuid } from "uuid";
import { useToast } from "vue-toastification";
import { defineStore } from "pinia";
import { ToastOptions } from "vue-toastification/dist/types/types";
import ToastNotification from "@/components/Snackbars/ToastNotification.vue";

const toast = useToast();

interface State {
  snackbars: SnackbarVm[];
}

export const useSnackbarsStore = defineStore("snackbars", {
  state: (): State => ({
    snackbars: []
  }),
  actions: {
    createSnackbar(command: CreateSnackbarCommand) {
      const snackbar = {
        ...command,
        id: command.id ?? uuid()
      };
      const type = command.type || "info";
      this.snackbars.push(snackbar);
      toast(
        {
          component: ToastNotification,
          props: {
            id: snackbar.id,
            type,
            message: command.message,
            error: command.error
          },
          listeners: {
            onClick(id: string) {
              if (command.onClick) {
                command.onClick(snackbar.id, () => {
                  toast.dismiss(id);
                });
              }
            }
          }
        },
        {
          type,
          position: command.position || "bottom-right",
          timeout: command.timeout || 15000,
          closeOnClick: command.closeable || false,
          onClose: command.onClose
        } as ToastOptions
      );
    },
    removeSnackbars(snackbars: SnackbarVm[]) {
      for (const snackbar of snackbars) {
        const index = this.snackbars.findIndex((x) => x.id === snackbar.id);
        if (index > -1) {
          this.snackbars.splice(index, 1);
        }
      }
    }
  }
});

export interface CreateSnackbarCommand {
  id?: string;
  message: string;
  error?: Error;
  type?: string;
  timeout?: number;
  closeable?: boolean;
  onClick?: (id: string, closeToast: () => void) => void;
  onClose?: () => void;
  position?: string;
}

export interface SnackbarVm {
    id: string;
    message: string;
}
