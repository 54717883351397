<script lang="ts" setup>
import Breadcrumbs from "@/components/Layout/Breadcrumbs.vue";
import ServicesGrid from "@/components/Services/ServicesGrid";
import { onMounted, ref, watch } from "vue";
import { ServiceDto } from "@masta/generated-model";
import { $t } from "@/i18n";
import SplitLayout from "@/components/Layout/SplitLayout.vue";
import CloseCardButton from "@/components/CloseCardButton.vue";
import ServiceDetails from "@/components/Services/ServiceDetails.vue";
import { useModelInstancesStore } from "@/store/ModelInstancesStore";
import { storeToRefs } from "pinia";
import { useSystemStore } from "@/store/SystemStore";
import { ModelInstanceTab } from "@/components/ModelInstances/model-instance-tab";
import { SelectionChangedEvent } from "ag-grid-community";
import { getSelectedRows } from "@/components/Grid/UseGridSelection";
import { useRoute, useRouter } from "vue-router";
import { isDefined } from "@vueuse/core";
import ApiService from "@/services/api";
import { useScenariosStore } from "@/store/ScenariosStore";
import { useErrorsStore } from "@/store/ErrorsStore";

const service = ref<ServiceDto | null>(null);
const splitLayoutRef = ref<typeof SplitLayout | null>(null);
const modelInstanceStore = useModelInstancesStore();
const { schemas } = storeToRefs(modelInstanceStore);
const systemStore = useSystemStore();
const schemaTabs = ref<ModelInstanceTab[]>([]);
const servicesGridRef = ref<typeof ServicesGrid | null>(null);
const router = useRouter();
const route = useRoute();

watch(
  () => service.value,
  (newVal) => {
    if (newVal) {
      showDetails();
    } else {
      closeDetails();
    }
  }
);

watch(
  () => route.query.serviceId,
  async (serviceId, _) => {
    if (isDefined(serviceId)) {
      if (isDefined(service.value) && service.value.id === serviceId) {
        return;
      } else {
        service.value = await fetchService(serviceId as string);
      }
    } else {
      service.value = null;
    }
  },
  { immediate: true }
);

onMounted(async () => {
  await loadTabSchemas();
});

async function fetchService(serviceId: string) {
  const scenariosStore = useScenariosStore();
  const selectedScenario = scenariosStore.selectedScenario;

  if (!selectedScenario) {
    return null;
  }
  try {
    const response = await ApiService.services.getSingle(serviceId, selectedScenario.id);
    return response.data;
  } catch (error) {
    const errorsStore = useErrorsStore();
    errorsStore.handleError(error);
  }
  return null;
}

function createSplitPanelGutterElement(index: number, direction: "horizontal" | "vertical") {
  const gutter = document.createElement("div");
  gutter.classList.add("gutter-orders");
  return gutter;
}

function showDetails() {
  splitLayoutRef.value?.showDetails();
}

function closeDetails() {
  splitLayoutRef.value?.closeDetails();
}

async function loadTabSchemas() {
  await modelInstanceStore.fetchSchemas();
  for (const tab of systemStore.modelInstanceTabs?.services ?? []) {
    const schema = schemas.value.find((x) => x.schemaName === tab.schemaName && x.schemaKey === tab.schemaKey && x.version === tab.schemaVersion);
    if (schema) {
      schemaTabs.value.push({ title: tab.title ?? "", schema, resourceSubTypes: tab.resourceSubTypes });
    }
  }
}

async function serviceUpdated(id: string) {
  servicesGridRef?.value.refreshSingle(id);
}

function onServiceSelected(event: SelectionChangedEvent) {
  const selectedRows = getSelectedRows(event.api);
  if (selectedRows.length === 0) {
    service.value = null;
    return;
  }

  const selectedService = selectedRows[0];

  if (selectedService) {
    service.value = selectedService;

    showDetails();

    const currentQuery = router.currentRoute.value.query;
    const newQuery = Object.assign({}, currentQuery, { serviceId: selectedService.id });
    router.push({ query: newQuery });
  }
}
</script>

<template>
  <split-layout ref="splitLayoutRef" identifier="services" :gutter="createSplitPanelGutterElement">
    <template #master>
      <v-card elevation="0" class="flexcard fill-height">
        <v-card-title>
          <breadcrumbs>{{ $t("services-view-services-title", { $: "Services" }) }}</breadcrumbs>
        </v-card-title>
        <v-card-text class="fill-height">
          <services-grid ref="servicesGridRef" @selection-changed="onServiceSelected" />
        </v-card-text>
      </v-card>
    </template>
    <template #detail>
      <service-details v-if="service" :service="service" :schema-tabs="schemaTabs" @updated="serviceUpdated" @closed="closeDetails" />
      <v-card v-if="!service" elevation="7" class="d-flex flex-column fill-height no-services-card">
        <close-card-button @click="closeDetails"></close-card-button>
        <v-card-text class="fill-height">
          <v-row class="fill-height">
            <v-col cols="12" class="d-flex align-center justify-center">
              <label class="text-secondary text-h6 font-weight-regular">
                {{ $t("services-view-noServiceSelected-title", { $: "No service selected" }) }}
              </label>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </split-layout>
</template>

<style scoped></style>
