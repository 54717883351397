﻿<script lang="ts" setup>
import Breadcrumbs from "@/components/Layout/Breadcrumbs.vue";
import { computed, nextTick, onMounted, ref, watch } from "vue";
import { useModelInstancesStore } from "@/store/ModelInstancesStore";
import { useMaterialsStore } from "@/store/MaterialsStore";
import { storeToRefs } from "pinia";
import { BomGraphDto, BomItemDto, MaterialDto, MaterialSubType, ResourceInfoDto, ResourceSubType, ResourceType } from "@masta/generated-model";
import { $t } from "@/i18n";
import { useRecentMaterialsStore } from "@/views/ProductOverview/RecentProductsStore";
import { useTagsStore } from "@/store/TagsStore";
import ApiService from "@/services/api";
import { useAuthStore } from "@/store/AuthStore";
import { useSystemStore } from "@/store/SystemStore";
import { ModelInstanceTab } from "@/components/ModelInstances/model-instance-tab";
import router from "@/router";
import MaterialDetails from "@/components/Material/MaterialDetails.vue";
import { translateResourceSubType } from "@/composables/translateEnum";
import { FilterGridActionItem } from "@/components/Grid/Filters/FilterGridAction.vue";
import MasterDetailLayout from "@/components/Layout/MasterDetailLayout.vue";
import BomGraphViewer from "@/components/Mermaid/BomGraphViewer.vue";
import SplitPanel from "@/components/Layout/SplitPanel.vue";
import { useStorage } from "@vueuse/core";
import { useRouteQuery } from "@vueuse/router";
import { useRoute } from "vue-router";
import { nameOrBusinessIdOrIdOrNull } from "@/components/ValueCellEditor/CommonFormatters";
import { $authService } from "@/services/AuthService";
import { useScenariosStore } from "@/store/ScenariosStore";

const $emit = defineEmits(["scenarioSelectionChanged"]);

const authStore = useAuthStore();
const { loading: isAuthLoading, isAuthenticated } = storeToRefs(authStore);

const route = useRoute();
const queryProductId = useRouteQuery<string | null | undefined>("product", undefined, {
  router: router,
  route
});
const querySubProductId = useRouteQuery<string | null | undefined>("selected", undefined, {
  router: router,
  route
});

const infos = ref<ResourceInfoDto[]>([]);
const selectedProductInfo = useStorage<ResourceInfoDto | null | undefined>(`selected-resource-${$authService.tenantId}`, null, undefined, {
  listenToStorageChanges: true,
  serializer: {
    read: (v) => (v ? JSON.parse(v) : null),
    write: (v) => JSON.stringify(v)
  }
});
const resourceSubTypes = ref<ResourceSubType[] | undefined>(undefined);
const loading = ref(false);
const search = ref<string | undefined>();

const currentTargetProduct = ref<MaterialDto>();
const currentSubProduct = ref<MaterialDto>();

const schemaTabs = ref<ModelInstanceTab[]>([]);
const graph = ref<BomGraphDto>();
const bomGraphViewerRef = ref<typeof BomGraphViewer>();
const bomGraphViewerHeight = ref<string>("100px");

const materialStore = useMaterialsStore();
const recentMaterialsStore = useRecentMaterialsStore();
const systemStore = useSystemStore();

const modelInstanceStore = useModelInstancesStore();
const { schemas } = storeToRefs(modelInstanceStore);
const tagStore = useTagsStore();
const scenarioStore = useScenariosStore();

const selectedResource = computed(() => currentSubProduct.value ?? currentTargetProduct.value);

watch(search, (newValue) => newValue && querySelections(newValue));

async function querySelections(query: string) {
  loading.value = true;
  const response = await ApiService.resources.findResources(query, scenarioStore.selectedScenario?.id ?? "", [ResourceType.Material], undefined, true);
  infos.value = response.data.resources ?? [];
  loading.value = false;
}

watch(selectedProductInfo, async (newValue) => {
  if (newValue) {
    await changeTargetProduct(newValue.id);
    await changeSubProduct(newValue.id);
    search.value = undefined;
  } else {
    await changeTargetProduct(undefined);
    await changeSubProduct(undefined);
  }
  queryProductId.value = newValue?.id;
  querySubProductId.value = newValue?.id;
});

async function changeTargetProduct(id: string | undefined) {
  if (id) {
    const p = await materialStore.fetchMaterial(id);
    const t = currentTargetProduct.value;
    currentTargetProduct.value = p;

    if (p?.id !== t?.id) {
      if (typeof p !== "undefined") {
        recentMaterialsStore.append(p);
        recentMaterialsStore.store();
      }
      reloadSnapshot();
    }
  } else {
    currentTargetProduct.value = undefined!;
    reloadSnapshot();
  }
}

async function changeSubProduct(id: string | undefined) {
  if (id) {
    const p = await materialStore.fetchMaterial(id);
    const t = currentSubProduct.value;
    currentSubProduct.value = p ?? currentTargetProduct.value;
  } else {
    currentSubProduct.value = undefined!;
  }
}

function reloadSnapshot() {
  graph.value = undefined;
  if (currentTargetProduct.value) {
    loadSnapshot(currentTargetProduct.value);
  }
}

function loadSnapshot(material: MaterialDto) {
  graph.value = undefined;
  ApiService.materials
    .getBomGraph(material.id, material.scenarioId)
    .then((response) => (graph.value = response.data))
    .catch(console.error);
}

onMounted(async () => {
  await recentMaterialsStore.restore();
  await modelInstanceStore.fetchSchemas();
  await tagStore.fetch();

  loadAdditionalTabs();

  if (queryProductId.value) {
    await changeTargetProduct(queryProductId.value);
    if (!selectedProductInfo.value) {
      selectedProductInfo.value = currentTargetProduct.value;
    }
  }
  if (querySubProductId.value) {
    await changeSubProduct(querySubProductId.value);
  }

  if (!queryProductId.value && !querySubProductId.value && selectedProductInfo.value) {
    await changeTargetProduct(selectedProductInfo.value.id);
    await changeSubProduct(selectedProductInfo.value.id);
    queryProductId.value = selectedProductInfo.value.id;
    querySubProductId.value = selectedProductInfo.value.id;
  }

  if (queryProductId.value && selectedProductInfo.value?.id !== queryProductId.value) {
    selectedProductInfo.value = currentTargetProduct.value;
  }
});

function loadAdditionalTabs() {
  schemaTabs.value = [];
  for (const tab of systemStore.modelInstanceTabs?.materials ?? []) {
    const schema = schemas.value.find((x) => x.schemaName === tab.schemaName && x.schemaKey === tab.schemaKey && x.version === tab.schemaVersion);
    if (schema) {
      schemaTabs.value.push({ title: tab.title ?? "", schema, resourceSubTypes: tab.resourceSubTypes });
    }
  }
}

async function modelInstanceUpdated(resourceId: string) {
  const material = await materialStore.fetchMaterial(resourceId);
  if (material) {
    if (currentTargetProduct.value?.id === material.id) {
      currentTargetProduct.value = material;
    }
    if (currentSubProduct.value?.id === material.id) {
      currentSubProduct.value = material;
    }
  }
}

function onTemplateAction() {
  reloadSnapshot();
}

async function onBomDetail(item: BomItemDto) {
  // We set the item as a selected value in the resource picker control
  const bomItemAsResourceInfo = { ...item, type: ResourceType.Material, plannable: false } as ResourceInfoDto;
  selectedProductInfo.value = bomItemAsResourceInfo;

  await changeTargetProduct(item.id);
  await changeSubProduct(item.id);
  queryProductId.value = item.id;
  querySubProductId.value = item.id;
}

const filterGridActionItems: FilterGridActionItem[] = [
  { value: MaterialSubType.RawOrSubcomponent, text: translateResourceSubType(ResourceSubType.MaterialRawOrSubcomponent) },
  { value: MaterialSubType.SemiFinishedOrFinalProduct, text: translateResourceSubType(ResourceSubType.MaterialSemiFinishedOrFinalProduct) }
];

function onResourcePickerFilerActionChanged(filterAction: any) {
  if (filterAction) {
    resourceSubTypes.value = Array.isArray(filterAction) ? filterAction : [filterAction];
  } else {
    resourceSubTypes.value = undefined;
  }
}

async function onBomGraphNodeSelected(id: string) {
  await changeSubProduct(id);
  querySubProductId.value = id;
}

function onSplitPanelSizeChanged(sizes: number[], height?: number, width?: number) {
  if (height) {
    /**
     * The height of the bomGraphViewer is calculated as follows:
     * 1. Get the height of the split panel (in px)
     * 2. Multiply the height of the split panel by the first size in the sizes array (percentage value)
     * 3. Subtract 75px for the card title and the filter grid action
     */
    const newHeight = (sizes[0] * height) / 100 - 75;

    bomGraphViewerHeight.value = `${newHeight}px`;
  }

  nextTick(() => {
    bomGraphViewerRef.value?.reload();
  });
}

function createSplitPanelGutterElement(index: number, direction: "horizontal" | "vertical") {
  const gutter = document.createElement("div");
  gutter.classList.add("gutter-product-overview");
  return gutter;
}
</script>

<template>
  <master-detail-layout scenario-selector>
    <template #master>
      <split-panel
        identifier="product-overview-split-panel"
        direction="vertical"
        :sizes="[25, 75]"
        :min-size="[50, 25]"
        :gutter="createSplitPanelGutterElement"
        @created="onSplitPanelSizeChanged"
        @resized="onSplitPanelSizeChanged"
      >
        <template #panel-1>
          <v-card elevation="4" class="d-flex flex-column fill-height">
            <v-card-title>
              <breadcrumbs>
                {{ $t("productOverview-view-productOverview-title", { $: "Product Card" }) }}
                <v-chip v-if="selectedResource" class="ml-2 resource-chip" variant="elevated" size="default" elevation="0" density="compact" label>
                  {{ nameOrBusinessIdOrIdOrNull(selectedResource) }}
                </v-chip>
              </breadcrumbs>
            </v-card-title>
            <v-card-text class="fill-height pa-0 d-flex flex-column">
              <v-row class="align-start px-6 ma-0">
                <v-col cols="12" :style="{ height: bomGraphViewerHeight }">
                  <bom-graph-viewer
                    ref="bomGraphViewerRef"
                    class="fill-height d-flex"
                    :selected-resource-id="selectedResource?.id"
                    :graph="graph"
                    @selected="onBomGraphNodeSelected"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
        <template #panel-2>
          <div class="fill-height pa-8" style="background-color: #e5e5e5">
            <v-fade-transition>
              <MaterialDetails
                v-if="currentSubProduct"
                :model-value="currentSubProduct"
                :schema-tabs="schemaTabs"
                product-card
                @updated="modelInstanceUpdated"
                @templates-updated="onTemplateAction"
                @bom-detail="onBomDetail"
              />
            </v-fade-transition>
          </div>
        </template>
      </split-panel>
    </template>
  </master-detail-layout>
</template>

<style lang="scss">
.resource-chip {
  background-color: rgb(var(--v-theme-primary), 0.3);
  color: rgb(var(--v-theme-primary));
  min-height: 22px;
}

.overview-container {
  height: 100%;

  .v-window__container {
    height: 100%;
  }

  > .v-card-title {
    padding: 0;
  }

  .master-detail-layout {
    height: 100%;
  }
}

.gutter-product-overview {
  cursor: row-resize;
  background-color: #e5e5e5;
}
</style>
