<script setup lang="ts">
import { $t } from "@/i18n";
import { ServiceDto } from "@masta/generated-model";
import ResourceModelInstances from "@/components/ModelInstances/ResourceModelInstances.vue";
import { ref } from "vue";
import { ModelInstanceTab } from "@/components/ModelInstances/model-instance-tab";
import ResourceCapacityDetails from "@/components/Resources/ResourceCapacityDetails.vue";
import CloseCardButton from "@/components/CloseCardButton.vue";
import Breadcrumbs from "@/components/Layout/Breadcrumbs.vue";

const props = defineProps<{
  service: ServiceDto;
  schemaTabs: ModelInstanceTab[];
}>();

const emit = defineEmits<{
  (e: "updated", id: string): void;
  (e: "closed"): void;
}>();

const currentTab = ref(props.schemaTabs?.[0]?.schema.schemaId || "resourceCapacities");

async function modelInstanceUpdated(resourceId: string) {
  emit("updated", resourceId);
}

function closeDetails() {
  emit("closed");
}
</script>

<template>
  <v-card elevation="7" class="d-flex flex-column fill-height services-card">
    <close-card-button @click="closeDetails"></close-card-button>
    <v-card-title class="tabs">
      <breadcrumbs>{{ service?.name || service?.businessId }}</breadcrumbs>
      <v-tabs
        v-model="currentTab"
        class="task-details text-disabled"
        selected-class="v-slide-group-item--active text-primary bg-white"
        density="compact"
        color="primary"
        show-arrows
      >
        <v-tab v-for="tab in schemaTabs" :key="tab.schema.schemaId" :value="tab.schema.schemaId">
          <v-icon class="pr-2">mdi-tag-multiple</v-icon>
          {{ tab.schema.label }}
        </v-tab>
        <v-tab value="resourceCapacities">
          <v-icon class="pr-2">mdi-treasure-chest</v-icon>
          {{ $t("serviceDetails-view-resourceCapacities-label", { $: "Resource Capacities" }) }}
        </v-tab>
      </v-tabs>
    </v-card-title>
    <v-card-text class="d-flex flex-column pa-0">
      <div class="flex-1-1 d-flex overflow-hidden">
        <v-card class="flex-1-1 d-flex flex-column rounded-0" elevation="6">
          <v-card-text class="flex-1-1 d-flex flex-column pa-0">
            <v-window v-model="currentTab" class="bg-white flex-fill d-block" style="position: absolute; width: 100%; height: 100%">
              <v-window-item v-for="tab in schemaTabs" :key="tab.schema.schemaId" :value="tab.schema.schemaId" class="fill-height pa-5 overflow-y-auto">
                <ResourceModelInstances :resource="service" :schema="tab.schema" @updated="modelInstanceUpdated" />
              </v-window-item>
              <v-window-item eager class="fill-height pa-5 overflow-y-auto" value="resourceCapacities">
                <resource-capacity-details :resource="service" />
              </v-window-item>
            </v-window>
          </v-card-text>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
</template>

<style lang="scss" scoped>
.services-card {
  border: 2px solid rgb(var(--v-theme-primary));
  border-radius: 0;

  .close-card-button {
    position: absolute;
    top: 0px;
    right: 10px;
  }
}
</style>
