<template>
  <split-layout ref="splitLayoutRef" identifier="assets" :gutter="createSplitPanelGutterElement">
    <template #master>
      <v-card elevation="0" class="flexcard fill-height">
        <v-card-title>
          <breadcrumbs>{{ $t("asset-view-assets-title", { $: "Assets" }) }}</breadcrumbs>
        </v-card-title>
        <v-card-text class="fill-height">
          <v-row class="fill-height" no-gutters>
            <v-col cols="12">
              <assets-grid :reference-value="$route.query.assetId?.toString()" @selection-changed="onAssetSelected" @resource-updated="onResourceUpdated" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
    <template #detail>
      <asset-details v-if="asset" :asset="asset" :schema-tabs="schemaTabs" @updated="assetUpdated" @closed="onCloseDetails" />
      <v-card v-if="!asset" elevation="7" class="d-flex flex-column fill-height no-assets-card">
        <close-card-button @click="onCloseDetails"></close-card-button>
        <v-card-text class="fill-height">
          <v-row class="fill-height">
            <v-col cols="12" class="d-flex align-center justify-center">
              <label class="text-secondary text-h6 font-weight-regular">
                {{ $t("asset-view-noAssetSelected-title", { $: "No asset selected" }) }}
              </label>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </split-layout>
</template>

<script lang="ts" setup>
import CloseCardButton from "@/components/CloseCardButton.vue";
import SplitLayout from "@/components/Layout/SplitLayout.vue";
import { $t } from "@/i18n";

/**
 * Called to create gutter element for split panel.
 */
function createSplitPanelGutterElement(index: number, direction: "horizontal" | "vertical") {
  const gutter = document.createElement("div");
  gutter.classList.add("gutter-assets");
  return gutter;
}
</script>

<script lang="ts">
import AssetDetails from "@/components/Assets/AssetDetails.vue";
import AssetsGrid from "@/components/Assets/AssetsGrid.vue";
import { getSelectedRows } from "@/components/Grid/UseGridSelection";
import Breadcrumbs from "@/components/Layout/Breadcrumbs.vue";
import ApiService from "@/services/api";
import { useErrorsStore } from "@/store/ErrorsStore";
import { useModelInstancesStore } from "@/store/ModelInstancesStore";
import { useScenariosStore } from "@/store/ScenariosStore";
import { useSystemStore } from "@/store/SystemStore";
import { AssetDto, ResourceAssignmentType } from "@masta/generated-model";
import { SelectionChangedEvent } from "ag-grid-community";
import { mapActions, mapState } from "pinia";

export default {
  name: "Assets",
  components: {
    AssetDetails,
    Breadcrumbs,
    AssetsGrid
  },
  title: "Assets",
  data() {
    return {
      showDetails: false,
      asset: null,
      refreshGridAction: null,
      schemaTabs: []
    };
  },
  computed: {
    ...mapState(useModelInstancesStore, ["schemas"]),
    ...mapState(useSystemStore, ["modelInstanceTabs"])
  },
  created() {
    this.$watch(
      () => this.$route.query.assetId,
      async (assetId, _) => {
        if (assetId) {
          if (this.asset && assetId === this.asset.id) {
            return;
          } else {
            const asset = await this.fetchAsset(assetId);
            if (asset) {
              this.asset = asset;
            }
          }
        } else {
          this.asset = null;
        }
      },
      { immediate: true }
    );
    this.$watch(
      () => this.asset,
      async (newVal, _) => {
        if (newVal) {
          this.onShowDetails();
        } else {
          this.onCloseDetails();
        }
      }
    );
  },
  mounted() {
    this.loadTabSchemas();
  },
  methods: {
    ...mapActions(useModelInstancesStore, ["fetchSchemas"]),
    async fetchAsset(assetId: string) {
      try {
        const scenariosStore = useScenariosStore();
        const selectedScenario = scenariosStore.selectedScenario;

        if (!selectedScenario) {
          return null;
        }

        const response = await ApiService.assets.getSingle(assetId, selectedScenario.id);
        return response.data;
      } catch (error) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(error);
      }
      return null;
    },
    onShowDetails() {
      this.$refs.splitLayoutRef.showDetails();
    },
    onCloseDetails() {
      this.$refs.splitLayoutRef.closeDetails();
    },
    async onAssetSelected(event: SelectionChangedEvent) {
      const selectedRows = getSelectedRows(event.api);
      if (selectedRows.length === 0) {
        this.asset = null;
        return;
      }

      const selectedAsset = selectedRows[0];

      if (selectedAsset) {
        this.asset = selectedAsset;

        this.onShowDetails();

        const currentQuery = this.$router.currentRoute.value.query;
        const newQuery = Object.assign({}, currentQuery, { assetId: selectedAsset.id });
        await this.$router.push({ query: newQuery });
      }
    },
    async loadTabSchemas() {
      await this.fetchSchemas();
      for (const tab of this.modelInstanceTabs?.assets ?? []) {
        const schema = this.schemas.find((x) => x.schemaName === tab.schemaName && x.schemaKey === tab.schemaKey && x.version === tab.schemaVersion);
        if (schema) {
          this.schemaTabs.push({ title: tab.title ?? "", schema, resourceSubTypes: tab.resourceSubTypes });
        }
      }
    },
    async assetUpdated(id: string) {
      (this.$refs.grid as typeof AssetsGrid).refreshSingle(id);
    },
    onResourceUpdated(resource?: AssetDto) {
      this.asset = resource as any;
    }
  }
};
</script>

<style lang="scss" scoped>
.no-assets-card {
  border: 2px solid rgb(var(--v-theme-primary), 0);
  border-radius: 0;

  .close-card-button {
    position: absolute;
    top: 0px;
    right: 10px;
  }
}
</style>
