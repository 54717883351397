﻿<script lang="ts" setup>
import ResourcesPickerDialog from "@/components/Resources/ResourcesPickerDialog.vue";
import { $t } from "@/i18n";
import ApiService from "@/services/api";
import { ResourceDto, ResourceInfoDto, ResourceSubType, ResourceType } from "@masta/generated-model";
import { computed, nextTick, ref, watch } from "vue";
import { VAutocomplete } from "vuetify/lib/components/index.mjs";
import { useScenariosStore } from "@/store/ScenariosStore";

const modelValue = defineModel<ResourceInfoDto[]>({ required: true });
const props = defineProps<{
  label?: string;
  color?: string | undefined;
  placeholder?: string;
  resourceType?: ResourceType | (() => ResourceType) | ResourceType[];
  resourceSubTypes?: ResourceSubType[];
  fullTextSearch?: boolean;
  readonly: boolean;
  rules?: any[] | undefined;
}>();
const loading = ref(false);
const search = ref<string | undefined>();
const resourceInfos = ref<ResourceInfoDto[]>([]);
const dialog = ref(false);
const scenarioStore = useScenariosStore();

const selectedResourcesToAdd = ref<ResourceDto[]>();

const autocompleteRef = ref<VAutocomplete>();

const noDataText = computed(() => {
  if (!search.value) {
    return $t("autocomplete-control-startTyping-label", { $: "Start typing ..." });
  }
  return $t("autocomplete-control-noData-label", { $: "No data" });
});
const fts = computed(() => props.fullTextSearch ?? false);

watch(search, (newValue) => {
  if (!newValue) {
    resourceInfos.value = [];
    return;
  }
  querySelections(newValue);
});

watch(selectedResourcesToAdd, (newValue) => {
  if (newValue) {
    modelValue.value = [...modelValue.value, ...newValue];
  }
});

async function querySelections(query: string) {
  loading.value = true;

  const resourceType = getResourceType();

  let resourceTypes: ResourceType[] = [];
  if (!resourceType && props.resourceType && Array.isArray(props.resourceType)) {
    resourceTypes = props.resourceType;
  }

  const response = await ApiService.resources.findResources(
    query,
    scenarioStore.selectedScenario?.id ?? "",
    typeof resourceType === "number" ? [resourceType] : resourceTypes,
    props.resourceSubTypes,
    fts.value
  );
  resourceInfos.value = response.data.resources ?? [];
  loading.value = false;
}

function updatedModel() {
  search.value = "";
}

function selectResource() {
  dialog.value = true;
}

function getResourceType(): ResourceType | null | undefined {
  const { resourceType } = props;

  if (typeof resourceType === "function") {
    return resourceType();
  }

  if (typeof resourceType === "number") {
    return resourceType;
  }

  return null;
}

function getFilteredResourceTypes(): ResourceType[] | undefined {
  if (Array.isArray(props.resourceType)) {
    return props.resourceType;
  }

  return undefined;
}

function focus() {
  nextTick(() => autocompleteRef.value?.focus());
}

function hasFocus(): boolean | undefined {
  return autocompleteRef.value?.focused;
}

function onSelectResource() {
  selectResource();
}

function setMenu(menu: boolean) {
  if (autocompleteRef.value) {
    autocompleteRef.value.menu = menu;
  }
}

function getMenu(): boolean | undefined {
  return autocompleteRef.value?.menu;
}

defineExpose({ focus, hasFocus, getMenu, setMenu, onSelectResource });
</script>

<template>
  <div class="resources-picker align-center">
    <v-autocomplete
      ref="autocompleteRef"
      v-model="modelValue"
      v-model:search="search"
      class="flex-1-1-100"
      auto-select-first
      :color="color"
      :label="label"
      :placeholder="placeholder"
      :items="resourceInfos"
      :loading="loading"
      :disabled="props.readonly"
      :rules="props.rules"
      no-filter
      multiple
      chips
      :max-width="100"
      clearable
      small-chips="false"
      closable-chips
      item-title="businessId"
      variant="outlined"
      density="compact"
      hide-details="auto"
      return-object
      hide-no-data
      :no-data-text="noDataText"
      @update:model-value="updatedModel"
    >
      <template #item="{ props, item }">
        <v-list-item v-bind="props" :title="item?.raw?.businessId ?? ''" :subtitle="item?.raw?.name ?? ''"></v-list-item>
      </template>
      <template #chip="{ props, item }">
        <v-chip v-bind="props" :title="item?.raw?.businessId ?? ''" :subtitle="item?.raw?.name ?? ''" class="chip-select text-body-1"></v-chip>
      </template>
      <template #append-inner>
        <v-btn v-if="!props.readonly" variant="plain" icon="mdi-plus-thick" density="compact" :ripple="false" @click.stop="onSelectResource"></v-btn>
      </template>
    </v-autocomplete>
    <resources-picker-dialog
      v-model:selected-resources="selectedResourcesToAdd"
      v-model:dialog="dialog"
      :preselected-resource-ids="modelValue.map((resource) => resource.id)"
      :resource-type="getResourceType()"
      :filtered-resource-types="getFilteredResourceTypes()"
    />
  </div>
</template>

<style scoped lang="scss">
.resources-picker {
  :deep(.v-autocomplete__menu-icon) {
    display: none;
  }
}

.chip-select {
  color: #000000de;

  :deep(.v-chip__underlay) {
    background-color: #593cff;
  }
}
</style>
