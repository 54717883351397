<script setup lang="ts">
import { DocumentModel, DocumentType } from "@/components/Documents/DocumentModel";
import { computed, ref, UnwrapRef, watch } from "vue";
import Online3dViewer from "@/views/ProductOverview/Online3dViewer.vue";
import { $t } from "@/i18n";
import Tags from "@/components/Tags/Tags.vue";


const documents = defineModel<DocumentModel[]>({
  default: []
});

const selectedDocuments = defineModel<UnwrapRef<DocumentModel[]>>("selectedDocuments", {
  default: []
});

const assignedDocuments = defineModel<UnwrapRef<DocumentModel[]>>("assignedDocuments", {
  default: []
});

const props = defineProps<{
  multiple?: boolean;
  editable?: boolean;
  deletable?: boolean;
}>();

const $emit = defineEmits<{
  (event: "edit", value: DocumentModel): void;
  (event: "delete", value: DocumentModel): void;
  (event: "documentDblClicked", value: DocumentModel): void;
}>();

const selectedItemIndex = ref<number>(0);
const selectedThumbnailIndex = ref<number>(-1);

const selectedDocument = computed(() => documents.value[selectedItemIndex.value]);

watch(selectedThumbnailIndex, (newIndex) => {
  const doc = documents.value[newIndex];
  if (doc) {
    selectedDocuments.value = [documents.value[newIndex]];
  } else {
    selectedDocuments.value = [];
  }
}, { immediate: true });

watch(documents, () => {
  if (selectedThumbnailIndex.value === -1 && documents.value.length > 0) {
    selectedThumbnailIndex.value = 0;
  }
}, { immediate: true });

watch(selectedDocuments, () => {
  if (selectedDocuments.value.length > 0) {
    const index = documents.value.findIndex((doc) => doc.id === selectedDocuments.value[0].id);
    if (index !== -1) {
      selectedThumbnailIndex.value = index;
    }
  }
}, { immediate: true });

const clickTimeout = ref<any>(null);

function onItemClick(toggle: () => void, itemIndex: number) {
  if (clickTimeout.value) {
    clearTimeout(clickTimeout.value);
  }

  clickTimeout.value = setTimeout(() => {
    toggle();
    selectedItemIndex.value = itemIndex;
    const document = documents.value[itemIndex];
    if (document) {
      selectedDocuments.value = [document];
    }

    clickTimeout.value = null;
  }, 10);
}

function onItemDblClick(document: DocumentModel) {
  if (clickTimeout.value) {
    clearTimeout(clickTimeout.value);
    clickTimeout.value = null;
  }

  $emit("documentDblClicked", document);
}

function getIconForDocument(item: DocumentModel) {
  if (item.documentType === DocumentType.ImageFile) {
    if (item.fileName.endsWith(".pdf")) {
      return "mdi-file-pdf-box";
    }

    return "mdi-image";
  }

  if (item.documentType === DocumentType.ModelFile) {
    return "mdi-file";
  }

  return "mdi-file";
}

async function openInNewTab(item: DocumentModel) {
  await item.openInNewTab();
}

function editItem(item: DocumentModel) {
  $emit("edit", item);
}

function deleteItem(item: DocumentModel) {
  $emit("delete", item);
}


</script>

<template>
  <v-row no-gutters class="fill-height gallery">
    <v-col v-if="documents.length === 0" cols="12" class="no-items">
      {{ $t("documents-noItems-label", { $: "No items found" }) }}
    </v-col>
    <v-col v-if="documents.length > 0" cols="8" class="fill-height d-flex gallery">
      <v-carousel v-if="documents.length > 0" v-model="selectedItemIndex" height="100%" class="text-center" :show-arrows="false" progress="primary">
        <template #prev="{ props }">
          <v-btn fab color="primary" icon="mdi mdi-chevron-left" @click="props.onClick"></v-btn>
        </template>
        <template #next="{ props }">
          <v-btn fab color="primary" icon="mdi mdi-chevron-right" @dclick="props.onClick"></v-btn>
        </template>
        <v-carousel-item v-for="document in documents" :key="document.id" @dblclick="onItemDblClick(document)">
          <div class="carousel-item-content">
            <div v-if="document.documentType === DocumentType.ModelFile && document.file" class="stp-model">
              <Online3dViewer :file="document.file" />
            </div>
            <v-img v-else-if="document.documentType === DocumentType.ImageFile" :src="document.downloadUrl" max-height="80vh" />
            <v-icon v-else size="80vh">mdi-file</v-icon>
            <div class="file-info py-8 text-white text-h4" :class="{'stp': document.documentType === DocumentType.ModelFile}">
              {{ document.fileName }}
            </div>
          </div>
        </v-carousel-item>
      </v-carousel>
    </v-col>
    <v-col v-if="documents.length > 0" class="d-flex flex-column overflow-hidden fill-height justify-center pa-5" cols="4">
      <!--      <div v-if="selectedDocument" class="flex-1-1">-->
      <!--        <v-row>-->
      <!--          <v-col cols="6">{{ $t("documents-galleryView-fileName-label", { $: "File name:" }) }}</v-col>-->
      <!--          <v-col cols="6">{{ selectedDocument.fileName }}</v-col>-->
      <!--          <v-col cols="6">{{ $t("documents-galleryView-mimeType-label", { $: "Mime type:" }) }}</v-col>-->
      <!--          <v-col cols="6">{{ selectedDocument.mimeType }}</v-col>-->
      <!--          <v-col cols="6">{{ $t("documents-galleryView-createdAt-label", { $: "Created at:" }) }}</v-col>-->
      <!--          <v-col cols="6">{{ $dateTimeFormatter(selectedDocument.createdAt) }}</v-col>-->
      <!--          <v-col cols="6">{{ $t("documents-galleryView-modifiedAt-label", { $: "Modified at:" }) }}</v-col>-->
      <!--          <v-col cols="6">{{ $dateTimeFormatter(selectedDocument.modifiedAt) }}</v-col>-->
      <!--          <v-col cols="6">{{ $t("documents-galleryView-createdBy-label", { $: "Created by:" }) }}</v-col>-->
      <!--          <v-col cols="6">{{ selectedDocument.createdBy }}</v-col>-->
      <!--          <v-col cols="6">{{ $t("documents-galleryView-modifiedBy-label", { $: "Modified by:" }) }}</v-col>-->
      <!--          <v-col cols="6">{{ selectedDocument.modifiedBy }}</v-col>-->
      <!--          <v-col cols="6">{{ $t("documents-galleryView-tags-label", { $: "Tags:" }) }}</v-col>-->
      <!--          <v-col cols="6">-->
      <!--            <tags v-model="selectedDocument.tags" />-->
      <!--          </v-col>-->
      <!--        </v-row>-->
      <!--      </div>-->
      <v-label v-if="documents.length > 0" class="py-4 text-subtitle-1 font-weight-medium">
        {{ $t("mediaGallery-generalInformation-uploadedAttachments-label", { $: "Uploaded Media:" }) }}
      </v-label>
      <v-slide-group
        ref="thumbnailSlideGroupRef"
        v-model="selectedThumbnailIndex"
        :show-arrows="true"
        center-active
        mandatory
        class="d-flex"
        direction="vertical"
        next-icon="mdi-chevron-double-down"
        prev-icon="mdi-chevron-double-up"
        selected-class="thumbnail-active"
        style="max-height: 60vh;"
      >
        <v-slide-group-item v-for="(item, index) in documents" :key="item.id" v-slot="{ toggle, selectedClass }">
          <v-hover v-slot="{ isHovering, props: hoveringProps }">
            <v-card
              elevation="0" rounded="2" :class="[selectedClass]" class="bg-material-design-grey-lighten-5 my-2"
              v-bind="hoveringProps"
              @click="onItemClick(toggle, index)"
              @dblclick="onItemDblClick(item)"
            >
              <div class="d-flex justify-space-between fill-height align-center pa-5" :class="[selectedClass]">
                <div class="revision-number text-caption pt-1">
                  <v-tooltip location="bottom" open-delay="300">
                    <template #activator="{ props }">
                      <v-chip v-bind="props" color="secondary" variant="elevated" size="small">{{ item.revisionNumber }}</v-chip>
                    </template>
                    <span>{{ $t("documents-view-revisionNumber-tooltip", { $: "Revision number" }) }}</span>
                  </v-tooltip>
                </div>
                <div class="d-flex align-center thumbnail-label">
                  <v-icon class="text-subtitle-2" :icon="getIconForDocument(item)"></v-icon>
                  <v-label class="ml-2 text-subtitle-2">
                    {{ item.fileName }}
                  </v-label>
                  <div v-if="item.tags && item.tags.length > 0" class="text-start flex-1-1 pl-8">
                    <tags v-model="item.tags" />
                  </div>
                </div>
                <div class="d-flex fill-height align-center">
                  <v-tooltip location="bottom" open-delay="300">
                    <template #activator="{ props }">
                      <v-fade-transition v-if="editable">
                        <v-btn
                          v-if="isHovering"
                          v-bind="props"
                          class="mx-2"
                          color="primary"
                          icon="mdi-information-outline"
                          density="compact"
                          size="x-small"
                          @click.stop="editItem(item)" />
                      </v-fade-transition>
                    </template>
                    <span>{{ $t("documents-view-itemInfo-tooltip", { $: "Document details" }) }}</span>
                  </v-tooltip>
                  <v-tooltip location="bottom" open-delay="300">
                    <template #activator="{ props }">
                      <v-fade-transition v-if="deletable">
                        <v-btn
                          v-if="isHovering"
                          v-bind="props"
                          class="mx-2"
                          color="error"
                          icon="mdi-trash-can"
                          density="compact"
                          size="x-small"
                          @click.stop="deleteItem(item)" />
                      </v-fade-transition>
                    </template>
                    <span>{{ $t("documents-view-deleteItem-tooltip", { $: "Delete" }) }}</span>
                  </v-tooltip>
                  <v-tooltip location="bottom" open-delay="300">
                    <template #activator="{ props }">
                      <v-fade-transition>
                        <v-btn
                          v-if="isHovering"
                          v-bind="props"
                          class="mx-2"
                          icon="mdi-open-in-new"
                          density="compact"
                          size="x-small"
                          @click.stop="openInNewTab(item)" />
                      </v-fade-transition>
                    </template>
                    <span>{{ $t("documents-view-openItemInNewTab-tooltip", { $: "Open in new tab" }) }}</span>
                  </v-tooltip>
                </div>
              </div>
            </v-card>
          </v-hover>
        </v-slide-group-item>
      </v-slide-group>
    </v-col>
  </v-row>
</template>

<style scoped lang="scss">
.gallery {
  overflow: auto;

  .no-items {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  :deep(.v-window) {
    height: 100% !important;

    .v-window-item {
      height: 100% !important;
    }
  }

  :deep(.v-carousel__controls) {
    top: 0;
  }

  .carousel-item-content {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;

    .file-info {
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: center;
      background-color: rgba(0, 0, 0, 0.8);
    }

    .stp-model {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;

      canvas {
        min-height: 100%;
      }
    }
  }
}

.thumbnail-label {
  flex: 1;
  height: 24px;
}

.thumbnail-active {
  background-color: rgb(var(--v-theme-primary), 0.1);

  .thumbnail-label {
    color: rgb(var(--v-theme-primary)) !important;
  }
}

.revision-number {
  position: absolute;
  top: 12px;
  right: 12px;
}
</style>
