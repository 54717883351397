<script lang="ts" setup>
import { $t } from "@/i18n";

const $emits = defineEmits(["update:modelValue", "confirm", "cancel"]);
const props = defineProps<{
  modelValue: boolean;
  question?: string;
  deleteButtonText?: string;
}>();

function onBtnConfirm() {
  $emits("update:modelValue", false);
  $emits("confirm");
}

function onBtnCancel() {
  $emits("update:modelValue", false);
  $emits("cancel");
}
</script>

<template>
  <v-dialog :model-value="modelValue" max-width="600px" @update:model-value="$emits('update:modelValue', false)">
    <v-card min-width="300px">
      <v-card-title class="text-h4">
        <v-icon class="pr-2">mdi-delete</v-icon>
        {{ question || $t("deleteConfirmationModalDialog-question-label", { $: "Do you want to delete this record?" }) }}
      </v-card-title>
      <v-card-actions>
        <v-row>
          <v-col cols="6">
            <v-btn color="secondary" variant="elevated" block @click="onBtnCancel">{{ $t("deleteConfirmationModalDialog-cancel-label", { $: "Cancel" }) }}</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn color="primary" variant="elevated" block @click="onBtnConfirm">
              {{ deleteButtonText || $t("deleteConfirmationModalDialog-delete-label", { $: "Delete" }) }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped></style>
