<script setup lang="ts">
import RecurrenceRuleEditor from "@/components/RecurrenceRule/RecurrenceRuleEditor.vue";
import { $t } from "@/i18n";
import { Availability, AvailabilityRuleDataDto } from "@masta/generated-model";
import { computed, ref, watch } from "vue";
import ApiService from "@/services/api";
import { getEnumTitleValuePairs } from "@/composables/enumHelpers";
import { translateAvailability } from "@/composables/translateEnum";

const props = defineProps<{
  rule: AvailabilityRuleDataDto;
}>();
const emit = defineEmits<{
  (e: "updated", id: string): void;
  (e: "cancelled"): void;
}>();

const rruleValue = ref<string>(props.rule.recurrence);
const startValue = ref<string>(props.rule.start);
const endValue = ref<string>(props.rule.end);
const businessId = ref<string>(props.rule.businessId);
const name = ref<string>(props.rule.name);
const color = ref<string>(props.rule.color);
const availability = ref<Availability>(props.rule.availability);
const availabilityType = getEnumTitleValuePairs(Availability, translateAvailability);
const editMode = ref<boolean>(false);

const disabled = computed(() => !editMode.value);

watch(
  () => props.rule,
  () => {
    initializeFields();
  },
  { immediate: true }
);

function initializeFields() {
  rruleValue.value = props.rule.recurrence;
  startValue.value = props.rule.start;
  endValue.value = props.rule.end;
  businessId.value = props.rule.businessId;
  name.value = props.rule.name;
  color.value = props.rule.color;
  availability.value = props.rule.availability;

  editMode.value = !props.rule.id;
}

// function updateRRuleValue(value: string) {
//   rruleValue.value = value;
// }
//
// function updateStartValue(value: Date) {
//   startValue.value = value;
// }
//
// function updateEndValue(value: Date) {
//   endValue.value = value;
// }

async function onSaveAction() {
  editMode.value = false;
  let id = props.rule.id;
  if (id) {
    await updateRule();
  } else {
    id = await createRule();
  }
  emit("updated", id);
}

function updateRule() {
  return ApiService.availabilityRules.update({
    id: props.rule.id,
    scenarioId: props.rule.scenarioId,
    businessId: businessId.value,
    name: name.value,
    recurrence: rruleValue.value,
    start: startValue.value,
    end: endValue.value,
    color: color.value,
    availability: availability.value
  });
}

async function createRule() {
  const response = await ApiService.availabilityRules.create({
    businessId: businessId.value,
    scenarioId: props.rule.scenarioId,
    name: name.value,
    recurrence: rruleValue.value,
    start: startValue.value,
    end: endValue.value,
    color: color.value,
    availability: availability.value
  });
  return response.data;
}

function onCancelAction() {
  console.log("cancel action");
  emit("cancelled");
  editMode.value = false;
  initializeFields();
}

function onEditAction() {
  editMode.value = true;
}
</script>

<template>
  <v-card max-width="850px" height="100%" class="reference-picker-card" location="center center">
    <v-card-title>
      <v-row>
        <v-col cols="12">
          <v-tooltip v-if="disabled" location="bottom" open-delay="300">
            <template #activator="{ props }">
              <div class="d-inline-flex pr-4">
                <v-btn size="small" v-bind="props" variant="tonal" density="compact" @click="onEditAction">
                  <v-icon icon="mdi-pencil" class="pr-4" />
                  {{ $t("availabilityRule-edit-edit-action", { $: "Edit" }) }}
                </v-btn>
              </div>
            </template>
            <span>{{ $t("availabilityRule-edit-edit-action", { $: "Edit" }) }}</span>
          </v-tooltip>
          <v-tooltip v-if="!disabled" location="bottom" open-delay="300">
            <template #activator="{ props }">
              <div class="d-inline-flex pr-4">
                <v-btn size="small" v-bind="props" variant="tonal" density="compact" @click="onCancelAction">
                  <v-icon icon="mdi-cancel" class="pr-4" />
                  {{ $t("availabilityRule-edit-cancel-action", { $: "Cancel" }) }}
                </v-btn>
              </div>
            </template>
            <span>{{ $t("availabilityRule-edit-cancel-action", { $: "Cancel" }) }}</span>
          </v-tooltip>
          <v-tooltip v-if="!disabled" location="bottom" open-delay="300">
            <template #activator="{ props }">
              <div class="d-inline-flex pr-4">
                <v-btn size="small" v-bind="props" color="primary" density="compact" @click="onSaveAction">
                  <v-icon class="pr-4" icon="mdi-content-save" />
                  {{ $t("availabilityRule-edit-save-action", { $: "Save" }) }}
                </v-btn>
              </div>
            </template>
            <span>{{ $t("availabilityRule-edit-save-action", { $: "Save" }) }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text class="px-0">
      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="businessId"
            label="Business Id"
            variant="outlined"
            density="compact"
            hide-details
            required
            clearable
            class="w-100"
            :disabled="disabled"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field v-model="name" label="Name" variant="outlined" density="compact" hide-details clearable class="w-100" :disabled="disabled"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-select
            v-model="availability"
            :disabled="disabled"
            :items="availabilityType"
            variant="outlined"
            density="compact"
            hide-details="auto"
            :label="$t('availabilityRule-edit-availability-label', { $: 'Availability' })"
          />
        </v-col>
        <v-col cols="6">
          <v-menu :close-on-content-click="false" :disabled="disabled">
            <template #activator="{ props }">
              <v-text-field
                :model-value="color"
                v-bind="props"
                :label="$t('availabilityRule-edit-color-label', { $: 'Color' })"
                readonly
                variant="outlined"
                density="compact"
                hide-details="auto"
              >
                <template #prepend-inner>
                  <v-sheet :style="{ backgroundColor: color }" width="1em" height="1em" rounded></v-sheet>
                </template>
              </v-text-field>
            </template>
            <v-color-picker v-model="color" mode="hex" show-swatches hide-canvas hide-sliders hide-inputs :disabled="disabled" />
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <RecurrenceRuleEditor ref="rruleEditorRef" :rrule="rruleValue" :start="startValue" :end="endValue" :disabled="disabled" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<style scoped lang="scss"></style>
