<script setup lang="ts">
import {
  AssignModelInstanceToCostCatalogueItemCommand,
  CostCatalogueItemDto,
  CreateDocumentForContextCommand,
  CreateNewDocumentRevisionWithNewAttachmentCommand,
  DocumentDto,
  UpdateDocumentCommand
} from "@masta/generated-model";
import { ref, toRef, watch } from "vue";
import { DocumentProvider, ModelSchemaName } from "@/components/Documents/IDocumentProvider";
import { useScenariosStore } from "@/store/ScenariosStore";
import ApiService from "@/services/api";
import { DocumentError, DocumentModel } from "@/components/Documents/DocumentModel";
import { useDocumentsStore } from "@/store/DocumentsStore";
import { v4 as uuidv4 } from "uuid";
import DocumentsManager from "@/components/Documents/DocumentsManager.vue";
import { DocumentManager } from "@/components/Documents/DocumentManager";

const props = defineProps<{ costCatalogueItem: CostCatalogueItemDto | null | undefined; profileName: ModelSchemaName }>();
const costCatalogueItem = toRef(props, "costCatalogueItem");

const documentsStore = useDocumentsStore();
const scenariosStore = useScenariosStore();

const uploading = ref(false);
const uploadMessage = ref("");

class CostCatalogueAssignedDocumentProvider extends DocumentProvider {
  profileName = props.profileName;

  constructor() {
    super();

    watch(
      () => props.costCatalogueItem,
      async () => {
        this.qrCodeOptions.value = {
          profileName: this.profileName,
          orderId: props.costCatalogueItem?.id
        };
      },
      {
        immediate: true
      }
    );
  }

  async getAll(): Promise<DocumentDto[]> {
    if (!props.costCatalogueItem?.id) return [];
    const scenarioStore = useScenariosStore();
    const { data } = await ApiService.documents.getDocumentsAssignedToCostCatalogueItemForContext(
      this.profileName,
      props.costCatalogueItem.id,
      scenarioStore.selectedScenario?.id ?? ""
    );
    return data.documentDtos;
  }
}

class CostCatalogueDocumentManager extends DocumentManager {
  profileName = props.profileName;

  constructor(public assignmentDocumentProvider: DocumentProvider) {
    super();
  }

  async assignDocument(doc: DocumentModel): Promise<void> {
    if (!costCatalogueItem.value) throw new DocumentError("CostCatalogue is not set");

    await ApiService.costCatalogue.assignModelInstance({
      costCatalogueItemId: costCatalogueItem.value.id,
      costCatalogueItemBusinessId: costCatalogueItem.value.businessId,
      scenarioId: costCatalogueItem.value.scenarioId,
      modelInstanceId: doc.dto.id,
      modelInstanceBusinessId: doc.dto.businessId
    } as Partial<AssignModelInstanceToCostCatalogueItemCommand> as AssignModelInstanceToCostCatalogueItemCommand);
  }

  async unassignDocument(doc: DocumentModel): Promise<void> {
    if (!costCatalogueItem.value) throw new DocumentError("CostCatalogue is not set");

    await documentsStore.deassignCostCatalogueItemDocument(doc.dto.id, costCatalogueItem.value.id);
  }

  async uploadDocument(file: File, doAssignment: boolean): Promise<void> {
    const selectedScenario = scenariosStore.selectedScenario;

    if (!selectedScenario) throw new DocumentError("Scenario is not set");

    const modelInstanceId = await documentsStore.createDocumentWithAttachment({
      scenarioId: selectedScenario.id,
      businessId: uuidv4(),
      profileName: this.assignmentDocumentProvider.profileName,
      tags: [],
      file: file,
      costCatalogueItemId: doAssignment ? costCatalogueItem.value?.id : null
    } as Partial<CreateDocumentForContextCommand> as CreateDocumentForContextCommand);
  }

  async deleteDocument(doc: DocumentModel): Promise<void> {
    if (!costCatalogueItem.value) throw new DocumentError("CostCatalogue is not set");

    await documentsStore.deleteCostCatalogueItemDocument(doc.dto.id, doc.dto.revisionNumber, costCatalogueItem.value.id);
  }

  async saveDocument(doc: DocumentModel): Promise<void> {
    if (!costCatalogueItem.value) throw new DocumentError("CostCatalogue is not set");

    await documentsStore.updateDocumentWithAttachment({
      id: doc.dto.id,
      description: doc.description,
      revisionNumber: doc.dto.revisionNumber,
      tags: doc.tags,
      businessId: doc.businessId
    } as Partial<UpdateDocumentCommand> as UpdateDocumentCommand);

    await this.assignmentDocumentProvider.refresh();
  }

  async createNewRevision(file: File, doc: DocumentModel, doAssignment: boolean): Promise<void> {
    const selectedScenario = scenariosStore.selectedScenario;

    if (!selectedScenario) throw new DocumentError("Scenario is not set");

    await documentsStore.createNewRevisionWithNewAttachment({ file: file, id: doc.dto.id } as CreateNewDocumentRevisionWithNewAttachmentCommand);
    if (doAssignment) {
      if (!costCatalogueItem.value) throw new DocumentError("CostCatalogue is not set");

      await ApiService.costCatalogue.assignModelInstance({
        scenarioId: selectedScenario.id,
        costCatalogueItemId: costCatalogueItem.value.id,
        modelInstanceId: doc.dto.id
      } as AssignModelInstanceToCostCatalogueItemCommand);
    }
  }
}

const assignedDocumentsProvider = new CostCatalogueAssignedDocumentProvider();
const manager = new CostCatalogueDocumentManager(assignedDocumentsProvider);

watch(
  costCatalogueItem,
  async () => {
    await assignedDocumentsProvider.refresh();
  },
  { immediate: true }
);

function uploadStart() {
  uploading.value = true;
}

function uploadEnd() {
  uploading.value = false;
}

function handleUploadStage(stage: string) {
  uploadMessage.value = stage;
}
</script>

<template>
  <v-overlay persistent :model-value="uploading" class="d-flex align-center justify-center">
    <v-progress-circular color="primary" indeterminate size="64"></v-progress-circular>
    <div class="mt-4">{{ uploadMessage }}</div>
  </v-overlay>
  <documents-manager :manager="manager" @upload-start="uploadStart" @upload-end="uploadEnd" @upload-stage="handleUploadStage" />
</template>

<style scoped lang="scss"></style>
