<script setup lang="ts">
import { $t } from "@/i18n";
import { DocumentsBrowserViewMode } from "@/components/Documents/DocumentsBrowserViewMode";
import { computed, ref, useSlots, watch } from "vue";
import { DocumentSort } from "@/components/Documents/DocumentModel";
import { enumToEditorEntries, translateEditorEntries } from "@/components/Grid/ColumnTypes";
import { ISelectEnumValueEntry } from "@/components/Grid/CellEditors/IEnumValueSelectCellEditorParams";

const mode = defineModel<DocumentsBrowserViewMode>("mode", {
  default: DocumentsBrowserViewMode.Grid,
  required: true
});

const documentSort = defineModel<DocumentSort>("documentSort", {
  default: DocumentSort.FileNameAsc,
  required: false,
  local: true
});

const searchText = defineModel<string | null | undefined>("searchText", {
  default: "",
  required: false,
  local: true
});

const props = defineProps<{
  toggleSidebar?: () => void;
}>();

const slots = useSlots();

const isGridMode = computed(() => mode.value === DocumentsBrowserViewMode.Grid);
const isListMode = computed(() => mode.value === DocumentsBrowserViewMode.List);
const isGalleryMode = computed(() => mode.value === DocumentsBrowserViewMode.Gallery);

// $t("documents-layout-view-documentSort-FileNameAsc-label", { $: "File name ASC" })
// $t("documents-layout-view-documentSort-FileNameDesc-label", { $: "File name DESC" })
// $t("documents-layout-view-documentSort-CreatedAtAsc-label", { $: "Created at ASC" })
// $t("documents-layout-view-documentSort-CreatedAtDesc-label", { $: "Created at DESC" })
// $t("documents-layout-view-documentSort-ModifiedAtAsc-label", { $: "Modified at ASC" })
// $t("documents-layout-view-documentSort-ModifiedAtDesc-label", { $: "Modified at DESC" })
// $t("documents-layout-view-documentSort-DescriptionAsc-label", { $: "Description ASC" })
// $t("documents-layout-view-documentSort-DescriptionDesc-label", { $: "Description DESC" })
// $t("documents-layout-view-documentSort-TagsAsc-label", { $: "Tags ASC" })
// $t("documents-layout-view-documentSort-TagsDesc-label", { $: "Tags DESC" })
function translateDocumentSort(t: DocumentSort): string {
  const key = DocumentSort[t];
  return $t(`documents-layout-view-documentSort-${key}-label`);
}

const documentSorts = ref<ISelectEnumValueEntry[]>(
  translateEditorEntries(enumToEditorEntries(DocumentSort), translateDocumentSort).sort((a, b) => a.value - b.value)
);


const showSearch = ref<boolean>(true);
const searchInputRef = ref<HTMLInputElement | undefined>(undefined);

watch(showSearch, (show) => {
  if (show) {
    setTimeout(() => {
      searchInputRef.value?.focus();
    }, 100);
  }
});
</script>

<template>
  <v-row no-gutters class="actions-row align-center">
    <slot>
      <div class="d-inline-flex" />
    </slot>
    <v-spacer />
    <v-slide-y-transition>
      <div v-if="mode === DocumentsBrowserViewMode.List && toggleSidebar" class="d-inline-flex">
        <v-tooltip location="bottom" open-delay="300">
          <template #activator="{ props }">
            <div class="d-inline-flex">
              <v-btn
                id="btn-sidebar-action"
                density="compact"
                variant="text"
                size="small"
                icon="mdi-cog"
                v-bind="props"
                @click="toggleSidebar"
              ></v-btn>
            </div>
          </template>
          <span>{{ $t("list-menu-toggleSidebar-action", { $: "Show/hide sidebar" }) }}</span>
        </v-tooltip>
        <div class="d-inline-flex text-secondary font-weight-bold pl-4 pr-6 h-separator">
          <v-divider vertical />
        </div>
      </div>
    </v-slide-y-transition>

    <div v-if="showSearch" class="d-inline-flex">
      <v-text-field
        ref="searchInputRef"
        v-model="searchText"
        :placeholder="$t('documents-layout-view-search-placeholder', { $: 'Search files...' })"
        color="primary"
        variant="outlined"
        density="compact"
        hide-details
        single-line
        clearable
        prepend-inner-icon="mdi-magnify"
        data-cy="document-search-input"
        class="documents-search-input"
      />
    </div>
    <!--    <v-tooltip location="bottom" open-delay="300">-->
    <!--      <template #activator="{ props }">-->
    <!--        <div class="d-inline-flex">-->
    <!--          <v-btn-->
    <!--            id="btn-search-files-action"-->
    <!--            density="compact"-->
    <!--            variant="text"-->
    <!--            size="small"-->
    <!--            icon="mdi-magnify"-->
    <!--            v-bind="props"-->
    <!--            @click="showSearch = !showSearch"-->
    <!--          ></v-btn>-->
    <!--        </div>-->
    <!--      </template>-->
    <!--      <span>{{ $t("'documents-layout-view-toggleSearch-action", { $: "Show/hide search" }) }}</span>-->
    <!--    </v-tooltip>-->
    <v-tooltip location="bottom" open-delay="100">
      <template #activator="tooltipActivator">
        <div class="d-inline-flex">
          <v-menu location="bottom">
            <template #activator="{ props }">
              <v-btn
                id="btn-search-files-action"
                density="compact"
                variant="text"
                size="small"
                icon="mdi-sort"
                v-bind="{...props, ...tooltipActivator.props}"
              ></v-btn>
            </template>

            <v-list>
              <v-list-item
                v-for="(item, index) in documentSorts"
                :key="index"
                :active="documentSort === item.value"
                @click="documentSort = item.value"
              >
                <v-list-item-title>{{ item.key }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
      <span>{{ $t("documents-layout-view-documentSortBy-label", { $: "Sort by " }) }}&nbsp;</span>
      <span>{{ documentSorts.find((s) => s.value === documentSort)?.key }}</span>
    </v-tooltip>
    <div class="d-inline-flex text-secondary font-weight-bold pl-4 pr-6 h-separator">
      <v-divider vertical />
    </div>
    <v-tooltip location="bottom" open-delay="600">
      <template #activator="{ props }">
        <div class="d-inline-flex">
          <v-btn
            id="btn-sidebar-action"
            density="compact"
            variant="text"
            size="small"
            :icon="isGridMode ? 'mdi-view-grid' : 'mdi-view-grid-outline'"
            :active="isGridMode"
            v-bind="props"
            @click="mode = DocumentsBrowserViewMode.Grid"
          ></v-btn>
          <v-btn
            id="btn-sidebar-action"
            density="compact"
            variant="text"
            size="small"
            :icon="isListMode ? 'mdi-view-list' : 'mdi-view-list-outline'"
            :active="isListMode"
            v-bind="props"
            @click="mode = DocumentsBrowserViewMode.List"
          ></v-btn>
          <v-btn
            id="btn-sidebar-action"
            density="compact"
            variant="text"
            size="small"
            :icon="isGalleryMode ? 'mdi-view-gallery' : 'mdi-view-gallery-outline'"
            :active="isGalleryMode"
            v-bind="props"
            @click="mode = DocumentsBrowserViewMode.Gallery"
          ></v-btn>
        </div>
      </template>
      <span>{{ $t("documents-layout-view-action", { $: "Show items as icons, in a list, in columns or in gallery" }) }}</span>
    </v-tooltip>
  </v-row>
</template>

<style scoped lang="scss">
.h-separator {
  height: 26px;
}

.actions-row {
  display: flex;
  flex: 0;
  flex-wrap: wrap;
  padding-bottom: .5em;
}

.documents-search-input {
  min-width: 10vw;

  :deep(.v-field__field) {
    input {
      padding: 0;
      min-height: calc(max(var(--v-input-control-height, 36px), 18px + var(--v-field-input-padding-top) + var(--v-field-input-padding-bottom) + var(--v-input-chips-margin-bottom)) - var(--v-input-chips-margin-top) - var(--v-input-chips-margin-bottom));
    }
  }
}
</style>
