﻿<script lang="ts" setup>
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { ref, watch } from "vue";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import { asIsoFormattedString, asJiraFormattedString } from "@masta/shared";
import { useTheme } from "vuetify";
import { $t } from "@/i18n";
import { ModelSchemaDto, ProductTemplateDto, StepType, TemplateStatus } from "@masta/generated-model";
import { durationRequiredRule, requiredRule } from "@/components/ValueCellEditor/CommonValidationRules";
import { RowNode, ValueFormatterParams, ValueParserParams } from "ag-grid-community";
import { translateStepType } from "@/composables/translateEnum";
import { IEnumValueSelectCellEditorParams } from "@/components/Grid/CellEditors/IEnumValueSelectCellEditorParams";
import { enumToEditorEntries } from "@/components/Grid/ColumnTypes";
import { ProductTemplateDetailsServerSideDataSource } from "@/components/ProductTemplates/ProductTemplateDetailsServerSideDataSource";
import SplitPanel from "@/components/Layout/SplitPanel.vue";
import ProductTemplateStepDetails from "@/components/ProductTemplates/ProductTemplateStepDetails.vue";
import TaskModelInstances from "@/components/ModelInstances/TaskModelInstances.vue";
import { useModelInstancesStore } from "@/store/ModelInstancesStore";
import { ModelInstanceTab } from "@/components/ModelInstances/model-instance-tab";

const theme = useTheme();
const gridWrapperRef = ref<GridWrapperComponent>();
const serverSideDataSource = ref(createServerSideDataSource());
const productTemplateStepDetailsRef = ref<typeof ProductTemplateStepDetails>();
const miStore = useModelInstancesStore();
const schema = miStore.schemas.find((x: ModelSchemaDto) => x.schemaName === "Quality Check")!;

watch(
  () => props.task,
  async () => {
    // gridWrapperRef.value?.gridApi?.refreshServerSide();
    gridWrapperRef.value?.gridApi?.refreshServerSide({ purge: true });
    if (productTemplateStepDetailsRef.value) {
      const nodes = gridWrapperRef.value?.gridApi.getSelectedNodes();
      await productTemplateStepDetailsRef.value.loadStepDetails(nodes ? nodes[0]?.data : undefined);
    }
  },
  { immediate: true }
);

function createServerSideDataSource() {
  return new ProductTemplateDetailsServerSideDataSource("product-template-details", getCurrentProductTemplateId);
}

const DEFAULT_CREATE_VALUE = {
  stepType: StepType.Production,
  name: () => resolveLongestCommonPrefix("name"), // generated
  quantityPerTime: 1,
  position: () => resolveNextPosition() // generated
};

const selectedTab = ref("steps");

const props = defineProps<{
  task: any;
  schemaTabs: ModelInstanceTab[];
}>();

const defaultColDef = ref({
  sortable: true,
  resizable: true,
  headerValueGetter: (_: any) => $t("productTemplateDetails-list-name-label", { $: "Name" })
});

function getCurrentProductTemplateId() {
  return props.task ? props.task.id : null;
}

function getModelInstances(instances: any[]) {
  return instances ? instances.map((x: any) => x.businessId).join() : "";
}

function resolveNextPosition(): number {
  try {
    const gridApi = gridWrapperRef.value?.gridApi;
    if (gridApi) {
      const positions: number[] = [...Array(gridApi.getDisplayedRowCount()).keys()]
        .map((i) => gridApi.getDisplayedRowAtIndex(i))
        .filter((row) => !row?.expanded ?? false) // filter expanded rows
        .map((row) => row?.data["position"])
        .map((x) => Number(x))
        .sort((a, b) => a - b);
      const lastPosition = positions.slice(-1)[0] ?? 0;
      return lastPosition + (positions.length > 1 ? lastPosition - positions.slice(-2)[0] : 10);
    }
  } catch (e) {
    console.debug(e);
  }

  // fallback computation - not perfect, when specs are visible it counts them as additional row
  const nextRowOrdinal = gridWrapperRef.value ? gridWrapperRef.value.gridApi.getDisplayedRowCount() + 1 : 1;
  return nextRowOrdinal * 10;
}

function asSequence(length: number) {
  return [...Array(length).keys()];
}

function resolveLongestCommonPrefix(columnName: string): string {
  if (gridWrapperRef.value) {
    const gridApi = gridWrapperRef.value.gridApi;
    const columnValues: string[] = asSequence(gridApi.getDisplayedRowCount())
      .map((i) => gridApi.getDisplayedRowAtIndex(i))
      .map((row) => row?.data[columnName]);
    let [prefix] = [...columnValues].sort((a, b) => a.length - b.length);
    while (prefix && !columnValues.every((str) => str.startsWith(prefix))) {
      prefix = prefix.slice(0, -1);
    }
    return prefix;
  }
  return "";
}

function onPrepareColumns(columnDefs: any) {
  columnDefs.value = [
    {
      field: "position",
      type: "numberInputTypeColumn",
      headerValueGetter: (_: any) => $t("productTemplateDetails-list-position-label", { $: "Position" }),
      editable: true,
      sortable: true,
      cellRenderer: "agGroupCellRenderer",
      cellEditorParams: {
        placeholder: $t("productTemplate-edit-position-label", { $: "Position" })
      }
    },
    {
      field: "stepType",
      type: "enumTypeColumn",
      headerValueGetter: (_: any) => $t("productTemplateDetails-list-stepType-label", { $: "Step Type " }),
      editable: true,
      sortable: true,
      valueFormatter: (params: any) => translateStepType(params.data.stepType),
      cellEditorParams: {
        values: enumToEditorEntries(StepType),
        rules: [requiredRule],
        placeholder: $t("productTemplateDetails-edit-stepType-label", { $: "Step Type" }),
        // onValueChange: onTypeChange,
        // isEditEnabled: () => !gridWrapperRef.value?.isUpdating(),
        clearOnEditDisabled: false,
        defaultValue: StepType.Production
      } as unknown as IEnumValueSelectCellEditorParams
    },
    {
      field: "name",
      type: "textInputTypeColumn",
      headerValueGetter: (_: any) => $t("productTemplateDetails-list-stepName-label", { $: "Step Name" }),
      editable: true,
      sortable: true,
      cellEditorParams: {
        rules: [requiredRule],
        placeholder: $t("productTemplate-edit-stepName-label", { $: "Step Name" })
      }
    },
    {
      field: "processingTime",
      type: "textInputTypeColumn",
      headerValueGetter: (_: any) => $t("productTemplateDetails-list-processingTime-label", { $: "Processing Time" }),
      editable: true,
      sortable: true,
      valueParser: (params: ValueParserParams) => asIsoFormattedString(params.data.value),
      valueFormatter: (params: ValueFormatterParams) => asJiraFormattedString(params.data.processingTime),
      cellEditorParams: {
        rules: [durationRequiredRule],
        valueInitializer: asJiraFormattedString,
        placeholder: $t("productTemplate-edit-processingTime-label", { $: "format: '?d ?h ?m ?s'" })
      }
    },
    {
      field: "quantityPerTime",
      type: "numberInputTypeColumn",
      headerValueGetter: (_: any) => $t("productTemplateDetails-list-quantityPerTime-label", { $: "Quantity Per Time" }),
      headerName: "Quantity Per Time",
      editable: true,
      sortable: true,
      cellEditorParams: {
        rules: [requiredRule],
        placeholder: $t("productTemplate-edit-quantityPerTime-label", { $: "Quantity Per Time" })
      }
    },
    {
      field: "modelInstances",
      headerValueGetter: (_: any) => $t("productTemplateDetails-list-modelInstances-label", { $: "Model Instances" }),
      headerName: "Model Instances",
      editable: false,
      sortable: true,
      filter: true,
      valueFormatter: (params: any) => {
        return getModelInstances(params.data.modelInstances);
      }
    }
  ];
}

function onStepRowSelected({ node, data }: { node: RowNode; data: any }) {
  if (node.isSelected()) {
    productTemplateStepDetailsRef.value.loadStepDetails(data);
  }
}

function onStepRowDeleted() {
  productTemplateStepDetailsRef.value.loadStepDetails(undefined);
}

function isReadOnly(): boolean {
  const productTemplate = props.task as ProductTemplateDto;
  return productTemplate.templateStatus !== TemplateStatus.WorkInProgress;
}
</script>

<template>
  <v-card class="d-flex flex-column h-100" elevation="0">
    <v-card-title class="tabs pt-0">
      <v-tabs
        v-model="selectedTab"
        class="tab-details text-disabled bg-white"
        selected-class="v-slide-group-item--active text-primary bg-white"
        density="compact"
        color="primary"
        show-arrows
      >
        <v-tab value="steps">
          <v-icon class="pr-2">mdi-debug-step-over</v-icon>
          {{ $t("productTemplateDetails-list-taskSteps-label", { $: "Task steps" }) }}
        </v-tab>
        <v-tab v-for="tab in schemaTabs" :key="tab.schema.schemaId" :value="tab.schema.schemaId">
          <v-icon class="pr-2">mdi-tag-multiple</v-icon>
          {{ tab.schema.label }}
        </v-tab>
      </v-tabs>
    </v-card-title>
    <v-card-text class="fill-height d-flex flex-column pa-0">
      <v-window v-model="selectedTab" class="fill-height">
        <v-window-item value="steps" class="fill-height">
          <v-card class="fill-height">
            <v-card-text class="fill-height py-0">
              <split-panel identifier="split-panel-product-template-details" class="pt-2" :sizes="[50, 50]" :min-size="[200, 0]" direction="vertical">
                <template #panel-1>
                  <grid-wrapper
                    ref="gridWrapperRef"
                    :create-default-value="DEFAULT_CREATE_VALUE"
                    :enable-group-edit="true"
                    enable-children-selection-only
                    :server-side="true"
                    :server-side-datasource="serverSideDataSource"
                    :create-btn="!isReadOnly()"
                    :duplicate-btn="!isReadOnly()"
                    :delete-btn="!isReadOnly()"
                    :edit-btn="!isReadOnly()"
                    identifier="product-template-details"
                    refresh-btn
                    row-selection="single"
                    :default-col-def="defaultColDef"
                    @prepare-columns="onPrepareColumns"
                    @row-selected="onStepRowSelected"
                    @delete-action="onStepRowDeleted"
                  />
                </template>
                <template #panel-2>
                  <product-template-step-details ref="productTemplateStepDetailsRef" :theme="theme.name.value" :readonly="isReadOnly()" />
                </template>
              </split-panel>
            </v-card-text>
          </v-card>
        </v-window-item>
        <v-window-item v-for="tab in schemaTabs" :key="tab.schema.schemaId" :value="tab.schema.schemaId" class="fill-height pa-5 overflow-y-auto">
          <task-model-instances :task-model-instances="task.modelInstances" :task-id="task.id" :scenario-id="task.scenarioId" :schema="tab.schema"></task-model-instances>
        </v-window-item>
      </v-window>
    </v-card-text>
  </v-card>
</template>

<style lang="scss" scoped>
.tabs {
  .tab-details {
    position: relative;

    > .v-tab {
      border-radius: 0.5rem 0.5rem 0 0;
    }
  }

  .v-tab {
    color: rgba(var(--v-theme-on-background), var(--v-disabled-opacity));
  }

  .v-tab:not(.v-tab--selected) {
    border-bottom: 1px solid #e0e0e0;
  }
}

.steps-grid-wrapper {
  height: 33%;
}
</style>
