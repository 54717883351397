﻿<script lang="ts" setup>
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { inject, onUnmounted, Ref, ref, toRefs } from "vue";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import { $durationFormatterSymbol, asIsoFormattedString, asJiraFormattedString, DateFormatter } from "@masta/shared";
import {
  InventoryChangeType,
  MeasurementUnit,
  ModelInstanceDataDto,
  ResourceDto,
  ResourceInfoDto,
  ResourceType,
  StepResourceAssignmentType,
  StepResourceSpecDto,
  StepResourceUsageType
} from "@masta/generated-model";
import { $t } from "@/i18n";
import { ProductTemplateStepResourceSpecsServerSideDataSource } from "@/components/ProductTemplates/ProductTemplateStepResourceSpecsServerSideDataSource";
import { requiredRule } from "@/components/ValueCellEditor/CommonValidationRules";
import { enumToEditorEntries, enumToEditorEntriesExcluding, enumToEditorEntriesOnlyIncluding, translateEditorEntries } from "@/components/Grid/ColumnTypes";
import { translateAssignmentType, translateInventoryChangeType, translateMeasurementUnit, translateResourceType, translateUsageType } from "@/composables/translateEnum";
import {
  CellEditingStartedEvent,
  GridOptions,
  GridReadyEvent, ICellEditorParams,
  IsServerSideGroupOpenByDefaultParams,
  ProcessCellForExportParams,
  ValueFormatterParams,
  ValueGetterParams,
  ValueSetterParams
} from "ag-grid-community";
import { ISelectEnumValueEntry } from "@/components/Grid/CellEditors/IEnumValueSelectCellEditorParams";
import { asResourcesRepresentation, asResourceType, asResourceTypeFromResourceInfos } from "@/components/Tasks/TaskUtils";
import { isDefined } from "@/components/Common/Types";

const DEFAULT_CREATE_VALUE = {
  assignmentType: StepResourceAssignmentType.Alternatives,
  usageType: StepResourceUsageType.Book,
  inventoryChangeType: InventoryChangeType.Default,
  quantity: 1,
  inventoryChangeThresholdQuantity: 0,
  resources: [],
  quantityUnit: MeasurementUnit.Piece,
  position: () => resolveNextPosition()
};

interface Props {
  step: any;
  readonly: boolean;
}

const props = defineProps<Props>();
const { step } = toRefs(props);

const serverSideDataSource = new ProductTemplateStepResourceSpecsServerSideDataSource("product-template-resource-specs", "product-templates", step.value);
const gridWrapperRef = ref<GridWrapperComponent>();

const defaultColDef = ref({
  sortable: true,
  resizable: true,
  headerValueGetter: (_: any) => $t("task-list-name-label", { $: "Name" })
});

const $durationFormatter = inject<DateFormatter>($durationFormatterSymbol)!;

const filteredUsageTypeValues = ref<ISelectEnumValueEntry[]>([]);
const filteredResourceTypeValues = ref<ISelectEnumValueEntry[]>([]);

const resourceEditEnabled = ref<boolean>(true);
const inventoryChangeTypeEditEnabled = ref<boolean>(true);
const thresholdQuantityEditEnabled = ref<boolean>(true);
const assignmentTypeEditorRef = ref<StepResourceAssignmentType>(StepResourceAssignmentType.Alternatives);

const resourceUpdatesLock = ref<boolean>(false);

const resourcesEditorRef = ref<ResourceDto[] | null>();
const resourceTypeEditorRef = ref<ResourceType | null>();
const usageTypeEditorRef = ref<StepResourceUsageType | null>();
const quantityUnitEditable = ref<boolean>(false);

function displayModelInstances(modelInstances: ModelInstanceDataDto[]) {
  if (!Array.isArray(modelInstances)) return "";
  return modelInstances.map((x) => x.businessId).join(",");
}

function updateSelectableUsageTypes(resourceType: ResourceType | undefined): Ref<ISelectEnumValueEntry[]> {
  const bookableOnlyTypes = [ResourceType.Equipment, ResourceType.EquipmentGroup, ResourceType.Person, ResourceType.PersonGroup, ResourceType.Asset];
  const notBookableTypes = [ResourceType.Material];

  const resolvedResourceType = resourceType ?? resourceTypeEditorRef.value ?? undefined;

  let values: ISelectEnumValueEntry[];
  if (typeof resolvedResourceType === "undefined") {
    values = enumToEditorEntries(StepResourceUsageType);
  } else if (bookableOnlyTypes.indexOf(resolvedResourceType) !== -1) {
    values = enumToEditorEntriesOnlyIncluding(StepResourceUsageType, [StepResourceUsageType.Book]);
  } else if (notBookableTypes.indexOf(resolvedResourceType) !== -1) {
    values = enumToEditorEntriesExcluding(StepResourceUsageType, [StepResourceUsageType.Book]);
  } else {
    values = enumToEditorEntries(StepResourceUsageType);
  }

  filteredUsageTypeValues.value = translateEditorEntries(values, translateUsageType);
  return filteredUsageTypeValues;
}

function updateSelectableResourceTypes(assignmentType: StepResourceAssignmentType | undefined): Ref<ISelectEnumValueEntry[]> {
  const values = enumToEditorEntries(ResourceType);
  filteredResourceTypeValues.value = translateEditorEntries(values, translateResourceType);
  return filteredResourceTypeValues;
}

function updateAssignmentType(resourceType: ResourceType | undefined) {
  const resolvedResourceType = resourceType ?? resourceTypeEditorRef.value ?? undefined;
  if (!isDefined(resolvedResourceType)) {
    return;
  } else {
    assignmentTypeEditorRef.value = StepResourceAssignmentType.Alternatives;
  }
}

function clearResourcesWithDifferentType(resourceType: ResourceType | undefined) {
  if (!resourceType) {
    return;
  }
  try {
    resourceUpdatesLock.value = true;
    if (resourcesEditorRef.value) {
      if (resourceType !== asResourceType(resourcesEditorRef.value)) {
        resourcesEditorRef.value = [];
      }
    }
    resourceTypeEditorRef.value = resourceType;
  } finally {
    resourceUpdatesLock.value = false;
  }
}

function onResourceTypeChange(resourceType: ResourceType | undefined) {
  clearResourcesWithDifferentType(resourceType);
  updateSelectableUsageTypes(resourceType);

  if (isAddingNewStepResource()) {
    updateAssignmentType(resourceType);
    applyResourceEditLockIfRequired();
  }
}

function onUsageTypeChange(usageType: StepResourceUsageType | undefined, _: StepResourceUsageType | undefined) {
  inventoryChangeTypeEditEnabled.value = usageType === StepResourceUsageType.Produce || usageType === StepResourceUsageType.Consume;
  applyResourceEditLockIfRequired();
}

function onInventoryChangeTypeChange(inventoryChangeType: InventoryChangeType | undefined, _: InventoryChangeType | undefined) {
  thresholdQuantityEditEnabled.value = inventoryChangeType !== InventoryChangeType.Default;
}

function onAssignmentTypeChange(assignmentType: StepResourceAssignmentType, _: StepResourceAssignmentType) {
  updateSelectableResourceTypes(assignmentType);
  applyResourceEditLockIfRequired();
  quantityUnitEditable.value = assignmentType === StepResourceAssignmentType.ByCapabilities;
}

function onResourceChange(newResource: ResourceDto[], _: ResourceDto[]) {
  if (resourceUpdatesLock.value) {
    return;
  }

  // For the first line to be added, automatically set the resourceType based on the selected resources
  if (isAddingNewStepResource() && !isAnyOtherStepResource()) {
    if (!isDefined(resourceTypeEditorRef.value)) {
      const resourceType = asResourceType(newResource);

      if (isDefined(resourceType)) {
        resourceTypeEditorRef.value = resourceType;

        // stop here, resourceTypeEditorRef.value change call the onResourceTypeChange
        return;
      }
    }
  }

  const resourceType = asResourceType(resourcesEditorRef.value ?? []);
  onResourceTypeChange(resourceType);
}

function applyResourceEditLockIfRequired() {
  if (isAddingNewStepResource()) {
    const isAssignmentTypeHasValue = typeof assignmentTypeEditorRef.value !== "undefined" && typeof assignmentTypeEditorRef.value !== "object";
    resourceEditEnabled.value = isAssignmentTypeHasValue;
  } else {
    const assignmentAlternatives = assignmentTypeEditorRef.value === StepResourceAssignmentType.Alternatives;
    const usageTypeHasValue = typeof usageTypeEditorRef.value !== "undefined" && typeof usageTypeEditorRef.value !== "object"; /* type of null is object */
    resourceEditEnabled.value = assignmentAlternatives && usageTypeHasValue;
  }
}

function resolveNextPosition(): number {
  try {
    const gridApi = gridWrapperRef.value?.gridApi;
    if (gridApi) {
      const maxPosition: number =
        [...Array(gridApi.getDisplayedRowCount()).keys()]
          .map((i) => gridApi.getDisplayedRowAtIndex(i))
          .map((row) => row?.data["position"])
          .map((x) => Number(x))
          .sort((a, b) => a - b)
          .slice(-1)[0] ?? 0;
      return maxPosition + 10;
    }
  } catch (e) {
    console.debug(e);
  }

  const nextRowOrdinal = gridWrapperRef.value ? gridWrapperRef.value.gridApi.getDisplayedRowCount() + 1 : 1;
  return nextRowOrdinal * 10;
}

function resolvedInitResourceTypeValue(): ResourceType | undefined {
  const gridApi = gridWrapperRef.value?.gridApi;
  if (gridApi) {
    const renderedNodes = gridApi.getRenderedNodes();
    if (renderedNodes) {
      for (let index = 0; index < renderedNodes.length; index++) {
        const node = renderedNodes[index];
        if (isDefined(node.data.resourceType)) {
          return node.data.resourceType;
        }
      }
    }
  }
}

const onCellEditingStarted = (event: CellEditingStartedEvent) => {
  if (event.colDef.field === "resourceType") {
    if (isAddingNewStepResource() && isAnyOtherStepResource()) {
      if (!isDefined(resourceTypeEditorRef.value)) {
        resourceTypeEditorRef.value = resolvedInitResourceTypeValue();
      }
    }
  }
};

async function onGridReady(event: GridReadyEvent) {
  gridWrapperRef.value?.gridApi.addEventListener("cellEditingStarted", onCellEditingStarted);
}

onUnmounted(() => {
  gridWrapperRef.value?.gridApi.removeEventListener("cellEditingStarted", onCellEditingStarted);
});

function isAddingNewStepResource() {
  const pinnedTopRowCount = gridWrapperRef.value?.gridApi.getPinnedTopRowCount();
  return pinnedTopRowCount && pinnedTopRowCount > 0;
}

function isAnyOtherStepResource() {
  const displayedRowCount = gridWrapperRef.value?.gridApi.getDisplayedRowCount();
  return !!displayedRowCount && displayedRowCount > 0;
}

function isServerSideGroupOpenByDefault(params: IsServerSideGroupOpenByDefaultParams) {
  // keep all nodes expanded
  return true;
}

const gridOptions: GridOptions = {
  processCellForClipboard: (params: ProcessCellForExportParams) => {
    const { value, column } = params;

    if (column.getColDef().type === "resourcesPickerTypeColumn") {
      return params.formatValue(value);
    }

    return value;
  }
};

function onPrepareColumns(columnDefs: any) {
  updateSelectableUsageTypes(undefined);
  updateSelectableResourceTypes(undefined);

  columnDefs.value = [
    {
      field: "assignmentType",
      type: "enumTypeColumn",
      headerValueGetter: (_: any) => $t("task-list-assignmentType-label", { $: "Assignment Type" }),
      editable: true,
      sortable: true,
      valueFormatter: (params: any) => translateAssignmentType(params.data.assignmentType),
      cellEditorParams: {
        valueRef: () => assignmentTypeEditorRef,
        rules: [requiredRule],
        values: translateEditorEntries(enumToEditorEntries(StepResourceAssignmentType), translateAssignmentType),
        placeholder: $t("productTemplate-edit-assignmentType-label", { $: "Assignment Type" }),
        onValueChange: onAssignmentTypeChange
      }
    },
    {
      field: "resourceType",
      type: "enumTypeColumn",
      headerValueGetter: (_: any) => $t("task-list-resourceType-label", { $: "Resource Type" }),
      editable: true,
      sortable: true,
      rowGroup: true,
      valueFormatter: (params: any) => translateResourceType(params.data.resourceType),
      cellEditorParams: {
        valueRef: () => resourceTypeEditorRef,
        values: () => filteredResourceTypeValues,
        placeholder: $t("productTemplate-edit-resourceType-label", { $: "ResourceType" }),
        onValueChange: onResourceTypeChange
      }
    },
    {
      field: "usageType",
      type: "enumTypeColumn",
      headerValueGetter: (_: any) => $t("productTemplate-list-usageType-label", { $: "Usage Type" }),
      editable: true,
      sortable: true,
      rowGroup: true,
      valueFormatter: (params: any) => translateUsageType(params.data.usageType),
      cellEditorParams: {
        valueRef: () => usageTypeEditorRef,
        rules: [requiredRule],
        values: () => filteredUsageTypeValues,
        placeholder: $t("productTemplate-edit-usageType-label", { $: "Usage Type" }),
        onValueChange: onUsageTypeChange
      }
    },
    {
      field: "inventoryChangeType",
      type: "enumTypeColumn",
      headerValueGetter: (_: any) => $t("productTemplate-list-inventoryChangeType-label", { $: "Inventory Change Type" }),
      editable: true,
      sortable: true,
      valueFormatter: (params: any) => translateInventoryChangeType(params.data.inventoryChangeType),
      cellEditorParams: {
        isEditEnabled: () => inventoryChangeTypeEditEnabled,
        clearOnEditDisabled: true,
        clearValue: InventoryChangeType.Default,
        rules: [requiredRule],
        values: translateEditorEntries(enumToEditorEntries(InventoryChangeType), translateInventoryChangeType),
        placeholder: $t("productTemplate-edit-inventoryChangeType-label", { $: "Inventory Change Type" }),
        onValueChange: onInventoryChangeTypeChange
      }
    },
    {
      field: "resources",
      type: "resourcesPickerTypeColumn",
      headerValueGetter: (_: any) => $t("productTemplate-list-resources-label", { $: "Resources" }),
      editable: true,
      valueFormatter: (params: ValueFormatterParams) => asResourcesRepresentation(params.value),
      valueSetter: (params: ValueSetterParams) => {
        const resourceSpec = params.data as StepResourceSpecDto;
        const resourceInfos: ResourceInfoDto[] = params.newValue?.filter((x: ResourceInfoDto) => !!x) ?? [];

        // params.newValue is comming here as a ResourceInfoDto[] (subtype of ResourceDto).
        // But the properties needed continue to be present in ResourceInfoDto
        resourceSpec.resources = params.newValue;

        resourceSpec.resourceIds = resourceInfos.map((x: ResourceInfoDto) => x.id);
        resourceSpec.resourceType = asResourceTypeFromResourceInfos(resourceInfos);
        return true;
      },
      valueGetter: (params: ValueGetterParams) => {
        const resourceSpec = params.data as StepResourceSpecDto;

        if (resourceSpec && resourceSpec.resources) {
          return resourceSpec.resources.map((resource: ResourceDto) => {
            const resourceInfo: ResourceInfoDto = {
              id: resource.id as string,
              scenarioId: resource.scenarioId,
              name: resource.name,
              businessId: resource.businessId,
              type: resourceSpec.resourceType,
              plannable: true
            };

            return resourceInfo;
          });
        }

        return [];
      },
      cellEditorParams: {
        placeholder: $t("productTemplate-edit-resources-label", { $: "Resources" }),
        isEditEnabled: () => resourceEditEnabled,
        onValueChange: onResourceChange,
        resourceType: () => resourceTypeEditorRef.value,
        valueRef: () => resourcesEditorRef,
        clearOnEditDisabled: true,
        clearValue: null
      }
    },
    // {
    //   field: "resourceIds",
    //   hide: true,
    //   headerValueGetter: (_: any) => $t("productTemplate-list-resourceIds-label", { $: "Resource IDs" }),
    //   valueFormatter: (params: ValueFormatterParams) => params.value?.join(", ") ?? ""
    // },
    {
      field: "quantity",
      type: "numberInputTypeColumn",
      headerValueGetter: (_: any) => $t("productTemplate-list-quantity-label", { $: "Quantity" }),
      editable: true,
      sortable: true,
      cellEditorParams: {
        rules: [requiredRule],
        placeholder: $t("productTemplate-edit-quantity-label", { $: "Quantity" })
      }
    },
    {
      field: "inventoryChangeThresholdQuantity",
      type: "numberInputTypeColumn",
      headerValueGetter: (_: any) => $t("productTemplate-list-inventoryChangeThresholdQuantity-label", { $: "Inventory Change Threshold" }),
      editable: true,
      sortable: true,
      cellEditorParams: {
        isEditEnabled: () => thresholdQuantityEditEnabled,
        clearValue: 0,
        clearOnEditDisabled: true,
        placeholder: $t("productTemplate-edit-inventoryChangeThresholdQuantity-label", { $: "Inventory Change Threshold" })
      }
    },
    {
      field: "quantityUnit",
      type: "enumTypeColumn",
      headerValueGetter: (_: any) => $t("productTemplate-list-quantityUnit-label", { $: "Measurement Unit" }),
      editable: true,
      sortable: true,
      valueFormatter: (params: any) => translateMeasurementUnit(params.data.quantityUnit),
      cellEditorParams: {
        isEditEnabled: () => quantityUnitEditable,
        values: translateEditorEntries(enumToEditorEntries(MeasurementUnit), translateMeasurementUnit),
        placeholder: $t("productTemplate-edit-quantityUnit-label", { $: "Measurement Unit" })
      }
    },
    {
      field: "processingTime",
      type: "textInputTypeColumn",
      headerValueGetter: (_: any) => $t("productTemplate-list-processingTime-label", { $: "Processing Time" }),
      editable: true,
      sortable: true,
      valueParser: (params: any) => asIsoFormattedString(params.newValue),
      valueFormatter: (params: any) => asJiraFormattedString(params.data.processingTime),
      cellEditorParams: {
        valueInitializer: (value: any) => asJiraFormattedString(value),
        placeholder: $t("productTemplate-edit-processingTime-label", { $: "format: '?d ?h ?m ?s'" })
      }
    },
    {
      field: "quantityPerTime",
      type: "numberInputTypeColumn",
      headerValueGetter: (_: any) => $t("productTemplate-list-quantityPerTime-label", { $: "Quantity Per Time" }),
      editable: true,
      sortable: true,
      cellEditorParams: {
        placeholder: $t("productTemplate-edit-quantityPerTime-label", { $: "Quantity Per Time" })
      }
    },
    {
      field: "modelInstances",
      sortable: true,
      valueFormatter: (params: any) => displayModelInstances(params.data.modelInstances),
      headerValueGetter: (_: any) => $t("task-list-requirements-label", { $: "Requirements" })
    }
  ];
}
</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    :create-default-value="DEFAULT_CREATE_VALUE"
    :grid-options="gridOptions"
    :default-col-def="defaultColDef"
    :server-side="true"
    :server-side-datasource="serverSideDataSource"
    :is-server-side-group-open-by-default="isServerSideGroupOpenByDefault"
    :suppress-group-rows-sticky="true"
    group-display-type="groupRows"
    refresh-btn
    :create-btn="!readonly"
    :duplicate-btn="!readonly"
    :delete-btn="!readonly"
    :edit-btn="!readonly"
    identifier="step-resource-specs"
    row-selection="multiple"
    @prepare-columns="onPrepareColumns"
    @ready="onGridReady"
  />
</template>

<style lang="scss" scoped></style>
