import { inject, injectable } from "inversify";
import { IContextMenuItemActionContext, IGanttAction, IocSymbols, Layer, Lifecycle } from "@masta/gantt2/core";
import { ResourceCapacityActivity } from "@/components/Gantt/Common/Model";
import { BehaviorSubject } from "rxjs";

@injectable()
export class OpenProductCardAction extends Lifecycle implements IGanttAction {
  constructor(@inject(IocSymbols.LayersSymbol) private _layers$$: BehaviorSubject<Layer[]>) {
    super();
  }

  public getResourceId(context: IContextMenuItemActionContext) {
    if (context.activityBounds.length < 1) {
      console.warn("invalid activity bounds count , should be 1 or more but was ", context.activityBounds.length);
      return;
    }
    const layers = this._layers$$;
    const bound = context.activityBounds[0];
    const activity: ResourceCapacityActivity | undefined = context.row.repository.getActivity(layers.value.find(l => l.id === bound.layerId)!, bound.activityId);
    if (!activity) {
      console.warn("no activity found");
      return;
    }
    return activity.userObject?.task.resourceId;
  }

  public async execute(context: IContextMenuItemActionContext) {
    return this.getResourceId(context);
  }

}
