<script setup lang="ts">
import DocumentsRepository from "@/components/Documents/DocumentsRepository.vue";
import CloseCardButton from "@/components/CloseCardButton.vue";
import { $t } from "@/i18n";
import { DocumentModel } from "@/components/Documents/DocumentModel";
import { ref, UnwrapRef } from "vue";

const props = defineProps<{
  multiple?: boolean
}>();
const dialog = defineModel<boolean>();

const emits = defineEmits(["confirm", "cancel"]);

function onBtnConfirm() {
  dialog.value = false;
  emits("confirm");
}

function onBtnCancel() {
  dialog.value = false;
  emits("cancel");
}

const confirmDisabled = ref(true);

function onSelectedDocumentsChanged(selectedDocuments: UnwrapRef<DocumentModel>[]) {
  confirmDisabled.value = props.multiple ? selectedDocuments.length > 0 : selectedDocuments.length !== 1;
}
</script>

<template>
  <v-dialog v-model="dialog" width="100%" height="80vh">
    <template #activator="scope">
      <slot name="activator" v-bind="scope" />
    </template>
    <template #default>
      <documents-repository v-bind="$attrs" @update:selected-documents="onSelectedDocumentsChanged">
        <template #breadcrumbs>
          <div>
            {{ $t("documentsPicker-dialog-selectDocument-label", { $: "Select document" }) }}
          </div>
          <close-card-button @click="dialog = false" />
        </template>
        <template #actions>
          <v-card-actions>
            <v-row>
              <v-col cols="6">
                <v-btn color="secondary" variant="elevated" block @click="onBtnCancel">{{ $t("DocumentsDeleteConfirmationModalDialog-cancel-label", { $: "Cancel" }) }}</v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn color="primary" variant="elevated" block :disabled="confirmDisabled" @click="onBtnConfirm">
                  {{ $t("DocumentsDeleteConfirmationModalDialog-confirm-label", { $: "Confirm" }) }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </template>
      </documents-repository>
    </template>
  </v-dialog>
</template>

<style scoped lang="scss">
.close-card-button {
  top: 0;
  right: 8px;
}
</style>
