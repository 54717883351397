<script lang="ts" setup>
import MasterDetailLayout from "@/components/Layout/MasterDetailLayout.vue";
import { AgGridVue } from "ag-grid-vue3";
import { usePlanningStore } from "@/store/PlanningStore";
import { storeToRefs } from "pinia";
import { computed, inject, ref } from "vue";
import { PeriodDto, ResourceInfoDto } from "@masta/generated-model";
import { CellClassParams, EditableCallbackParams, ValueFormatterParams, ValueSetterParams } from "ag-grid-community";
import { useScenariosStore } from "@/store/ScenariosStore";
import { $longTermSecondsBasedDurationFormatterSymbol, SecondsDurationFormatter } from "@masta/shared";
import Breadcrumbs from "@/components/Layout/Breadcrumbs.vue";
import { $t } from "@/i18n";
import { requiredRule } from "@/components/ValueCellEditor/CommonValidationRules";
import ResourcePicker from "@/components/Resources/ResourcePicker.vue";

const planningStore = usePlanningStore();
const { view, request, isViewScenarioEditable } = storeToRefs(planningStore);
const scenariosStore = useScenariosStore();
const { selectedScenario } = storeToRefs(scenariosStore);
const supplyResource = ref<ResourceInfoDto | null | undefined>();
const filterExpanded = ref<boolean>(false);
const groupDefaultExpanded = -1;
const autoGroupColumnDef = {
  headerName: "Agreement",
  minWidth: 220,
  cellRendererParams: {
    suppressCount: true
  }
};

const timeZones = [
  { name: "Local", value: Intl.DateTimeFormat().resolvedOptions().timeZone },
  { name: "UTC (+0)", value: "Etc/UTC" }
];

const aggregates = [
  { id: 0, label: "Weekly" },
  { id: 1, label: "Monthly" },
  { id: 2, label: "Quarterly" },
  { id: 3, label: "Yearly" }
];
const colors = {
  agreed: "#FFFFFF",
  scheduled: "#BCB5DF",
  executed: "#A8E8FF"
};

const workloadUnits = [
  { id: 0, label: "FTE" },
  { id: 1, label: "Working Time" }
];

const $longTermSecondsBasedDurationFormatter = inject<SecondsDurationFormatter>($longTermSecondsBasedDurationFormatterSymbol)!;

function createIsEditable() {
  return (params: EditableCallbackParams) => params.data.editable && isViewScenarioEditable;
}

function createValueSetters() {
  return (params: ValueSetterParams) => {
    if (params.newValue !== params.oldValue) {
      const update = {
        resourceId: params.data.id,
        value: params.newValue,
        periodId: params.colDef.field
      };
      planningStore.updateRow(update);
    }
    return false;
  };
}

function createCellStyle() {
  return (params: CellClassParams) => {
    if (params.value && params.data.workload) {
      return { "background-color": colors[params.data.workload] };
    }
  };
}

function createValueFormatter(workloadUnit: number) {
  return (params: ValueFormatterParams) => {
    let value = params.value;

    if (!value || isNaN(value)) {
      return "";
    }
    if (typeof value !== "number") {
      value = parseFloat(value);
    }
    if (workloadUnit === 0) {
      //FTE
      return value.toFixed(2);
    } else {
      //Working Time
      return $longTermSecondsBasedDurationFormatter(value);
    }
  };
}

const columnDefinitions = computed(() => {
  const periods = view.value.periods;

  const defs = periods.map((x: PeriodDto) => {
    return {
      field: x.id,
      headerName: x.name,
      editable: createIsEditable(),
      valueSetter: createValueSetters(),
      cellStyle: createCellStyle(),
      valueFormatter: createValueFormatter(view.value.workloadUnit),
      type: "rightAligned"
    };
  });
  defs.unshift({
    field: "workload",
    headerName: "Workload",
    editable: false
  });
  defs.unshift({
    field: "name",
    headerName: "Agreement",
    editable: false,
    rowGroup: true,
    hide: true
  });
  //console.log("computing definitions", defs);
  return defs;
});

async function fetchPlanningView() {
  if (!selectedScenario.value || !supplyResource.value) {
    return;
  }
  await planningStore.fetchView({ ...request.value, scenarioId: selectedScenario.value.id, supplyResourceId: supplyResource.value.id });
}

async function onRefreshAction() {
  await fetchPlanningView();
  //gridWrapperRef.value?.gridApi.sizeColumnsToFit();
}
</script>

<template>
  <master-detail-layout>
    <template #master>
      <v-card elevation="0" class="flexcard fill-height">
        <v-card-title>
          <breadcrumbs>{{ $t("planning-roughCutCapacity-roughCutCapacity-title", { $: "Rough Cut Capacity Planning" }) }}</breadcrumbs>
        </v-card-title>
        <v-card-text class="">
          <v-row class="align-center">
            <v-col cols="12">
              <v-btn density="compact" size="small" variant="tonal" color="primary" @click="filterExpanded = !filterExpanded">
                <v-icon>mdi-filter-variant</v-icon>
                {{ $t("planning-roughCutCapacity-filterResult-label", { $: "Filter result" }) }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-fade-transition>
                <v-sheet v-show="filterExpanded">
                  <v-row class="align-center">
                    <v-col cols="2" lg="1">
                      <v-text-field
                        v-model="request.start"
                        variant="outlined"
                        density="compact"
                        hide-details
                        prepend-inner-icon="mdi-calendar"
                        :label="$t('planning-roughCutCapacity-start-label', { $: 'Start' })"
                      />
                    </v-col>
                    <v-col cols="2" lg="1">
                      <v-text-field
                        v-model="request.end"
                        variant="outlined"
                        density="compact"
                        hide-details
                        prepend-inner-icon="mdi-calendar"
                        :label="$t('planning-roughCutCapacity-end-label', { $: 'End' })"
                      />
                    </v-col>
                    <v-col cols="2" lg="1">
                      <v-select
                        v-model="request.timeZone"
                        variant="outlined"
                        density="compact"
                        hide-details
                        :items="timeZones"
                        item-title="name"
                        item-value="value"
                        :label="$t('planning-roughCutCapacity-timeZone-label', { $: 'Time zone' })"
                      />
                    </v-col>
                    <v-col cols="6" lg="3" class="d-flex">
                      <v-radio-group
                        v-model="request.aggregate"
                        class="radio-select"
                        density="compact"
                        variant="outlined"
                        hide-details
                        :label="$t('planning-roughCutCapacity-aggregate-label', { $: 'Aggregate' })"
                        inline
                      >
                        <v-radio v-for="item in aggregates" :key="item.id" :label="item.label" :value="item.id" />
                      </v-radio-group>
                      <v-radio-group
                        v-model="request.workloadUnit"
                        class="radio-select"
                        variant="outlined"
                        density="compact"
                        hide-details
                        :label="$t('planning-roughCutCapacity-quantityUnit-label', { $: 'Measurement Unit' })"
                        inline
                      >
                        <v-radio v-for="item in workloadUnits" :key="item.id" :label="item.label" :value="item.id" />
                      </v-radio-group>
                    </v-col>
                    <v-col cols="5" lg="3">
                      <ResourcePicker
                        v-model="supplyResource"
                        class="flex-1-1-100"
                        :readonly="false"
                        :rules="[requiredRule]"
                        :hide-details="'auto'"
                        :label="$t('planning-roughCutCapacity-supply-label', { $: 'Supply' })"
                      />
                    </v-col>
                    <v-col cols="2">
                      <v-btn color="primary" block @click="onRefreshAction">{{ $t("planning-roughCutCapacity-applyFilters-action", { $: "Apply filters" }) }}</v-btn>
                    </v-col>
                  </v-row>
                </v-sheet>
              </v-fade-transition>
            </v-col>
          </v-row>
          <v-row class="fill-height">
            <v-col cols="12">
              <ag-grid-vue
                :column-defs="columnDefinitions"
                :row-data="view.data"
                :auto-group-column-def="autoGroupColumnDef"
                :group-default-expanded="groupDefaultExpanded"
                :class="$vuetify.theme.name === 'dark' ? 'ag-theme-alpine-dark' : 'ag-theme-alpine'"
                row-selection="single"
                style="width: 100%; height: 100%"
              >
              </ag-grid-vue>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </master-detail-layout>
</template>

<style lang="scss">
.radio-select {
  .v-input__control > .v-label {
  }

  .v-selection-control-group {
    margin-top: 0 !important;
  }
}
</style>
