<script setup lang="ts">
import SystemEnumService from "@/services/system-enum.service";
import { useProductTemplatesStore } from "@/store/ProductTemplatesStore";
import { useScenariosStore } from "@/store/ScenariosStore";
import ProductTemplateStepResourceSpecGrid from "@/components/ProductTemplates/ProductTemplateStepResourceSpecsGrid.vue";
import { nextTick, ref } from "vue";
import { ProductTemplateDetailsDto, StepTemplateDto } from "@masta/generated-model";

const props = defineProps<{
  theme: any;
  readonly: boolean;
}>();

const productTemplatesStore = useProductTemplatesStore();
const scenariosStore = useScenariosStore();

const tab = ref("specifications");

const step = ref<StepTemplateDto | undefined>(undefined);
const task = ref<ProductTemplateDetailsDto | undefined>(undefined);
const spec = ref<any>(undefined);

const renderDetails = ref<boolean>(false);

function getAssignmentType() {
  return SystemEnumService.stepResourceAssignmentType(spec.value.assignmentType);
}

function getResourceType() {
  return SystemEnumService.resourceType(spec.value.resourceType);
}

async function loadStepDetails(stepTemplate: StepTemplateDto | undefined) {
  renderDetails.value = false;

  // Wait for the change to get flushed to the DOM
  // Needed to force a re-creation of `product-template-step-resource-spec-grid`
  await nextTick();

  if (stepTemplate) {
    step.value = stepTemplate;
    task.value = productTemplatesStore.productTemplateDetail(stepTemplate?.taskId);

    renderDetails.value = true;
  } else {
    step.value = undefined;
    task.value = undefined;
  }
}

defineExpose({ loadStepDetails });
</script>

<template>
  <div v-if="renderDetails" class="fill-height pa-4 d-flex flex-column">
    <div class="tabs">
      <v-tabs
        v-model="tab"
        class="tab-details text-disabled bg-white"
        selected-class="v-slide-group-item--active text-primary bg-white"
        density="compact"
        color="primary"
        show-arrows
      >
        <v-tab :theme="theme.current" value="specifications">
          {{ $t("productTemplate-stepDetailsSpecification-specification-title", { $: "Specification" }) }}
        </v-tab>
      </v-tabs>
    </div>
    <v-sheet class="flex-fill mt-1">
      <v-window v-model="tab" direction="vertical" :theme="theme.current" class="fill-height">
        <v-window-item value="specifications" class="fill-height">
          <product-template-step-resource-spec-grid :readonly="readonly" :step="step" />
        </v-window-item>
      </v-window>
    </v-sheet>
  </div>
  <v-card-text v-else class="fill-height">
    <v-row class="fill-height">
      <v-col cols="12" class="d-flex align-center justify-center">
        <label class="text-secondary text-h6 font-weight-regular">
          {{ $t("productTemplate-stepDetailsSpecification-noTaskSelected-label", { $: "No Task selected" }) }}
        </label>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<style lang="scss">
@import "vuetify/lib/styles/settings/colors";

.task-steps-details {
  display: flex;
  flex-direction: column;

  .v-slide-group__content {
    .v-btn {
      text-align: left;
      justify-content: start;
    }

    .v-btn {
      border-right: unset;
    }
  }

  .v-window {
    flex-grow: 1;
  }
}
</style>
