import { AttachmentDto, DocumentDto } from "@masta/generated-model";
import ApiService from "@/services/api";
import { useDocumentObjectUrlsStore } from "@/store/DocumentObjectUrlsStore";

export class DocumentError extends Error {
  constructor(
    message: string
  ) {
    super(message);
    Object.setPrototypeOf(this, DocumentError.prototype);
  }
}

function isImage(attachment: AttachmentDto) {
  return attachment.mimeType?.startsWith("image");
}

function isPdf(attachment: AttachmentDto) {
  return attachment.mimeType?.startsWith("application/pdf");
}

function isModel(attachment: AttachmentDto) {
  const fileExtensions = [".obj", ".stp", ".step"];
  return fileExtensions.some((ext) => attachment.fileName?.toLowerCase().endsWith(ext));
}

export enum DocumentType {
  Unknown,
  ImageFile,
  ModelFile
}

export enum DocumentSort {
  FileNameAsc,
  FileNameDesc,
  CreatedAtAsc,
  CreatedAtDesc,
  ModifiedAtAsc,
  ModifiedAtDesc,
  DescriptionAsc,
  DescriptionDesc,
  TagsAsc,
  TagsDesc
}

export function getDocumentDtoId(dto: DocumentDto): string {
  return `${dto.id}#${dto.revisionNumber}`;
}

export class DocumentModel {
  businessId: string;
  readonly revisionNumber: number;
  readonly createdBy: string;
  readonly createdAt: string;
  readonly modifiedBy: string;
  readonly modifiedAt: string;
  fileName: string;
  description: string;
  readonly mimeType: string;
  tags: string[];

  private _isSynchronized = false;
  private _thumbnailUrl: string;
  private _downloadUrl?: string;
  private _documentType: DocumentType = DocumentType.Unknown;
  private _file?: File;

  private _urlStore = useDocumentObjectUrlsStore();

  constructor(readonly dto: DocumentDto, readonly options: { thumbnailWidth: number } = { thumbnailWidth: 480 }) {
    if (!dto.value || !dto.value.attachment) {
      throw new DocumentError("Invalid document data");
    }
    if (!dto.value.attachment.uri) {
      throw new DocumentError(`Invalid document attachment (id=${dto.id} rev=${dto.revisionNumber}) - missing URI`);
    }
    this.businessId = dto.businessId;
    this.revisionNumber = dto.revisionNumber;
    this.createdBy = dto.createdBy;
    this.createdAt = dto.createdAt;
    this.modifiedBy = dto.modifiedBy;
    this.modifiedAt = dto.modifiedAt;
    this.fileName = dto.value.attachment.fileName;
    this.description = dto.value.attachment.description;
    this.mimeType = dto.value.attachment.mimeType;
    this.tags = dto.tags;
  }

  get id(): string {
    return getDocumentDtoId(this.dto);
  }

  get file(): (() => Promise<File>) | undefined {
    if (this._file) {
      return async () => this._file!;
    }
    return undefined;
  }

  get thumbnailUrl(): string {
    return this._thumbnailUrl;
  }

  private _isGettingDownloadUrl = false;

  get downloadUrl() {
    if (!this._downloadUrl && !this._isGettingDownloadUrl) {
      this._isGettingDownloadUrl = true;
      this.getDocumentDownloadUrl().then((url) => {
        if (url !== this._downloadUrl) {
          this._downloadUrl = url;
        }
        this._isGettingDownloadUrl = false;
      });
    }
    return this._downloadUrl;
  }

  get documentType(): DocumentType {
    return this._documentType;
  }

  get fileExtension(): string | null {
    const parts = this.fileName.split(".");
    return parts.length > 1 ? parts.pop() || null : null;
  }

  async synchronize(): Promise<void> {
    if (this._isSynchronized) {
      return;
    }
    const thumbnailDtoId = `${this.dto.id}_thumbnail_${this.options.thumbnailWidth}`;
    if (isImage(this.dto.value.attachment)) {
      if (!this._urlStore.has(thumbnailDtoId, this.revisionNumber)) {
        const { data } = await ApiService.documents.getDocumentContentThumbnailStream({
          documentId: this.dto.id,
          revisionNumber: this.dto.revisionNumber,
          attachmentContentStorageConfigurationId: this.dto.value.attachment.attachmentContentStorageConfigurationId ?? undefined,
          attachmentSourceType: this.dto.value.attachment.sourceType,
          width: this.options.thumbnailWidth
        });
        this._thumbnailUrl = this._urlStore.add(thumbnailDtoId, this.revisionNumber, data);
      } else {
        this._thumbnailUrl = this._urlStore.get(thumbnailDtoId, this.revisionNumber)!;
      }
      this._documentType = DocumentType.ImageFile;
    }
    if (isPdf(this.dto.value.attachment)) {
      if (!this._urlStore.has(thumbnailDtoId, this.revisionNumber)) {
        const { data } = await ApiService.documents.getDocumentContentThumbnailStream({
          documentId: this.dto.id,
          revisionNumber: this.dto.revisionNumber,
          attachmentContentStorageConfigurationId: this.dto.value.attachment.attachmentContentStorageConfigurationId ?? undefined,
          attachmentSourceType: this.dto.value.attachment.sourceType
        });
        this._thumbnailUrl = this._urlStore.add(thumbnailDtoId, this.revisionNumber, data);
      } else {
        this._thumbnailUrl = this._urlStore.get(thumbnailDtoId, this.revisionNumber)!;
      }
      this._documentType = DocumentType.ImageFile;
    }
    if (isModel(this.dto.value.attachment)) {
      const { data } = await ApiService.documents.getDocumentContentStream({
        documentId: this.dto.id,
        revisionNumber: this.dto.revisionNumber,
        attachmentContentStorageConfigurationId: this.dto.value.attachment.attachmentContentStorageConfigurationId ?? undefined,
        attachmentSourceType: this.dto.value.attachment.sourceType
      });
      const blob = new Blob([data], { type: "application/octet-stream" });
      this._file = new File([blob], this.fileName);
      this._documentType = DocumentType.ModelFile;
    }

    this._isSynchronized = true;
  }

  async openInNewTab() {
    if (!this.dto) {
      console.warn("No dto found in document");
      return;
    }

    const url = await this.getDocumentDownloadUrl();
    if (this.mimeType == "application/pdf") {
      const newTab = window.open("", "_blank")!;
      newTab.document.body.style.margin = "0";
      newTab.document.body.style.overflow = "hidden";
      const iframeElement = document.createElement("iframe");
      iframeElement.src = url;
      iframeElement.style.width = "100%";
      iframeElement.style.height = "100%";
      newTab.document.body.appendChild(iframeElement);
    } else if (this.file && this.mimeType === "application/octet-stream") {
      try {
        const a = document.createElement("a");
        a.href = url;
        a.type = this.mimeType;
        a.download = this.fileName ?? "file";
        a.target = "_blank";
        a.click();
        a.remove();
      } catch (e) {
        console.error(e);
      }
    } else {
      window.open(url, "_blank");
    }
  }

  async download() {
    if (!this.dto) {
      console.warn("No dto found in document");
      return;
    }
    const url = await this.getDocumentDownloadUrl();
    const a = document.createElement("a");
    a.href = url!;
    a.download = this.fileName;
    a.target = "_blank";
    a.click();
    a.remove();
  }

  public async getDocumentDownloadUrl() {
    if (!this._urlStore.has(this.dto.id, this.revisionNumber)) {
      const { data } = await ApiService.documents.getDocumentContentStream({
        documentId: this.dto.id,
        revisionNumber: this.dto?.revisionNumber
      });

      return this._urlStore.add(this.dto.id, this.revisionNumber, data);
    } else {
      return this._urlStore.get(this.dto.id, this.revisionNumber)!;
    }
  }
}
