<script lang="ts" setup>
import { $t } from "@/i18n";
import { ref, Ref } from "vue";
import { ModelSchemaName } from "@/components/Documents/IDocumentProvider";

const props = defineProps<{
  confirmAction: () => Promise<void>;
  profileName: ModelSchemaName;
}>();

const dialog = defineModel<boolean>("dialog", {
  default: false
});

const saving = ref(false);

async function onBtnConfirm(isActive: Ref<boolean>) {
  try {
    saving.value = true;
    await props.confirmAction();
    isActive.value = false;
  } finally {
    saving.value = false;
  }
}

function onBtnCancel(isActive: Ref<boolean>) {
  isActive.value = false;
}
</script>

<template>
  <v-dialog v-model="dialog" width="100%" height="80vh">
    <template #default="{ isActive }">
      <v-card v-if="isActive" height="100%" class="d-flex flex-column justify-space-between">
        <v-card-title class="d-flex justify-space-between">
          <span :title="profileName">{{ $t("contextDocumentsBrowserDialog-view-title", { $: "Media Library for {profileName}", profileName: profileName }) }}</span>
          <v-btn icon @click="isActive.value = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text style="overflow: hidden; overflow-y: auto">
          <slot v-if="isActive" />
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col cols="6">
              <v-btn color="secondary" variant="elevated" block @click="onBtnCancel(isActive)">{{ $t("contextDocumentsBrowserDialog-cancel-label", { $: "Cancel" }) }}</v-btn>
            </v-col>
            <v-col cols="6">
              <slot name="confirm-btn" :loading="saving" :disabled="saving" @click="onBtnConfirm(isActive)">
                <v-btn color="primary" variant="elevated" block :loading="saving" :disabled="saving" @click="onBtnConfirm(isActive)">
                  <slot name="confirm-btn-label">
                    {{ $t("contextDocumentsBrowserDialog-assign-label", { $: "Assign" }) }}
                  </slot>
                </v-btn>
              </slot>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<style scoped lang="scss"></style>
