<script lang="ts" setup>
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { reactive, ref, watch } from "vue";
import SystemEnumService from "@/services/system-enum.service";
import { CreateResourceAssignmentCommand, ProductionTaskDto, ResourceAssignmentType, ResourceInfoDto, ResourceType } from "@masta/generated-model";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import { $t } from "@/i18n";
import { ResourceAssignmentServerSideDataSource } from "@/components/Resources/ResourceAssignment/ResourceAssignmentServerSideDataSource";
import { IRowNode, SelectionChangedEvent } from "ag-grid-community";
import ResourcesPickerDialog from "@/components/Resources/ResourcesPickerDialog.vue";
import { getSelectedRows } from "@/components/Grid/UseGridSelection";
import ApiService from "@/services/api";
import { useSnackbarsStore } from "@/store/SnackbarsStore";

const props = defineProps<{
  assignmentType: ResourceAssignmentType;
  resourceType: ResourceType;
  resource: ResourceInfoDto;
  reverse: boolean;
}>();

const selectedResourcesToAssign = ref<ResourceInfoDto[]>();
const assignDialog = ref(false);
const selectedRow = ref<ResourceInfoDto | null>(null);
const snackbarsStore = useSnackbarsStore();

const serverSideDataSource = new ResourceAssignmentServerSideDataSource("resource-assignment", {
  assignmentType: props.assignmentType,
  resourceType: props.resourceType,
  owningResourceId: props.resource.id,
  reverse: props.reverse
});

watch(() => props.resource, (resource) => {
  if (resource) {
    serverSideDataSource.useFilteringByOwningResourceId(resource.id);
    refresh();
  }
}, { immediate: true });

watch(selectedResourcesToAssign, async (resources) => {
  if (resources) {
    for (const resource of resources) {
      await ApiService.resourceAssignment.createResourceAssignment({
        resource1Id: props.reverse ? resource.id : props.resource.id,
        resource2Id: props.reverse ? props.resource.id : resource.id,
        scenarioId: resource.scenarioId,
        assignmentType: props.assignmentType
      } as CreateResourceAssignmentCommand);
      snackbarsStore.createSnackbar({
        message: $t("resource-assignment-resource-assigned", { $: "Resource {resource} assigned", resource: resource.businessId }),
        closeable: true,
        timeout: 2500
      });
    }
    refresh();
  }
});

function refresh() {
  gridWrapperRef.value?.gridApi.refreshServerSide();
}

const gridWrapperRef = ref<GridWrapperComponent>();
const defaultColumnDef = ref({
  filter: true,
  floatingFilter: true,
  filterParams: {
    applyMiniFilterWhileTyping: true
  },
  sortable: true,
  resizable: true
});

function onPrepareColumns(columnDefs: any) {
  columnDefs.value = [
    {
      field: "id",
      hide: true,
      editable: false,
      sortable: true,
      filter: true,
      headerValueGetter: (_: any) => $t("resource-assignment-id-label", { $: "Id" })
    },
    {
      field: "name",
      editable: false,
      sortable: true,
      filter: true,
      headerValueGetter: (_: any) => $t("resource-assignment-name-label", { $: "Name" })
    },
    {
      field: "businessId",
      editable: false,
      sortable: true,
      filter: true,
      headerValueGetter: (_: any) => $t("resource-assignment-businessId-label", { $: "Business ID" })
    },
    {
      field: "type",
      editable: false,
      sortable: true,
      filter: true,
      valueFormatter: (params: any) => SystemEnumService.resourceType(params.data.type),
      headerValueGetter: (_: any) => $t("resource-assignment-type-label", { $: "Type" })
    }
  ];
}

function onSelectionChanged({ api }: SelectionChangedEvent<ProductionTaskDto>) {
  const selectedRows = getSelectedRows(api);
  selectedRow.value = selectedRows.length > 0 ? selectedRows[0] : null;
}

function assignResource() {
  assignDialog.value = true;
}

async function unassignResource() {
  if (selectedRow.value) {
    const request = {
      resource1: props.reverse ? selectedRow.value.id : props.resource.id,
      resource2: props.reverse ? props.resource.id : selectedRow.value.id,
      scenarioId: selectedRow.value.scenarioId,
      assignmentType: props.assignmentType
    };
    await ApiService.resourceAssignment.deleteResourceAssignment(request);
    refresh();
  }
}

</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    identifier="resource-assignment"
    :default-col-def="defaultColumnDef"
    row-selection="single"
    server-side
    :server-side-datasource="serverSideDataSource"
    refresh-btn
    @selection-changed="onSelectionChanged"
    @prepare-columns="onPrepareColumns"
  >
    <template #custom-buttons>
      <v-tooltip bottom open-delay="300">
        <template #activator="{ props }">
          <div class="d-inline-flex pr-4">
            <v-btn size="small" variant="text" density="compact" v-bind="props" @click="assignResource">
              <v-icon icon="mdi-playlist-plus" class="pr-4"></v-icon>
              {{ $t("resource-assignment-assign-action", { $: "Assign" }) }}
            </v-btn>
          </div>
        </template>
        <span>{{ $t("resource-assignment-assign-tooltip", { $: "Assign" }) }}</span>
      </v-tooltip>

      <v-tooltip bottom open-delay="300">
        <template #activator="{ props }">
          <div class="d-inline-flex pr-4">
            <v-btn
              size="small" variant="text" density="compact" v-bind="props" :disabled="!selectedRow" @click="unassignResource">
              <v-icon icon="mdi-playlist-minus" class="pr-4"></v-icon>
              {{ $t("resource-assignment-unassign-action", { $: "Unassign" }) }}
            </v-btn>
          </div>
        </template>
        <span>{{ $t("resource-assignment-unassign-tooltip", { $: "Unassign" }) }}</span>
      </v-tooltip>
    </template>
  </grid-wrapper>
  <resources-picker-dialog
    v-model:selected-resources="selectedResourcesToAssign"
    v-model:dialog="assignDialog"
    :resource-type="resourceType">
  </resources-picker-dialog>
</template>

<style lang="scss" scoped></style>
