import { ProcessDraft, ProductTemplateDraft, ProductTemplateDraftType, StepDraft, StepDraftResourceSpec } from "@/components/ProcessDrafts/ProcessDraftModels";
import { ResourceInfoDto, ResourceType, StepResourceAssignmentType, StepResourceUsageType, StepType } from "@masta/generated-model";
import { durationRequiredRule } from "@/components/ValueCellEditor/CommonValidationRules";
import { $t } from "@/i18n";
import { ProcessValidationError } from "@/components/ProcessDrafts/ProcessValidationError";

export const nameRules = [(v: any) => !!v || $t("processDrafts-validation-nameRequired-message", { $: "Name is required" })];
export const quantityRules = [
  (v: any) => !isNaN(parseFloat(v)) || $t("processDrafts-validation-quantityNumber-message", { $: "Quantity must be a number" }),
  (v: any) => v >= 0 || $t("processDrafts-validation-quantityZeroOrMore-message", { $: "Quantity must be zero or more" })
];
export const positiveQuantityRules = [
  (v: any) => !isNaN(parseFloat(v)) || $t("processDrafts-validation-quantityNumber-message", { $: "Quantity must be a number" }),
  (v: any) => v > 0 || $t("processDrafts-validation-quantityPositiveNumber-message", { $: "Quantity must be a positive value" })
];

// prettier-ignore
export const processingTimeRules = [(v: any) => !!v || $t("processDrafts-validation-processingTimeRequired-message", { $: "Processing time is required" }), durationRequiredRule];

export interface ProcessDraftValidationOptions {
  directiveResourceType: ResourceType;
}

export function validateProcessDraft(processDraft: ProcessDraft, options: ProcessDraftValidationOptions): ProcessValidationError[] {
  return [processDraftShouldHaveTargetResourceWithTemplate, processDraftShouldHaveValidProductTemplateDrafts, processDraftShouldConsumeEveryTemplates]
    .flatMap((x) => x.apply(null, [processDraft, options]))
    .map((x) => {
      return {
        ...x,
        isErrorShown: true
      };
    });
}

function processDraftShouldHaveTargetResourceWithTemplate(processDraft: ProcessDraft, options: ProcessDraftValidationOptions): ProcessValidationError[] {
  if (!processDraft.targetResource) {
    return [
      {
        // prettier-ignore
        message: $t("processDrafts-validation-noTargetResource-message", { $: "Target resource must be defined" })
      }
    ];
  }
  if (
    !processDraft.productTemplates
      .filter((x) => x.resource)
      .map((x) => x.resource)
      .some((x) => x?.id === processDraft.targetResource?.id)
  ) {
    return [
      {
        // prettier-ignore
        message: $t("processDrafts-validation-targetResourceHasNoTemplate-message", { $: "Target resource must have its template" })
      }
    ];
  }
  return [];
}

function processDraftShouldHaveValidProductTemplateDrafts(processDraft: ProcessDraft, options: ProcessDraftValidationOptions): ProcessValidationError[] {
  if (!processDraft.productTemplates || processDraft.productTemplates.length <= 0) {
    return [
      {
        // prettier-ignore
        message: $t("processDrafts-validation-processDraftRequired-message", { $: "There must be some process draft defined" })
      }
    ];
  } else {
    return processDraft.productTemplates.flatMap((x) => validateProductTemplateDraft(x, options));
  }
}

function processDraftShouldConsumeEveryTemplates(processDraft: ProcessDraft, options: ProcessDraftValidationOptions): ProcessValidationError[] {
  if (!processDraft.productTemplates || processDraft.productTemplates.length <= 0) return [];

  return processDraft.productTemplates.flatMap((x) => isConsumed(x, x.resource, processDraft));
}

function isConsumed(productTemplateDraft: ProductTemplateDraft, resource: ResourceInfoDto | null, processDraft: ProcessDraft): ProcessValidationError[] {
  if (!resource) return [];

  if (processDraft.targetResource?.id === resource.id || processDraft.productTemplates.some((x) => isTaskConsuming(x, resource))) {
    return [];
  }
  return [
    {
      // prettier-ignore
      message: $t("processDrafts-validation-resourceNotConsumed-message", { $: "Resource {x} is not consumed anywhere", x: resource.businessId }),
      productTemplate: productTemplateDraft
    }
  ];
}

function validateProductTemplateDraft(productTemplateDraft: ProductTemplateDraft, options: ProcessDraftValidationOptions): ProcessValidationError[] {
  // if (!productTemplateDraft.name) {
  //   return [
  //     {
  //       // prettier-ignore
  //       message: $t("processDrafts-validation-productTemplateDraftNameRequired-message", { $: "Product template draft name is required" }),
  //       productTemplate: productTemplateDraft
  //     }
  //   ];
  // }
  switch (productTemplateDraft.taskType) {
    case ProductTemplateDraftType.ProductionTask:
      return [
        productTemplateDraftShouldNotHaveChildProductTemplateDraftsRule,
        productTemplateDraftShouldHaveValidStepsRule,
        productTemplateDraftStepsShouldHaveTheSameSingleDirectiveResourceRule,
        productTemplateDraftHierarchyShouldHaveProduct,
        productTemplateDraftShouldNotProduceAndConsumeTheSameResource
      ].flatMap((x) => x.apply(null, [productTemplateDraft, options]));
    case ProductTemplateDraftType.WorkOrder:
      return [
        productTemplateDraftShouldHaveValidChildProductTemplateDraftsRule,
        productTemplateDraftShouldNotHaveStepsRule,
        productTemplateDraftHierarchyShouldHaveProduct
      ].flatMap((x) => x.apply(null, [productTemplateDraft, options]));
    default:
      return [
        {
          // prettier-ignore
          message: $t("processDrafts-validation-unknownTaskType-message", { $: "Unknown task type {x}", x: productTemplateDraft.taskType }),
          productTemplate: productTemplateDraft
        }
      ];
  }
}

function identifyProductTemplateDraft(productTemplateDraft: ProductTemplateDraft) {
  return productTemplateDraft.wbs;
}

function productTemplateDraftShouldHaveValidChildProductTemplateDraftsRule(
  productTemplateDraft: ProductTemplateDraft,
  options: ProcessDraftValidationOptions
): ProcessValidationError[] {
  if (!productTemplateDraft.childProductTemplates || productTemplateDraft.childProductTemplates.length <= 0) {
    return [
      {
        // prettier-ignore
        message: $t("processDrafts-validation-productTemplateDraftWithoutChildProductTemplateDrafts-message", { $: "Product Template Draft {x} should have childProductTemplateDrafts", x: identifyProductTemplateDraft(productTemplateDraft) }),
        productTemplate: productTemplateDraft
      }
    ];
  }
  return productTemplateDraft.childProductTemplates.flatMap((x) => validateProductTemplateDraft(x, options));
}

function productTemplateDraftShouldNotHaveChildProductTemplateDraftsRule(
  productTemplateDraft: ProductTemplateDraft,
  options: ProcessDraftValidationOptions
): ProcessValidationError[] {
  if (!productTemplateDraft.childProductTemplates || productTemplateDraft.childProductTemplates.length <= 0) {
    return [];
  }
  return [
    {
      // prettier-ignore
      message: $t("processDrafts-validation-productTemplateDraftShouldNotHaveChildProductTemplateDrafts-message", { $: "Product Template Draft {x} should not have child product template drafts", x: identifyProductTemplateDraft(productTemplateDraft) }),
      productTemplate: productTemplateDraft
    }
  ];
}

function productTemplateDraftShouldHaveValidStepsRule(productTemplateDraft: ProductTemplateDraft, options: ProcessDraftValidationOptions): ProcessValidationError[] {
  if (!productTemplateDraft.steps || productTemplateDraft.steps.length <= 0) {
    return [
      {
        // prettier-ignore
        message: $t("processDrafts-validation-productTemplateDraftShouldHaveSteps-message", { $: "Product Template Draft {x} should have steps", x: identifyProductTemplateDraft(productTemplateDraft) }),
        productTemplate: productTemplateDraft
      }
    ];
  }
  return productTemplateDraft.steps.flatMap((x) => validateStep(productTemplateDraft, x, options));
}

function productTemplateDraftShouldNotHaveStepsRule(productTemplateDraft: ProductTemplateDraft, options: ProcessDraftValidationOptions): ProcessValidationError[] {
  if (!productTemplateDraft.steps || productTemplateDraft.steps.length <= 0) {
    return [];
  }
  return [
    {
      // prettier-ignore
      message: $t("processDrafts-validation-productTemplateDraftShouldNotHaveSteps-message", { $: "Product Template Draft {x} should not have steps", x: identifyProductTemplateDraft(productTemplateDraft) }),
      productTemplate: productTemplateDraft
    }
  ];
}

function productTemplateDraftShouldNotProduceAndConsumeTheSameResource(
  productTemplateDraft: ProductTemplateDraft,
  options: ProcessDraftValidationOptions
): ProcessValidationError[] {
  if (!productTemplateDraft.steps || productTemplateDraft.steps.length <= 0) {
    return [];
  }

  const consumed = productTemplateDraft.steps
    .flatMap((x) => x.specs)
    .filter((x) => x.usageType === StepResourceUsageType.Consume)
    .flatMap((x) => x.resources.map((r) => r.id));
  const produced = productTemplateDraft.steps
    .flatMap((x) => x.specs)
    .filter((x) => x.usageType === StepResourceUsageType.Produce)
    .flatMap((x) => x.resources.map((r) => r.id));

  const intersection = consumed.filter((x) => produced.includes(x));

  if (intersection.length > 0) {
    return [
      {
        // prettier-ignore
        message: $t("processDrafts-validation-productTemplateDraftProducesAnsConsumesTheSameResource-message", { $: "Product Template Draft {x} produces and consumes the same resource", x: identifyProductTemplateDraft(productTemplateDraft) }),
        productTemplate: productTemplateDraft
      }
    ];
  }
  return [];
}

function productTemplateDraftHierarchyShouldHaveProduct(productTemplateDraft: ProductTemplateDraft, options: ProcessDraftValidationOptions): ProcessValidationError[] {
  if (!productTemplateDraft.isTopLevel) return [];

  if (!productTemplateDraft.resource) {
    return [
      {
        // prettier-ignore
        message: $t("processDrafts-validation-productTemplateDraftShouldHaveProduct-message", { $: "Product Template Draft {x} should have a product specified", x: identifyProductTemplateDraft(productTemplateDraft) }),
        productTemplate: productTemplateDraft
      }
    ];
  }

  let producing = false;
  switch (productTemplateDraft.taskType) {
    case ProductTemplateDraftType.ProductionTask:
      producing = isProductionTaskProducing(productTemplateDraft, productTemplateDraft.resource);
      break;
    case ProductTemplateDraftType.WorkOrder:
      producing = isWorkOrderProducing(productTemplateDraft, productTemplateDraft.resource);
      break;
    default:
      return [];
  }
  return producing
    ? []
    : [
        {
          // prettier-ignore
          message: $t("processDrafts-validation-productTemplateDraftProductIsNotProducedAnywhere-message", { $: "Product Template Draft {x} has a product specified which is not produced anywhere in the hierarchy", x: identifyProductTemplateDraft(productTemplateDraft) }),
          productTemplate: productTemplateDraft
        }
      ];
}

function isWorkOrderProducing(productTemplateDraft: ProductTemplateDraft, resource: ResourceInfoDto): boolean {
  if (!productTemplateDraft.childProductTemplates) return false;

  return productTemplateDraft.childProductTemplates.some((x) =>
    x.taskType === ProductTemplateDraftType.WorkOrder ? isWorkOrderProducing(x, resource) : isProductionTaskProducing(x, resource)
  );
}

function isProductionTaskProducing(productTemplateDraft: ProductTemplateDraft, resource: ResourceInfoDto) {
  if (!productTemplateDraft.steps) return false;

  return productTemplateDraft.steps.filter((x) => x.type === StepType.Production || x.type === StepType.Receiving).some((x) => isStepProducing(x, resource));
}

function isStepProducing(step: StepDraft, resource: ResourceInfoDto) {
  if (!step.specs) return false;

  return step.specs.some((x) => x.usageType === StepResourceUsageType.Produce && x.resources.some((y) => y.id === resource.id));
}

function isTaskConsuming(productTemplateDraft: ProductTemplateDraft, resource: ResourceInfoDto): boolean {
  return productTemplateDraft.taskType === ProductTemplateDraftType.WorkOrder
    ? isWorkOrderConsuming(productTemplateDraft, resource)
    : isProductionTaskConsuming(productTemplateDraft, resource);
}

function isWorkOrderConsuming(productTemplateDraft: ProductTemplateDraft, resource: ResourceInfoDto): boolean {
  if (!productTemplateDraft.childProductTemplates) return false;

  return productTemplateDraft.childProductTemplates.some((x) => isTaskConsuming(x, resource));
}

function isProductionTaskConsuming(productTemplateDraft: ProductTemplateDraft, resource: ResourceInfoDto) {
  if (!productTemplateDraft.steps) return false;

  return productTemplateDraft.steps.some((x) => isStepConsuming(x, resource));
}

function isStepConsuming(step: StepDraft, resource: ResourceInfoDto) {
  if (!step.specs) return false;

  return step.specs.some((x) => x.usageType === StepResourceUsageType.Consume && x.resources.some((y) => y.id === resource.id));
}

function productTemplateDraftStepsShouldHaveTheSameSingleDirectiveResourceRule(
  productTemplateDraft: ProductTemplateDraft,
  options: ProcessDraftValidationOptions
): ProcessValidationError[] {
  if (!productTemplateDraft.steps || productTemplateDraft.steps.length <= 0) {
    return [];
  }
  let directiveSpec: StepDraftResourceSpec | undefined;

  for (const step of productTemplateDraft.steps) {
    const specs = getDirectiveSpecs(step, options.directiveResourceType);
    if (specs.length === 0) {
      return [
        {
          // prettier-ignore
          message: $t("processDrafts-validation-stepHasNoDirectiveResource-message", { $: "Step {x} does not have directive resource defined", x: identifyStep(productTemplateDraft, step) }),
          productTemplate: productTemplateDraft,
          step
        }
      ];
    } else if (specs.length > 1) {
      return [
        {
          // prettier-ignore
          message: $t("processDrafts-validation-stepHasTooManyDirectiveResources-message", { $: "Step {x} has too many directive resources defined - there must be exactly one.", x: identifyStep(productTemplateDraft, step) }),
          productTemplate: productTemplateDraft,
          step
        }
      ];
    }
    const spec = specs[0];

    if (!!directiveSpec && specHaveDifferentParams(directiveSpec, spec)) {
      return [
        {
          // prettier-ignore
          message: $t("processDrafts-validation-productTemplateDraftShouldHaveTheSameDirectiveResource-message", { $: "Product Template Draft {x} should have the same directive resource defined in each step in the same way", x: identifyProductTemplateDraft(productTemplateDraft) }),
          productTemplate: productTemplateDraft,
          step
        }
      ];
    } else if (!directiveSpec) {
      directiveSpec = spec;
    }
  }
  return [];
}

export function specHaveDifferentParams(spec1: StepDraftResourceSpec, spec2: undefined | StepDraftResourceSpec) {
  if (!spec2) return true;

  return spec1.usageType !== spec2.usageType || spec1.assignmentType !== spec2.assignmentType || resourcesDiffer(spec1.resources, spec2.resources);
}

function resourcesDiffer(resources1: ResourceInfoDto[], resources2: ResourceInfoDto[]) {
  if (!resources1 || !resources2 || resources1.length !== resources2.length) return true;

  for (const resource of resources1) {
    if (resources2.findIndex((x) => x.id === resource.id) === -1) {
      return true;
    }
  }
  return false;
}

export function getDirectiveSpecs(step: StepDraft, directiveResourceType: ResourceType) {
  if (!step.specs) return [];

  return step.specs.filter((x) => isFromTheSameGroup(x.resourceType, directiveResourceType));
}

function identifyStep(productTemplateDraft: ProductTemplateDraft, step: StepDraft) {
  return `${productTemplateDraft.wbs}/${step.position}`;
}

function validateStep(productTemplateDraft: ProductTemplateDraft, step: StepDraft, options: ProcessDraftValidationOptions) {
  return [stepShouldHaveSpecs, stepShouldHaveDefinedType, stepShouldHaveAtMostOneProduce, stepShouldHaveAtMostOneBaseResource].flatMap((x) =>
    x.apply(null, [productTemplateDraft, step, options])
  );
}

function stepShouldHaveSpecs(productTemplateDraft: ProductTemplateDraft, step: StepDraft, options: ProcessDraftValidationOptions): ProcessValidationError[] {
  if (!step.specs || step.specs.length <= 0) {
    return [
      {
        // prettier-ignore
        message: $t("processDrafts-validation-stepNoResources-message", { $: "Step {x} has no resource specifed", x: identifyStep(productTemplateDraft, step) }),
        productTemplate: productTemplateDraft,
        step
      }
    ];
  }
  return step.specs.flatMap((x, pos) => validateSpec(productTemplateDraft, step, x, pos, options));
}

function stepShouldHaveDefinedType(productTemplateDraft: ProductTemplateDraft, step: StepDraft, options: ProcessDraftValidationOptions): ProcessValidationError[] {
  if (step.type === StepType.Undefined) {
    return [
      {
        // prettier-ignore
        message: $t("processDrafts-validation-stepShouldHaveType-message", { $: "Step {x} should have a defined type", x: identifyStep(productTemplateDraft, step) }),
        productTemplate: productTemplateDraft,
        step
      }
    ];
  }
  return [];
}

function stepShouldHaveAtMostOneProduce(productTemplateDraft: ProductTemplateDraft, step: StepDraft, options: ProcessDraftValidationOptions) {
  if (!step.specs) {
    return [];
  }
  const produceSpecs = step.specs.filter((x) => x.usageType === StepResourceUsageType.Produce);
  if (produceSpecs.length > 1) {
    return [
      {
        // prettier-ignore
        message: $t("processDrafts-validation-stepShouldHaveAtMostOneProduce-message", { $: "Step {x} should have at most one produce spec", x: identifyStep(productTemplateDraft, step) }),
        productTemplate: productTemplateDraft,
        step
      }
    ];
  }
  return [];
}

function stepShouldHaveAtMostOneBaseResource(productTemplateDraft: ProductTemplateDraft, step: StepDraft, options: ProcessDraftValidationOptions) {
  if (!step.specs) {
    return [];
  }
  const baseSpecs = step.specs.filter((x) => x.isBase);
  if (baseSpecs.length > 1) {
    return [
      {
        // prettier-ignore
        message: $t("processDrafts-validation-stepShouldHaveAtMostOneBaseResource-message", { $: "Step {x} should have at most one base resource", x: identifyStep(productTemplateDraft, step) }),
        productTemplate: productTemplateDraft,
        step
      }
    ];
  }
  return [];
}

function identifySpec(productTemplateDraft: ProductTemplateDraft, step: StepDraft, specPos: number) {
  return `${productTemplateDraft.wbs}/${step.position}/${specPos + 1}`;
}

function validateSpec(productTemplateDraft: ProductTemplateDraft, step: StepDraft, spec: StepDraftResourceSpec, specPos: number, options: ProcessDraftValidationOptions) {
  return [specResourcesShouldBeDefinedAndMatchType, specResourcesShouldHaveValidNumberOfResources, specProductionResourceMustBeSingle, specPlannabilityValidation].flatMap((x) =>
    x.apply(null, [productTemplateDraft, step, spec, specPos, options])
  );
}

function specResourcesShouldBeDefinedAndMatchType(
  productTemplateDraft: ProductTemplateDraft,
  step: StepDraft,
  spec: StepDraftResourceSpec,
  specPos: number,
  options: ProcessDraftValidationOptions
): ProcessValidationError[] {
  if (spec.assignmentType === StepResourceAssignmentType.ByCapabilities || !spec.resources || spec.resources.length <= 0) return [];

  if (spec.resources.some((x) => !isFromTheSameGroup(x.type, spec.resourceType))) {
    return [
      {
        message: // prettier-ignore
          $t("processDrafts-validation-specHasResourcesNotMatchingDefinedType-message", { $: "Spec {x} has resources not matching specified resource type", x: identifySpec(productTemplateDraft, step, specPos) }),
        productTemplate: productTemplateDraft,
        step,
        spec,
        specPos
      }
    ];
  }

  return [];
}

function specResourcesShouldHaveValidNumberOfResources(
  productTemplateDraft: ProductTemplateDraft,
  step: StepDraft,
  spec: StepDraftResourceSpec,
  specPos: number,
  options: ProcessDraftValidationOptions
): ProcessValidationError[] {
  const nrOfResources = !spec.resources ? 0 : spec.resources.length;

  switch (spec.assignmentType) {
    case StepResourceAssignmentType.Alternatives:
      return nrOfResources > 0
        ? []
        : [
            {
              // prettier-ignore
              message: $t("processDrafts-validation-specHasNoResources-message", { $: "Spec {x} has no resources specified", x: identifySpec(productTemplateDraft, step, specPos) }),
              productTemplate: productTemplateDraft,
              step,
              spec,
              specPos
            }
          ];
    case StepResourceAssignmentType.ByCapabilities:
      return nrOfResources !== 0
        ? [
            {
              // prettier-ignore
              message: $t("processDrafts-validation-specMustHaveNoResources-message", { $: "Spec {x} must have no resources specified", x: identifySpec(productTemplateDraft, step, specPos) }),
              productTemplate: productTemplateDraft,
              step,
              spec,
              specPos
            }
          ]
        : [];
    default:
      return [];
  }
}

function isFromTheSameGroup(type1: ResourceType, type2: ResourceType) {
  if (type1 === type2) return true;

  switch (type1) {
    case ResourceType.Person:
      return type2 == ResourceType.PersonGroup;
    case ResourceType.PersonGroup:
      return type2 == ResourceType.Person;
    case ResourceType.Agreement:
      return type2 == ResourceType.AgreementGroup;
    case ResourceType.AgreementGroup:
      return type2 == ResourceType.Agreement;
    case ResourceType.Equipment:
      return type2 == ResourceType.EquipmentGroup;
    case ResourceType.EquipmentGroup:
      return type2 == ResourceType.Equipment;
  }

  return false;
}

function specProductionResourceMustBeSingle(
  productTemplateDraft: ProductTemplateDraft,
  step: StepDraft,
  spec: StepDraftResourceSpec,
  specPos: number,
  options: ProcessDraftValidationOptions
): ProcessValidationError[] {
  if (spec.usageType !== StepResourceUsageType.Produce) return [];

  if (spec.resources.length !== 1) {
    return [
      {
        message: // prettier-ignore
          $t("processDrafts-validation-specProductionResourceMustBeSingle-message", { $: "Spec {x} must have exactly one production resource", x: identifySpec(productTemplateDraft, step, specPos) }),
        productTemplate: productTemplateDraft,
        step,
        spec,
        specPos
      }
    ];
  }

  return [];
}

function specPlannabilityValidation(
  productTemplateDraft: ProductTemplateDraft,
  step: StepDraft,
  spec: StepDraftResourceSpec,
  specPos: number,
  options: ProcessDraftValidationOptions
): ProcessValidationError[] {
  if (spec.resources.some((x) => !x.plannable) && spec.resources.some((x) => x.plannable)) {
    return [
      {
        message: // prettier-ignore
          $t("processDrafts-validation-specOnlyOneTypeOfPlannable-message", { $: "Spec {x} all resources must be plannable or all must be unplannable", x: identifySpec(productTemplateDraft, step, specPos) }),
        productTemplate: productTemplateDraft,
        step,
        spec,
        specPos
      }
    ];
  }
  return [];
}
